import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Form, Input, Button } from 'antd';
import { CountrySelect } from './country-select';
import { userState } from '../../state/user';
import { useMessage } from '../common';

const { Item } = Form;
const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

export const ProfileForm = ({ auth, callback = () => {} }) => {
  // userId = auth.username (cognito)
  const [user, setUser] = useRecoilState(userState(auth.username));
  const [validCountry, setValidCountry] = useState(user.country);
  const message = useMessage();
  const isSaving = message === 'Saving...';

  const onFinish = (values) => {
    // removed readonly records keys
    const { createdAt, updatedAt, owner, projects, ...rest } = user;
    // createdAt used to determine new user record
    const input = { createdAt, ...rest, ...values };
    setUser(input);
    // used by verify profile
    callback();
  };

  return (
    <Form
      name="profile"
      initialValues={user}
      onFinish={(data) => {
        onFinish({ ...data, country: validCountry });
      }}
      {...layout}
    >
      <Item name="name" label="Name" rules={[{ required: true, message: 'Please enter your name' }]}>
        <Input />
      </Item>

      <Item name="company" label="Company">
        <Input />
      </Item>

      <Item name="phone" label="Phone">
        <Input />
      </Item>

      <Item name="address" label="Address">
        <Input />
      </Item>

      <Item name="city" label="City">
        <Input />
      </Item>

      <Item name="state" label="State/Province">
        <Input />
      </Item>

      <Item label="Country">
        <CountrySelect
          name="country"
          value={user.country}
          onChange={(selected) => {
            setValidCountry(selected);
          }}
        />
      </Item>
      <Item {...tailLayout} style={{ textAlign: 'right' }}>
        <Button type="primary" size="medium" loading={isSaving} htmlType="submit">
          Save
        </Button>
      </Item>
    </Form>
  );
};
