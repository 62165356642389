import { useRecoilValue } from 'recoil';
import { Button } from 'antd';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { extrusionDims, dimensionString } from '../../../utils/extrusion-info';
import { authState, userRoleState } from '../../../state/auth';
import { userState } from '../../../state/user';
import { customersState } from '../../../state/customers';
import XlsxPopulate from 'xlsx-populate';
import { type } from '../../../state/units';
import { autoFlycutApplied } from '../../../utils/line-minimum';

const finishes = {
  NONE: 'None',
  ETCHWASH: 'Etch/Wash - RoHS No finish; Clean part',
  AAVSHIELD3: 'Chromate - RoHS Clear (AavShield3); MIL-DTL-5541 Type II',
  CHROMATE: 'Chromate - Non-RoHS Gold or Clear; MIL-DTL-5541 Type I',
  ANODIZE: 'Anodize - RoHS Black (Class 2); MIL-A-8625 Type II',
  ANODIZECLEAR: 'Anodize - RoHS Clear (Class 1); MIL-A-8625 Type II',
};

const markings = {
  NONE: 'None',
  INK: 'Ink',
  BARCODE: 'Bar code label',
  LASER: 'Laser',
};

const maxColumn = 8;

export const costingExcelBlob = ({ project, userEmail, userName, customerName, userRole }) => {
  const quoteSpecs = project.quoteSpecs;
  const extrusion = project.designs[project.activeDesignId].extrusion;

  const addSpecificationRow = (ws, rowId, label, value) => {
    let range = ws.range(rowId, 1, rowId, maxColumn / 2);
    range.value(label);
    range.merged(true);
    range.style('border', true);
    range.style('verticalAlignment', 'top');
    range.style('horizontalAlignment', 'left');
    range.style('wrapText', true);

    range = ws.range(rowId, maxColumn / 2 + 1, rowId, maxColumn);
    range.value(value);
    range.merged(true);
    range.style('border', true);
    range.style('verticalAlignment', 'top');
    range.style('horizontalAlignment', 'left');
  };

  return XlsxPopulate.fromBlankAsync().then((workbook) => {
    const ws = workbook.sheet(0);
    var rowId = 2;

    var range = ws.range(rowId, 1, rowId, maxColumn);
    range.merged(true);
    range.value('Summary');
    range.style('bold', true);

    const summaryHeaders = [
      'Project name',
      'Quote Number',
      'Date',
      'Quote Entity',
      'Customer',
      'Customer part number',
      'Boyd part number',
      'Costed By',
      'Costed By Email',
    ];

    const summaryValues = [
      project.name,
      quoteSpecs.quoteHeader.quoteNo ? quoteSpecs.quoteHeader.quoteNo : 'NA',
      dayjs(quoteSpecs.quoteHeader.date).format('MM-DD-YYYY'),
      'Laconia',
      customerName,
      quoteSpecs.quoteHeader.customerPartNumber ? quoteSpecs.quoteHeader.customerPartNumber : 'NA',
      project.activeDesignId === 1 ? parseInt(project.designs[1].extrusion.partId) : 'NA',
      userName,
      userEmail,
    ];

    rowId = rowId + 1;

    summaryHeaders.forEach((sh, index) => {
      range = ws.range(rowId, 1, rowId, maxColumn / 2);
      range.value(sh);
      range.merged(true);
      range.style('border', true);

      range = ws.range(rowId, maxColumn / 2 + 1, rowId, maxColumn);
      range.value(summaryValues[index]);
      range.merged(true);
      range.style('border', true);
      range.style('horizontalAlignment', 'left');

      rowId = rowId + 1;
    });

    const quoteValues = project.designs[project.activeDesignId].costEstimates.map((q) => {
      let quote = [
        parseInt(q.quantity),
        parseFloat(q.materialCost.toFixed(2)),
        parseFloat(q.osvCost.toFixed(2)),
        parseFloat(q.laborCost.toFixed(2)),
        parseFloat(q.overheadCost.toFixed(2)),
        parseFloat((q.totalCost + q.osvCost).toFixed(2)),
        parseFloat(q.marginPercentage.toFixed(2)),
        parseFloat(q.sellPrice.toFixed(2)),
      ];

      let conditionCodes = [];
      if (q.lineMinimumImposed === 1) {
        conditionCodes.push('1');
      }
      if (q.materialMinimumImposed === 1) {
        conditionCodes.push('2');
      }
      if (q.sellPrice * q.quantity > 100000.0) {
        conditionCodes.push('3');
      }

      if (conditionCodes.length > 0) {
        quote.push(conditionCodes.join(', '));
      } else {
        quote.push('-');
      }

      if (userRole === type.userRole.test || userRole === type.userRole.admin) {
        quote = quote.concat([
          parseFloat(q.quantityByWt.toFixed(2)),
          parseFloat(q.materialPlusVendorCostPerLb.toFixed(2)),
          q.vendor,
          parseFloat(q.lineMinimumCost.toFixed(2)),
          parseFloat(q.minQuantityByWt.toFixed(2)),
        ]);
      }
      return quote;
    });

    let quoteHeaders = ['Quantity', 'Material', 'OSV', 'Labor', 'Overhead', 'Total', 'GM %', 'SellPrice', 'Condition'];
    if (userRole === type.userRole.test || userRole === type.userRole.admin) {
      quoteHeaders = quoteHeaders.concat(['Qty (Lbs)', '$/Lb', 'Vendor', 'Line Min Cost', 'Material Min Qty (Lbs)']);
    }

    if (quoteValues.length > 0) {
      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      range.value('Quotes');
      range.style('bold', true);

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, quoteHeaders.length);
      range.value([quoteHeaders]);
      range.style('bold', true);
      range.style('border', true);
      range.style('horizontalAlignment', 'center');
      range.style('verticalAlignment', 'center');
      range.style('wrapText', true);

      if (userRole === type.userRole.test || userRole === type.userRole.admin) {
        ws.row(rowId).height(30);
      }

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId + quoteValues.length - 1, quoteHeaders.length);
      range.value(quoteValues);
      range.style('border', true);
      range.style('horizontalAlignment', 'right');

      rowId = rowId + quoteValues.length;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.value('All cost/price values are in US$');
      range.merged(true);

      rowId = rowId + 2;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      range.value('Condition codes:');
      range.style('bold', true);

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      let cell = ws.row(rowId).cell(1);
      cell.value(new XlsxPopulate.RichText());
      cell.value().add('1', { bold: true }).add(' - Line minimum imposed');

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      cell = ws.row(rowId).cell(1);
      cell.value(new XlsxPopulate.RichText());
      cell.value().add('2', { bold: true }).add(' - Material minimum imposed');

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      cell = ws.row(rowId).cell(1);
      cell.value(new XlsxPopulate.RichText());
      cell
        .value()
        .add('3', { bold: true })
        .add(' - High project value - please use Boyd’s white glove service through the factory or NPI team');

      // NRE cost section
      const nreHeaders = ['FAI cost', 'Tooling cost', 'Drawing cost', 'GM %', 'NRE cost'];
      const firstQuote = project.designs[project.activeDesignId].costEstimates[0];
      const nreValues = [
        parseFloat(firstQuote.faiCost.toFixed(2)),
        parseFloat(firstQuote.toolingCost.toFixed(2)),
        parseFloat(firstQuote.drawingCost.toFixed(2)),
        parseFloat(quoteSpecs.nreGMPercentage.toFixed(2)),
        parseFloat(firstQuote.nreCost.toFixed(2)),
      ];

      rowId = rowId + 2;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      range.value('One-time NRE');
      range.style('bold', true);

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, nreHeaders.length);
      range.value([nreHeaders]);
      range.style('bold', true);
      range.style('border', true);
      range.style('horizontalAlignment', 'center');
      range.style('verticalAlignment', 'center');
      range.style('wrapText', true);
      ws.row(rowId).height(30);

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, nreHeaders.length);
      range.value([nreValues]);
      range.style('border', true);
      range.style('horizontalAlignment', 'right');

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.value('All cost values are in US$');
      range.merged(true);
    }

    // Heat sink
    rowId = rowId + 2;
    range = ws.range(rowId, 1, rowId, maxColumn);
    range.merged(true);
    range.value('Heat sink');
    range.style('bold', true);

    rowId = rowId + 1;
    addSpecificationRow(
      ws,
      rowId,
      'Extrusion type',
      { 0: 'Customer', 1: 'Standard', 2: 'Optimal', 3: 'Custom' }[project.activeDesignId],
    );

    if (project.activeDesignId === 1) {
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Part ID', parseInt(extrusion.partId));
    }

    rowId = rowId + 1;
    addSpecificationRow(ws, rowId, 'Dimensions', extrusionDims(project));

    rowId = rowId + 1;
    addSpecificationRow(ws, rowId, 'Non-flatback extrusion', extrusion.nonFlatback ? 'Yes' : 'No');

    if (extrusion.nonFlatback) {
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Weight/length', dimensionString(extrusion.weightPerFeet));
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Perimeter', dimensionString(extrusion.perimeter));
    } else {
      rowId = rowId + 1;
      addSpecificationRow(
        ws,
        rowId,
        'Auto calculate weight per length from profile',
        extrusion.autoCalcWeightPerFeet ? 'Yes' : 'No',
      );
      if (!extrusion.autoCalcWeightPerFeet) {
        rowId = rowId + 1;
        addSpecificationRow(ws, rowId, 'Weight/length', dimensionString(extrusion.weightPerFeet));
      }
      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      range.value('Base Dimensions');
      range.style('bold', true);
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Width', dimensionString(extrusion.baseWidth));
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Length', dimensionString(extrusion.dimensions.length));
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Thickness', dimensionString(extrusion.baseThick));

      rowId = rowId + 1;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      range.value('Fin Dimensions');
      range.style('bold', true);
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Thickness', dimensionString(extrusion.finThick));
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Height', dimensionString(extrusion.finHeight));
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Number of fins', parseInt(extrusion.numFins));
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Pitch', dimensionString(extrusion.finPitch));
    }

    // CNC operations
    let cncHeaders = [
      'Number of views having holes, pockets, or other machined features',
      'Number of holes (all types) in the part',
      'Number of holes that do not break through (Blind holes)',
      'Number of different faces these blind holes are placed on',
      'Number of counterbores on the fin side',
      'Number of fin gaps having hole or counterbore break through',
    ];

    let cncValues = [
      quoteSpecs.cnc.sides,
      quoteSpecs.cnc.holes,
      quoteSpecs.finish.blindHoles,
      quoteSpecs.finish.blindFaces,
      quoteSpecs.counterBores.cbores,
      quoteSpecs.sandGaps,
    ];

    const cncRowHeights = [30, 15, 30, 30, 15, 30, 15, 15];

    if (userRole !== type.userRole.sales) {
      cncHeaders.push('Total volume of pockets', 'Custom CNC time per part (minutes)');
      cncValues.push(
        `${quoteSpecs.cnc.pocketVolume.value} ${quoteSpecs.cnc.pocketVolume.units}`,
        quoteSpecs.counterBores.addTime,
      );
    }

    rowId = rowId + 2;
    range = ws.range(rowId, 1, rowId, maxColumn);
    range.merged(true);
    range.value('CNC Operations');
    range.style('bold', true);

    cncHeaders.forEach((ch, index) => {
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, ch, cncValues[index]);
      ws.row(rowId).height(cncRowHeights[index]);
    });

    // Fin machining
    if (userRole !== type.userRole.sales) {
      rowId = rowId + 2;
      range = ws.range(rowId, 1, rowId, maxColumn);
      range.merged(true);
      range.value('Fin machining');
      range.style('bold', true);

      rowId = rowId + 1;
      addSpecificationRow(
        ws,
        rowId,
        'Total length of fins removed',
        `${quoteSpecs.finMachining.length.value} ${quoteSpecs.finMachining.length.units}`,
      );

      rowId = rowId + 1;
      addSpecificationRow(
        ws,
        rowId,
        'Average depth of fins removed',
        `${quoteSpecs.finMachining.depth.value} ${quoteSpecs.finMachining.depth.units}`,
      );
    }

    // Fly cut
    let flycutHeaders = [
      'Does the print call out a specific flatness requirement on the heat sink base?',
      'Does the print call out a surface roughness less than < Ra 63µinch or 1.6µm or N7 on the heat sink base?',
      'Part requires a fly cut (custom requirement, to reduce the base thickness etc.)?',
      'Auto flycut applied? \n Auto flycut will be applied if heat sink width is greater than or equal to 6" and there are machined pockets or blind holes.'
    ];

    let flycutValues = [
      quoteSpecs.cnc.flycutFlatnessCallout ? 'Yes' : 'No',
      quoteSpecs.cnc.flycutRoughnessCallout ? 'Yes' : 'No',
      quoteSpecs.cnc.flycut ? 'Yes' : 'No',
      autoFlycutApplied(project) ? 'Yes' : 'No',
    ];

    if (userRole !== type.userRole.sales) {
      flycutHeaders.push('Require custom flycut area?');
      flycutValues.push(quoteSpecs.cnc.customFlycutArea ? 'Yes' : 'No');
      if (quoteSpecs.cnc.customFlycutArea) {
        flycutHeaders.push('Flycut width', 'Flycut length');
        flycutValues.push(`${quoteSpecs.cnc.customFlycutWidth.value} ${quoteSpecs.cnc.customFlycutWidth.units}`);
        flycutValues.push(`${quoteSpecs.cnc.customFlycutDepth.value} ${quoteSpecs.cnc.customFlycutDepth.units}`);
      }
    }

    const flycutRowHeights = [30, 30, 30, 60, 15, 15, 15];

    rowId = rowId + 2;
    range = ws.range(rowId, 1, rowId, maxColumn);
    range.merged(true);
    range.value('Flycut');
    range.style('bold', true);

    flycutHeaders.forEach((fh, index) => {
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, fh, flycutValues[index]);
      ws.row(rowId).height(flycutRowHeights[index]);
    });

    // Finish & Hradware section
    rowId = rowId + 2;
    range = ws.range(rowId, 1, rowId, maxColumn);
    range.merged(true);
    range.value('Finish & Hardware');
    range.style('bold', true);

    rowId = rowId + 1;
    addSpecificationRow(ws, rowId, 'Finish', finishes[quoteSpecs.finish.style], 30);

    rowId = rowId + 1;
    addSpecificationRow(ws, rowId, 'Marking', markings[quoteSpecs.marking], 15);

    if (userRole !== type.userRole.sales) {
      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Number of inserts in the part', quoteSpecs.inserts.insertQty, 15);

      rowId = rowId + 1;
      addSpecificationRow(ws, rowId, 'Average cost of each insert (in US$)', quoteSpecs.inserts.insertCost, 15);
    }

    for (let i = 1; i < quoteHeaders.length + 1; i++) {
      ws.column(i).width(12);
    }

    return workbook.outputAsync(workbook);
  });
};

export const GenerateCostingXLSX = ({ project }) => {
  const auth = useRecoilValue(authState);
  const user = useRecoilValue(userState(auth.username));
  const customers = useRecoilValue(customersState(null));
  const customer = customers.filter(({ id }) => id === project.customerId);
  const customerName = customer.length === 1 ? customer[0].name : 'NA';
  const userEmail = auth.email;
  const userName = user.name;
  const userRole = useRecoilValue(userRoleState);

  // Get user name from User email
  // const emailPrefix = userEmail.split('@')[0].split('.').map(n => n ? n[0].toUpperCase() + n.toLowerCase().slice(1): '');
  // const userName = emailPrefix.join(' ');
  const handleOnClick = () =>
    costingExcelBlob({ project, userEmail, userName, customerName, userRole }).then((blob) => {
      FileSaver.saveAs(blob, `costing.xlsx`);
    });

  return (
    <Button type="secondary" onClick={handleOnClick}>
      Internal Cost Sheet XLSX
    </Button>
  );
};
