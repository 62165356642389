import { useState } from 'react'; //, useRef
import Draggable from 'react-draggable';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { HeatSourcePopOver } from './heat-source-popover';
import { convertToMillimeters } from '../../../../utils/unit-conversion';
//import { nanoid } from 'nanoid';

export const HeatSource = ({
  index,
  project,
  updateProject,
  heatSource,
  size,
  position,
  setPosition,
  selected,
  setSelectedIndex,
  maxWidth,
  maxLength,
}) => {
  const { label = index, power } = heatSource;
  const [showPopOver, setShowPopOver] = useState(false);

  //const id = useRef(`hs-${nanoid(5)}`).current;

  //console.log(id);

  // valid position based on container
  const hsWidth = convertToMillimeters(heatSource.size.width);
  const width = convertToMillimeters(maxWidth);
  const posX = convertToMillimeters(heatSource.position.x);
  const maxX = width - hsWidth;
  const maxPositionX = { type: 'DIMENSION', value: maxX > 0 ? maxX : 0, units: 'mm' };

  const hsHeight = convertToMillimeters(heatSource.size.height);
  const height = convertToMillimeters(maxLength);
  const posY = convertToMillimeters(heatSource.position.y);
  const maxY = height - hsHeight;
  const maxPositionY = { type: 'DIMENSION', value: maxY > 0 ? maxY : 0, units: 'mm' };

  const hasError = posY > maxY || posX > maxX || heatSource.power.value <= 0;

  return (
    <Draggable
      bounds="parent"
      position={position}
      disabled={showPopOver}
      onMouseDown={() => !showPopOver && !selected && setSelectedIndex(index)}
      onStop={(_, { x, y }) => {
        if (position.x !== x || position.y !== y) {
          setPosition({ index, label, position: { x: Math.round(Math.max(x, 0)), y: Math.round(Math.max(y, 0)) } });
        }
      }}
    >
      <div
        className={clsx('heat-source', { selected: selected }, { error: hasError })}
        style={{ width: size.width, height: size.height }}
        onClick={(event) => event.stopPropagation()}
      >
        <div className="popover-wrapper">
          <Popover
            trigger="click"
            placement="right"
            title={`Heat Source: ${label}`}
            visible={showPopOver}
            onVisibleChange={setShowPopOver}
            content={
              <HeatSourcePopOver
                index={index}
                heatSource={heatSource}
                project={project}
                updateProject={updateProject}
                maxWidth={maxWidth}
                maxLength={maxLength}
                maxPositionX={maxPositionX}
                maxPositionY={maxPositionY}
              />
            }
          >
            <EditOutlined />
          </Popover>
        </div>
        <div className="label">{label}</div>
        <div className="power">{power.value}W</div>
        <div className="heatsource-origin-marker" />
      </div>
    </Draggable>
  );
};
