import { atom, atomFamily, DefaultValue } from 'recoil';
import { dimension, volume, mass, type } from './units';
import { API } from '@aws-amplify/api';
import { getLibraryFactory } from '../graphql/queries';
import { updateLibraryFactory } from '../graphql/mutations';
import { setAutoSaveMessage } from '../utils/set-autosave-message';

const QuoteHeader = {
  quoteNo: '',
  date: new Date().toISOString(), // ISO 8601 format
  quoteEntity: 'Laconia',
  customerPartNumber: '',
  revision: '',
  aavidPartNumber: '',
  costedBy: '',
  quantity: '500',
  customerContact: '',
  customerAddress: '',
  customerEmail: '',
  customerPhone: '',
  useUserContactAsSales: true,
  salesContact: '',
  salesEmail: '',
  salesPhone: '',
};

const CNCSpecifications = {
  sides: 0,
  holes: 0,
  flycut: false,
  flycutFlatnessCallout: false,
  flycutRoughnessCallout: false,
  customFlycutArea: false,
  customFlycutWidth: { ...dimension, value: 0.0, units: 'mm' },
  customFlycutDepth: { ...dimension, value: 0.0, units: 'mm' },
  pocketVolume: { ...volume, value: 0.0, units: 'mm3' },
};

const CounterBoresSpecifications = {
  cbores: 0,
  finCheck: false,
  addTime: 0.0,
};

const FinishSpecifications = {
  style: type.finishStyle.etchwash,
  blindHoles: 0,
  blindFaces: 0,
  cosmetic: false,
  nonStandardFinish: false,
};

const FinMachiningSpecifications = {
  length: { ...dimension, value: 0.0, units: 'mm' },
  depth: { ...dimension, value: 0.0, units: 'mm' },
};

const InsertSpecifications = {
  insertQty: 0,
  insertCost: 0.0,
};

const initialInventoryCost = {
  cost: 3.0,  // To be do: -  Get value from backend API using current date when API available.
  minQtyWeight: { ...mass, value: 500.0, units: 'lb'},
  override: false,
  toolingOverride: false,
  toolingOverrideCost: 0.0,
  factory: 'laconia',
}

export const initialQuoteSpecifications = {
  quoteHeader: QuoteHeader,
  cutLength: { ...dimension, value: 3.0, units: 'in' },
  stackable: false,
  inventoryStock: dimension,
  inventoryCosts: [initialInventoryCost],
  cnc: CNCSpecifications,
  counterBores: CounterBoresSpecifications,
  sandGaps: 0,
  finish: FinishSpecifications,
  finMachining: FinMachiningSpecifications,
  inserts: InsertSpecifications,
  marking: type.markStyle.none,
  margin: 50.0,
  surchargePercentage: 15.0,
  nreGMPercentage: 20.0,
  itarCompliance: false,
  highProjectValue: false,
  rawExtrusionBar: false,
  visitedCostingTabs: [false, false, false, false],
  cuttingOption: type.cuttingType.full,
  priceType: type.priceType.lca1,
};

export const quoteSpecificationsState = atom({
  key: 'quoteSpecificationState',
  default: initialQuoteSpecifications,
});

const getFactory = async ({ label }) => {
  try {
    const response = await API.graphql({
      query: getLibraryFactory,
      variables: { label },
    });
    return response.data.getLibraryFactory;
  } catch (error) {
    console.error(error);
    throw new Error('Error getting factory');
  }
};

export const saveFactory = async ({input}) => {
  try { 
    const response = await API.graphql({
      query: updateLibraryFactory,
      variables: { input },
    });
    return response.data.updateLibraryFactory;
  } catch (error) {
    console.error(error);
  }
};

export const factoryState = atomFamily({
  key: 'factoryState',
  default: 0.0,
  effects: () => [
    ({ setSelf, onSet }) => {
      const projectPromise = getFactory({label: 'Laconia'})
        .then((factory) =>factory)
        .catch((error) => {
          console.error('Recoil factory state', error);
          return new DefaultValue();
        });

      setSelf(projectPromise);

      onSet((factory) => {
        const { createdAt, updatedAt, ...input } = factory;
        setAutoSaveMessage({ message: 'Saving...' });
        saveFactory({ input })
          .then((response) => {
            setAutoSaveMessage();
          })
          .catch(() => setAutoSaveMessage({ message: 'Error' }));
      });
    },
  ],
});
