import { API } from '@aws-amplify/api';
import { estimatorGetRequest } from '../../../graphql/queries';
import { ENDPOINTS } from '../../../utils/config-api';
import { message } from 'antd';

const { SIMULATIONAPI } = ENDPOINTS.PROD;

export const startSimulation = async ({ projectId }) => {
  try {
    const response = await API.graphql({
      query: estimatorGetRequest,
      variables: { url: `${SIMULATIONAPI}/simulation/${projectId}` },
    });
    //console.log(response);
    return JSON.parse(response.data.estimatorGetRequest);
  } catch (error) {
    console.error('getEstimatorData', error);
    message.error(error.errors[0].message);
  }
};
