import { atomFamily, DefaultValue } from 'recoil';
import { API } from '@aws-amplify/api';
import { getCustomer } from '../graphql/queries';
import { createCustomer, updateCustomer } from '../graphql/mutations';
import { setAutoSaveMessage } from '../utils/set-autosave-message';

const getSelectedCustomer = async ({ id }) => {
  try {
    const response = await API.graphql({
      query: getCustomer,
      variables: { id },
    });
    return response.data.getCustomer;
  } catch (error) {
    console.error(error);
    throw new Error('Error loading project');
  }
};

export const createUpdateCustomer = async ({ customerId, input }) => {
  try {
    setAutoSaveMessage({ message: 'Saving...' });
    const isNew = customerId === undefined;
    const response = await API.graphql({
      query: isNew ? createCustomer : updateCustomer,
      variables: { input },
    });
    setAutoSaveMessage();
    return isNew ? response.data.createCustomer : response.data.updateCustomer;
  } catch (error) {
    setAutoSaveMessage({ messsage: 'Error' });
    console.error(error);
  }
};

// customer id = undefined for NEW customer
export const initialCustomerState = {
  type: 'CUSTOMER',
  name: '',
};

export const customerState = atomFamily({
  key: 'customerState',
  default: initialCustomerState,
  effects: (customerId) => [
    ({ setSelf, onSet }) => {
      const customerPromise =
        customerId === undefined
          ? new DefaultValue()
          : getSelectedCustomer({ id: customerId })
              .then((customer) => customer)
              .catch((error) => {
                console.error('Recoil customer state', error);
                return new DefaultValue();
              });

      setSelf(customerPromise);

      onSet((customer) => {
        const { createdAt, updatedAt, owner, projects, ...input } = customer;
        createUpdateCustomer({ customerId, input }).then((result) => {
          console.log('customer', result);
        });
      });
    },
  ],
});
