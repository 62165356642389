import { useState } from 'react';
import { Drawer, Typography, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Link } = Typography;

export const Help = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <>
      <div style={{ position: 'fixed', top: '16px', right: '24px' }} onClick={() => setShowDrawer(true)}>
        <Button icon={<QuestionCircleOutlined title="Help"/>}/>
      </div>
      <Drawer
        title="Help"
        placement="right"
        closable={true}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        width={520}
      >
        <div className="help-content">
          <h2>Menu</h2>

          <h3>Projects</h3>
          <p>List all projects your assigned role has permissions to access.</p>

          <h3>Customers</h3>
          <p>List all customers available to your role.</p>

          <h3>Training Video Links</h3>
          <li><Link href='https://boydcorp-my.sharepoint.com/:v:/p/brad_whitney/Edn8A1AbVd9KiTIH7EThkoMBfLXwBsAHofsPeG7ENJRo5g' target="_blank" style={{fontWeight: 500}}>{'Boyd Genie – Intro & Quick Start Training Video'}</Link></li>
          <li><Link href='https://boydcorp-my.sharepoint.com/:v:/p/brad_whitney/EcYqLMutE09Jt1ewxemqdiEBs7awZidMyXLISLd_L5H5IQ?e=ka1OLt' target="_blank" style={{fontWeight: 500}}>{'Boyd Genie – Deep Dive Training Video'}</Link></li>
        </div>
      </Drawer>
    </>
  );
};
