import { useState, useEffect } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import { Form, Card, InputNumber, Table, Switch, Collapse, Space, Input, Button, notification, Popover} from 'antd';
import { extrusionInfo } from '../../../utils/extrusion-info';
import { CaretRightOutlined, EditOutlined, QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { GenerateCostingPDF } from './project-costing-pdf';
import { GenerateCustomerQuotePDF } from './project-customer-costing-pdf';
import './project-costing.less';
import { lineMinimum, minGrossMargin } from '../../../utils/line-minimum';
import { mass, type } from '../../../state/units';
import { useRecoilValue } from 'recoil';
import { extendedPriceHelp, highProjectValueHelp, laconiaMaterialCostHelp, nreCostHelp, toolingCostHelp, visitCostingTabsHelp } from '../../common/tooltip';
import { dirtyCostEstimate } from '../../../utils/dirty-cost-estimate';
import { HelpCard, Measurement } from '../../common';
import { userRoleState } from '../../../state/auth';
import { GenerateCostingXLSX } from './project-costing-xlsx';
import { stepsTitles } from './project-costing';

const { Item } = Form;
const { Panel } = Collapse;

const panelHeaderFooterHeight = '250px';
const cardHeaderHeight = '64px';

const columns = [
  {
    key: 'quantity',
    title: 'Quantity',
    dataIndex: 'quantity',
    width: 80,
    align: 'right',
    fixed: 'left',
    sorter: (a, b) => parseInt(a.quantity) - parseInt(b.quantity),
    defaultSortOrder: 'ascend',
  },
  {
    key: 'materialCost',
    title: 'Material',
    width: 80,
    dataIndex: 'materialCost',
    align: 'right',
    render: (c) => c.toFixed(2),
  },
  {
    key: 'osv-cost',
    title: 'OSV',
    width: 80,
    dataIndex: 'osvCost',
    align: 'right',
    editable: true,
    render: (c) => c.toFixed(2),
  },
  {
    key: 'labor-cost',
    title: 'Labor',
    width: 80,
    dataIndex: 'laborCost',
    align: 'right',
    render: (c) => c.toFixed(2),
  },
  {
    key: 'overhead-cost',
    title: 'Overhead',
    width: 80,
    dataIndex: 'overheadCost',
    align: 'right',
    render: (c) => c.toFixed(2),
  },
  {
    key: 'total-cost',
    title: 'Total',
    width: 80,
    dataIndex: 'totalCost',
    align: 'right',
    render: (c, record) => {
      if ((record.sellPrice) * record.quantity > 100000.0) {
        return (
          <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center' }}>
            <Popover placement="right" content={highProjectValueHelp}>
              <WarningOutlined style={{color: 'red'}} />
            </Popover>
            <div />
            <label>{c.toFixed(2)}</label>
          </div>
        )
      } else {
        return c.toFixed(2)
      }
    },
  },
  {
    key: 'margin-percentage',
    title: 'GM %',
    width: 80,
    dataIndex: 'marginPercentage',
    align: 'left',
    editable: true,
    render: (c) => c.toFixed(2),
  },
  {
    key: 'sell-price',
    title: 'Sell Price',
    width: 80,
    dataIndex: 'sellPrice',
    align: 'right',
    //fixed: 'right',
    render: (c, record) => {
      if (record.isLowerExtendedPrice) {
        return (
          <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center' }}>
            <Popover placement="right" content={extendedPriceHelp}>
              <WarningOutlined style={{color: 'red'}} />
            </Popover>
            <div />
            <label>{c.toFixed(2)}</label>
          </div>
        )
      } else {
        return c.toFixed(2)
      }
    }
  },
  // {
  //   key: 'surcharge-cost',
  //   title: 'Surcharge',
  //   width: 80,
  //   dataIndex: 'surcharge',
  //   align: 'right',
  //   render: (c) => c.toFixed(2),
  // },
  // {
  //   key: 'freight-cost',
  //   title: 'Freight',
  //   width: 80,
  //   dataIndex: 'freightCost',
  //   align: 'right',
  //   editable: true,
  //   render: (c) => c.toFixed(2),
  // },
  {
    key: 'special-condition',
    title: 'Condition',
    width: 80,
    dataIndex: 'specialCondition',
    align: 'center',
    render: (c, record) => { 
      let conditionCodes = [];
      if (record.lineMinimumImposed === 1) { 
        conditionCodes.push(1);
      }
      if (record.materialMinimumImposed === 1) {
        conditionCodes.push(2);
      }
      if (record.sellPrice * record.quantity > 100000.0) {
        conditionCodes.push(3);
      }
      if (conditionCodes.length > 0) {
        return conditionCodes.join(', ');
      } else {
        return '-';
      }  
    }
  },
];

const testerColumns = [
  {
    key: 'qty-lbs',
    title: 'Qty (Lbs)',
    width: 80,
    dataIndex: 'quantityByWt',
    align: 'right',
  },
  {
    key: 'total-by-qty-lbs',
    title: '$/Lb',
    width: 80,
    dataIndex: 'materialPlusVendorCostPerLb',
    align: 'right',
    render: (c, record) =>record.materialPlusVendorCostPerLb.toFixed(2),
  },
  {
    key: 'vendor',
    title: 'Vendor',
    width: 80,
    dataIndex: 'vendor',
    align: 'center',
  },
  {
    key: 'min-line-cost',
    title: 'Line Min Cost',
    width: 80,
    dataIndex: 'lineMinimumCost',
    align: 'right',
    render: (c) => c.toFixed(2),
  },
  {
    key: 'min-material-cost',
    title: 'Material Min Qty (Lbs)',
    width: 80,
    dataIndex: 'minQuantityByWt',
    align: 'right',
    render: (c) => c.toFixed(2),
  },
];

const grossMarginWarning = (minGM, maxGM) => (
  <>
    <Space direction="vertical" size={32}>
      <label>{`Gross margin (GM) % value must be greater than or equal to ${minGM.toFixed(2)} and less than ${maxGM.toFixed(2)}`}</label>
      <table className="gross-margin-table">
        <tr>
          <th>Sr. No.</th>
          <th>Design</th> 
          <th>Min GM %</th>
        </tr>
        <tr>
          <td>1</td>
          <td>Standard extrusion (A and C classes) without cnc operation(s) or flycut.</td>
          <td>50.0</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Other designs</td>
          <td>40.0</td>
        </tr>
      </table>
    </Space>
  </>
)

export const ProjectEstimateGrid = ({ project, onChange, updateProject, saveCosting, loading }) => {
  // const [showFreight, setShowFreight] = useState(false);
  const userRole = useRecoilValue(userRoleState);
  const [data, setData] = useState(project.designs[project.activeDesignId].costEstimates);
  const [editableColRowId, setEditableColRowId] = useState(-1);
  const [editableColKey, setEditableColKey] = useState('');
  const [editableCellValue, setEditableCellValue] = useState(0.0);
  // var filteredColumns = columns.filter((column) => (showFreight ? column : column.key !== 'freight-cost'));
  var filteredColumns = columns.filter((column) => (userRole !== type.userRole.sales ? column : column.key !== 'osv-cost'));
  if (userRole === type.userRole.test) {
    filteredColumns = filteredColumns.concat(testerColumns);
  }
  const maxMargin = 100.0;
  const minMargin = minGrossMargin(project);
  // required to ensure no lagging comes in the table update when any osv cost cell updated
  useEffect(() => {
    setData(_cloneDeep(project.designs[project.activeDesignId].costEstimates));
  }, [project]);

  const updateCostEstimate = (record, value) => {
    
    const colKey = editableColKey;
    setEditableColRowId(-1);
    setEditableColKey('');
    const newValue = parseFloat(value);
    if (!isNaN(newValue)) {
      const designId = project.activeDesignId;
      var estimates = [];
      switch (colKey) {
        case 'margin-percentage':
          estimates = project.designs[designId].costEstimates.map((quote) => {
            if (quote.quantity === record.quantity) {
              if (newValue < minMargin || newValue >= maxMargin ){
                notification.error({ 
                  className:"error-notification-box",
                  description: grossMarginWarning(minMargin, maxMargin),
                  placement: 'topLeft',
                  top: '50%', 
                  duration: null,
                });  
              }
              let marginPercentage = newValue < minMargin ? minMargin : newValue;
              marginPercentage = newValue >= maxMargin ? maxMargin - 0.1 : marginPercentage;
              const totalCost = quote.totalCost;
              let sellPrice = parseFloat((totalCost / (1 - marginPercentage / 100)).toFixed(3));
              const lineMinimumCost = lineMinimum(project) / quote.quantity;
              const lineMinimumImposed = lineMinimumCost > sellPrice ? 1 : 0
              if (lineMinimumImposed=== 1) {
                sellPrice = parseFloat(lineMinimumCost.toFixed(3))
              }
              const surcharge = parseFloat((sellPrice * project.quoteSpecs.surchargePercentage) / 100.0);
              return { ...quote, marginPercentage, sellPrice, surcharge, lineMinimumImposed, lineMinimumCost};
            } else {
              return quote;
            }
          });
          // setData(estimates);
          // updateProject([{ path: `designs.${designId}.costEstimates`, value: estimates }]);
          break;

        case 'osv-cost':
          estimates = project.designs[designId].costEstimates.map((quote) => {
            if (quote.quantity === record.quantity) { 
              const osvCost = newValue
              const totalCost = quote.materialCost + quote.laborCost + quote.overheadCost + osvCost;
              let sellPrice = parseFloat((totalCost / (1 - quote.marginPercentage / 100)).toFixed(3));
              const lineMinimumCost = lineMinimum(project) / quote.quantity;
              const lineMinimumImposed = lineMinimumCost > sellPrice ? 1 : 0
              if (lineMinimumImposed=== 1) {
                sellPrice = parseFloat(lineMinimumCost.toFixed(3))
              }
              const surcharge = parseFloat((sellPrice * project.quoteSpecs.surchargePercentage) / 100.0);
              return {...quote, totalCost, osvCost, sellPrice, surcharge, lineMinimumImposed, lineMinimumCost };
            } else {
              return quote;
            }
          });
          // setData(estimates);
          // updateProject([{ path: `designs.${designId}.costEstimates`, value: estimates }]);
          break;
        case 'freight-cost':
          estimates = project.designs[designId].costEstimates.map((quote) =>
            quote.quantity === record.quantity ? { ...quote, freightCost: newValue } : quote,
          );
          // setData(estimates);
          // updateProject([{ path: `designs.${designId}.costEstimates`, value: estimates }]);
          break;
        default:
      }
      // check extended price
      let previousExtendedPrice = 0.0;
      estimates = estimates.map((quote) => {
        let isLowerExtendedPrice = false;
        if (quote.sellPrice * quote.quantity < previousExtendedPrice) {
          isLowerExtendedPrice = true;
        } else {
          previousExtendedPrice = quote.sellPrice * quote.quantity;
        }
        return { ...quote, isLowerExtendedPrice: isLowerExtendedPrice};
      });
      setData(estimates);
      updateProject([{ path: `designs.${designId}.costEstimates`, value: estimates }]);
    }
  };

  // const onSurchargePercentageChange = (surchargePercentage) => {
  //   const newEstimates = project.designs[project.activeDesignId].costEstimates.map((quote) => ({
  //     ...quote,
  //     surcharge: parseFloat(((surchargePercentage / 100.0) * quote.sellPrice).toFixed(3)),
  //   }));

  //   setData(newEstimates);
  //   updateProject([
  //     { path: `designs.${project.activeDesignId}.costEstimates`, value: newEstimates },
  //     { path: 'quoteSpecs.surchargePercentage', value: surchargePercentage },
  //   ]);
  // };

  const tableColumns = filteredColumns.map((column) =>
    !column.editable
      ? column
      : {
          ...column,
          onCell: (record, index) => {
            return {
              onClick: (event) => {
                setEditableCellValue(record[column.dataIndex].toFixed(2));
                setEditableColRowId(index);
                setEditableColKey(column.key);
              }, // click cell
            };
          },
          render: (c, record, index) => {
            if (editableColRowId === index && editableColKey === column.key) {
              return (
                <Input
                  value={editableCellValue}
                  autoFocus
                  controls={false}
                  size="small"
                  onChange={(e) => setEditableCellValue(e.target.value)}
                  onPressEnter={(e) => updateCostEstimate(record, e.target.value)}
                  onBlur={(e) => updateCostEstimate(record, e.target.value)}
                />
              );
            } else {
              if (column.key === 'margin-percentage' && c < minMargin) {
                return (
                  <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr 1fr auto', alignItems: 'center' }}>
                    <EditOutlined />
                    <Popover placement="right" content={<HelpCard title="Gross Margin (GM) %" width={600}>{grossMarginWarning(minMargin, maxMargin)}</HelpCard>}>
                      <WarningOutlined style={{color: 'red'}} />
                    </Popover>
                    <div />
                    <label>{c.toFixed(2)}</label>
                  </div>
                )
              } else {
                return (
                  <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center' }}>
                    <EditOutlined />
                    <div />
                    <label>{c.toFixed(2)}</label>
                  </div>
                );
              }
            }
          },
        },
  );

  const onItemChange = (value) => {
    const updatedItems = [{ path: value[0].name[0], value: value[0].value }];
      // Make cost estimates isdirty value true for all designs since change in the override material input.
    for (let did = 0; did < 4; did++) {
        if (project.designs[did].costEstimates) {
          updatedItems.push({path: `designs.${did}.costEstimates`, value: dirtyCostEstimate(project, did)});
        }  
      }   
      updateProject(updatedItems);
  };

  const onNREGMPercentageChange = (value) => {
    if (value.length > 0) {
      const updatedItems = [{ path: value[0].name[0], value: value[0].value }];
      const newNREGMPercentage = value[0].value;
      if (newNREGMPercentage !== null) {
        for (let did = 0; did < 4; did++) {
          if (project.designs[did].costEstimates) {
            const  newEstimates = project.designs[did].costEstimates.map((quote) => {
              const  newNRECost = parseFloat((quote.faiCost + (quote.toolingCost + quote.drawingCost) / (1 - newNREGMPercentage / 100.0)).toFixed(3));
              return { ...quote, nreCost: newNRECost}
            })
            if (did === project.activeDesignId) {
              setData(newEstimates);
            }
            updatedItems.push({path: `designs.${did}.costEstimates`, value: newEstimates});
          }  
        }   
        updateProject(updatedItems);
      }
    }
  };

  const isDirtyEstimate = project.designs[project.activeDesignId].costEstimates.length > 0 && project.designs[project.activeDesignId].costEstimates[0].isDirty;
  const allTabsVisited = project.quoteSpecs.visitedCostingTabs.every(vct => vct);
  var notVisitedTabsTitles = [];
  project.quoteSpecs.visitedCostingTabs.forEach((vct, index) => {
    if (!vct) {
      notVisitedTabsTitles.push(stepsTitles[index]);
    }   
  });
  return (
    
    <Card
      title={
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center' }}>
          <Space>
            <div>Cost Estimate</div>
            <Button disabled={!allTabsVisited} type="primary" loading={loading} onClick={saveCosting} style={ isDirtyEstimate? {backgroundColor: 'red', borderColor: 'red'}: {}}>
              { isDirtyEstimate ? 'Reupdate' : 'Update'}
            </Button>
            <Popover placement="right" content={visitCostingTabsHelp(notVisitedTabsTitles)}>
              <WarningOutlined style={{color: 'red'}} hidden={allTabsVisited}/>
            </Popover>
          </Space>
          <div />
          <div style={{ fontWeight: 'normal', fontSize: '14px' }}>{extrusionInfo(project)}</div>
        </div>
      }

      bodyStyle={{ overflow: 'auto', height: `calc(100vh - ${cardHeaderHeight} - ${panelHeaderFooterHeight})` }}
      bordered={true}
    >
      <Collapse
        defaultActiveKey={['laconia']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        <Panel header={<b>Laconia, USA</b>} key="laconia">
          <Form name="material cost" onFieldsChange={onItemChange}>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center' }}>
              <Space size={32}>
                <Item
                  name="quoteSpecs.inventoryCosts[0].override"
                  label="Override material"
                  initialValue={project.quoteSpecs.inventoryCosts[0].override}
                  valuePropName="checked"
                >
                  <Switch />
                </Item>
              
              {/*<Item label="Show Freight">
                  <Switch onChange={setShowFreight} />
              </Item>*/}
              
                <Item label="Material cost ($/Lb)" 
                  hidden={!project.quoteSpecs.inventoryCosts[0].override} 
                  style={{ marginBottom: 0 }}
                >
                  <Space>
                    <Item
                      name="quoteSpecs.inventoryCosts[0].cost"
                      initialValue={project.quoteSpecs.inventoryCosts[0].cost}  
                    >
                      <InputNumber style={{ width: 80 }} min={0.0} precision={2} step={0.1} />
                    </Item>
                    <Item>
                      <Popover placement="right" content={laconiaMaterialCostHelp}>
                        <QuestionCircleOutlined/>
                      </Popover>
                    </Item>
                  </Space>
                </Item>
              
                <Item
                  name="quoteSpecs.inventoryCosts[0].minQtyWeight"
                  label="Min Qty weight"
                  initialValue={project.quoteSpecs.inventoryCosts[0].minQtyWeight}
                  hidden={!project.quoteSpecs.inventoryCosts[0].override}  
                  style={{padding: 0}}
                >
                  <Measurement.Mass  minimum={mass}/>
                </Item>
              </Space>

              {/*<Item
                name="quoteSpecs.surchargePercentage"
                label="Surcharge %"
                initialValue={project.quoteSpecs.surchargePercentage}
              >
                <InputNumber style={{ width: 80 }} min={0} onChange={onSurchargePercentageChange} />
              </Item> */}     
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center' }}>
              <Space size={32}>
                <Item
                  name="quoteSpecs.inventoryCosts[0].toolingOverride"
                  label="Override NRE tooling cost"
                  initialValue={project.quoteSpecs.inventoryCosts[0].toolingOverride}
                  valuePropName="checked"
                >
                  <Switch />
                </Item>
                <Item label="Extrusion die cost ($)" 
                  hidden={!project.quoteSpecs.inventoryCosts[0].toolingOverride} 
                  style={{ marginBottom: 0 }}
                >
                  <Space>
                    <Item
                      name="quoteSpecs.inventoryCosts[0].toolingOverrideCost"
                      initialValue={project.quoteSpecs.inventoryCosts[0].toolingOverrideCost}  
                    >
                      <InputNumber style={{ width: 80 }} min={0.0} precision={2} step={0.1} />
                    </Item>
                    <Item>
                      <Popover placement="right" content={toolingCostHelp}>
                        <QuestionCircleOutlined/>
                      </Popover>
                    </Item>
                  </Space>
                </Item>
              </Space>
            </div>

          </Form>
          <Table
            className="costing-table"
            size="small"
            columns={tableColumns}
            dataSource={data}
            scroll={{ x: 'max-content' }}
            pagination={false}
            bordered
            rowKey={(record) => `costing-estimates-${record.quantity}`}
          />
          
          { data.length > 0 && (
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto' }}> 
            <div>
              <Space size={32}>
                <p style={{ marginTop: '16px' }}><b>One-time NRE <Popover placement="right" content={nreCostHelp}><QuestionCircleOutlined/></Popover> :</b> &nbsp; ${data[0].nreCost.toFixed(2)}</p> 
                <Form name="nre-gm-percentage" onFieldsChange={onNREGMPercentageChange}>
                  <Item
                    name="quoteSpecs.nreGMPercentage"
                    label="NRE - GM %"
                    initialValue={project.quoteSpecs.nreGMPercentage}
                    style ={{marginBottom: 0.0}}
                    >
                      <InputNumber style={{ width: 80 }} min={0.0} max={99.9} step={0.1}  />
                    </Item>
                </Form>
              </Space>  
              <p style={{ marginTop: '16px', marginBottom: '0px' }}><b>Condition codes:</b></p>
              <p style={{ marginLeft: '16px', marginBottom: '0px' }}><b>1</b> - Line minimum imposed</p>
              <p style={{ marginLeft: '16px', marginBottom: '0px' }}><b>2</b> - Material minimum imposed</p>
              <p style={{ marginLeft: '16px' }}><b>3</b> - High project value - please use Boyd’s white glove service through the factory or NPI team</p>
            </div>
            <div/>
            <section className="table-footer">
              <p>All cost/price values are in US$</p>
            </section>
            </div>
          )}
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr auto 1fr auto 1fr', alignItems: 'center' }}>
            <div/>
            <GenerateCostingPDF project={project} />
            <div/>
            <GenerateCostingXLSX project={project} />
            <div/>
            <GenerateCustomerQuotePDF project={project} updateProject={updateProject}/>
            <div/>
          </div>
        </Panel>
        {/* <Panel header={<b>Juarez, Mexico</b>} key="juarez">
          <p style={{ margin: 0 }}>Not yet available</p>
        </Panel>
        <Panel header={<b>Shenzhen, China</b>} key="shenzhen">
          <p style={{ margin: 0 }}>Not yet available</p>
        </Panel> */}
      </Collapse>
    </Card>
  );
};
