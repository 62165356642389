import { Redirect } from 'wouter';
import { VerifyEmail } from '../components/auth/verify-email';
import { IsAuth } from '../components/auth';

export const Verify = () => {
  return localStorage.getItem('verifyCode') ? (
    <Redirect to="/projects" />
  ) : (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <IsAuth verify={false}>
        <VerifyEmail />
      </IsAuth>
    </div>
  );
};
