import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import './layout-step-panel.less';

export const LayoutStepPanel = ({ show = false, children }) => {
  const panelEl = useRef(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    show && panelEl.current.scrollTo(0, 0);
  }, [show]);

  return (
    <div ref={panelEl} className={clsx('layout-step-panel', { show: show })}>
      {children}
    </div>
  );
};
