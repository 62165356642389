import { useState, useEffect } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import { HeatSourceControls } from './heat-source-controls';
import { HeatSource } from './heat-source';
//import { validateHeatSources } from './heat-source-validators';
import { convertToMillimeters, convertUnits, roundDecimalPlaces } from '../../../../utils/unit-conversion';
import './heat-sources.less';
import { type } from '../../../../state/units';

//import { validationState } from '../../../../state/validation';

// used for spacing heat source layout base (margin and adding flow panel from original step)
const WRAPPER_MARGIN = 48 + 360;

export const HeatSources = ({ project, updateProject, panelSize }) => {
  const [heatSources, setHeatSources] = useState(_cloneDeep(project.heatSources));
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [scale, setScale] = useState(1);
  const [zoom, setZoom] = useState(0.5);
  const displayScale = roundDecimalPlaces(scale * zoom, 0);
  const { length, width } = project.designs[project.activeDesignId].extrusion.dimensions;
  const baseplateSize = { width: convertToMillimeters(width), height: convertToMillimeters(length) };
  const displaySize = { width: baseplateSize.width * displayScale, height: baseplateSize.height * displayScale };
  const defaultHSSize = baseplateSize.width / 2;

  /*
  useEffect(() => {
    const v = validateHeatSources({ baseplate: { length, width }, heatSources: project.heatSources });
    console.log(v);
  }, [length, width, project.heatSources]);
*/

  // required to ensure heat source popover form is updated
  useEffect(() => {
    setHeatSources(_cloneDeep(project.heatSources));
  }, [project.heatSources]);

  // update scale if view changes
  useEffect(() => {
    setScale(roundDecimalPlaces((panelSize.left - WRAPPER_MARGIN) / baseplateSize.width), 0);
  }, [panelSize.left, baseplateSize.width]);

  const action = (action) => {
    switch (action) {
      case 'ADD':
        const addHS = {
          label: (heatSources.length + 1).toString(),
          power: { type: 'POWER', value: 10, units: 'W' },
          size: {
            width: { type: 'DIMENSION', value: defaultHSSize, units: 'mm' },
            height: { type: 'DIMENSION', value: defaultHSSize, units: 'mm' },
            length: { type: 'DIMENSION', value: 0, units: 'mm' },
          },
          position: {
            x: { type: 'DIMENSION', value: 0, units: 'mm' },
            y: { type: 'DIMENSION', value: 0, units: 'mm' },
          },
          rjc: {
            type: 'RESISTANCE',
            value: 0,
            units: 'C/W',
          },
          interface: 'NONE',
          interfaceMaterial: '',
        };
        heatSources.push(addHS);
        setSelectedIndex(heatSources.length - 1);
        setHeatSources(heatSources);
        updateProject({ path: 'heatSources', value: heatSources });
        break;
      case 'COPY':
        const copyHS = _cloneDeep(heatSources[selectedIndex]);
        copyHS.label = `Copy ${copyHS.label}`;
        copyHS.position.x.value = 0;
        copyHS.position.y.value = 0;
        const newLength = heatSources.push(copyHS);
        setSelectedIndex(newLength - 1);
        setHeatSources(heatSources);
        updateProject({ path: 'heatSources', value: heatSources });
        break;
      case 'DELETE':
        const remainingHeatSources = heatSources.filter((_, index) => index !== selectedIndex);
        setSelectedIndex(-1);
        setHeatSources(remainingHeatSources);
        updateProject({ path: 'heatSources', value: remainingHeatSources });
        break;
      case 'ROTATE':
        const rotatedHS = heatSources[selectedIndex];
        const { width, height, length } = rotatedHS.size;
        rotatedHS.size = { width: height, height: width, length };
        heatSources.splice(selectedIndex, 1, rotatedHS);
        setHeatSources(heatSources);
        updateProject({ path: 'heatSources', value: heatSources });
        break;
      case 'CENTER':
        const centeredHS = heatSources[selectedIndex];
        const { size, position } = centeredHS;
        let { x, y } = position;
        x.value = (baseplateSize.width - convertToMillimeters(size.width)) / 2;
        y.value = (baseplateSize.height - convertToMillimeters(size.height)) / 2;
        // convert to correct units
        if (x.units !== 'mm') {
          x = convertUnits({ value: { ...x, units: 'mm' }, to: x.units });
        }
        if (y.units !== 'mm') {
          y = convertUnits({ value: { ...y, units: 'mm' }, to: y.units });
        }
        centeredHS.position = { x, y };
        heatSources.splice(selectedIndex, 1, centeredHS);
        setHeatSources(heatSources);
        updateProject({ path: 'heatSources', value: heatSources });
        break;
      case 'ZOOMIN':
        if (zoom <= 0.7) {
          setZoom((zoom) => zoom + 0.1);
        } else {
          setZoom(0.8);
        }
        break;
      case 'ZOOMOUT':
        if (zoom >= 0.3) {
          setZoom((zoom) => zoom - 0.1);
        } else {
          setZoom(0.2);
        }
        break;
      default:
      //console.log('Invalid action', action);
    }
  };

  const setPosition = ({ index, label, position }) => {
    const updatedHeatSources = [...heatSources];
    const hs = _cloneDeep(updatedHeatSources[index]);
    const { size } = hs;
    const { x, y } = hs.position;
    hs.label = label;
    x.value = position.x / displayScale;
    y.value = baseplateSize.height - convertToMillimeters(size.height) - position.y / displayScale;
    if (x.units !== 'mm') {
      hs.position.x = convertUnits({ value: { ...x, units: 'mm' }, to: x.units });
    }
    if (y.units !== 'mm') {
      hs.position.y = convertUnits({ value: { ...y, units: 'mm' }, to: y.units });
    }
    updatedHeatSources.splice(index, 1, hs);
    setHeatSources(updatedHeatSources);
    updateProject({ path: 'heatSources', value: updatedHeatSources });
  };

  const displayHSSize = ({ width, height }) => ({
    width: convertToMillimeters(width) * displayScale,
    height: convertToMillimeters(height) * displayScale,
  });

  const displayHSPosition = ({ x, y }, { height }) => ({
    x: convertToMillimeters(x) * displayScale,
    y: (baseplateSize.height - convertToMillimeters(height) - convertToMillimeters(y)) * displayScale,
  });

  const baseSize = { width: Math.round(width.value), height: Math.round(length.value) };

  return (
    <div className="heat-sources-wrapper">
      <HeatSourceControls action={action} isSelected={selectedIndex !== -1} />
      <div className="baseplate-wrapper" onClick={(event) => setSelectedIndex(-1)}>
        <div className="flow-direction">
          <div className="arrow arrow-up" />
          <div className="arrow-body">
            <div className="label">
              <div className="text">Air Flow Direction</div>
            </div>
          </div>
          {((project.airFlow.airFlowType === type.airflow.naturalConvection &&
            project.airFlow.orientation === type.naturalConvection.horizontal) ||
            (project.airFlow.airFlowType === type.airflow.forceConvection &&
              project.airFlow.setup === type.flowSetup.impinge)) && <div className="arrow arrow-down" />}
        </div>
        <div className="baseplate" style={{ width: displaySize.width + 'px', height: displaySize.height + 'px' }}>
          <div className="baseplate-size-marker" />
          <div className="baseplate-size-label">
            Base ({baseSize.width},{baseSize.height})
          </div>
          {heatSources.map((heatSource, index) => {
            const { size, position } = heatSource;
            const ds = displayHSSize(size);
            const dp = displayHSPosition(position, size);
            return (
              <HeatSource
                key={`hs-${index}`}
                index={index}
                project={project}
                updateProject={updateProject}
                heatSource={heatSource}
                size={ds}
                position={dp}
                setPosition={setPosition}
                selected={selectedIndex === index}
                setSelectedIndex={setSelectedIndex}
                maxWidth={width}
                maxLength={length}
              />
            );
          })}
          <div className="baseplate-origin-marker" />
          <div className="baseplate-origin-label" />
        </div>
        <div className="fin-direction">
          <div className="arrow arrow-up" />
          <div className="arrow-body">
            <div className="label">
              <div className="text">Fin Direction</div>
            </div>
          </div>
          <div className="arrow arrow-down" />
        </div>
      </div>
    </div>
  );
};
