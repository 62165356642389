import { useState, useRef, useEffect, useLayoutEffect, Suspense } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import clsx from 'clsx';
import { Button, Steps } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { BsLayoutSidebar, BsReverseLayoutSidebarReverse } from 'react-icons/bs';
import './layout-workspace.less';

const { Step } = Steps;

const HeaderSteps = ({ steps, current, onChange }) => {
  return (
    <Steps type="navigation" size="small" current={current} onChange={onChange}>
      {steps.map(({ step, title }) => (
        <Step key={step} title={title} />
      ))}
    </Steps>
  );
};

export const LayoutWorkspace = ({
  steps = [{ step: 0, title: 'Step 0' }],
  selectedStep = 0,
  setSelectedStep = () => {},
  previousTab,
  nextTab,
  setActiveTab = () => {},
  left,
  right,
  showLeft = true,
  showRight = true,
  expandLeft = false,
  expandRight = false,
  onChange = () => {},
}) => {
  const [showLeftPanel, setShowLeftPanel] = useState(left && showLeft);
  const [showRightPanel, setShowRightPanel] = useState(right && showRight);
  const [size, setSize] = useState({ left: 0, right: 0 });
  const leftEl = useRef(null);
  const rightEl = useRef(null);

  const toggleLeftPanel = () => setShowRightPanel((prevState) => !prevState);
  const toggleRightPanel = () => setShowLeftPanel((prevState) => !prevState);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setShowLeftPanel(left && showLeft);
    setShowRightPanel(right && showRight);
  }, [showLeft, showRight]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    const left = leftEl.current.getBoundingClientRect().width;
    const right = rightEl.current.getBoundingClientRect().width;
    setSize({ left, right });
    onChange({ left, right });
  }, [leftEl, rightEl]);

  useResizeObserver(leftEl, (entry) => {
    const left = entry.contentRect.width;
    setSize((size) => ({ ...size, left }));
    onChange({ ...size, left });
  });

  useResizeObserver(rightEl, (entry) => {
    const right = entry.contentRect.width;
    setSize((size) => ({ ...size, right }));
    onChange({ ...size, right });
  });

  const onPrevious = () => setSelectedStep(selectedStep - 1);
  const onNext = () => setSelectedStep(selectedStep + 1);
  const onPreviousTab = () => setActiveTab(previousTab);
  const onNextTab = () => setActiveTab(nextTab);

  const PreviousButton = () =>
    selectedStep === 0 && previousTab ? (
      <Button size="small" onClick={onPreviousTab}>
        <LeftOutlined /> Previous
      </Button>
    ) : (
      <Button size="small" disabled={selectedStep === 0} onClick={onPrevious}>
        <LeftOutlined /> Previous
      </Button>
    );

  const NextButton = () =>
    selectedStep === steps.length - 1 && nextTab ? (
      <Button size="small" onClick={onNextTab}>
        Next <RightOutlined />
      </Button>
    ) : (
      <Button size="small" disabled={selectedStep === steps.length - 1} onClick={onNext}>
        Next <RightOutlined />
      </Button>
    );

  return (
    <div className="layout-workspace-wrapper">
      <div className="layout-workspace-header">
        <HeaderSteps steps={steps} current={selectedStep} onChange={setSelectedStep} />
      </div>
      <div className={clsx('layout-workspace', { 'single-column': !showLeftPanel || !showRightPanel })}>
        <Suspense fallback={<div>Loading...</div>}>
          <div ref={leftEl} className={clsx('layout-workspace-left', { hide: !showLeftPanel })}>
            {expandLeft && showRight && (
              <div className="panel-control-container">
                {right && (
                  <Button
                    type="text"
                    size="small"
                    onClick={toggleLeftPanel}
                    icon={showRightPanel ? <BsReverseLayoutSidebarReverse /> : <BsLayoutSidebar />}
                  />
                )}
              </div>
            )}
            <div>{left}</div>
          </div>
          <div ref={rightEl} className={clsx('layout-workspace-right', { hide: !showRightPanel })}>
            {expandRight && showLeft && (
              <div className="panel-control-container">
                <Button
                  type="text"
                  size="small"
                  onClick={toggleRightPanel}
                  icon={showLeftPanel ? <BsLayoutSidebar /> : <BsReverseLayoutSidebarReverse />}
                />
              </div>
            )}
            <div>{right}</div>
          </div>
        </Suspense>
      </div>
      <div className="layout-workspace-footer">
        <PreviousButton />
        <NextButton />
      </div>
    </div>
  );
};
