import { convertValueUnitsTo } from "./unit-conversion";

const machiningrequired = (project) => {
  const quoteSpecs = project.quoteSpecs;
  const cnc = quoteSpecs.cnc;
  const finish = quoteSpecs.finish;
  const counterBores = quoteSpecs.counterBores;
  const machiningrequired = cnc.sides > 0 || cnc.holes > 0 || finish.blindHoles > 0 || finish.blindFaces > 0 || 
    cnc.pocketVolume.value > 0.0 || counterBores.addTime > 0.0 || counterBores.cbores > 0 || quoteSpecs.sandGaps > 0 ||
    cnc.flycut || cnc.flycutFlatnessCallout || cnc.flycutRoughnessCallout;
  return machiningrequired;
};

// line minimum rules:
//  1. Standard Extrusion (A or C Class) that is only cut and finished = $850
//  2. Any one of the following should trigger $1500 being used: 1) Custom extrusion, 2) machining like flycut, holes, pockets, etc.
export const lineMinimum = (project) => {
  return project.activeDesignId !== 1 || machiningrequired(project) ? 1500.0 : 850.0;
}

export const defaultGrossMargin = (project) => {
  return project.activeDesignId !== 1 || machiningrequired(project) ? 50.0 : 65.0;
}

export const minGrossMargin = (project) => {
  return project.activeDesignId !== 1 || machiningrequired(project) ? 40.0 : 50.0;
}

export const autoFlycutApplied = (project) => {
  const hasPocketVolume = project.quoteSpecs.cnc.pocketVolume.value > 0.0;
  const hasBlindHoles = project.quoteSpecs.finish.blindHoles > 0;
  const hsWidth = convertValueUnitsTo({ value: project.designs[project.activeDesignId].extrusion.dimensions.width, to: 'in' });
  return hsWidth >= 6 && (hasPocketVolume || hasBlindHoles);
}