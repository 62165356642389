import { useState } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { Button, Radio } from 'antd';
import { UserOutlined, UsergroupAddOutlined, PlusOutlined, AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { useLocation } from 'wouter';
import { hasGroup, authState, userRoleState } from '../state/auth';
import { customersState } from '../state/customers';
import { initialSolution, initialSimulationStatus } from '../state/designs';
import { type } from '../state/units';
import { deleteProjectRequest, getSelectedProject, projectState } from '../state/project';
import { ProjectModal } from '../components/project';
import { LayoutCards, LayoutList } from '../components/layout';
import { ProjectsCards } from './projects-cards';
import { ProjectsList } from './projects-list';
import { addActivity } from '../state/activity';
import { useProjects } from '../components/common/use-projects';
import { tagsState } from '../state/tags';
import { userFilterState } from '../state/user';
import { convertObjectUnits } from '../utils/unit-conversion';

export const Projects = ({ customerId = undefined, title = 'Projects' }) => {
  const auth = useRecoilValue(authState);
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const userRole = useRecoilValue(userRoleState);
  const customers = useRecoilValue(customersState(null));
  const [, setLocation] = useLocation();
  const setCopyProject = useSetRecoilState(projectState({ userId: auth.username, projectId: undefined }));
  const [ newEmptyProject, setNewEmptyProject] = useRecoilState(projectState({ userId: auth.username, undefined }));
  const [userFilter, setUserFilter] = useRecoilState(userFilterState);
  const [layout, setLayout] = useState('CARDS');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectId, setProjectId] = useState();
  const { projects: p } = useProjects();
  const selectedTags = useRecoilValue(tagsState);

  const projects = p
    .filter((project) => customerId === undefined || project.customerId === customerId)
    .filter((project) => (userFilter === 'USER' ? project.userId === auth.username : true))
    .filter(({ tags }) =>
      selectedTags.length === 0 ? true : tags.filter((tag) => selectedTags.includes(tag)).length !== 0,
    );

  const tags = [...new Set(projects.map(({ tags }) => tags || []).flat())].sort();

  const selectProject = ({ id }) => setLocation(`/p/${id}`);

  const newProject = () => {
    if (userRole !== type.userRole.lite) {
      setProjectId();
      setIsModalVisible(true);
    } else {
      setProjectId();
      setNewEmptyProject(convertObjectUnits({ ...newEmptyProject, name: '' }, type.system.american ));
    }
  };

  const editProject = ({ id }) => {
    setProjectId(id);
    setIsModalVisible(true);
  };

  const copyProject = async ({ id }) => {
    // load project to copy
    const project = await getSelectedProject({ id });
    const { createdAt, updatedAt, designs, name, ...rest } = project;
    // reset designs/simulations (simulation files not copied to new project)
    const newDesigns = designs.map((design) => ({
      ...design,
      solution: initialSolution,
      simulationStatus: initialSimulationStatus,
      status: type.designStatus.incomplete,
    }));
    // create new project
    setCopyProject({ ...rest, designs: newDesigns, id: undefined, name: `Copy of ${name}` });
  };

  const deleteProject = async ({ id }) => {
    const project = await getSelectedProject({ id });
    const customer = customers.filter(({ id }) => id === project.customerId);
    addActivity({
      activity: type.activityType.prjDelete,
      userEmail: auth.email,
      userId: project.userId,
      projectName: project.name,
      projectId: project.id,
      customer: customer.length === 1 ? customer[0].name : '',
      customerId: project.customerId,
    });
    await deleteProjectRequest({ id });
  };

  // todo switch between tiles and list
  const toggleLayout = (event) => setLayout(event.target.value);
  const toggleFilter = (event) => setUserFilter(event.target.value);

  const Header = () => (
    <>
      <div>
        <h3>{title}</h3>
      </div>
      <div />
      {isAdmin ? (
        <div>
          <Radio.Group onChange={toggleFilter} value={userFilter}>
            <Radio.Button value="USER">
              <UserOutlined />
            </Radio.Button>
            <Radio.Button value="ALL">
              <UsergroupAddOutlined />
            </Radio.Button>
          </Radio.Group>
        </div>
      ) : (
        <div />
      )}
      <div>
        <Radio.Group onChange={toggleLayout} value={layout}>
          <Radio.Button value="CARDS">
            <AppstoreOutlined />
          </Radio.Button>
          <Radio.Button value="LIST">
            <BarsOutlined />
          </Radio.Button>
        </Radio.Group>
      </div>
      <div>
        <Button icon={<PlusOutlined />} onClick={newProject}>
          New Project
        </Button>
      </div>
    </>
  );

  return (
    <>
      {layout === 'CARDS' ? (
        <LayoutCards header={<Header />}>
          <ProjectsCards
            customers={customers}
            projects={projects}
            selectProject={selectProject}
            editProject={editProject}
            copyProject={copyProject}
            deleteProject={deleteProject}
            tags={tags}
          />
        </LayoutCards>
      ) : (
        <LayoutList header={<Header />}>
          <ProjectsList
            customers={customers}
            projects={projects}
            selectProject={selectProject}
            editProject={editProject}
            copyProject={copyProject}
            deleteProject={deleteProject}
            tags={tags}
          />
        </LayoutList>
      )}
      <ProjectModal projectId={projectId} visible={isModalVisible} onCancel={() => setIsModalVisible(false)} />
    </>
  );
};
