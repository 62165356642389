import { atom, selector, selectorFamily } from 'recoil';
import { type } from './units';

export const authState = atom({
  key: 'authState',
  default: undefined,
});

export const isAuthenticated = selector({
  key: 'isAuth',
  get: ({ get }) => get(authState) !== undefined,
});

export const hasGroup = selectorFamily({
  key: 'hasGroup',
  get: (group) => ({ get }) => get(authState).groups?.includes(group),
});

// role should be set from cognito group (auth)
export const groupsState = selector({
  key: 'groupsState',
  get: ({ get }) => {
    const groups = get(authState).groups;
    if (groups === undefined) {
      return type.userRole.sales;
    } else {
      if (groups.includes(type.userRole.admin)) {
        return type.userRole.test;
      } else if (groups.includes(type.userRole.test)) {
        return type.userRole.test;
      } else if (groups.includes(type.userRole.engineer)) {
        return type.userRole.engineer; 
      } else if (groups.includes(type.userRole.ops)) {
        return type.userRole.ops;   
      } else if (groups.includes(type.userRole.lite)) {
        return type.userRole.lite;   
      }
      else {
        return type.userRole.sales;
      }
    }
  }
});

export const userRoleState = atom({
  key: 'userRoleState',
  default: groupsState,
})
