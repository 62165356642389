/*
Allowing user to change their email address is not straight forward since
it is tied to their Cognito authentication and typically requires receiving
and submitting confirmation code to validate changes.

https://serverless-stack.com/chapters/allow-users-to-change-their-email.html
 */
import React from 'react';
import { Form } from 'antd';

const { Item } = Form;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};


export const SecurityForm = ({ auth }) => {
  return (
    <Form name="security" size="small" {...layout}>
      <Item label="Email">
        <div style={{color: '#666'}}>{auth.email}</div>
      </Item>

      <Item label="Password">
        <div style={{color: '#666'}}>Reset Password option is available when you Sign In</div>
      </Item>

      <Item label="Groups">
        <div style={{color: '#666'}}>{auth.groups}</div>
      </Item>

      <Item label="User Id">
        <div style={{color: '#666'}}>{auth.username}</div>
      </Item>
    </Form>
  );
};
