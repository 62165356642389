import { useState, useEffect, Suspense } from 'react';
import { Card, Modal, Image, Table, Popover, Spin, Button, Space, InputNumber } from 'antd';
import { PictureOutlined, QuestionCircleOutlined, FilterFilled } from '@ant-design/icons';
import { type } from '../../../state/units';
import { nanoid } from 'nanoid';
import { customerExtrusionSpecs, extrusionDims } from '../../../utils/extrusion-info';
import './extrusion-select-modal.less';
import { classHelp } from '../../common/tooltip';

const getMinMaxFilterProps = ({ filterDataIndex, filterTitle }) => ({
  filterIcon: (filtered) => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const ranges = selectedKeys.length > 0 ? selectedKeys[0].split(',') : ['', ''];
    const minVal = ranges[0];
    const maxVal = ranges[1];
    return (
      <Card
        className="filter-card"
        bodyStyle={{ padding: (0, 4) }}
        actions={[
          <Button
            type="text"
            style={{ color: minVal || maxVal ? '#1890ff' : undefined }}
            disabled={!(minVal || maxVal)}
            onClick={() => {
              clearFilters();
            }}
          >
            Reset
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              if (!(minVal && maxVal)) {
                clearFilters();
              }
              confirm();
            }}
          >
            Ok
          </Button>,
        ]}
      >
        <div>
          <Space>
            <label className="filter-label">{`Min ${filterTitle}`}</label>
            <InputNumber
              className="filter-input"
              placeholder={`Min ${filterTitle}`}
              value={minVal}
              onChange={(e) => {
                e ? setSelectedKeys([e + ',' + maxVal]) : setSelectedKeys([',' + maxVal]);
              }}
            />
          </Space>
        </div>
        <div>
          <Space>
            <label className="filter-label">{`Max ${filterTitle}`}</label>
            <InputNumber
              className="filter-input"
              placeholder={`Max ${filterTitle}`}
              value={maxVal}
              onChange={(e) => {
                e ? setSelectedKeys([minVal + ',' + e]) : setSelectedKeys([minVal + ',']);
              }}
            />
          </Space>
        </div>
      </Card>
    );
  },
  onFilter: (value, record) => {
    if (value) {
      const minMaxRange = value.split(',');
      if (minMaxRange[0] && minMaxRange[1]) {
        return (
          parseFloat(minMaxRange[0]) <= parseFloat(record[filterDataIndex]) &&
          parseFloat(record[filterDataIndex]) <= parseFloat(minMaxRange[1])
        );
      }
    }
    return record[filterDataIndex];
  },
});

const columns = [
  {
    key: 'partId',
    title: 'Part ID',
    dataIndex: 'partId',
    align: 'center',
    width: 70,
    sorter: (a, b) => a.partId - b.partId,
  },
  {
    key: 'class',
    title: (
      <>
        <label>Class</label>{' '}
        <Popover content={classHelp}>
          <QuestionCircleOutlined />
        </Popover>
      </>
    ),
    dataIndex: 'class',
    align: 'center',
    width: 100,
    filters: [
      { text: 'A', value: 'A' },
      { text: 'C', value: 'C' },
    ],
    onFilter: (value, record) => record.class.indexOf(value) === 0,
  },
  {
    title: 'Deviation %',
    key: 'deviation',
    children: [
      {
        key: 'perimeterDeviation',
        title: 'Perimeter',
        dataIndex: 'perimeterDeviation',
        sorter: (a, b) => Math.abs(a.perimeterDeviation) - Math.abs(b.perimeterDeviation),
        defaultSortOrder: 'ascend',
        width: 90,
        align: 'right',
      },
      {
        key: 'materialDeviation',
        title: 'Weight',
        dataIndex: 'materialDeviation',
        sorter: (a, b) => Math.abs(a.materialDeviation) - Math.abs(b.materialDeviation),
        width: 90,
        align: 'right',
      },
    ],
  },
  {
    key: 'width',
    title: 'Width (in)',
    dataIndex: 'width',
    align: 'right',
    width: 90,
    sorter: (a, b) => a.width - b.width,
    ...getMinMaxFilterProps({ filterDataIndex: 'width', filterTitle: 'width' }),
  },
  {
    key: 'height',
    title: 'Height (in)',
    dataIndex: 'height',
    align: 'right',
    width: 90,
    sorter: (a, b) => a.height - b.height,
    ...getMinMaxFilterProps({ filterDataIndex: 'height', filterTitle: 'height' }),
  },
  {
    key: 'finThickness',
    title: 'Fin Thickness (in)',
    dataIndex: 'finThickness',
    align: 'right',
    width: 90,
    sorter: (a, b) => a.finThickness - b.finThickness,
  },
  {
    key: 'numFins',
    title: 'Number of fins',
    dataIndex: 'numFins',
    align: 'right',
    width: 90,
    sorter: (a, b) => a.numFins - b.numFins,
  },
  {
    key: 'image',
    title: 'Image',
    dataIndex: 'image',
    align: 'center',
    width: 70,
    render: (_, record) => (
      <Popover placement="right" content={ExtrusionCodeHelp({ code: record.partId })}>
        <PictureOutlined />
      </Popover>
    ),
  },
];

const nonFlatbackExtrusionColumns = [
  ['baseLength', 'Base Length (in)'],
  ['width', 'Width (in)'],
  ['height', 'Height (in)'],
].map((d) => ({ key: d[0], title: d[1], dataIndex: d[0], align: 'center', width: 90 }));

const flatbackExtrusionColumns = [
  ['baseLength', 'Base Length (in)'],
  ['width', 'Width (in)'],
  ['height', 'Height (in)'],
  ['finThick', 'Fin Thickness (in)'],
  ['numFins', 'Nums of fins'],
].map((d) => ({ key: d[0], title: d[1], dataIndex: d[0], align: 'right', width: 90 }));

const ExtrusionCodeHelp = ({ code }) =>
  code === '0' ? null : (
    <Card title={`Extrusion ${code}`} bordered={false}>
      <Image
        src={`https://www.boydcorp.com/files/part-documentation/extrusion-${code}-drawing.png`}
        height={180}
        preview={false}
      />
    </Card>
  );

export const StandardExtrusionSelectModal = ({
  selectedCode,
  data,
  unitSystem,
  visible,
  showDeviation,
  onCallback,
  onCancel,
  loadingData,
  project,
}) => {
  const [curSel, setCurSel] = useState(selectedCode);
  const filteredColumns = columns.filter((column) => (showDeviation ? column : column.key !== 'deviation'));
  if (unitSystem === type.system.metric) {
    filteredColumns.forEach((column) => {
      if (column.key !== 'class') {
        column.title = column.title.replace('(in)', '(mm)');
      }
    });
  } else {
    filteredColumns.forEach((column) => {
      if (column.key !== 'class') {
        column.title = column.title.replace('(mm)', '(in)');
      }
    });
  }

  var customerTableColumns = project.designs[0].extrusion.nonFlatback ? nonFlatbackExtrusionColumns : flatbackExtrusionColumns;
  if (unitSystem === type.system.metric) {
    customerTableColumns = customerTableColumns.map(ctc => ({...ctc, title: ctc.title.replace('(in)', '(mm)')}))
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setCurSel(selectedCode);
  }, [visible]);

  const tableScrollY = project.specifications.customerExtrusionProvided ? 200 : 340;
  const flatbackInfo = project.designs[0].extrusion.nonFlatback ? 'Non-flatback' : 'Flatback';

  return (
    <Modal
      title={`Select Extrusion`}
      visible={visible}
      onOk={(e) => onCallback(curSel)}
      onCancel={onCancel}
      centered
      width={850}
      bodyStyle={{ height: '520px', overflowY: 'auto' }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        {loadingData ? (
          <div className="table-spinner">
            <Spin tip="Loading..." />
          </div>
        ) : (
          <div
            style={{
              display: 'grid',
              gridTemplateRows: 'auto auto',
              gridRowGap: project.specifications.customerExtrusionProvided ? 32 : 0,
              alignItems: 'center',
            }}
          >
            {project.specifications.customerExtrusionProvided ? (
              <div>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center' }}>
                  <h4>Customer Extrusion Specifications</h4>
                  <div />
                  <h4 style={{ color: '#1890ff' }}>{flatbackInfo + ': ' + extrusionDims(project, 0)}</h4>
                </div>
                <Table
                  className="extrusion-table"
                  style={{ marginLeft: '260px', marginRight:  project.designs[0].extrusion.nonFlatback ? '270px': '90px'}}
                  size="small"
                  columns={customerTableColumns}
                  dataSource={customerExtrusionSpecs(project)}
                  pagination={false}
                  bordered
                  rowKey={(record) => nanoid(10)}
                />
              </div>
            ) : (
              <div />
            )}
            <div>
              <h4>Standard Extrusions</h4>
              <Table
                className="extrusion-table"
                rowClassName={(record) => (record.partId === curSel ? 'table-row-select' : 'table-row-unselect')}
                size="small"
                columns={filteredColumns}
                dataSource={data}
                pagination={false}
                bordered
                rowKey={(record) => record.partId}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      setCurSel(record.partId);
                    }, // click row
                  };
                }}
                scroll={{ y: tableScrollY }}
              />
            </div>
          </div>
        )}
      </Suspense>
    </Modal>
  );
};
