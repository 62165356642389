import { SuspenseModal } from '../common';
import { ProjectForm } from './project-form';

export const ProjectModal = ({ projectId, visible, onCancel }) => {
  return (
    <SuspenseModal
      title={projectId === undefined ? 'New Project' : 'Edit Project'}
      visible={visible}
      onCancel={onCancel}
    >
      <ProjectForm projectId={projectId} visible={visible} onCancel={onCancel} />
    </SuspenseModal>
  );
};
