
// these are really enums

export const type = {
  altitude: 'ALTITUDE',
  dimension: 'DIMENSION',
  flowRate: 'FLOWRATE',
  mass: 'MASS',
  power: 'POWER',
  pressure: 'PRESSURE',
  resistance: 'RESISTANCE',
  temperature: 'TEMPERATURE',
  velocity: 'VELOCITY',
  area: 'AREA',
  volume: 'VOLUME',
  linearDensity: 'LINEARDENSITY',
  position: 'POSITION',
  size: 'SIZE',
  userType: {
    user: 'USER',
    deleted: 'DELETED',
  },
  userRole: {
    lite: 'LITE',
    engineer: 'ENG',
    sales: 'SALES',
    test: 'TEST',
    ops: 'OPS',
    admin: 'ADMIN',
  },
  costingTableView: {
    user: 'USER',
    tester: 'TESTER',
  },
  system: {
    metric: 'METRIC',
    american: 'AMERICAN',
  },
  design: {
    extrusion: 'EXTRUSION',
    heatsink: 'HEATSINK',
    lcp: 'LIQUIDCOLDPLATE',
  },
  designStatus: {
    incomplete: 'INCOMPLETE',
    complete: 'COMPLETE',
    queued: 'QUEUED',
    solving: 'SOLVING',
    solution: 'SOLUTION',
    failed: 'FAILED',
  },
  designStatusString: {
    INCOMPLETE: 'Incomplete',
    COMPLETE: 'Complete',
    QUEUED: 'Queued',
    SOLVING: 'Solving',
    SOLUTION: 'Solution',
    FAILED: 'Failed',
  },
  finishStyle: {
    none: 'NONE',
    etchwash: 'ETCHWASH',
    aavshield3: 'AAVSHIELD3',
    chromate: 'CHROMATE',
    anodize: 'ANODIZE',
    anodizeclear: 'ANODIZECLEAR',
    millfinish: 'MILLFINISH',
  },
  cuttingType: {
    full: 'FULL',
    short: 'SHORT',
    custom: 'CUSTOM',
  },
  priceType: {
    lca1: 'LCA1',
    lcb1: 'LCB1',
  },
  interface: {
    none: 'NONE',
    material: 'MATERIAL',
    thermalResistance: 'THERMALRESISTANCE',
    thermalImpedance: 'THERMALIMPEDANCE',
    conductivityThickness: 'CONDUCTIVITY_THICKNESS',
  },
  airflow: {
    naturalConvection: 'NATURALCONVECTION',
    forceConvection: 'FORCEDCONVECTION',
  },
  airflowTypesString: {
    NATURALCONVECTION: 'Natural convection',
    FORCEDCONVECTION: 'Forced convection',
  },
  naturalConvection: {
    horizontal: 'HORIZONTAL',
    vertical: 'VERTICAL',
  },
  naturalConvectionTypesString: {
    HORIZONTAL: 'Horizontal',
    VERTICAL: 'Vertical',
  },
  forcedFlow: {
    flowRate: 'FLOWRATE',
    velocity: 'VELOCITY',
    fan: 'FAN',
  },
  forcedFlowTypesString: {
    FLOWRATE: 'Flow rate',
    VELOCITY: 'Velocity',
    FAN: 'Fan',
  },
  flowSetup: {
    push: 'PUSH',
    pushpull: 'PUSHPULL',
    impinge: 'IMPINGE',
  },
  flowSetupTypesString: {
    PUSH: 'Push',
    PUSHPULL: 'Push-pull',
    IMPINGE: 'Impinge',
  },
  markStyle: {
    none: 'NONE',
    ink: 'INK',
    barcode: 'BARCODE',
    laser: 'LASER',
  },
  thermalConstraintType: {
    temperature: 'TEMPERATURE',
    thermalResistance: 'THERMALRESISTANCE',
  },
  finLayoutStyle: {
    finPitch: 'FINPITCH',
    numFins: 'NUMFINS',
  },
  specifyExtrusionHeightBy: {
    overall: 'OVERALL',
    finHeight: 'FINHEIGHT',
  },
  activityType: {
    prjCreate: 'PRJ_CREATE',
    prjUpdate: 'PRJ_UPDATE',
    prjDelete: 'PRJ_DELETE',
    prjShare: 'PRJ_SHARE',
    prjQuote: 'PRJ_QUOTE',
    prjLiteQuote: 'PRJ_LITE_QUOTE',
  }
};

export const measurementUnits = {
  dimension: ['mm', 'in'],
  flowRate: ['m3/min', 'm3/s', 'cfm', 'gpm'], // cfm = ft3/min (convert-units library)
  flowRateLiquid: ['l/min', 'gpm'],
  flowRateGas: ['m3/min', 'cfm', 'gpm'],
  flowVelocity: ['m/s', 'ft/s', 'LFM'],
  temperature: ['C', 'F'],
  altitude: ['m', 'ft'],
  pressure: ['Pa', 'inH2O'],
  thermalResistance: ['C/W'],
  thermalImpedance: ['C in2/W'],
  thermalConductivity: ['W/M-K'],
  power: ['W'],
  mass: ['kg', 'lb'],
  area: ['m2', 'in2'],
  volume: ['mm3', 'cm3', 'in3'],
  linearDensity: ['kg/m', 'lb/ft'],
};

export const altitude = {
  type: type.altitude,
  value: 0,
  units: 'm',
};

export const dimension = {
  type: type.dimension,
  value: 0,
  units: 'mm',
};

export const temperature = {
  type: type.temperature,
  value: 0,
  units: 'C',
};

export const resistance = {
  type: type.resistance,
  value: 0,
  units: 'C/W',
};

export const pressure = {
  type: type.pressure,
  value: 0,
  units: 'Pa',
};

export const mass = {
  type: type.mass,
  value: 0,
  units: 'kg',
};

export const power = {
  type: type.power,
  value: 0,
  units: 'W',
};

export const area = {
  type: type.area,
  value: 0,
  units: 'm2',
};

export const volume = {
  type: type.volume,
  value: 0,
  units: 'mm3',
};

export const linearDensity = {
  type: type.linearDensity,
  value: 0,
  units: 'kg/m',
};

export const velocity = {
  type: type.velocity,
  value: 0,
  units: 'm/s',
};

export const flowRate = {
  type: type.flowRate,
  value: 0,
  units: 'm3/s',
};

export const size = {
  width: dimension,
  length: dimension,
  height: dimension,
};

export const position = {
  x: dimension,
  y: dimension,
};
