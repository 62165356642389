import { useEffect, useState } from 'react';
import { Input, Form } from 'antd';
import { Measurement } from '../../../common';
import _set from 'lodash/set';

const { Item } = Form;

export const HeatSourcePopOver = ({
  index,
  heatSource,
  maxPositionX,
  maxPositionY,
  maxWidth,
  maxLength,
  project,
  updateProject,
}) => {
  const [form] = Form.useForm();
  const [updatedHeatSource, setUpdatedHeatSource] = useState(heatSource);

  // must update with local state as the popover does not update
  // correctly on subsequent launches, position can be changed by dragging
  // so reset form to reset initial state on position change
  useEffect(() => {
    if (
      heatSource.position.x.value !== form.getFieldValue('position.x').value ||
      heatSource.position.y.value !== form.getFieldValue('position.y').value ||
      heatSource.size.width.value !== form.getFieldValue('size.width').value ||
      heatSource.size.height.value !== form.getFieldValue('size.height').value
    ) {
      form.resetFields();
    }
    setUpdatedHeatSource(heatSource);
  }, [form, heatSource]);

  const onItemChange = (item) => {
    const path = item[0].name[0];
    const value = item[0].value;
    const hs = _set({ ...updatedHeatSource }, path, value);
    const updatedHeatSources = [...project.heatSources];
    updatedHeatSources.splice(index, 1, hs);
    updateProject({ path: 'heatSources', value: updatedHeatSources });
    setUpdatedHeatSource(hs);
  };

  return (
    <div className="heat-source-popover">
      <Form form={form} name="heatsource" layout="vertical" onFieldsChange={onItemChange}>
        <Item name="label" initialValue={updatedHeatSource.label} label="Label">
          <Input />
        </Item>
        <Item name="power" initialValue={updatedHeatSource.power} label="Power">
          <Measurement.Power minimum={{ type: 'POWER', value: 0, units: 'W' }} />
        </Item>
        <Item name="size.width" initialValue={updatedHeatSource.size.width} label="Width">
          <Measurement.Dimension minimum={{ type: 'DIMENSION', value: 0, units: 'mm' }} maximum={maxWidth} />
        </Item>
        <Item name="size.height" initialValue={updatedHeatSource.size.height} label="Length">
          <Measurement.Dimension minimum={{ type: 'DIMENSION', value: 0, units: 'mm' }} maximum={maxLength} />
        </Item>
        <Item name="position.x" initialValue={updatedHeatSource.position.x} label="Position X">
          <Measurement.Dimension minimum={{ type: 'DIMENSION', value: 0, units: 'mm' }} maximum={maxPositionX} />
        </Item>
        <Item name="position.y" initialValue={updatedHeatSource.position.y} label="Position Y">
          <Measurement.Dimension minimum={{ type: 'DIMENSION', value: 0, units: 'mm' }} maximum={maxPositionY} />
        </Item>
      </Form>
    </div>
  );
};
