import { useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { Tabs, Radio, Space, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { ProjectSpecifications } from './specifications/project-specifications';
import { ProjectCosting } from './costing/project-costing';
import { ProjectPerformance } from './performance/project-performance';
import { ProjectHeatSink } from './heatsink/project-heatsink';
import { AutoSaveHeader } from '../common';
import { authState, hasGroup, userRoleState } from '../../state/auth';
import { getSelectedProject, projectState } from '../../state/project';
import { type } from '../../state/units';
import { costingToolAllowed } from '../../utils/costing-tool-allowed';
import { initialSimulationStatus, initialSolution } from '../../state/designs';
import { ProjectShareModal } from './project-share-modal';
import { ProjectLiteCosting } from './costing/project-lite-costing';
import './project-layout.less';

const { TabPane } = Tabs;

const roleOptions = [
  { label: 'Lite', value: type.userRole.lite},
  { label: 'Sales', value: type.userRole.sales },
  { label: 'OPS', value: type.userRole.ops },
  { label: 'Engineer', value: type.userRole.engineer },
  { label: 'Test', value: type.userRole.test },
];

const Header = ({ projectId }) => {
  const auth = useRecoilValue(authState);
  const project = useRecoilValue(projectState({ userId: auth.username, projectId }));
  return (
    <div className="project-layout-header">
      <AutoSaveHeader title={project.name} />
    </div>
  );
};

export const ProjectLayout = ({ projectId }) => {
  const auth = useRecoilValue(authState);
  const isAdmin = useRecoilValue(hasGroup(type.userRole.admin));
  const isTest = useRecoilValue(hasGroup(type.userRole.test));
  const [activeTab, setActiveTab] = useState('1');
  const [project, setProject] = useRecoilState(projectState({ userId: auth.username, projectId }));
  const [userRole, setUserRole] = useRecoilState(userRoleState);
  const setCopyProject = useSetRecoilState(projectState({ userId: auth.username, projectId: undefined }));
  const [liteActiveTab, setLiteActiveTab] = useState('1');

  // updated items buffer
  const updatedItems = [];

  const copyProject = async ({ id }) => {
    // load project to copy
    const project = await getSelectedProject({ id });
    const { createdAt, updatedAt, designs, name, ...rest } = project;
    // reset designs/simulations (simulation files not copied to new project)
    const newDesigns = designs.map((design) => ({
      ...design,
      solution: initialSolution,
      simulationStatus: initialSimulationStatus,
      status: type.designStatus.incomplete,
    }));
    // create new project
    setCopyProject({ ...rest, designs: newDesigns, id: undefined, name: `Copy of ${name}` });
  };

  const saveProject = _throttle((updatedProject) => {
    if (updatedItems.length) {
      // apply updates to clone of project (project is frozen)
      let updatedProject = _cloneDeep(project);
      // batch updates and apply changes to project
      while (updatedItems.length) {
        const i = updatedItems.length - 1;
        const { path, value } = updatedItems[i];
        updatedItems.splice(i, 1);
        updatedProject = _set(updatedProject, path, value);
      }
      // persist updated project (async via atom effect onSet method)
      setProject(updatedProject);
    }
  }, 2000);

  const updateProject = _debounce((item) => {
    // add items to start of array - will loop backwards when batching
    Array.isArray(item) ? updatedItems.unshift(...item) : updatedItems.unshift(item);
    // batch and persist
    saveProject();
  }, 250);

  return project ? (
    <>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center', gap: '16px' }}>
        <Header projectId={projectId} />
        <div />
        {isAdmin || isTest ? (
          <Space>
            <Button onClick={() => navigator.clipboard.writeText(projectId)}>Copy Project ID</Button>
            <Button onClick={() => copyProject({ id: projectId })} icon={<CopyOutlined title="Copy project" />} />
            <ProjectShareModal projectId={projectId} />
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={roleOptions}
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
            />
          </Space>
        ) : (
          <Space>
            <Button onClick={() => copyProject({ id: projectId })} icon={<CopyOutlined title="Copy project" />} />
            <ProjectShareModal projectId={projectId} />
          </Space>
        )}
      </div>

      <Tabs type="card" activeKey={activeTab} onChange={setActiveTab} hidden={userRole === type.userRole.lite}>
        <TabPane key="1" tab="Customer Specifications">
          <ProjectSpecifications
            project={project}
            updateProject={updateProject}
            nextTab="2"
            setActiveTab={setActiveTab}
            show={activeTab === '1'}
          />
        </TabPane>
        <TabPane key="2" tab="Heat Sink">
          <ProjectHeatSink
            project={project}
            updateProject={updateProject}
            previousTab="1"
            nextTab={
              (userRole === type.userRole.engineer || userRole === type.userRole.test) &&
              project.specifications.thermalSpecificationsProvided &&
              !project.designs[0].extrusion.nonFlatback
                ? '3'
                : '4'
            }
            setActiveTab={setActiveTab}
            show={activeTab === '2'}
          />
        </TabPane>
        {(userRole === type.userRole.engineer || userRole === type.userRole.test) &&
          project.specifications.thermalSpecificationsProvided &&
          !project.designs[0].extrusion.nonFlatback && (
            <TabPane key="3" tab="Performance">
              <ProjectPerformance
                project={project}
                updateProject={updateProject}
                previousTab="2"
                nextTab={costingToolAllowed(project) ? '4' : undefined}
                setActiveTab={setActiveTab}
                show={activeTab === '3'}
              />
            </TabPane>
          )}

        {costingToolAllowed(project) && (
          <TabPane key="4" tab="Costing">
            <ProjectCosting
              project={project}
              updateProject={updateProject}
              previousTab={
                (userRole === type.userRole.engineer || userRole === type.userRole.test) &&
                project.specifications.thermalSpecificationsProvided &&
                !project.designs[0].extrusion.nonFlatback
                  ? '3'
                  : '2'
              }
              setActiveTab={setActiveTab}
              show={activeTab === '4'}
            />
          </TabPane>
        )}
      </Tabs>

      <Tabs type="card" activeKey={liteActiveTab} onChange={setLiteActiveTab} hidden={userRole !== type.userRole.lite}>
        <TabPane key="1" tab="Lite Costing" >
          <ProjectLiteCosting
            project={project}
            updateProject={updateProject}
            nextTab="2"
            setActiveTab={setLiteActiveTab}
            show={liteActiveTab === '1'}
          />
        </TabPane>
      </Tabs>
    </>
  ) : null;
};
