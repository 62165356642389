import { atomFamily, DefaultValue } from 'recoil';
import { API } from '@aws-amplify/api';
import { Storage } from '@aws-amplify/storage';
import { type } from './units';
import { initialCustomerDesignState, initialStandardDesignState, initialOptimalDesignState, initialCustomDesignState} from './designs';
import { initialProjectSpecifications } from './project-specifications';
import { initialQuoteSpecifications } from './quote-specifications';
import { initialAirFlowModuleState } from './modules';
import { getProject } from '../graphql/queries';
import { createProject, deleteProject, updateProject } from '../graphql/mutations';
import { setAutoSaveMessage } from '../utils/set-autosave-message';
import { message } from 'antd';
import { addActivity } from './activity';

export const getSelectedProject = async ({ id }) => {
  try {
    const response = await API.graphql({
      query: getProject,
      variables: { id },
    });
    return response.data.getProject;
  } catch (error) {
    console.error(error);
    throw new Error('Error loading project');
  }
};

const createUpdateProject = async ({ projectId, input }) => {
  try {
    const isNew = projectId === undefined;
    if (isNew) message.loading('Creating project');
    const response = await API.graphql({
      query: isNew ? createProject : updateProject,
      variables: { input },
    });

    // create project S3 bucket (path) using placeholder file
    if (isNew) {
      const { id, userId, customerId, name } = response.data.createProject;
      addActivity({
        activity: type.activityType.prjCreate, 
        userEmail: '', 
        userId: userId,
        projectName: name,
        projectId: id,
        customer: '',
        customerId: customerId
      });
      await Storage.put(`${id}/.keep.txt`, id);
      message.destroy();
    }

    return isNew ? response.data.createProject : response.data.updateProject;
  } catch (error) {
    message.error('Error updating project');
    console.error(error);
  }
};

export const deleteProjectRequest = async ({ id }) => {
  try {
    message.loading('Deleting project');
    const response = await API.graphql({
      query: deleteProject,
      variables: { input: { id } },
    });

    // delete files and bucket
    try {
      const filesResults = await Storage.list(`${id}/` , {pageSize: 1000});
      const  files = filesResults.results;
      for (const { key } of files) {
        await Storage.remove(key);
        //console.log(key);
      }
    } catch (err) {
      message.error('Error deleting project files.');
      console.error(err);
    };

    message.destroy();
    return response.data.deleteProject;
  } catch (error) {
    message.error('Error deleting project');
    console.error(error);
  }
};

// project id = undefined for NEW project
export const initialProjectState = {
  type: 'PROJECT',
  name: '',
  userId: '',
  customerId: '',
  description: '',
  star: false,
  tags: [],
  units: type.system.metric,
  specifications: initialProjectSpecifications,
  designs: [initialCustomerDesignState, initialStandardDesignState, initialOptimalDesignState, initialCustomDesignState],
  activeDesignId: 0,
  heatSources: [],
  airFlow: initialAirFlowModuleState,
  quoteSpecs: initialQuoteSpecifications,
  viewState: JSON.stringify(''),
};

export const projectState = atomFamily({
  key: 'projectState',
  default: ({ userId }) => ({ ...initialProjectState, userId, customerId: userId }),
  effects: ({ projectId }) => [
    ({ setSelf, onSet }) => {
      const projectPromise =
        projectId === undefined
          ? new DefaultValue()
          : getSelectedProject({ id: projectId })
              .then((project) => project)
              .catch((error) => {
                console.error('Recoil project state', error);
                return new DefaultValue();
              });

      setSelf(projectPromise);

      onSet((project) => {
        const { createdAt, updatedAt, owner, ...input } = project;
        setAutoSaveMessage({ message: 'Saving...' });
        createUpdateProject({ projectId, input })
          .then((response) => {
            setAutoSaveMessage({ message: 'Saved' });
          })
          .catch(() => setAutoSaveMessage({ message: 'Error' }));
      });
    },
  ],
});

/*
export const projectState = selectorFamily({
  key: 'projectState',
  default: initialProjectState,
  get: (id) => async () => {
    if (id === undefined) return initialProjectState;
    return await getSelectedProject({ id });
  },
});
*/
