import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
// import { nanoid } from 'nanoid';
import { Card, Form, Space, Switch, Select, Popover, Input, Button, Table, Collapse, message, notification, Image } from 'antd';
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined, CaretRightOutlined, WarningOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';
import { LayoutWorkspace, LayoutStepPanel } from '../../layout';
import { Measurement, EditableTag } from '../../common';
import { getExtrusionData, getLiteCostEstimates, getStandardPartsIds } from './project-api';
import { convertValueUnitsTo } from '../../../utils/unit-conversion';
import { dimension, linearDensity, type } from '../../../state/units';
import { authState, hasGroup } from '../../../state/auth';
import { projectsState } from '../../../state/projects';
import { convertUnits } from '../../../utils/unit-conversion';
import {
  quantityHelp,
  finishHelp,
  classHelp,
  cutLengthHelp,
  highProjectValueHelp,
  oracleHelp,
  cdfNumberHelp,
} from '../../common/tooltip';
import { GenerateCustomerLiteQuotePDF } from './project-customer-lite-costing-pdf';

const { Item } = Form;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const inputWidth = '400px';
const selectWidth = '216px';

const panelHeaderFooterHeight = '250px';
const cardHeaderHeight = '64px';

const steps = [
  { step: 0, title: 'Costing Details' },
];

export const stepsTitles = steps.map(step => step.title);

const cuttingOptions = {
  'FULL': '1',
  'SHORT': '2',
  'CUSTOM': '3',
};

const finishValues = {
  'NONE': 'U', 
  'ETCHWASH' : 'U',
  'AAVSHIELD3': 'V',
  'CHROMATE': 'C',
  'ANODIZE': 'B',
  'ANODIZECLEAR': 'N',
  'MILLFINISH': 'F',
};

const rohsFinishes = [
  type.finishStyle.millfinish,
  type.finishStyle.etchwash,
  type.finishStyle.aavshield3,
  type.finishStyle.anodize
];

const columns = [
  {
    key: 'quantity',
    title: 'Quantity',
    dataIndex: 'quantity',
    width: 80,
    align: 'right',
    fixed: 'left',
    sorter: (a, b) => parseInt(a.quantity) - parseInt(b.quantity),
    defaultSortOrder: 'ascend',
  },
  {
    key: 'piecePrice',
    title: 'Piece Price',
    width: 80,
    dataIndex: 'piecePrice',
    align: 'right',
    render: (c, record) => {
      if (record.extendedPrice > 100000.0) {
        return (
          <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center' }}>
            <Popover placement="right" content={highProjectValueHelp}>
              <WarningOutlined style={{color: 'red'}} />
            </Popover>
            <div />
            <label>#</label>
          </div>
        )
      } else {
        return c.toFixed(2)
      }
    },
  },
  {
    key: 'extra-price',
    title: 'Price Adder',
    width: 80,
    dataIndex: 'extraPrice',
    align: 'right',
    editable: true,
    render: (c, record) => record.extraPrice > 100000.0 ? '#' : c.toFixed(2),
  },
  {
    key: 'finalPrice',
    title: 'Final Piece Price',
    width: 80,
    dataIndex: 'finalPrice',
    align: 'right',
    render: (c, record) => record.extendedPrice > 100000.0 ? '#' : c.toFixed(2),
  },
  {
    key: 'extendedPrice',
    title: 'Extended Price',
    width: 80,
    dataIndex: 'extendedPrice',
    align: 'right',
    render: (c, record) => record.extendedPrice > 100000.0 ? '#' : c.toFixed(2),
  },
  {
    key: 'totalMaterialLength',
    title: 'Total Material (ft)',
    width: 80,
    dataIndex: 'totalMaterialLength',
    align: 'right',
    render: (c, record) =>record.extendedPrice > 100000.0 ? '#' : c.toFixed(2),
  }
];

const LiteCostEstimateGrid = ({ project, customCDFNumber, updateProject, standardPartsIds, show }) => {
  const [loading, setLoading] = useState(false);
  const [costEstimates, setCostEstimates] = useState([]);
  const [costedCDFNumber, setCostedCDFNumber] = useState('');
  const [editableColRowId, setEditableColRowId] = useState(-1);
  const [editableColKey, setEditableColKey] = useState('');
  const [editableCellValue, setEditableCellValue] = useState(0.0);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setCostEstimates(project.designs[0].liteCosting.liteCostEstimates);
    setCostedCDFNumber(project.designs[0].liteCosting.costedCDF);
  }, []);

  const updateLiteCostEstimate = (record, value) => {
    const colKey = editableColKey;
    setEditableColRowId(-1);
    setEditableColKey('');
    const newValue = parseFloat(value);
    if (!isNaN(newValue)) {
      const designId = 0;
      var estimates = [];
      switch (colKey) {
        case 'extra-price':
          // estimates = project.designs[designId].liteCosting.liteCostEstimates.map((quote) => {
          estimates = costEstimates.map((quote) => {
            if (quote.quantity === record.quantity) { 
              const extraPrice = newValue < 0.0 ? 0.0 : newValue;
              const finalPrice = quote.piecePrice + extraPrice;
              const extendedPrice = finalPrice * quote.quantity
              return {...quote, extraPrice, finalPrice, extendedPrice };
            } else {
              return quote;
            }
          });
          // setData(estimates);
          // updateProject([{ path: `designs.${designId}.costEstimates`, value: estimates }]);
          break;
        default:
      }
      setCostEstimates(estimates);
      updateProject([{ path: `designs.${designId}.liteCosting.liteCostEstimates`, value: estimates }]);
    }
  };

  const tableColumns = columns.map((column) =>
  !column.editable
    ? column
    : {
        ...column,
        onCell: (record, index) => {
          return {
            onClick: (event) => {
              setEditableCellValue(record[column.dataIndex].toFixed(2));
              setEditableColRowId(index);
              setEditableColKey(column.key);
            }, // click cell
          };
        },
        render: (c, record, index) => {
          if (editableColRowId === index && editableColKey === column.key) {
            return (
              <Input
                value={editableCellValue}
                autoFocus
                controls={false}
                size="small"
                onChange={(e) => setEditableCellValue(e.target.value)}
                onPressEnter={(e) => updateLiteCostEstimate(record, e.target.value)}
                onBlur={(e) => updateLiteCostEstimate(record, e.target.value)}
              />
            );
          } else {
            return (
              <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center' }}>
                <EditOutlined />
                <div />
                <label>{c.toFixed(2)}</label>
              </div>
            );
          } 
        },
      },
  );

  const showCDFNumberError = (msg) => {
    notification.error({ 
      className:"cdf-number-error-notification-box",
      placement: 'topLeft',
      description: (
        <>
          <p>{msg}</p>
          <Image src="/images/cdf-number.png" preview={false} />
        </>
      ),
    }); 
  }

  const validateCDFNumber = () => {
    const cdfNumber = project.specifications.cdfNumber;
    if (!['1', '2', '3'].includes(cdfNumber[5])) {
      showCDFNumberError('Sixth digit value of the CDF number for cutting option must be either 1, 2, or 3.')
      return true
    }
    if (['1', '2'].includes(cdfNumber[5]) && (cdfNumber[6] !== 'F')) {
      showCDFNumberError('When sixth digit for cutting option is 1 or 2 (Full or Half cut length), Seventh digit value of the CDF number for finish must be F (Mill finish).');
      return true
    }
    if (!['F', 'U', 'B', 'N', 'C', 'V'].includes(cdfNumber[6])) {
      showCDFNumberError('Seventh digit value of the CDF number for finish must be either F, U, B, N, C or V.');
      return true
    }
    if (['1', '2'].includes(cdfNumber[5]) && (cdfNumber.slice(7, 12) !== '00000')) {
      showCDFNumberError('When sixth digit for cutting option is 1 or 2 (Full or Half cut length), 8-12 digits value of the CDF number for cut length must be 00000 (5 zeroes).');
      return true
    }
    if (cdfNumber[5] === '3' && (cdfNumber.slice(7, 12) === '00000')) {
      showCDFNumberError('When sixth digit for cutting option is 3 (Custom cut length), 8-12 digits value of the CDF number for cut length must be non zeros.');
      return true
    }
    const rohsValue = cdfNumber.length > 12 ? cdfNumber[12] : '';
    if (['1', '2'].includes(cdfNumber[5])) {
      if (rohsValue !== '') {
        showCDFNumberError('When sixth digit for cutting option is 1 or 2 (Full or Half cut length), 13 digit of the CDF number must be blank.');
        return true
      }
    } else {
      if (cdfNumber[6] === 'C' && rohsValue !== '') {
        showCDFNumberError('When seventh digit for Gold Chromate - Non-RoHS finish is C, 13 digit of the CDF number must be blank.');
        return true
      }
      if (cdfNumber[6] !== 'C' && rohsValue !== 'G') {
        showCDFNumberError('When seventh digit for RoHS finish is either F, U, B, N or V, 13 digit of the CDF number must be G.');
        return true
      }
  }
  return false;
  }

  const saveCosting = async() => {
    setLoading(true)
    var validCDFNumber = true;
    var cdfNumber = ''
    if (project.specifications.cdfNumberProvided) {
      if (project.specifications.cdfNumber && project.specifications.cdfNumber.length > 11 && project.specifications.cdfNumber.length < 14) {
        cdfNumber = project.specifications.cdfNumber;
        if (validateCDFNumber()) {
          setLoading(false);
          return
        }
      } else {
        validCDFNumber = false;
      }
    } else {
      cdfNumber = customCDFNumber;
    }

    if (validCDFNumber) {
      const newEstimates = await getLiteCostEstimates(cdfNumber, project);
      const costedCDF = newEstimates.length > 0 ? cdfNumber : '';
      const newLiteCosting = {liteCostEstimates: newEstimates, costedCDF: costedCDF, isDirty: false}
      setCostEstimates(newEstimates);
      setCostedCDFNumber(costedCDF);
      updateProject([{ path: `designs.${0}.liteCosting`, value: newLiteCosting }]);
    } else {
      message.error("Please enter valid 12 or 13 digit CDF number.");
    }   
    // updateProject({ path: `designs.${project.activeDesignId}.costEstimates`, value: newEstimates });
    setLoading(false);
  };

  const isDirtyEstimate = project.designs[0].liteCosting.liteCostEstimates.length > 0 && project.designs[0].liteCosting.isDirty;

  return (
    <Card
      title={
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', alignItems: 'center' }}>
          <Space>
            <div>Cost Estimate</div>
            <Button type="primary" loading={loading} onClick={saveCosting} style={ isDirtyEstimate? {backgroundColor: 'red', borderColor: 'red'}: {}}>
              { isDirtyEstimate ? 'Reupdate' : 'Update' }
            </Button>
          </Space>
          <div />
          {costedCDFNumber ? <div style={{ fontWeight: 'normal', fontSize: '14px' }}>{`Costed CDF number: ${costedCDFNumber}`}</div> : <></>}
        </div>
      }
      style={{ width: '800px' }}
      bodyStyle={{ overflow: 'auto', height: `calc(100vh - ${cardHeaderHeight} - ${panelHeaderFooterHeight})` }}
      bordered={true}
    >
      { project.quoteSpecs.highProjectValue ? <></> :
      <Collapse
        defaultActiveKey={['laconia']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        <Panel header={<b>Laconia, USA</b>} key="laconia">
          <Table
            className="costing-table"
            size="small"
            columns={tableColumns}
            dataSource={costEstimates}
            scroll={{ x: 'max-content' }}
            pagination={false}
            bordered
            rowKey={(record) => `lite-costing-estimates-${record.quantity}`}
          />
          <section className="table-footer">
            <p>All cost/price values are in US$</p>
          </section>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr auto 1fr auto 1fr', alignItems: 'center' }}>
            <div/>
              <></>
              {/* <GenerateLiteCostingPDF project={project} cdfNumber={costedCDFNumber} costEstimates={costEstimates} /> */}
            <div/>
              <></>
              {/* <GenerateLiteCostingXLSX project={project} cdfNumber={costedCDFNumber} costEstimates={costEstimates}/> */}
            <div/>
              <GenerateCustomerLiteQuotePDF project={project} updateProject={updateProject} cdfNumber={costedCDFNumber}  costEstimates={costEstimates} standardPartsIds={standardPartsIds}/>
            <div/>
          </div>
        </Panel>
      </Collapse>  
    }   
    </Card>
  )
}

const LiteCostingForm = ({ project, customCDFNumber, standardPartsIds, selectedStep, show, onFinishChange, cdfOracle}) => {
  const designIndex = 0;
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const auth = useRecoilValue(authState);
  const projects = useRecoilValue(projectsState({ userId: auth.username, isAdmin }));

  const validProjectName = (value) => {
    const str = value.trim();
    if (str === '') return 0;
    const matches = isAdmin
      ? projects
          .filter(({ userId }) => userId === auth.username)
          .filter(({ id, name }) => id !== project.id && name.localeCompare(str) === 0)
      : projects.filter(({ id, name }) => id !== project.id && name.localeCompare(str) === 0);
    return matches.length === 0;
  };

  const copyCdfNumber = () => {
    if (project.specifications.cdfNumberProvided) {
      navigator.clipboard.writeText(cdfOracle);
    } else {
      navigator.clipboard.writeText(project.designs[designIndex].extrusion.vendorId);
    }
    notification.success({ 
      placement: 'top',
      description: 'Copied Oracle # to clipboard.',
      duration: 2.0,
    }); 
  }

  return (

    <LayoutStepPanel show={show && selectedStep === 0}>
      <Card title="Quote Details" bordered={false}>
        <Item
          name="name"
          initialValue={project.name}
          label="Project name"
          style={{ width: inputWidth }}
          validateTrigger={['onChange', 'onFocus']}
          validateFirst={true}
          rules={[
            { required: true, message: 'Project name is required' },
            // do not need to use getFieldValue(fieldName)
            () => ({
              validator(_, value) {
                if (validProjectName(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Project name not unique'));
              },
            }),
          ]}
        >
          <Input />
        </Item>
        <Item hidden={project.quoteSpecs.highProjectValue}>
          <Item
            label="CDF number provided"
            name="specifications.cdfNumberProvided"
            initialValue={project.specifications.cdfNumberProvided}
            valuePropName="checked"
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Item>

          <Item
            label="CDF number"
            style={{ marginBottom: 0 }}
            hidden={!project.specifications.cdfNumberProvided}
          >
            <Space>
              <Item
                name="specifications.cdfNumber"
                initialValue={project.specifications.cdfNumber}
                style={{ width: inputWidth }}
              >
                <Input maxLength={13}/>
              </Item>
              <Popover placement="right" content={cdfNumberHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Oracle #" style={{ marginBottom: 0 }} hidden={!project.specifications.cdfNumberProvided}>
            <Space>
              <Item>
                <Input disabled value={cdfOracle} />
              </Item>
              <Popover placement="right" content={oracleHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
              <Item>
                <Button onClick={copyCdfNumber} icon={<CopyOutlined title="Copy Oracle #" />} />
              </Item>
            </Space>
          </Item>

          <Item
            label="Standard part number and class"
            style={{ marginBottom: 0 }}
            hidden={project.specifications.cdfNumberProvided}
          >
            <Space>
              <Item
                name={`designs.${designIndex}.extrusion.partId`}
                initialValue={project.designs[designIndex].extrusion.partId}
              >
                <Select showSearch style={{ width: selectWidth }} options={standardPartsIds} />
              </Item>
              <Popover placement="right" content={classHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Oracle #" style={{ marginBottom: 0 }} hidden={project.specifications.cdfNumberProvided}>
            <Space>
              <Item>
                <Input disabled value={project.designs[designIndex].extrusion.vendorId} />
              </Item>
              <Popover placement="right" content={oracleHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
              <Item>
                <Button onClick={copyCdfNumber} icon={<CopyOutlined title="Copy Oracle #" />} />
              </Item>
            </Space>
          </Item>

          <Item
            label="Cutting option"
            name="quoteSpecs.cuttingOption"
            initialValue={project.quoteSpecs.cuttingOption}
            hidden={project.specifications.cdfNumberProvided}
            style={{ width: inputWidth }}
          >
            <Select>
              <Option value="FULL">Full stick length</Option>
              <Option value="SHORT">Half stick length</Option>
              <Option value="CUSTOM">Cut to custom length</Option>
            </Select>
          </Item>

          <Item label="Finish" hidden={project.specifications.cdfNumberProvided} style={{ marginBottom: 0 }}>
            <Space>
              <Item
                style={{ width: inputWidth }}
              >
                <Select value={project.quoteSpecs.finish.style} disabled={project.quoteSpecs.cuttingOption === type.cuttingType.full || project.quoteSpecs.cuttingOption === type.cuttingType.short} onChange={onFinishChange}>
                  <Option value="MILLFINISH"><b>Mill</b> as extruded finish</Option>
                  <Option value="ETCHWASH"><b>Etch/Wash - RoHS</b> No finish; Clean part</Option>
                  <Option value="AAVSHIELD3"><b>Chromate - RoHS</b> Clear (AavShield3); MIL-DTL-5541 Type II</Option>
                  <Option value="CHROMATE"><b>Chromate - Non-RoHS</b> Gold or Clear; MIL-DTL-5541 Type I</Option>
                  <Option value="ANODIZE"><b>Anodize - RoHS</b> Black (Class 2); MIL-A-8625 Type II</Option>
                  <Option value="ANODIZECLEAR"><b>Anodize - RoHS</b> Clear (Class 1); MIL-A-8625 Type II</Option>
                </Select>
              </Item>
              <Popover placement="right" content={finishHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item 
            label="Cut length" 
            hidden={project.specifications.cdfNumberProvided || project.quoteSpecs.cuttingOption !== type.cuttingType.custom}
            style={{ marginBottom: 0 }}
          >
            <Space>
              <Item
                name={`designs.${designIndex}.extrusion.dimensions.length`}
                initialValue={project.designs[designIndex].extrusion.dimensions.length}
              >
                <Measurement.Dimension 
                  minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}  
                  maximum={{ type: 'DIMENSION', value: 99.99, units: 'in' }}
                />
              </Item>
              <Popover placement="right" content={cutLengthHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item
            label="Custom CDF number"
            hidden={project.specifications.cdfNumberProvided}
            style={{ marginBottom: 0 }}
          >
            <Space>
              <Item style={{ width: inputWidth }}>
                <Input  value={customCDFNumber} maxLength={13} disabled/>
              </Item>
              <Popover placement="right" content={cdfNumberHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>     
          </Item>

          <Item
            label="Price list"
            name="quoteSpecs.priceType"
            initialValue={project.quoteSpecs.priceType}
            style={{ width: inputWidth }}
          >
            <Select>
              <Option value="LCA1">LC-A1</Option>
              <Option value="LCB1">LC-B1</Option>
            </Select>
          </Item>

          <Item label="Quantities" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.quoteHeader.quantity" initialValue={project.quoteSpecs.quoteHeader.quantity}>
                <EditableTag addLabel="Quantity" />
              </Item>
              <Popover placement="right" content={quantityHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

        </Item>
      </Card>
    </LayoutStepPanel>
  )
}

export const ProjectLiteCosting = ({ project, updateProject, previousTab, setActiveTab, show }) => {
  const [form] = Form.useForm();
  const [selectedStep, setSelectedStep] = useState(0);
  const [customCDFNumber, setCustomCDFNumber] = useState('');
  const [cdfOracle, setCdfOracle] = useState('');
  const [cdfPartId, setCdfPartId] = useState('');
  const [standardPartsIds, setStandardPartsIds] = useState([]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    async function featchStandardParts() {
      const partIds = await getStandardPartsIds();
      setStandardPartsIds(partIds);

      // Get oracle number from part number provided in cdf number.
      if (project.specifications.cdfNumber.length >= 5) {
        const partId = project.specifications.cdfNumber.slice(0, 5);
        setCdfPartId(partId);
        const standardPart = partIds.find(item => item.value === partId);
        if (standardPart) {
          setCdfOracle(standardPart.oracle);
        }
      }  
    }
    featchStandardParts();
    const cdfNum = calcCustomCDFNumber();
    setCustomCDFNumber(cdfNum);
  }, []);

  // const onItemChange = (value) => {
  //   const path = value[0].name[0];
  //   const contactItems = [
  //     'customerContact',
  //     'customerAddress',
  //     'customerEmail',
  //     'customerPhone',
  //     'useUserContactAsSales',
  //     'salesContact',
  //     'salesEmail',
  //     'salesPhone',
  //   ];
  //   if (path === 'rawMaterialCost') {
  //     setFactory({ ...factory, rawMaterialCost: value[0].value });
  //   } else {
  //     const updatedItems = [{ path: value[0].name[0], value: value[0].value }]
  //     const pathArray = path.split('.');
  //     const itemName = pathArray.length > 1 ? pathArray[pathArray.length - 1] : path;
  //     if (!contactItems.includes(itemName)) {
  //       // Make cost estimates isdirty value true for all designs since change in the costing input.
  //       for (let did = 0; did < 4; did++) {
  //         if (project.designs[did].costEstimates) {
  //           const  dirtyEstimates = project.designs[did].costEstimates.map(quote => {return {...quote, isDirty: true}});
  //           updatedItems.push({path: `designs.${did}.costEstimates`, value: dirtyEstimates})
  //         }  
  //       }
  //     }
      
  //     updateProject(updatedItems)   
  //   }
  // };

  const calcCustomCDFNumber = () => {
    const partId = project.designs[0].extrusion.partId.toString().padStart(5, '0');
    const cuttingValue = cuttingOptions[project.quoteSpecs.cuttingOption];
    const finishValue = finishValues[project.quoteSpecs.finish.style];
    var cl = '00000';
    var rohsValue =''
    if (project.quoteSpecs.cuttingOption === type.cuttingType.custom) {
      cl = convertValueUnitsTo({ value: project.designs[0].extrusion.dimensions.length, to: 'in' }).toFixed(3);
      cl = cl.replace('.', '');
      cl = cl.padStart(5, '0');
      rohsValue = rohsFinishes.includes(project.quoteSpecs.finish.style) ? 'G' : ''
    } else {
      rohsValue = ''
    }
    const cdfNumber = partId + cuttingValue + finishValue + cl + rohsValue;
    return cdfNumber;
  };

  const updateCustomCDFNumber = (updatedItem, value) => {
    var newCustomCDFNum = customCDFNumber;
    var cl = '00000';
    if (updatedItem === 'partId') {
      const partId = value.toString().padStart(5, '0');
      newCustomCDFNum = partId + newCustomCDFNum.slice(5);
    } else if (updatedItem === 'cuttingOption') {
      const cuttingValue = cuttingOptions[value];
      if (value === type.cuttingType.custom) {
        cl = convertValueUnitsTo({ value: project.designs[0].extrusion.dimensions.length, to: 'in' }).toFixed(3);
        cl = cl.replace('.', '');
        cl = cl.padStart(5, '0');
      } 
      const finishValue =  value === type.cuttingType.full || value === type.cuttingType.short ? finishValues[type.finishStyle.millfinish] : newCustomCDFNum.slice(6, 7);
      // const rohsValue = rohsFinishes.includes(type.finishStyle.millfinish) ? 'G' : ''
      const rohsValue = '';
      newCustomCDFNum = newCustomCDFNum.slice(0, 5) + cuttingValue + finishValue + cl + rohsValue;  
    } else if (updatedItem === 'style') {
      newCustomCDFNum = newCustomCDFNum.slice(0, 12);
      const rohsValue = rohsFinishes.includes(value) ? 'G' : ''
      const finishValue = finishValues[value];
      newCustomCDFNum = newCustomCDFNum.slice(0, 6) + finishValue + newCustomCDFNum.slice(7) + rohsValue; 
    } else if (updatedItem === 'length') {
      const rohsValue = newCustomCDFNum.length === 13 ? newCustomCDFNum[12] : '';
      newCustomCDFNum = newCustomCDFNum.slice(0, 12);
      cl = convertValueUnitsTo({ value: value, to: 'in' }).toFixed(3);
      cl = cl.replace('.', '');
      cl = cl.padStart(5, '0');
      newCustomCDFNum = newCustomCDFNum.slice(0, 7) + cl + rohsValue ;
    }
    setCustomCDFNumber(newCustomCDFNum);
  };

  const changeExtrusionCode = (value) => {
    const designId = 0;
    const unitSystem = project.units;
    const lengthUnit = unitSystem === type.system.american ? 'in' : 'mm';
    const result = getExtrusionData({ partId: value });
    result.then((res) => {
      const extrusionInfo = res.extrusion;
      const newBaseWidth = convertUnits({ value: extrusionInfo.dimensions.width, to: lengthUnit }); // Note: - extrusionInfo.baseWidth does not have value so use from dimensions.width
      const newBaseThick = convertUnits({
        value: { ...dimension, value: extrusionInfo.baseThick, units: 'in' },
        to: lengthUnit,
      });
      // const newLength = extrusionInfo.dimensions.length;
      const newFinHeight = convertUnits({
        value: { ...dimension, value: extrusionInfo.finHeight, units: 'in' },
        to: lengthUnit,
      });
      const newFinThick = convertUnits({
        value: { ...dimension, value: extrusionInfo.finThick, units: 'in' },
        to: lengthUnit,
      });
      const newNumFins = extrusionInfo.numFins;
      const newFinPitch = convertUnits({
        value: { ...dimension, value: extrusionInfo.finPitch, units: 'in' },
        to: lengthUnit,
      });
      const newWidth = convertUnits({ value: extrusionInfo.dimensions.width, to: lengthUnit });
      const newHeight = convertUnits({ value: extrusionInfo.dimensions.height, to: lengthUnit });
      const newPerimeter = convertUnits({
        value: { ...dimension, value: extrusionInfo.perimeter, units: 'in' },
        to: lengthUnit,
      });
      const newStickLength = convertUnits({
        value: { ...dimension, value: extrusionInfo.stickLength, units: 'in' },
        to: lengthUnit,
      });
      const wtPerFeetUnit = unitSystem === type.system.american ? 'lb/ft' : 'kg/m';
      const newWeightPerFeet = convertUnits({
        value: { ...linearDensity, value: extrusionInfo.weightPerFeet, units: 'lb/ft' },
        to: wtPerFeetUnit,
      });
      // const stockUnit = unitSystem === type.system.american ? 'ft' : 'm';
      const nonFlatback = extrusionInfo.nonFlatback;
      const length = project.designs[designId].extrusion.dimensions.length; // Note: db don't have length data
      const newExtrusion = {
        ...project.designs[designId].extrusion,
        partId: value,
        baseWidth: newBaseWidth,
        baseThick: newBaseThick,
        dimensions: { width: newWidth, height: newHeight, length: length },
        finHeight: newFinHeight,
        finThick: newFinThick,
        finPitch: newFinPitch,
        numFins: newNumFins,
        perimeter: newPerimeter,
        class: extrusionInfo.class,
        vendorId: extrusionInfo.vendorId,
        stickLength: newStickLength,
        weightPerFeet: newWeightPerFeet,
        nonFlatback: nonFlatback,
        hasOffset: false,
      };
      let updateItems = [
        { path: `designs.${designId}.extrusion`, value: newExtrusion },
        { path: `designs.${designId}.liteCosting.isDirty`, value: true },
      ];
      updateProject(updateItems);
    });
  };

  const onItemChange = (value) => {
    const customCDFNumberItems = ['partId', 'cuttingOption', 'style', 'length'];
    const path = value[0].name[0];
    const pathArray = path.split('.');
    const itemName = pathArray.length > 1 ? pathArray[pathArray.length - 1] : path;
    const itemValue =  value[0].value;
    if (customCDFNumberItems.includes(itemName)) {
      updateCustomCDFNumber(itemName, itemValue);
    };
    var updatedItems = [{ path: path, value: itemValue }, {path: 'designs[0].liteCosting.isDirty', value: true}];
    if(itemName === 'cuttingOption' && (itemValue === type.cuttingType.full || itemValue === type.cuttingType.short)) {
      updatedItems.push({ path: 'quoteSpecs.finish.style', value: type.finishStyle.millfinish })
    }

    if (itemName === 'cdfNumber') {
      if (itemValue.length >= 5) {
        const partId = itemValue.slice(0, 5);
        if (partId !== cdfPartId) {
          setCdfPartId(partId);
          const standardPart = standardPartsIds.find(item => item.value === partId);
          if (standardPart) {
            setCdfOracle(standardPart.oracle);
          }
          else {
            setCdfOracle('');
          }
        }  
      }
      else {
        setCdfPartId('');
        setCdfOracle('');
      }
    }

    if (itemName === 'partId') {
      changeExtrusionCode(itemValue);
    } else {
      updateProject(updatedItems);
    } 
  };

  const onFinishChange = (finishValue) => {
    onItemChange([{name: ['quoteSpecs.finish.style'], value: finishValue}])
  };

  return (
    <LayoutWorkspace
      steps={steps}
      selectedStep={selectedStep}
      setSelectedStep={setSelectedStep}
      previousTab={previousTab}
      setActiveTab={setActiveTab}
      left={
        <Form form={form} name="costing" layout="vertical" onFieldsChange={onItemChange} {...layout}>
         
         <div style={{ display: 'grid', gap: '8px', gridTemplateColumns: '1fr auto' }}>
              <LiteCostingForm project={project} customCDFNumber={customCDFNumber} selectedStep={selectedStep} standardPartsIds={standardPartsIds} show={show} onFinishChange={onFinishChange} cdfOracle={cdfOracle} /> 
              <LiteCostEstimateGrid project={project} customCDFNumber={customCDFNumber} updateProject={updateProject} standardPartsIds={standardPartsIds} show={show} />
          </div>
        </Form>
      }
      expandRight={true}
    />
  );
};
