import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Card, Form, Input, Radio, InputNumber, Popover, Space, Button, Image, Popconfirm, Select, Switch } from 'antd';
import { QuestionCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getExtrusionData, getClosestExtrusionMatch, getOptimalExtrusionDesigns } from '../costing/project-api';
import { LayoutWorkspace, LayoutStepPanel } from '../../layout';
import { Measurement } from '../../common';
import { StandardExtrusionSelectModal } from './standard-extrusion-select-modal';
import { OptimalExtrusionSelectModal } from './optimal-extrusion-select-modal';
import { dimension, linearDensity, type } from '../../../state/units';
import { userRoleState } from '../../../state/auth';
import { calcExtrusionDims } from '../../../utils/calc-extrusion-dims';
import { convertUnits } from '../../../utils/unit-conversion';
import {
  extrusionCodeHelp,
  extrusionTypeHelp,
  classHelp,
  cutLengthHelp,
  baseThicknessHelp,
  finThicknessHelp,
  finHeightHelp,
  finPitchHelp,
  baseWidthHelp,
  finNumbersHelp,
  oracleHelp,
  weightByHeightHelp,
  heatsinkWidthHelp,
  heatsinkHeightHelp,
  heatsinkHasOffsetHelp,
  heatsinkLeftOffsetHelp,
  heatsinkRightOffsetHelp,
} from '../../common/tooltip';
import { dirtyCostEstimate } from '../../../utils/dirty-cost-estimate';

const { Item } = Form;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const selectWidth = '108px';

const HeatSinkForm = ({
  project,
  selectStandardExtrusionClick,
  selectOptimalExtrusionClick,
  resetExtrusion,
  updateProject,
}) => {
  const [resetToId, setResetToId] = useState(1);
  const userRole = useRecoilValue(userRoleState);
  const extrusionTypeOptions = [
    { label: 'Customer', value: 0, disabled: !project.specifications.customerExtrusionProvided },
    { label: 'Standard', value: 1 },
    {
      label: 'Optimal',
      value: 2,
      disabled:
        !project.specifications.thermalSpecificationsProvided ||
        userRole === type.userRole.sales ||
        userRole === type.userRole.ops,
    },
    { label: 'Custom', value: 3 },
  ];
  const finLayoutStyleOptions = [
    { label: 'Number of fins', value: type.finLayoutStyle.numFins },
    { label: 'Fin pitch', value: type.finLayoutStyle.finPitch },
  ];
  const resetOptions = [
    { label: 'Customer', value: 0, hidden: !project.specifications.customerExtrusionProvided },
    { label: 'Standard', value: 1 },
    { label: 'Optimal', value: 2, hidden: !project.specifications.thermalSpecificationsProvided },
  ];
  const specifyHeightByOptions = [
    { label: 'Overall height', value: type.specifyExtrusionHeightBy.overall },
    { label: 'Fin height', value: type.specifyExtrusionHeightBy.finHeight },
  ];

  const getResetDesignId = () => {
    if (resetToId === 0 && !project.specifications.customerExtrusionProvided) {
      return undefined;
    } else if (resetToId === 2 && !project.specifications.thermalSpecificationsProvided) {
      return undefined;
    } else {
      return resetToId;
    }
  };

  const confirmReset = () => {
    resetExtrusion(resetToId);
  };

  const updateExtrusionDesign = (e) => {
    updateProject({ path: 'activeDesignId', value: e.target.value });
  };

  const updateStandardLength = (newLength) => {
    updateProject([
      { path: `designs.${project.activeDesignId}.extrusion.dimensions.length`, value: newLength },
      {
        path: `designs.${project.activeDesignId}.costEstimates`,
        value: dirtyCostEstimate(project, project.activeDesignId),
      },
    ]);
  };

  const updateAutoCalcWeightPerFeet = (newValue) => {
    updateProject([
      { path: `designs.${project.activeDesignId}.extrusion.autoCalcWeightPerFeet`, value: newValue },
      {
        path: `designs.${project.activeDesignId}.costEstimates`,
        value: dirtyCostEstimate(project, project.activeDesignId),
      },
    ]);
  };

  const updateWeightPerFeet = (newWeightPerFeet) => {
    updateProject([
      { path: `designs.${project.activeDesignId}.extrusion.weightPerFeet`, value: newWeightPerFeet },
      {
        path: `designs.${project.activeDesignId}.costEstimates`,
        value: dirtyCostEstimate(project, project.activeDesignId),
      },
    ]);
  };

  return (
    <Card title="Heat Sink Details">
      <Item>
        <Item label="Extrusion type" style={{ marginBottom: 0 }}>
          <Space>
            <Item>
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={extrusionTypeOptions}
                value={project.activeDesignId}
                onChange={updateExtrusionDesign}
              />
            </Item>
            <Popover placement="right" content={extrusionTypeHelp}>
              <Item>
                <QuestionCircleOutlined />
              </Item>
            </Popover>
          </Space>
        </Item>
        <Item label="Part ID" hidden={project.activeDesignId !== 1} style={{ marginBottom: 0 }}>
          <Space>
            <Item style={{ width: 100 }}>
              <Input disabled value={project.designs[project.activeDesignId].extrusion.partId} />
            </Item>
            <Popover
              placement="right"
              content={extrusionCodeHelp({ imageCode: project.designs[project.activeDesignId].extrusion.partId })}
            >
              <Item>
                <QuestionCircleOutlined />
              </Item>
            </Popover>
            <Item>
              <Button onClick={selectStandardExtrusionClick}>Select Extrusion</Button>
            </Item>
            <Item>
              {project.designs[project.activeDesignId].extrusion.partId !== '0' && (
                <Image
                  src={`https://www.boydcorp.com/files/part-documentation/extrusion-${
                    project.designs[project.activeDesignId].extrusion.partId
                  }-drawing.png`}
                  preview={false}
                />
              )}
            </Item>
          </Space>
        </Item>
        <Item hidden={project.activeDesignId !== 2}>
          <Button onClick={selectOptimalExtrusionClick}>Select Extrusion</Button>
        </Item>
        <Item
          style={{ marginBottom: 0 }}
          hidden={
            (!project.specifications.customerExtrusionProvided && project.activeDesignId === 0) ||
            (!project.specifications.thermalSpecificationsProvided && project.activeDesignId === 2)
          }
        >
          <Item hidden={[2, 3].includes(project.activeDesignId)} style={{ marginBottom: 0 }}>
            <Item label="Non-flatback extrusion">
              <Switch
                disabled
                checked={project.designs[project.activeDesignId].extrusion.nonFlatback}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Item>

            <Item label="Cut length" style={{ marginBottom: 0 }}>
              <Space>
                <Item>
                  <Measurement.Dimension
                    disabled={project.specifications.customerExtrusionProvided}
                    value={project.designs[project.activeDesignId].extrusion.dimensions.length}
                    onChange={updateStandardLength}
                  />
                </Item>
                <Popover placement="right" content={cutLengthHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Width" style={{ marginBottom: 0 }}>
              <Space>
                <Item>
                  <Measurement.Dimension
                    disabled
                    value={project.designs[project.activeDesignId].extrusion.dimensions.width}
                  />
                </Item>
                <Popover placement="right" content={heatsinkWidthHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Height" style={{ marginBottom: 0 }}>
              <Space>
                <Item>
                  <Measurement.Dimension
                    disabled
                    value={project.designs[project.activeDesignId].extrusion.dimensions.height}
                  />
                </Item>
                <Popover placement="right" content={heatsinkHeightHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item
              label="Fin thickness"
              style={{ marginBottom: 0 }}
              hidden={project.designs[project.activeDesignId].extrusion.nonFlatback}
            >
              <Space>
                <Item>
                  <Measurement.Dimension disabled value={project.designs[project.activeDesignId].extrusion.finThick} />
                </Item>
                <Popover placement="right" content={finThicknessHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item
              label="Fin pitch"
              style={{ marginBottom: 0 }}
              hidden={project.designs[project.activeDesignId].extrusion.nonFlatback}
            >
              <Space>
                <Item>
                  <Measurement.Dimension disabled value={project.designs[project.activeDesignId].extrusion.finPitch} />
                </Item>
                <Popover placement="right" content={finPitchHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item
              label="Number of fins"
              style={{ marginBottom: 0 }}
              hidden={project.designs[project.activeDesignId].extrusion.nonFlatback}
            >
              <Space>
                <Item>
                  <InputNumber min={2} disabled value={project.designs[project.activeDesignId].extrusion.numFins} />
                </Item>
                <Popover placement="right" content={finNumbersHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item 
              label="Has offset"
              hidden={project.designs[project.activeDesignId].extrusion.nonFlatback}
              style={{ marginBottom: 0 }}
            >
              <Space>
                <Item>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={project.designs[project.activeDesignId].extrusion.hasOffset}
                    disabled
                  />
                </Item>
                <Popover placement="right" content={heatsinkHasOffsetHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
          
            <Item
              label="Left offset"
              hidden={!project.designs[project.activeDesignId].extrusion.hasOffset || project.designs[project.activeDesignId].extrusion.nonFlatback}
              style={{ marginBottom: 0 }}
            >
              <Space>
                <Item>
                  <Measurement.Dimension 
                    disabled 
                    value={project.designs[project.activeDesignId].extrusion.leftOffset}
                    minimum={dimension}
                  />
                </Item>
                <Popover placement="right" content={heatsinkLeftOffsetHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item
              label="Right Offset"
              hidden={!project.designs[project.activeDesignId].extrusion.hasOffset || project.designs[project.activeDesignId].extrusion.nonFlatback}
              style={{ marginBottom: 0 }}
            >
              <Space>
                <Item>
                  <Measurement.Dimension 
                    disabled 
                    value={project.designs[project.activeDesignId].extrusion.rightOffset}
                    minimum={dimension} 
                  />
                </Item>
                <Popover placement="right" content={heatsinkRightOffsetHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
          
            <Item
              label="Class"
              style={{ marginBottom: 0 }}
              hidden={project.activeDesignId !== 1 && project.designs[project.activeDesignId].extrusion.nonFlatback}
            >
              <Space>
                <Item>
                  <Input disabled value={project.designs[project.activeDesignId].extrusion.class} />
                </Item>
                <Popover placement="right" content={classHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Oracle #" style={{ marginBottom: 0 }} hidden={project.activeDesignId !== 1}>
              <Space>
                <Item>
                  <Input disabled value={project.designs[project.activeDesignId].extrusion.vendorId} />
                </Item>
                <Popover placement="right" content={oracleHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item
              label="Auto calculate weight per length from profile"
              hidden={project.activeDesignId !== 0 || project.designs[project.activeDesignId].extrusion.nonFlatback}
            >
              <Switch
                checked={project.designs[project.activeDesignId].extrusion.autoCalcWeightPerFeet}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={updateAutoCalcWeightPerFeet}
              />
            </Item>

            <Item
              label="Weight/length"
              style={{ marginBottom: 0 }}
              hidden={
                project.activeDesignId !== 1 &&
                !project.designs[project.activeDesignId].extrusion.nonFlatback &&
                project.designs[project.activeDesignId].extrusion.autoCalcWeightPerFeet
              }
            >
              <Space>
                <Item>
                  <Measurement.LinearDensity
                    disabled={
                      project.designs[project.activeDesignId].extrusion.nonFlatback ||
                      project.designs[project.activeDesignId].extrusion.autoCalcWeightPerFeet
                    }
                    value={project.designs[project.activeDesignId].extrusion.weightPerFeet}
                    onChange={updateWeightPerFeet}
                  />
                </Item>
                <Popover placement="right" content={weightByHeightHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Perimeter" hidden={!project.designs[project.activeDesignId].extrusion.nonFlatback}>
              <Measurement.Dimension disabled value={project.designs[project.activeDesignId].extrusion.perimeter} />
            </Item>
          </Item>

          <Item hidden={project.activeDesignId === 0 || project.activeDesignId === 1} style={{ marginBottom: 0 }}>
            <Item label="Cut length" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.dimensions.length`}
                  initialValue={project.designs[project.activeDesignId].extrusion.dimensions.length}
                >
                  <Measurement.Dimension
                    disabled={project.activeDesignId === 1 && project.specifications.customerExtrusionProvided}
                    minimum={{ ...dimension, value: 0.1 }}
                  />
                </Item>
                <Popover placement="right" content={cutLengthHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Base width" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.baseWidth`}
                  initialValue={project.designs[project.activeDesignId].extrusion.baseWidth}
                >
                  <Measurement.Dimension minimum={{ ...dimension, value: 0.1 }} />
                </Item>
                <Popover placement="right" content={baseWidthHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Base thickness" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.baseThick`}
                  initialValue={project.designs[project.activeDesignId].extrusion.baseThick}
                >
                  <Measurement.Dimension minimum={{ ...dimension, value: 0.1 }} />
                </Item>
                <Popover placement="right" content={baseThicknessHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item
              name={`designs.${project.activeDesignId}.extrusion.specifyHeightBy`}
              initialValue={project.designs[project.activeDesignId].extrusion.specifyHeightBy}
              label="Specify height by"
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={specifyHeightByOptions}
                disabled={project.specifications.standardExtrusionRequested}
              />
            </Item>

            <Item label="Overall height" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.dimensions.height`}
                  initialValue={project.designs[project.activeDesignId].extrusion.dimensions.height}
                >
                  <Measurement.Dimension
                    minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                    disabled={
                      project.designs[project.activeDesignId].extrusion.specifyHeightBy !==
                      type.specifyExtrusionHeightBy.overall
                    }
                  />
                </Item>
                <Popover placement="right" content={heatsinkHeightHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item label="Fin height" hidden={project.activeDesignId === 1} style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.finHeight`}
                  initialValue={project.designs[project.activeDesignId].extrusion.finHeight}
                >
                  <Measurement.Dimension
                    minimum={{ ...dimension, value: 0.1 }}
                    disabled={
                      project.designs[project.activeDesignId].extrusion.specifyHeightBy !==
                      type.specifyExtrusionHeightBy.finHeight
                    }
                  />
                </Item>
                <Popover placement="right" content={finHeightHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item label="Fin thickness" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.finThick`}
                  initialValue={project.designs[project.activeDesignId].extrusion.finThick}
                >
                  <Measurement.Dimension
                    disabled={project.activeDesignId === 1}
                    minimum={{ ...dimension, value: 0.1 }}
                  />
                </Item>
                <Popover placement="right" content={finThicknessHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item
              name={`designs.${project.activeDesignId}.extrusion.finLayoutStyle`}
              initialValue={project.designs[project.activeDesignId].extrusion.finLayoutStyle}
              label="Specify fins by"
              hidden={project.activeDesignId === 1}
            >
              <Radio.Group optionType="button" buttonStyle="solid" options={finLayoutStyleOptions} />
            </Item>
            <Item label="Number of fins" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.numFins`}
                  initialValue={project.designs[project.activeDesignId].extrusion.numFins}
                >
                  <InputNumber
                    min={2}
                    disabled={
                      project.activeDesignId === 1 ||
                      (project.activeDesignId !== 1 &&
                        project.designs[project.activeDesignId].extrusion.finLayoutStyle !==
                          type.finLayoutStyle.numFins)
                    }
                  />
                </Item>
                <Popover placement="right" content={finNumbersHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Fin pitch" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.finPitch`}
                  initialValue={project.designs[project.activeDesignId].extrusion.finPitch}
                >
                  <Measurement.Dimension
                    minimum={{ ...dimension, value: 0.1 }}
                    disabled={
                      project.activeDesignId === 1 ||
                      (project.activeDesignId !== 1 &&
                        project.designs[project.activeDesignId].extrusion.finLayoutStyle !==
                          type.finLayoutStyle.finPitch)
                    }
                  />
                </Item>
                <Popover placement="right" content={finPitchHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item
              label="Has offset"
              style={{ marginBottom: 0 }}
            >
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.hasOffset`}
                  initialValue={project.designs[project.activeDesignId].extrusion.hasOffset}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Item>
                <Popover placement="right" content={heatsinkHasOffsetHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item
              label="Left offset"
              hidden={!project.designs[project.activeDesignId].extrusion.hasOffset}
              style={{ marginBottom: 0 }}
            >
              <Space>       
                <Item 
                  name={`designs.${project.activeDesignId}.extrusion.leftOffset`}
                  initialValue={project.designs[project.activeDesignId].extrusion.leftOffset}
                >
                  <Measurement.Dimension minimum={dimension}/>
                </Item>
                <Popover placement="right" content={heatsinkLeftOffsetHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item
              label="Right Offset"
              hidden={!project.designs[project.activeDesignId].extrusion.hasOffset}
              style={{ marginBottom: 0 }}
            >
              <Space>
                <Item 
                    name={`designs.${project.activeDesignId}.extrusion.rightOffset`}
                    initialValue={project.designs[project.activeDesignId].extrusion.rightOffset}
                >
                  <Measurement.Dimension  minimum={dimension} />
                </Item>
                <Popover placement="right" content={heatsinkRightOffsetHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item
              name={`designs.${project.activeDesignId}.extrusion.autoCalcWeightPerFeet`}
              initialValue={project.designs[project.activeDesignId].extrusion.autoCalcWeightPerFeet}
              label="Auto calculate weight per length from profile"
              valuePropName="checked"
            >
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Item>

            <Item
              label="Weight/length"
              style={{ margin: 0 }}
              hidden={project.designs[project.activeDesignId].extrusion.autoCalcWeightPerFeet}
            >
              <Space>
                <Item
                  name={`designs.${project.activeDesignId}.extrusion.weightPerFeet`}
                  initialValue={project.designs[project.activeDesignId].extrusion.weightPerFeet}
                >
                  <Measurement.LinearDensity minimum={linearDensity} />
                </Item>
                <Popover placement="right" content={weightByHeightHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
          </Item>
        </Item>
        {/*
        <Item
          name={`designs.${project.activeDesignId}.extrusion.stickLength`}
          initialValue={project.designs[project.activeDesignId].extrusion.stickLength}
          label="Stick length"
        >
          <Measurement.Dimension disabled={project.activeDesignId === 1} minimum={{ ...dimension, value: 0.1 }} />
        </Item> 
        */}
        <Item hidden={project.activeDesignId !== 3}>
          <Space>
            <Select
              options={resetOptions}
              value={getResetDesignId()}
              style={{ width: selectWidth }}
              onSelect={(val) => setResetToId(val)}
            />
            <Popconfirm
              title="Are you sure to reset the custom extrusion?"
              onConfirm={confirmReset}
              okText="Yes"
              cancelText="No"
              disabled={getResetDesignId() === undefined}
            >
              <Button type="primary" disabled={getResetDesignId() === undefined}>
                Reset
              </Button>
            </Popconfirm>
          </Space>
        </Item>
      </Item>
    </Card>
  );
};

export const ProjectHeatSink = ({ project, updateProject, previousTab, nextTab, setActiveTab, show }) => {
  const [form] = Form.useForm();
  const [showStandardExtrusionSelect, setShowStandardExtrusionSelect] = useState(false);
  const [showOptimalExtrusionSelect, setShowOptimalExtrusionSelect] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [closestMatchData, setClosestMatchData] = useState([]);

  // extract the field name (path) and value
  const onItemChange = (value) => {
    const path = value[0].name[0];
    const pathArray = path.split('.');
    const itemName = pathArray.length > 1 ? pathArray[pathArray.length - 1] : path;
    const extrusionItems = [
      'length',
      'baseWidth',
      'baseThick',
      'finThick',
      'finHeight',
      'finPitch',
      'numFins',
      'height',
      'weightPerFeet',
      'autoCalcWeightPerFeet',
      'perimeter',
      'hasOffset',
      'leftOffset',
      'rightOffset',
    ];
    if (extrusionItems.indexOf(itemName) > -1) {
      let updateItems = calcExtrusionDims(project, path, itemName, value[0].value);
      const fieldsToUpdate = Object.fromEntries(
        updateItems.map((item) => {
          return [item.path, item.value];
        }),
      );
      form.setFieldsValue(fieldsToUpdate);
      updateItems.unshift({ path: value[0].name[0], value: value[0].value });
      updateItems.push({
        path: `designs.${project.activeDesignId}.costEstimates`,
        value: dirtyCostEstimate(project, project.activeDesignId),
      });
      updateProject(updateItems);
    } else {
      updateProject({ path: value[0].name[0], value: value[0].value });
    }
  };

  const onStandardSelectExtrusionOK = (code) => {
    setShowStandardExtrusionSelect(false);
    changeExtrusionCode(code);
  };

  const onOptimalSelectExtrusionOK = (flow) => {
    setShowOptimalExtrusionSelect(false);
    if (flow) {
      const toUnit = project.units === type.system.metric ? 'mm' : 'in';
      const results = closestMatchData.filter((data) => data.flow === flow)[0];
      const newLength = { ...dimension, value: results.length, units: toUnit };
      const newWidth = { ...dimension, value: results.width, units: toUnit };
      const newHeight = { ...dimension, value: results.baseThick + results.finHeight, units: toUnit };
      const newBaseWidth = newWidth;
      const newBaseThick = { ...dimension, value: results.baseThick, units: toUnit };
      const newFinThick = { ...dimension, value: results.finThick, units: toUnit };
      const newFinHeight = { ...dimension, value: results.finHeight, units: toUnit };
      const newFinPitch = { ...dimension, value: results.finPitch, units: toUnit };
      const newNumFins = results.numFins;
      const newPerimeter = {
        ...dimension,
        value: 2 * results.width + 2 * results.baseThick + 2 * results.finHeight * results.numFins,
        units: toUnit,
      };
      const newHasOffset = false;
      const newLeftOffset = { ...dimension };
      const newRightOffset = { ...dimension };

      const newExtrusion = {
        ...project.designs[2].extrusion,
        baseWidth: newBaseWidth,
        baseThick: newBaseThick,
        dimensions: { width: newWidth, height: newHeight, length: newLength },
        finHeight: newFinHeight,
        finThick: newFinThick,
        finPitch: newFinPitch,
        numFins: newNumFins,
        perimeter: newPerimeter,
        hasOffset: newHasOffset,
        leftOffset: newLeftOffset,
        rightOffset: newRightOffset,
      };

      const designId = 2;
      let updateItems = [
        { path: `designs.${designId}.extrusion`, value: newExtrusion },
        { path: `designs.${designId}.costEstimates`, value: dirtyCostEstimate(project, designId) },
      ];
      updateProject(updateItems);
      form.setFieldsValue({
        [`designs.${designId}.extrusion.dimensions.length`]: newLength,
        [`designs.${designId}.extrusion.dimensions.height`]: newHeight,
        [`designs.${designId}.extrusion.baseWidth`]: newBaseWidth,
        [`designs.${designId}.extrusion.baseThick`]: newBaseThick,
        [`designs.${designId}.extrusion.finThick`]: newFinThick,
        [`designs.${designId}.extrusion.finHeight`]: newFinHeight,
        [`designs.${designId}.extrusion.finPitch`]: newFinPitch,
        [`designs.${designId}.extrusion.numFins`]: newNumFins,
        [`designs.${designId}.extrusion.hasOffset`]: newHasOffset,
        [`designs.${designId}.extrusion.leftOffset`]: newLeftOffset,
        [`designs.${designId}.extrusion.rightOffset`]: newRightOffset,
      });
    }
  };

  const onStandardSelectExtrusion = async (e) => {
    setLoadingData(true);
    setShowStandardExtrusionSelect(true);
    const matchData = await getClosestExtrusionMatch(project);
    setClosestMatchData(matchData);
    setLoadingData(false);
  };

  const onOptimalSelectExtrusion = async (e) => {
    setLoadingData(true);
    setShowOptimalExtrusionSelect(true);
    const optimalDesignsData = await getOptimalExtrusionDesigns(project);
    setClosestMatchData(optimalDesignsData);
    setLoadingData(false);
  };

  const changeExtrusionCode = (value) => {
    // setImageCode(value);
    // const designId = 1;
    const unitSystem = project.units;
    const lengthUnit = unitSystem === type.system.american ? 'in' : 'mm';
    const result = getExtrusionData({ partId: value });
    result.then((res) => {
      const extrusionInfo = res.extrusion;
      const newBaseWidth = convertUnits({ value: extrusionInfo.dimensions.width, to: lengthUnit }); // Note: - extrusionInfo.baseWidth does not have value so use from dimensions.width
      const newBaseThick = convertUnits({
        value: { ...dimension, value: extrusionInfo.baseThick, units: 'in' },
        to: lengthUnit,
      });
      // const newLength = extrusionInfo.dimensions.length;
      const newFinHeight = convertUnits({
        value: { ...dimension, value: extrusionInfo.finHeight, units: 'in' },
        to: lengthUnit,
      });
      const newFinThick = convertUnits({
        value: { ...dimension, value: extrusionInfo.finThick, units: 'in' },
        to: lengthUnit,
      });
      const newNumFins = extrusionInfo.numFins;
      const newFinPitch = convertUnits({
        value: { ...dimension, value: extrusionInfo.finPitch, units: 'in' },
        to: lengthUnit,
      });
      const newWidth = convertUnits({ value: extrusionInfo.dimensions.width, to: lengthUnit });
      const newHeight = convertUnits({ value: extrusionInfo.dimensions.height, to: lengthUnit });
      const newPerimeter = convertUnits({
        value: { ...dimension, value: extrusionInfo.perimeter, units: 'in' },
        to: lengthUnit,
      });
      const newStickLength = convertUnits({
        value: { ...dimension, value: extrusionInfo.stickLength, units: 'in' },
        to: lengthUnit,
      });
      const wtPerFeetUnit = unitSystem === type.system.american ? 'lb/ft' : 'kg/m';
      const newWeightPerFeet = convertUnits({
        value: { ...linearDensity, value: extrusionInfo.weightPerFeet, units: 'lb/ft' },
        to: wtPerFeetUnit,
      });
      const stockUnit = unitSystem === type.system.american ? 'ft' : 'm';
      const newInventoryStock = convertUnits({
        value: { ...dimension, value: extrusionInfo.inventory, units: 'ft' },
        to: stockUnit,
      });
      const nonFlatback = extrusionInfo.nonFlatback;
      const length = project.designs[1].extrusion.dimensions.length; // Note: db don't have length data
      const newExtrusion = {
        ...project.designs[1].extrusion,
        partId: value,
        baseWidth: newBaseWidth,
        baseThick: newBaseThick,
        dimensions: { width: newWidth, height: newHeight, length: length },
        finHeight: newFinHeight,
        finThick: newFinThick,
        finPitch: newFinPitch,
        numFins: newNumFins,
        perimeter: newPerimeter,
        class: extrusionInfo.class,
        vendorId: extrusionInfo.vendorId,
        stickLength: newStickLength,
        weightPerFeet: newWeightPerFeet,
        nonFlatback: nonFlatback,
        hasOffset: false,
      };
      let updateItems = [
        { path: `designs.${1}.extrusion`, value: newExtrusion },
        { path: 'quoteSpecs.inventoryStock', value: newInventoryStock },
        { path: `designs.${1}.costEstimates`, value: dirtyCostEstimate(project, 1) },
      ];
      updateProject(updateItems);
    });
  };

  const resetCustomDesign = (designId) => {
    const extrusion = { ...project.designs[designId].extrusion, nonFlatback: false };
    const {
      dimensions,
      baseWidth,
      baseThick,
      finThick,
      finHeight,
      numFins,
      finPitch,
      stickLength,
      finLayoutStyle,
      weightPerFeet,
      autoCalcWeightPerFeet,
      hasOffset,
      leftOffset,
      rightOffset,
    } = extrusion;
    const customDesignId = 3;
    let updateItems = [
      { path: `designs.${customDesignId}.extrusion`, value: extrusion },
      { path: `designs.${customDesignId}.costEstimates`, value: dirtyCostEstimate(project, customDesignId) },
    ];
    updateProject(updateItems);
    form.setFieldsValue({
      [`designs.${customDesignId}.extrusion.dimensions.length`]: dimensions.length,
      [`designs.${customDesignId}.extrusion.dimensions.height`]: dimensions.height,
      [`designs.${customDesignId}.extrusion.baseWidth`]: baseWidth,
      [`designs.${customDesignId}.extrusion.baseThick`]: baseThick,
      [`designs.${customDesignId}.extrusion.finThick`]: finThick,
      [`designs.${customDesignId}.extrusion.finHeight`]: finHeight,
      [`designs.${customDesignId}.extrusion.finLayoutStyle`]: finLayoutStyle,
      [`designs.${customDesignId}.extrusion.finPitch`]: finPitch,
      [`designs.${customDesignId}.extrusion.numFins`]: numFins,
      [`designs.${customDesignId}.extrusion.stickLength`]: stickLength,
      [`designs.${customDesignId}.extrusion.weightPerFeet`]: weightPerFeet,
      [`designs.${customDesignId}.extrusion.autoCalcWeightPerFeet`]: autoCalcWeightPerFeet,
      [`designs.${customDesignId}.extrusion.hasOffset`]: hasOffset,
      [`designs.${customDesignId}.extrusion.leftOffset`]: leftOffset,
      [`designs.${customDesignId}.extrusion.rightOffset`]: rightOffset,
    });
  };

  return project ? (
    <>
      <LayoutWorkspace
        steps={[{ step: 0, title: 'Heat Sink Details' }]}
        previousTab={previousTab}
        nextTab={nextTab}
        setActiveTab={setActiveTab}
        left={
          <Form form={form} name="heatsink" layout="vertical" onFieldsChange={onItemChange} {...layout}>
            <LayoutStepPanel show={show}>
              <HeatSinkForm
                project={project}
                selectStandardExtrusionClick={onStandardSelectExtrusion}
                selectOptimalExtrusionClick={onOptimalSelectExtrusion}
                resetExtrusion={resetCustomDesign}
                updateProject={updateProject}
              />
            </LayoutStepPanel>
          </Form>
        }
        right={
          <Card title="Heat Sink Dimensions">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Image src="/images/extrusion-dims.png" width={'100%'} preview={false} />
            </div>
          </Card>
        }
      />
      <StandardExtrusionSelectModal
        selectedCode={parseInt(project.designs[1].extrusion.partId)}
        data={closestMatchData}
        unitSystem={project.units}
        visible={showStandardExtrusionSelect}
        showDeviation={project.specifications.customerExtrusionProvided}
        onCallback={(code) => onStandardSelectExtrusionOK(code.toString())}
        onCancel={(e) => setShowStandardExtrusionSelect(false)}
        loadingData={loadingData}
        project={project}
      />
      <OptimalExtrusionSelectModal
        selectedFlow={''}
        data={closestMatchData}
        unitSystem={project.units}
        visible={showOptimalExtrusionSelect}
        showDeviation={project.specifications.customerExtrusionProvided}
        onCallback={(code) => onOptimalSelectExtrusionOK(code.toString())}
        onCancel={(e) => setShowOptimalExtrusionSelect(false)}
        loadingData={loadingData}
      />
    </>
  ) : null;
};
