import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Form, Input, Modal } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import { shareProject } from './costing/project-api';
import { projectState } from '../../state/project';
import { authState } from '../../state/auth';
import { addActivity } from '../../state/activity';
import { type } from '../../state/units';
import { customersState } from '../../state/customers';

const { Item } = Form;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
};

const tailLayout = {
  wrapperCol: { offset: 20 },
};

export const ProjectShareModal = ({
  projectId,
  showButton = true,
  showIcon = false,
  show,
  close = () => {},
}) => {
  const auth = useRecoilValue(authState);
  const project = useRecoilValue(projectState({ userId: auth.username, projectId }));
  const [showModal, setShowModal] = useState(false);
  const customers = useRecoilValue(customersState(null));
  const customer = customers.filter(({ id }) => id === project.customerId) 

  const onCancel = () => {
    setShowModal(false);
    close();
  };

  const onShare = ({ email }) => shareProject(project, email).then((res) => {
    if (res) {
      addActivity({ 
        activity: type.activityType.prjShare, 
        userEmail: auth.email, 
        userId: project.userId,
        projectName: project.name,
        projectId: project.id,
        customer: customer.length === 1 ? customer[0].name : '',
        customerId: project.customerId,
      });
    }
      onCancel()
  });

  const visible = showModal || show;

  return (
    <>
      {showButton && <Button onClick={() => setShowModal(true)} icon={<ShareAltOutlined title="Share project" />} />}
      {showIcon && <ShareAltOutlined onClick={() => setShowModal(true)} />}
      <Modal title={'Share Project'} visible={visible} centered onCancel={onCancel} width={480} footer={null}>
        <Form name="share-project" {...layout} onFinish={onShare}>
          <Item
            name="email"
            label="User Email"
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Enter valid email!' },
            ]}
          >
            <Input />
          </Item>
          <Item {...tailLayout}>
            <Button type="primary" htmlType="submit" size="medium">
              Save
            </Button>
          </Item>
        </Form>
      </Modal>
    </>
  );
};
