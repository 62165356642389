import { SuspenseModal } from '../common';
import { CustomerForm } from './customer-form';

export const CustomerModal = ({ customerId = undefined, visible, onCancel }) => {
  return (
    <SuspenseModal
      title={customerId === undefined ? 'New Customer' : 'Edit Customer'}
      visible={visible}
      onCancel={onCancel}
    >
      <CustomerForm reset={visible} customerId={customerId} callback={onCancel} />
    </SuspenseModal>
  );
};
