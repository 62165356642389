import { useState } from 'react';
import { Tag, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// convert comma-delimited string '0,1,,2 ,3,4  ,cat,' to array of integers [1,2,3,4]
const stringToNumberArray = (str) =>
  str
    .split(',')
    .map((c) => parseInt(c))
    .filter(Boolean);
// convert array of integers [1,2,3,4] to comma-delimited string '1,2,3,4'
const numberArrayToString = (arr) => arr.join();

export const EditableTag = ({ value = '', addLabel = '', onChange }) => {
  const [tags, setTags] = useState(stringToNumberArray(value));
  const [addInputVisible, setAddInputVisible] = useState(false);

  const updateTags = (newTags) => {
    setTags(newTags);
    onChange(numberArrayToString(newTags));
  };

  const handleAddInputConfirm = (e) => {
    const newValue = parseInt(e.currentTarget.value);
    if (newValue && !tags.includes(newValue)) {
      updateTags([...tags, newValue]);
    }
    setAddInputVisible(false);
  };

  const handleClose = (removedTag) => updateTags(tags.filter((tag) => tag !== removedTag));

  const AddInput = ({ name = '' }) =>
    addInputVisible ? (
      <Input
        style={{ width: '60px' }}
        autoFocus
        type="text"
        size="small"
        onPressEnter={handleAddInputConfirm}
        onBlur={handleAddInputConfirm}
      />
    ) : (
      <Tag className="tag-add" onClick={() => setAddInputVisible(true)}>
        <PlusOutlined /> {name}
      </Tag>
    );

  return (
    <>
      {tags.map((tag) => (
        <Tag
          closable
          key={tag}
          onClose={(e) => {
            e.preventDefault();
            handleClose(tag);
          }}
        >
          {tag}
        </Tag>
      ))}
      <AddInput name={addLabel} />
    </>
  );
};
