import { Suspense } from 'react';
import { Modal } from 'antd';

export const SuspenseModal = ({ title, visible, onCancel, children }) => {
  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null} zIndex={1001}>
      <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
    </Modal>
  );
};
