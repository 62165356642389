import { useState, useEffect } from 'react';
import { Card, Form, Input, Radio, Select } from 'antd';
import { CustomerSelect } from '../../customer';
import { type } from '../../../state/units';
import { useRecoilValue } from 'recoil';
import { authState, hasGroup } from '../../../state/auth';
import { projectsState } from '../../../state/projects';
import { useProjects } from '../../common/use-projects';

const { Item } = Form;
const { Option } = Select;
const inputWidth = '432px';

export const SpecificationsPanel0 = ({ project, updateProject }) => {
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const auth = useRecoilValue(authState);
  const projects = useRecoilValue(projectsState({ userId: auth.username, isAdmin }));
  const [quoteNo, setQuoteNO] = useState(project.quoteSpecs.quoteHeader.quoteNo);
  const { tags } = useProjects();

  const validProjectName = (value) => {
    const str = value.trim();
    if (str === '') return 0;
    const matches = isAdmin
      ? projects
          .filter(({ userId }) => userId === auth.username)
          .filter(({ id, name }) => id !== project.id && name.localeCompare(str) === 0)
      : projects.filter(({ id, name }) => id !== project.id && name.localeCompare(str) === 0);
    return matches.length === 0;
  };

  // required to use state for Opportunity number (quote no) to avoid lagging comes while updating value
  useEffect(() => {
    setQuoteNO(project.quoteSpecs.quoteHeader.quoteNo);
  }, [project.quoteSpecs.quoteHeader.quoteNo]);

  const updateQuoteNo = (e) => {
    setQuoteNO(e.target.value);
    updateProject({ path: 'quoteSpecs.quoteHeader.quoteNo', value: e.target.value });
  };

  return (
    <Card title="Project Details" bordered={false}>
      <Item
        name="name"
        initialValue={project.name}
        label="Project name"
        style={{ width: inputWidth }}
        validateTrigger={['onChange', 'onFocus']}
        validateFirst={true}
        rules={[
          { required: true, message: 'Project name is required' },
          // do not need to use getFieldValue(fieldName)
          () => ({
            validator(_, value) {
              if (validProjectName(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Project name not unique'));
            },
          }),
        ]}
      >
        <Input />
      </Item>
      <Item name="description" initialValue={project.description} label="Description" style={{ width: inputWidth }}>
        <Input.TextArea />
      </Item>
      <Item name="units" initialValue={project.units} label="Units">
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={type.system.metric} disabled={true}>
            Metric (mm, m, kg, °C)
          </Radio.Button>
          <Radio.Button value={type.system.american} disabled={true}>
            English (in, ft, lbs, °C)
          </Radio.Button>
        </Radio.Group>
      </Item>
      <Item name="customerId" initialValue={project.customerId} label="Customer" style={{ width: inputWidth }}>
        <CustomerSelect projectId={project.id} />
      </Item>
      <Item
        // name="quoteSpecs.quoteHeader.quoteNo"
        // initialValue={project.quoteSpecs.quoteHeader.quoteNo}
        label="Opportunity number"
        style={{ width: inputWidth }}
      >
        <Input value={quoteNo} onChange={updateQuoteNo} />
      </Item>
      <Item name="tags" initialValue={project.tags} label="Tags">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          tokenSeparators={[',']}
          maxTagCount="responsive"
          placeholder="Tags..."
        >
          {tags.map((name) => (
            <Option key={name}>{name}</Option>
          ))}
        </Select>
      </Item>
    </Card>
  );
};
