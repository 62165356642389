import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useLocation } from 'wouter';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { authState } from '../../state/auth';

export const SignInAuth = () => {
  const [, setLocation] = useLocation();
  const setAuth = useSetRecoilState(authState);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      if (nextAuthState === AuthState.SignedIn) {
        const { payload } = authData.signInUserSession.idToken;
        setAuth({
          email: payload['email'],
          username: payload['cognito:username'],
          groups: payload['cognito:groups'],
        });
        setLocation('/verify');
      }
    });
  }, []);

  return (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
      <AmplifySignUp slot="sign-up" usernameAlias="email" formFields={[{ type: 'email' }, { type: 'password' }]} />
    </AmplifyAuthenticator>
  );
};
