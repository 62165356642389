import React, { useState, useEffect } from 'react'; // useRef
import { InputNumber, Select, Alert } from 'antd';
//import { Hub } from '@aws-amplify/core';
import { measurementUnits } from '../../state/units';
import { convertValueUnits, roundDecimalPlaces } from '../../utils/unit-conversion';
//import _uniqueId from 'lodash/uniqueId';
import './measurement.less';

const { Option } = Select;

// default to dimension if no type is provided
export const Measurement = ({
  step = 'measurement',
  label = '',
  value = measurementUnits.dimension,
  type = measurementUnits.dimension,
  onChange,
  minimum,
  maximum,
  disabled = false,
  validate = (value) => '',
  ...other
}) => {
  // unique id so we can track validation globally (not using hub ATM)
  //const id = useRef(_uniqueId('measure-')).current;
  const [measurementValue, setMeasurementValue] = useState(value);
  const [inputValue, setInputValue] = useState(value.value);
  const [errorMessage, setErrorMessage] = useState('');

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setMeasurementValue(value);
    setInputValue(value.value);
  }, [value]);

  // dynamic precision - more digits for number < 1
  const precision = (value) => {
    const exponent = Number(value.toExponential().split('-')[1]);
    return isNaN(exponent) ? 3 : exponent + 2;
  };

  const roundTo = (value) => {
    // undefined values required for optional min/max numberInput attributes
    if (value === undefined) return value;
    const multiplier = 10 ** precision(value);
    return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
  };

  /* set to 0.1 on number input
  const stepSize = (value) => {
    const p = precision(value);
    return p > 1 ? 1 / 10 ** p : p;
  };
  */
  // width must be set when dynamically populating options
  // stop event propagation avoids problems in control is inside
  // a clickable parent (button)
  const AddonAfter = ({ value }) => {
    return (
      <Select
        style={{ width: '100px' }}
        value={value.units}
        disabled={disabled}
        onClick={(event) => event.stopPropagation()}
        onChange={(newUnits) => {
          const newValue = convertValueUnits({
            value: value.value,
            from: value.units,
            to: newUnits,
          });
          setInputValue(newValue);
          setMeasurementValue({
            ...value,
            value: newValue,
            units: newUnits,
          });
          onChange({
            ...value,
            value: newValue,
            units: newUnits,
          });
        }}
      >
        {measurementUnits[type].map((unit) => (
          <Option key={unit} value={unit}>
            {unit}
          </Option>
        ))}
      </Select>
    );
  };

  const convertedMin = minimum
    ? convertValueUnits({
        value: minimum.value,
        from: minimum.units,
        to: value.units,
      })
    : undefined;

  const convertedMax = maximum
    ? convertValueUnits({
        value: maximum.value,
        from: maximum.units,
        to: value.units,
      })
    : undefined;

  const dispatchError = ({ error = false }) => {
    // must have valid step
    /* not using hub - Amplify docs outdated as channel = ui is now protected
    if (step) {
      Hub.dispatch('ui', {
        event: 'validation',
        data: { step, id, error },
        message: step,
      });
    }
     */
  };

  const validateMinMax = (value) => {
    if (convertedMin !== undefined && value.value < convertedMin) {
      setErrorMessage(`Value must be greater than or equal to ${roundTo(convertedMin)} ${value.units}`);
      dispatchError({ error: true });
      return false;
    }
    if (convertedMax !== undefined && value.value > convertedMax) {
      setErrorMessage(`Value must be less than or equal to ${roundTo(convertedMax)} ${value.units}`);
      dispatchError({ error: true });
      return false;
    }
    setErrorMessage('');
    dispatchError({ error: false });
    return true;
  };

  // Not checking NaN as this should be handled in the unit conversion
  // InputNumber control enforces numeric entry
  // InputNumber only calls onValueChange when min <= value <= max
  const onValueChange = (number) => {
    const newValue = { ...value, value: Number(number) };
    setMeasurementValue(newValue);
    setInputValue(newValue.value);
    // min/max validation in part of the component
    if (validateMinMax(newValue)) {
      // optional external custom validator
      const externalValidatorError = validate(newValue);
      externalValidatorError === '' ? onChange(newValue) : setErrorMessage(externalValidatorError);
    }
  };

  // re-run validation if conditions change
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (disabled) {
      dispatchError({ error: false });
    } else {
      validateMinMax(value);
    }
  }, [disabled, value, minimum, maximum]);

  // formatter is required to maintain precision when switching units without displaying
  // all decimals. userTyping prevents clearing leading zeros when editing values like 0.005

  return (
    <div className={`number-input-wrapper ${!disabled && errorMessage !== '' ? 'error' : ''}`}>
      <div className="number-input">
        {label !== '' && <div className="number-input-label">{label}</div>}
        <div className="value-input">
          <InputNumber
            value={inputValue}
            step={0.1}
            formatter={(value, { input, userTyping }) => {
              return userTyping ? input : roundDecimalPlaces(value);
            }}
            onChange={onValueChange}
            disabled={disabled}
          />
        </div>
        <div className="units-select">
          <AddonAfter value={measurementValue} />
        </div>
      </div>
      {!disabled && (
        <div className="error-message">
          <Alert type="error" message={errorMessage} />
        </div>
      )}
    </div>
  );
};

Measurement.Power = (props) => <Measurement {...props} type="power" />;
Measurement.Dimension = (props) => <Measurement {...props} type="dimension" />;
Measurement.FlowRate = (props) => <Measurement {...props} type="flowRate" />;
Measurement.FlowRateGas = (props) => <Measurement {...props} type="flowRateGas" />;
Measurement.FlowRateLiquid = (props) => <Measurement {...props} type="flowRateLiquid" />;
Measurement.Altitude = (props) => <Measurement {...props} type="altitude" />;
Measurement.Pressure = (props) => <Measurement {...props} type="pressure" />;
Measurement.FlowVelocity = (props) => <Measurement {...props} type="flowVelocity" />;
Measurement.Temperature = (props) => <Measurement {...props} type="temperature" />;
Measurement.ThermalResistance = (props) => <Measurement {...props} type="thermalResistance" />;
Measurement.ThermalImpedance = (props) => <Measurement {...props} type="thermalImpedance" />;
Measurement.ThermalConductivity = (props) => <Measurement {...props} type="thermalConductivity" />;
Measurement.Area = (props) => <Measurement {...props} type="area" />;
Measurement.Volume = (props) => <Measurement {...props} type="volume" />;
Measurement.LinearDensity = (props) => <Measurement {...props} type="linearDensity" />;
Measurement.Mass = (props) => <Measurement {...props} type="mass" />;

export default Measurement;
