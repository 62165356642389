import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Form } from 'antd';
import { LayoutWorkspace, LayoutStepPanel } from '../../layout';
import { SpecificationsPanel0 } from './specifications-panel-0';
import { SpecificationsPanel1 } from './specifications-panel-1';
import { SpecificationsPanel2 } from './specifications-panel-2';
import { calcExtrusionDims } from '../../../utils/calc-extrusion-dims';
import { getExtrusionData, getStandardPartsIds } from '../costing/project-api';
import { userRoleState } from '../../../state/auth';
import { dimension, linearDensity, type } from '../../../state/units';
import { convertUnits } from '../../../utils/unit-conversion';
import { dirtyCostEstimate } from '../../../utils/dirty-cost-estimate';
import _cloneDeep from 'lodash/cloneDeep';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const ProjectSpecifications = ({ project, updateProject, nextTab, setActiveTab, show }) => {
  const userRole = useRecoilValue(userRoleState);
  const [form] = Form.useForm();
  const [selectedStep, setSelectedStep] = useState(0);
  const designIndex = 0;
  const [standardPartsIds, setStandardPartsIds] = useState([]);

  const steps =
    userRole === type.userRole.sales || userRole === type.userRole.ops
      ? [
          { step: 0, title: 'Project Details' },
          { step: 1, title: 'Extrusion Specifications' },
        ]
      : [
          { step: 0, title: 'Project Details' },
          { step: 1, title: 'Extrusion Specifications' },
          { step: 2, title: 'Thermal Specifications' },
        ];

  useEffect(() => {
    async function featchStandardParts() {
      const partIds = await getStandardPartsIds();
      setStandardPartsIds(partIds);
    }
    featchStandardParts();
  }, []);

  // extract the field name (path) and value
  const onItemChange = (item) => {
    /* validator is async so this does not prevent autosave of invalid value
    const isValid = item[0].errors.length === 0;
    console.log(item, isValid);
    */
    const path = item[0].name[0];
    const value = item[0].value;
    const pathArray = path.split('.');
    const itemName = pathArray.length > 1 ? pathArray[pathArray.length - 1] : path;
    // Update related extrusion fields based current value
    if (
      [
        'baseWidth',
        'baseThick',
        'finThick',
        'finHeight',
        'finPitch',
        'numFins',
        'width',
        'height',
        'weightPerFeet',
        'perimeter',
        'hasOffset',
        'leftOffset',
        'rightOffset',
      ].includes(itemName)
    ) {
      const updateItems = calcExtrusionDims(project, path, itemName, value, 0);
      const fieldsToUpdate = Object.fromEntries(updateItems.map((item) => [item.path, item.value]));
      form.setFieldsValue(fieldsToUpdate);
      updateItems.unshift({ path, value });
      updateItems.push({ path: `designs.${0}.costEstimates`, value: dirtyCostEstimate(project, 0) });
      updateProject(updateItems);
    } else if (itemName === 'standardExtrusionRequested') {
      updateProject([
        { path, value },
        { path: 'activeDesignId', value: value ? 1 : 0 },
      ]);
    } else if (itemName === 'partId') {
      changeStandardPartNumber(value);
    } else if (itemName === 'nonFlatback') {
      updateProject([
        { path, value },
        { path: `designs.${0}.costEstimates`, value: dirtyCostEstimate(project, 0) },
      ]);
    } else if (itemName === 'length') {
      // Update lenght for customer and standard designs.
      const updateItems = [
        { path, value },
        { path: `designs.${1}.extrusion.dimensions.length`, value: value },
        { path: `designs.${0}.costEstimates`, value: dirtyCostEstimate(project, 0) },
        { path: `designs.${1}.costEstimates`, value: dirtyCostEstimate(project, 1) },
      ];
      updateProject(updateItems);
    } else {
      updateProject({ path, value });
    }
  };

  const changeStandardPartNumber = (value) => {
    // setImageCode(value);
    const designId = 0;
    const unitSystem = project.units;
    const lengthUnit = unitSystem === type.system.american ? 'in' : 'mm';
    const result = getExtrusionData({ partId: value });
    result.then((res) => {
      const extrusionInfo = res.extrusion;
      const newBaseWidth = convertUnits({ value: extrusionInfo.dimensions.width, to: lengthUnit }); // Note: - extrusionInfo.baseWidth does not have value so use from dimensions.width
      const newBaseThick = convertUnits({
        value: { ...dimension, value: extrusionInfo.baseThick, units: 'in' },
        to: lengthUnit,
      });
      // const newLength = extrusionInfo.dimensions.length;
      const newFinHeight = convertUnits({
        value: { ...dimension, value: extrusionInfo.finHeight, units: 'in' },
        to: lengthUnit,
      });
      const newFinThick = convertUnits({
        value: { ...dimension, value: extrusionInfo.finThick, units: 'in' },
        to: lengthUnit,
      });
      const newNumFins = extrusionInfo.numFins;
      const newFinPitch = convertUnits({
        value: { ...dimension, value: extrusionInfo.finPitch, units: 'in' },
        to: lengthUnit,
      });
      const newWidth = convertUnits({ value: extrusionInfo.dimensions.width, to: lengthUnit });
      const newHeight = convertUnits({ value: extrusionInfo.dimensions.height, to: lengthUnit });
      const newPerimeter = convertUnits({
        value: { ...dimension, value: extrusionInfo.perimeter, units: 'in' },
        to: lengthUnit,
      });
      const newStickLength = convertUnits({
        value: { ...dimension, value: extrusionInfo.stickLength, units: 'in' },
        to: lengthUnit,
      });
      const wtPerFeetUnit = unitSystem === type.system.american ? 'lb/ft' : 'kg/m';
      const newWeightPerFeet = convertUnits({
        value: { ...linearDensity, value: extrusionInfo.weightPerFeet, units: 'lb/ft' },
        to: wtPerFeetUnit,
      });
      // const stockUnit = unitSystem === type.system.american ? 'ft' : 'm';
      // const newInventoryStock = convertUnits({
      //   value: { ...dimension, value: extrusionInfo.inventory, units: 'ft' },
      //   to: stockUnit,
      // });
      const nonFlatback = extrusionInfo.nonFlatback;
      const newClass = extrusionInfo.class;
      const length = project.designs[designId].extrusion.dimensions.length; // Note: db don't have length data
      const newExtrusion = {
        partId: value,
        baseWidth: newBaseWidth,
        baseThick: newBaseThick,
        dimensions: { width: newWidth, height: newHeight, length: length },
        finHeight: newFinHeight,
        finThick: newFinThick,
        finPitch: newFinPitch,
        numFins: newNumFins,
        perimeter: newPerimeter,
        class: newClass,
        vendorId: extrusionInfo.vendorId,
        stickLength: newStickLength,
        weightPerFeet: newWeightPerFeet,
        nonFlatback: nonFlatback,
        hasOffset: false,
      };

      const designs = _cloneDeep(project.designs);
      const newStandardExtrusion = _cloneDeep(newExtrusion);
      designs[0].extrusion = { ...project.designs[0].extrusion, ...newExtrusion };
      designs[0].costEstimates = dirtyCostEstimate(project, 0);
      designs[1].extrusion = { ...project.designs[1].extrusion, ...newStandardExtrusion, autoCalcWeightPerFeet: true };
      designs[1].costEstimates = dirtyCostEstimate(project, 1);

      updateProject({ path: 'designs', value: designs });

      if (nonFlatback) {
        form.setFieldsValue({
          [`designs.${designId}.extrusion.partId`]: value,
          [`designs.${designId}.extrusion.nonFlatback`]: nonFlatback,
          [`designs.${designId}.extrusion.dimensions.width`]: newBaseWidth,
          [`designs.${designId}.extrusion.dimensions.height`]: newHeight,
          [`designs.${designId}.extrusion.weightPerFeet`]: newWeightPerFeet,
          [`designs.${designId}.extrusion.class`]: newClass,
          [`designs.${designId}.extrusion.perimeter`]: newPerimeter,
        });
      } else {
        form.setFieldsValue({
          [`designs.${designId}.extrusion.partId`]: value,
          [`designs.${designId}.extrusion.baseWidth`]: newBaseWidth,
          [`designs.${designId}.extrusion.baseThick`]: newBaseThick,
          [`designs.${designId}.extrusion.finThick`]: newFinThick,
          [`designs.${designId}.extrusion.finHeight`]: newFinHeight,
          [`designs.${designId}.extrusion.dimensions.height`]: newHeight,
          [`designs.${designId}.extrusion.finPitch`]: newFinPitch,
          [`designs.${designId}.extrusion.numFins`]: newNumFins,
          [`designs.${designId}.extrusion.nonFlatback`]: nonFlatback,
          [`designs.${designId}.extrusion.class`]: newClass,
          [`designs.${designId}.extrusion.hasOffset`]: false,
        });
      }
    });
  };

  return project ? (
    <LayoutWorkspace
      steps={steps}
      selectedStep={selectedStep}
      setSelectedStep={setSelectedStep}
      nextTab={nextTab}
      setActiveTab={setActiveTab}
      left={
        <Form form={form} name="design" layout="vertical" onFieldsChange={onItemChange} {...layout}>
          <LayoutStepPanel show={show && selectedStep === 0}>
            <SpecificationsPanel0 project={project} updateProject={updateProject} />
          </LayoutStepPanel>
          <LayoutStepPanel show={show && selectedStep === 1}>
            <SpecificationsPanel1 project={project} standardPartsIds={standardPartsIds} />
          </LayoutStepPanel>
          <LayoutStepPanel show={show && selectedStep === 2}>
            <SpecificationsPanel2 designIndex={designIndex} project={project} />
          </LayoutStepPanel>
        </Form>
      }
    />
  ) : null;
};
