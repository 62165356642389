import { Card } from 'antd';
import { HeatSources } from './heat-sources';
import { extrusionInfo } from '../../../utils/extrusion-info';

const SelectedHS = ({ project }) => (
  <div style={{ display: 'flex', gap: 6 }}>
    <div style={{ fontWeight: 600 }}>Heat Sink</div>
    <div>{extrusionInfo(project)}</div>
  </div>
);

export const PerformancePanel0 = ({ project, updateProject, panelSize }) => {
  return (
    <Card title="Heat Sources" bordered={false} extra={<SelectedHS project={project} />}>
      <HeatSources project={project} updateProject={updateProject} panelSize={panelSize} />
    </Card>
  );
};
