import { atomFamily } from 'recoil';
import { Storage } from '@aws-amplify/storage';

export const getFile = async (key) =>
  key.endsWith('.x3d')
    ? await Storage.get(key, { level: 'public', contentType: 'model/x3d+xml' })
    : await Storage.get(key, { level: 'public' });

export const listProjectFiles = ({ projectId }) =>
  Storage.list(`${projectId}/`, {pageSize: 1000})
    .then(async ({ results }) => {
      let files = [];
      for (let index in results) {
        const file = results[index];
        const url = await getFile(file.key);
        const name = file.key.split('/').pop();
        files.push({ ...file, url, name });
      }
      return files;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });

export const projectFilesState = atomFamily({
  key: 'projectFiles',
  default: () => [],
  effects: ({ projectId }) => [
    ({ setSelf }) => {
      const projectFilesPromise = projectId === undefined ? [] : listProjectFiles({ projectId });
      setSelf(projectFilesPromise);
    },
  ],
});


