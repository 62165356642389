import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { tagsState } from '../../state/tags';
import clsx from 'clsx';
import { Link, useRoute } from 'wouter';
import { Button, Tag } from 'antd';
import { BsLayoutSidebar, BsReverseLayoutSidebarReverse } from 'react-icons/bs';
import { Profile } from '../profile';
import { Help } from '../common';
import { useProjectsSubscribe } from '../project';
import { useCustomersSubscribe } from '../customer';
import { useProjects } from '../common/use-projects';
import './layout.less';

const { CheckableTag } = Tag;

export const Layout = ({ children }) => {
  const { tags } = useProjects();
  const [selectedTags, setSelectedTags] = useRecoilState(tagsState);
  const [toggle, setToggle] = useState(false);

  // subscribe to project changes to keep projects list updated
  useProjectsSubscribe();
  useCustomersSubscribe();

  const PanelButton = () => (
    <Button
      type="text"
      onClick={() => setToggle((prevState) => !prevState)}
      icon={toggle ? <BsReverseLayoutSidebarReverse size={18} /> : <BsLayoutSidebar size={18} />}
    />
  );

  const CustomLink = (props) => {
    const [isActive] = useRoute(props.href);
    return (
      <Link {...props}>
        <div className={clsx('item', { active: isActive })}>{props.children}</div>
      </Link>
    );
  };

  const selectTag = (tag, checked) => {
    setSelectedTags((tags) => (checked ? [...tags, tag] : tags.filter((t) => t !== tag)));
  };

  return (
    <div className="layout-wrapper">
      <section className={clsx('panel-wrapper', { closed: toggle })}>
        <aside className="panel-left">
          <div className="panel-header">
            <div className="panel-header-content">
              <Profile />
            </div>
          </div>
          <div className="panel-left-content">
            <div className="items-wrapper">
              <CustomLink href="/projects">Projects</CustomLink>
              <CustomLink href="/customers">Customers</CustomLink>
            </div>
            <div className="items-wrapper">
              <div className="item">Tags</div>
              {tags.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedTags.includes(tag)}
                  onChange={(checked) => selectTag(tag, checked)}
                >
                  {tag}
                </CheckableTag>
              ))}
            </div>
          </div>
        </aside>
        <div className="panel-control">
          <PanelButton />
        </div>
        <section className="panel-main">
          <div className="panel-main-content">{children}</div>
        </section>
      </section>
      <Help />
    </div>
  );
};
