import { API } from '@aws-amplify/api';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'wouter';
import { Popconfirm, Menu, Dropdown } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { formatDate } from '../../utils/date';
import { deleteCustomer } from '../../graphql/mutations';
import { hasGroup } from '../../state/auth';
import { LayoutCard } from '../layout';
import { setAutoSaveMessage } from '../../utils/set-autosave-message';

const deleteCustomerRequest = async ({ id }) => {
  try {
    setAutoSaveMessage({ message: 'Deleting...' });
    const response = await API.graphql({
      query: deleteCustomer,
      variables: { input: { id } },
    });
    setAutoSaveMessage();
    return response.data.deleteCustomer;
  } catch (error) {
    setAutoSaveMessage({ message: 'Error' });
    console.error(error);
  }
};

export const CustomerCard = ({ customer, editCustomer = () => {} }) => {
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const [, setLocation] = useLocation();
  const { id, name, description, projects, createdAt } = customer;

  const confirmDeleteCustomer = async () => await deleteCustomerRequest({ id });

  const menuAction = ({ key }) => {
    switch (key) {
      case 'edit':
        editCustomer({ id });
        break;
      default:
      // nothing
    }
  };

  const dropDownMenu = (
    <Menu onClick={menuAction}>
      <Menu.Item key="edit" icon={<EditOutlined />}>
        Edit
      </Menu.Item>
      {isAdmin && (
        <Menu.Item key="delete" icon={<DeleteOutlined />}>
          <div onClick={(event) => event.stopPropagation()}>
            <Popconfirm
              title="Are you sure to delete this customer?"
              onConfirm={confirmDeleteCustomer}
              okText="Yes"
              cancelText="No"
            >
              Delete
            </Popconfirm>
          </div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <LayoutCard
      title={name}
      onClick={() => projects.items.length && setLocation(`/c/${id}`)}
      extra={
        <div onClick={(event) => event.stopPropagation()}>
          <Dropdown trigger="click" overlay={dropDownMenu}>
            <MoreOutlined />
          </Dropdown>
        </div>
      }
    >
      <main>{description}</main>
      <footer>
        <div>{formatDate(createdAt)}</div>
        <div />
        <div />
        <div>{projects.items.length} projects</div>
      </footer>
    </LayoutCard>
  );
};
