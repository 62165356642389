import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Popconfirm, Menu, Dropdown, Tooltip, Typography } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
  MoreOutlined,
  UserOutlined,
  ShareAltOutlined,
  TagOutlined,
} from '@ant-design/icons';
// import { deleteProjectRequest } from '../../state/project';
import { formatDate } from '../../utils/date';
import { hasGroup, authState } from '../../state/auth';
import { LayoutCard } from '../layout';
import { type } from '../../state/units';
import { ProjectShareModal } from './project-share-modal';
import { ProjectTagsModal } from './project-tags-modal';

const { Text } = Typography;

export const ProjectCard = ({
  project,
  customers,
  selectProject = () => {},
  editProject = () => {},
  copyProject = () => {},
  deleteProject = () => {},
  tags,
}) => {
  const auth = useRecoilValue(authState);
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const { id, name, description, units, owner, customerId, createdAt } = project;
  const isOwner = auth.username === owner;
  const [showShareModal, setShowShareModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);

  const CustomerName = ({ customerId }) => {
    const customer = customers.filter(({ id }) => id === customerId);
    return customer.length === 1 ? (
      <div style={{ margin: '0.5rem 0' }}>{customer[0].name}</div>
    ) : (
      <div style={{ margin: '0.5rem 0', color: '#e00' }}>Unassigned</div>
    );
  };

  // const confirmDeleteProject = async () => await deleteProjectRequest({ id });

  const menuAction = ({ key }) => {
    switch (key) {
      case 'edit':
        editProject({ id });
        break;
      case 'tags':
        setShowTagsModal(true);
        break;
      case 'copy':
        copyProject({ id });
        break;
      case 'share':
        setShowShareModal(true);
        break;
      default:
      // nothing
    }
  };

  const dropDownMenu = (
    <Menu onClick={menuAction}>
      <Menu.Item key="edit" icon={<EditOutlined />}>
        Edit
      </Menu.Item>
      <Menu.Item key="tags" icon={<TagOutlined />}>
        Tags
      </Menu.Item>
      <Menu.Item key="share" icon={<ShareAltOutlined />}>
        Share
      </Menu.Item>
      <Menu.Item key="copy" icon={<CopyOutlined />}>
        Copy
      </Menu.Item>
      <Menu.Item key="delete" icon={<DeleteOutlined />}>
        <div onClick={(event) => event.stopPropagation()}>
          <Popconfirm
            title="Are you sure to delete this project?"
            onConfirm={() => deleteProject({ id })}
            okText="Yes"
            cancelText="No"
          >
            Delete
          </Popconfirm>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <LayoutCard
        title={name}
        onClick={() => selectProject({ id })}
        extra={
          (isAdmin || isOwner) && (
            <div
              onClick={(event) => event.stopPropagation()}
              style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}
            >
              {isOwner ? (
                <div />
              ) : (
                <div>
                  <Tooltip title="You are not the project owner">
                    <UserOutlined />
                  </Tooltip>
                </div>
              )}
              <div>
                <Dropdown trigger="click" overlay={dropDownMenu}>
                  <MoreOutlined />
                </Dropdown>
              </div>
            </div>
          )
        }
      >
        <main>
          <Text ellipsis={{ tooltip: description }}>{description}</Text>
          <CustomerName customerId={customerId} />
        </main>
        <footer>
          <div>{formatDate(createdAt)}</div>
          <div />
          <div />
          <div>{units === type.system.metric ? 'Metric' : 'Mixed'}</div>
        </footer>
      </LayoutCard>
      <ProjectShareModal projectId={id} showButton={false} show={showShareModal} close={setShowShareModal} />
      <ProjectTagsModal
        projectId={id}
        showButton={false}
        show={showTagsModal}
        close={setShowTagsModal}
        tags={tags}
      />
    </>
  );
};
