import { atomFamily } from 'recoil';
import { API } from '@aws-amplify/api';
import { listCustomers } from '../graphql/queries';
import { alphaSort } from '../utils/alpha-sort';

const getCustomers = async ({ nextToken }) => {
  try {
    const response = await API.graphql({
      query: listCustomers,
      variables: {
        nextToken,
      },
    });
    return response.data.listCustomers;
  } catch (error) {
    console.error(error);
  }
};

const getAllCustomers = async () => {
  let response = await getCustomers({
    nextToken: null,
  });

  if (response === undefined) return [];

  let { items, nextToken } = response;
  let customers = [...items];

  while (nextToken !== null) {
    const response = await getCustomers({
      nextToken,
    });
    customers = [...customers, ...response.items];
    nextToken = response.nextToken;
  }
  return customers;
};

export const customersState = atomFamily({
  key: 'customersState',
  default: () =>
    getAllCustomers()
      .then((customers) => customers.sort((a, b) => alphaSort(a.name, b.name)))
      .catch((error) => {
        console.error('Recoil customers state', error);
      }),
});
