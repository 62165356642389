import { useState, useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Card, Steps, Tabs, Button, Space, Modal, Progress, Skeleton, Image, Row, Col } from 'antd';
import { isFirefox } from 'react-device-detect';
import { projectFilesState, listProjectFiles } from '../../../state/project-files';
import { type } from '../../../state/units';
import { startSimulation } from './simulation-api';
import { getSelectedProject } from '../../../state/project';
import { dimensionString, extrusionDims } from '../../../utils/extrusion-info';
import './project-performance.less';

const { designStatus } = type;
const { Step } = Steps;
const { TabPane } = Tabs;

export const PerformancePanel2 = ({ project }) => {
  const iframeEl = useRef(null);
  const [selectedStep, setSelectedStep] = useState(0);
  const [activeTab, setActiveTab] = useState('1');
  const [showProgress, setShowProgress] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('Incomplete');
  const [projectFiles, setProjectFiles] = useState(useRecoilValue(projectFilesState({ projectId: project.id })));
  const [solution, setSolution] = useState(project.designs[project.activeDesignId].solution);

  const images = {
    x3d: projectFiles.find(({ key }) => key.endsWith('sol.x3d')),
    iso: projectFiles.find(({ key }) => key.endsWith('v_iso_3.png')),
    bot: projectFiles.find(({ key }) => key.endsWith('v_bot_3.png')),
  };

  const files = {
    pdf: projectFiles.find(({ key }) => key.endsWith('report_en.pdf')),
    qom: projectFiles.find(({ key }) => key.endsWith('project.qom')),
    step: projectFiles.find(({ key }) => key.endsWith('heatsink.step')),
  };

  useEffect(() => {
    const { status } = project.designs[project.activeDesignId];
    const isValid = project.heatSources.length !== 0;
    setIsValid(isValid);
    switch (status) {
      case designStatus.solution:
        setIsComplete(true);
        setSelectedStep(2);
        setActiveTab('2');
        break;
      default:
        // incomplete, failed, queued, solving, solution
        if (isValid) {
          setSelectedStep(1);
        } else {
          setSelectedStep(0);
        }
    }
  }, [project]);

  // this is not going to work directly as the design query is cached by recoil
  useEffect(() => {
    if (showProgress) {
      setIsComplete(false);
      const interval = setInterval(() => {
        const { id, activeDesignId } = project;
        getSelectedProject({ id })
          .then((project) => {
            const { progress, message } = project.designs[activeDesignId].simulationStatus;
            setProgress(progress);
            setMessage(message);
            if (progress === 100) {
              clearInterval(interval);
              setIsComplete(true);
              setSolution(project.designs[project.activeDesignId].solution);
              listProjectFiles({ projectId: id }).then((files) => setProjectFiles(files));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 600);
      return () => clearInterval(interval);
    }
  }, [showProgress, project]);

  const runSimulation = () => {
    setSelectedStep(1);
    setShowProgress(true);
    setMessage('Incomplete');
    startSimulation({ projectId: project.id })
      //.then((result) => console.log(result))
      .catch((error) => console.log(error));
  };

  const handleOk = () => {
    setActiveTab('2');
    setSelectedStep(2);
    setProgress(0);
    setMessage('Incomplete');
    setShowProgress(false);
  };

  const handleCancel = () => {
    setSelectedStep(0);
    setProgress(0);
    setMessage('Incomplete');
    setShowProgress(false);
  };

  const tabButtons = {
    right: (
      <Space direction="horizontal">
        <Button type="primary" onClick={runSimulation} disabled={!isValid || showProgress}>
          {isComplete ? 'Rerun Simulation' : 'Run Simulation'}
        </Button>
      </Space>
    ),
  };

  // Firefox throws WebGL warnings from x3d library and does not display iframe content
  // when switching tabs so force reload
  const refreshIFrame = (activeKey) =>
    activeKey === '2' && isFirefox && iframeEl.current?.contentWindow.location.reload(true);

  const Header = () => {
    const workflow = [
      { step: 0, title: 'Project Summary', disabled: false },
      { step: 1, title: 'Run Simulation', disabled: !isValid },
      { step: 2, title: 'Solution', disabled: !isComplete },
    ];
    return (
      <Steps size="small" current={selectedStep}>
        {workflow.map(({ step, title, disabled = false }) => (
          <Step key={step} title={title} disabled={disabled} />
        ))}
      </Steps>
    );
  };

  const envelopeMaxSize = () =>
    project.specifications.customerExtrusionProvided && project.specifications.useCustomerExtrusionMaxSize
      ? project.designs[0].extrusion.dimensions
      : project.specifications.maxSize;

  const heatSourcePosition = (hs) => {
    const x = dimensionString(hs.position.x);
    const y = dimensionString(hs.position.y);
    return `${x}, ${y}`;
  };

  const heatSourceSize = (hs) => {
    const w = dimensionString(hs.size.width);
    const h = dimensionString(hs.size.height);
    return `${w} x ${h}`;
  };

  return (
    <Card title="Simulation" bordered={false}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <div>
          <Header />
        </div>
        <div>
          <Tabs
            type="card"
            tabBarExtraContent={tabButtons}
            activeKey={activeTab}
            onChange={(key) => {
              setActiveTab(key);
              refreshIFrame(key);
            }}
          >
            <TabPane key="1" tab="Summary">
              <div className="section-layout">
                <h4 className="section-heading">Overview</h4>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">Name</Col>
                    <Col className="grid-col">{project.name}</Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Status</Col>
                    <Col className="grid-col">
                      {type.designStatusString[project.designs[project.activeDesignId].status]}
                    </Col>
                  </Row>
                </section>
              </div>

              <div className="section-layout">
                <h4 className="section-heading">Boundary Conditions</h4>
                <p className="grid-panel">
                  <b>Environment</b>
                </p>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">Ambient temperature</Col>
                    <Col className="grid-col">{dimensionString(project.specifications.ambientTemperature)}</Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Operating altitude</Col>
                    <Col className="grid-col">{dimensionString(project.specifications.altitude)}</Col>
                  </Row>
                </section>
                <p className="grid-panel">
                  <b>Working Envelope</b>
                </p>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">Width</Col>
                    <Col className="grid-col">{dimensionString(envelopeMaxSize().width)}</Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Height</Col>
                    <Col className="grid-col">{dimensionString(envelopeMaxSize().height)}</Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Length</Col>
                    <Col className="grid-col">{dimensionString(envelopeMaxSize().length)}</Col>
                  </Row>
                </section>
                <p className="grid-panel">
                  <b>Design Constraints</b>
                </p>
                <section className="grid-section">
                  {project.specifications.thermalConstraintType === type.thermalConstraintType.temperature ? (
                    <Row>
                      <Col className="grid-col">Max temperature rise</Col>
                      <Col className="grid-col">{dimensionString(project.specifications.maxTemperatureRise)}</Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col className="grid-col">Max thermal resistance</Col>
                      <Col className="grid-col">{dimensionString(project.specifications.maxThermalResis)}</Col>
                    </Row>
                  )}
                  <Row>
                    <Col className="grid-col">Max pressure drop</Col>
                    <Col className="grid-col">{dimensionString(project.specifications.maxPressureDrop)}</Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Total load of all devices</Col>
                    <Col className="grid-col">{dimensionString(project.specifications.totalLoad)}</Col>
                  </Row>
                </section>
              </div>

              <div className="section-layout">
                <h4 className="section-heading">Heatsink</h4>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">Extrusion type</Col>
                    <Col className="grid-col">
                      {{ 0: 'Customer', 1: 'Standard', 2: 'Optimal', 3: 'Custom' }[project.activeDesignId]}
                    </Col>
                  </Row>
                  {project.activeDesignId === 1 ? (
                    <Row>
                      <Col className="grid-col">Part ID</Col>
                      <Col className="grid-col">{project.designs[project.activeDesignId].extrusion.partId}</Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col className="grid-col">Dimensions</Col>
                    <Col>{extrusionDims(project)}</Col>
                  </Row>
                </section>
                <p className="grid-panel">
                  <b>Base Dimensions</b>
                </p>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">Width</Col>
                    <Col className="grid-col">
                      {dimensionString(project.designs[project.activeDesignId].extrusion.baseWidth)}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Length</Col>
                    <Col className="grid-col">
                      {dimensionString(project.designs[project.activeDesignId].extrusion.dimensions.length)}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Thickness</Col>
                    <Col className="grid-col">
                      {dimensionString(project.designs[project.activeDesignId].extrusion.baseThick)}
                    </Col>
                  </Row>
                </section>
                <p className="grid-panel">
                  <b>Fin Dimensions</b>
                </p>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">Thickness</Col>
                    <Col className="grid-col">
                      {dimensionString(project.designs[project.activeDesignId].extrusion.finThick)}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Height</Col>
                    <Col className="grid-col">
                      {dimensionString(project.designs[project.activeDesignId].extrusion.finHeight)}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Number of fins</Col>
                    <Col className="grid-col">{project.designs[project.activeDesignId].extrusion.numFins}</Col>
                  </Row>
                  <Row>
                    <Col className="grid-col">Pitch</Col>
                    <Col className="grid-col">
                      {dimensionString(project.designs[project.activeDesignId].extrusion.finPitch)}
                    </Col>
                  </Row>
                </section>
              </div>

              <div className="section-layout">
                <h4 className="section-heading">Heat Sources</h4>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">
                      <b>Label</b>
                    </Col>
                    <Col className="grid-col">
                      <b>Power</b>
                    </Col>
                    <Col className="grid-col">
                      <b>Position(X, Y)</b>
                    </Col>
                    <Col className="grid-col">
                      <b>Size (Width x Length)</b>
                    </Col>
                  </Row>
                  {project.heatSources.map((hs, index) => (
                    <Row key={`hs-${index}`}>
                      <Col className="grid-col">{hs.label}</Col>
                      <Col className="grid-col">{dimensionString(hs.power)}</Col>
                      <Col className="grid-col">{heatSourcePosition(hs)}</Col>
                      <Col className="grid-col">{heatSourceSize(hs)}</Col>
                    </Row>
                  ))}
                </section>
              </div>

              <div className="section-layout">
                <h4 className="section-heading">Flow Conditions</h4>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">Air flow type</Col>
                    <Col className="grid-col">{type.airflowTypesString[project.airFlow.airFlowType]}</Col>
                  </Row>
                  {project.airFlow.airFlowType === type.airflow.naturalConvection ? (
                    <Row>
                      <Col className="grid-col">Orientation</Col>
                      <Col className="grid-col">{type.naturalConvectionTypesString[project.airFlow.orientation]}</Col>
                    </Row>
                  ) : (
                    // forced convection options
                    <>
                      <Row>
                        <Col className="grid-col">Forced flow type</Col>
                        <Col className="grid-col">
                          {type.forcedFlowTypesString[project.airFlow.forcedConvectionType]}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="grid-col">Flow setup</Col>
                        <Col className="grid-col">{type.flowSetupTypesString[project.airFlow.setup]}</Col>
                      </Row>
                    </>
                  )}
                  {project.airFlow.airFlowType === type.airflow.forceConvection &&
                    (project.airFlow.forcedConvectionType === type.forcedFlow.velocity ? (
                      <Row>
                        <Col className="grid-col">Velocity</Col>
                        <Col className="grid-col">{dimensionString(project.airFlow.velocity)}</Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col className="grid-col">Flow rate</Col>
                        <Col className="grid-col">{dimensionString(project.airFlow.flowRate)}</Col>
                      </Row>
                    ))}
                  {project.airFlow.airFlowType === type.airflow.forceConvection &&
                    (project.airFlow.setup === type.flowSetup.impinge ? (
                      <Row>
                        <Col className="grid-col">{`Fan Heatsink coverage (%)`}</Col>
                        <Col className="grid-col">{project.airFlow.impingePercentage}</Col>
                      </Row>
                    ) : (
                      <></>
                    ))}
                </section>
              </div>
            </TabPane>

            <TabPane key="2" tab="Solution" disabled={!isComplete}>
              <div className="section-wrapper columns">
                <div>
                  <h4>Overview</h4>
                  <div className="section-content columns">
                    <div>Thermal Constraint</div>
                    {project.specifications.thermalConstraintType === 'TEMPERATURE' && (
                      <>
                        <div>Max temperature rise</div>
                        <div>Max temperature rise</div>
                        <div>{dimensionString(project.specifications.maxTemperatureRise)}</div>
                      </>
                    )}
                    {project.specifications.thermalConstraintType === 'THERMALRESISTANCE' && (
                      <>
                        <div>Max thermal resistance</div>
                        <div>Max thermal resistance</div>
                        <div>{dimensionString(project.specifications.maxThermalResis)}</div>
                      </>
                    )}
                    <div>Max pressure drop</div>
                    <div>{dimensionString(project.specifications.maxPressureDrop)}</div>
                  </div>
                </div>
                <div>
                  <h4>Result Files</h4>
                  <div className="section-content columns">
                    <div>Report (PDF)</div>
                    {files.pdf ? (
                      <div>
                        <a href={files.pdf.url} rel="noreferrer">
                          {files.pdf.name}
                        </a>
                      </div>
                    ) : (
                      <div>-</div>
                    )}

                    <div>SmartCFD Project (QOM)</div>
                    {files.qom ? (
                      <div>
                        <a href={files.qom.url} rel="noreferrer">
                          {files.qom.name}
                        </a>
                      </div>
                    ) : (
                      <div>-</div>
                    )}

                    <div>CAD (STEP)</div>
                    {files.step ? (
                      <div>
                        <a href={files.step.url} rel="noreferrer">
                          {files.step.name}
                        </a>
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="section-wrapper">
                <h4>Thermal Contours</h4>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    justifyItems: 'center',
                    marginBottom: '16px',
                    fontSize: '0.75rem',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                >
                  <div>
                    {images.x3d ? (
                      <div style={{ display: 'flex' }}>
                        <div>
                          <iframe
                            ref={iframeEl}
                            title="x3d"
                            width="100%"
                            height="340px"
                            style={{ border: 0 }}
                            src={`/x3d.html?url=${encodeURIComponent(images.x3d.url)}`}
                          />
                        </div>
                      </div>
                    ) : (
                      <Skeleton.Image />
                    )}
                  </div>
                  <div>{images.iso ? <Image src={images.iso.url} preview={false} /> : <Skeleton.Image />}</div>
                  <div>{images.bot ? <Image src={images.bot.url} preview={false} /> : <Skeleton.Image />}</div>
                  <div>3D View (Select image to Zoom/Rotate)</div>
                  <div>Side View</div>
                  <div>Top View</div>
                </div>
              </div>

              <div className="section-layout">
                <h4 className="section-heading">Heat Sources</h4>
                <section className="grid-section">
                  <Row>
                    <Col className="grid-col">
                      <b>Label</b>
                    </Col>
                    <Col className="grid-col">
                      <b>Power</b>
                    </Col>
                    <Col className="grid-col">
                      <b>Tmax</b>
                    </Col>
                    <Col className="grid-col">
                      <b>dT</b>
                    </Col>
                  </Row>
                  {solution.perHeatSource.map((hs, index) => (
                    <Row key={`hs-${index}`}>
                      <Col className="grid-col">{project.heatSources[index].label}</Col>
                      <Col className="grid-col">{dimensionString(project.heatSources[index].power)}</Col>
                      <Col className="grid-col">{dimensionString(hs.hskMaximumT)}</Col>
                      <Col className="grid-col">{dimensionString(hs.junctionT)}</Col>
                    </Row>
                  ))}
                </section>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      <Modal
        title="Simulation Progress"
        visible={showProgress}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        okButtonProps={{ disabled: !isComplete, loading: !isComplete }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', marginBottom: '16px' }}>
          {isComplete ? <div style={{ fontWeight: 600 }}>Complete</div> : <div>{message}</div>}
          <div>
            <Progress steps={5} percent={progress} />
          </div>
        </div>
      </Modal>
    </Card>
  );
};
