
import { dimension, type } from '../state/units';
import { convertValueUnitsTo } from './unit-conversion';

export const calcExtrusionDims = (project, path, itemName, value, designId) => {
  const id = designId === undefined ? project.activeDesignId : designId;
  const extrusion = project.designs[id].extrusion
  const leftPath = path.replace('.'+ itemName, '');
  if (itemName === "baseWidth") {
    return calcDimsFromBaseWidth(extrusion, leftPath, value);
  } else if (itemName === "baseThick") {
    return calcDimsFromBaseThick(extrusion, leftPath, value);
  } else if (itemName === "finThick"){
    return calcDimsFromFinThick(extrusion, leftPath, value);
  } else if (itemName === "finHeight") {
    return calcDimsFromFinHeight(extrusion, leftPath, value);
  } else if (itemName === "height" && !project.designs[id].extrusion.nonFlatback) {
    return calcDimsFromOverallHeight(extrusion, leftPath.replace('.dimensions', ''), value);
  } else if (itemName === 'finPitch'){
    return calcDimsFromFinPitch(extrusion, leftPath, value);
  } else if (itemName === 'numFins'){
    return calcDimsFromNumFins(extrusion, leftPath, value);
  } else if (itemName === 'hasOffset') {
    return calcDimsFromHasOffset(extrusion, leftPath, value);
  } else if (itemName === 'leftOffset') {
    return calcDimsFromLeftOffset(extrusion, leftPath, value);
  } else if (itemName === 'rightOffset') {
    return calcDimsFromRightOffset(extrusion, leftPath, value);
  }
  return []
};

const calcDimsFromBaseWidth = (extrusion, leftPath, newBaseWidth) => {
  const newWidth = newBaseWidth;
  let newDims = [ {path: leftPath + '.dimensions.width', value: newWidth }];
  const finPitchUnit = extrusion.finPitch.units;
  const leftOffset = extrusion.hasOffset ? extrusion.leftOffset : dimension;
  const rightOffset = extrusion.hasOffset ? extrusion.rightOffset : dimension;
  if (extrusion.finLayoutStyle === type.finLayoutStyle.numFins) {
    const newFinPitch = calcFinPitch(newWidth, extrusion.finThick, extrusion.numFins, finPitchUnit, leftOffset, rightOffset);
    newDims.push({path: leftPath + '.finPitch', value: newFinPitch});
  } else {
    const newNumFins = calcNumFins(newWidth, extrusion.finThick, extrusion.finPitch, leftOffset, rightOffset);
    newDims.push({path: leftPath + '.numFins', value: newNumFins});
  }
  return newDims;  
};

const calcDimsFromBaseThick = (extrusion, leftPath, newBaseThick) => {
  if (extrusion.specifyHeightBy === type.specifyExtrusionHeightBy.finHeight) {
    const fh = extrusion.finHeight.value;
    const fhUnit = extrusion.finHeight.units;
    const bh = convertValueUnitsTo({ value: newBaseThick, to: fhUnit});
    const newHeight = { ...dimension, value: bh + fh, units: fhUnit };
    return [{path: leftPath + '.dimensions.height', value: newHeight}];
  } else {
    const h = extrusion.dimensions.height.value;
    const hUnit = extrusion.dimensions.height.units;
    const bh = convertValueUnitsTo({ value: newBaseThick, to: hUnit});
    const newFinHeight = { ...dimension, value: h - bh, units: hUnit };
    return [{path: leftPath + '.finHeight', value: newFinHeight}];
  }
};

const calcDimsFromFinThick = (extrusion, leftPath, newFinThick) => {
  const leftOffset = extrusion.hasOffset ? extrusion.leftOffset : dimension;
  const rightOffset = extrusion.hasOffset ? extrusion.rightOffset : dimension;
  if (extrusion.finLayoutStyle === type.finLayoutStyle.numFins) {
    const newFinPitch = calcFinPitch(extrusion.baseWidth, newFinThick, extrusion.numFins, extrusion.finPitch.units, leftOffset, rightOffset);
    return [{path: leftPath + '.finPitch', value: newFinPitch}];
  } else {
    const newNumFins = calcNumFins(extrusion.baseWidth, newFinThick, extrusion.finPitch, leftOffset, rightOffset);
    return [{path: leftPath + '.numFins', value: newNumFins}];
  }  
};

const calcDimsFromFinHeight = (extrusion, leftpath, newFinHeight) => {
  const fh = newFinHeight.value;
  const fhUnit = newFinHeight.units;
  const bh = convertValueUnitsTo({ value:  extrusion.baseThick, to: fhUnit});
  const newHeight = { ...dimension, value: bh + fh, units: fhUnit };
  return [{path: leftpath + '.dimensions.height', value: newHeight}];
};

const calcDimsFromOverallHeight = (extrusion, leftpath, newOverallHeight) => {
  const h = newOverallHeight.value;
  const hUnit = newOverallHeight.units;
  const bh = convertValueUnitsTo({ value: extrusion.baseThick, to: hUnit});
  const newFh = { ...dimension, value: h - bh, units: hUnit };
  return [{path: leftpath + '.finHeight', value: newFh}];
}

const calcDimsFromFinPitch = (extrusion, leftPath, newFinPitch) => {
  const leftOffset = extrusion.hasOffset ? extrusion.leftOffset : dimension;
  const rightOffset = extrusion.hasOffset ? extrusion.rightOffset : dimension;
  const newNumFins = calcNumFins(extrusion.baseWidth, extrusion.finThick, newFinPitch, leftOffset, rightOffset);
  return [{path: leftPath + '.numFins', value: newNumFins}];
}

const calcDimsFromNumFins = (extrusion, leftPath, newNumFins) => {
  const leftOffset = extrusion.hasOffset ? extrusion.leftOffset : dimension;
  const rightOffset = extrusion.hasOffset ? extrusion.rightOffset : dimension;
  const newFinPitch = calcFinPitch(extrusion.baseWidth, extrusion.finThick, newNumFins, extrusion.finPitch.units, leftOffset, rightOffset);
  return [{path: leftPath + '.finPitch', value: newFinPitch}];
}

const calcDimsFromHasOffset = (extrusion, leftPath, newHasOffset) => {
  const leftOffset = newHasOffset ? extrusion.leftOffset : dimension;
  const rightOffset = newHasOffset ? extrusion.rightOffset : dimension;
  if (extrusion.finLayoutStyle === type.finLayoutStyle.numFins) {
    const newFinPitch = calcFinPitch(extrusion.baseWidth, extrusion.finThick, extrusion.numFins, extrusion.finPitch.units, leftOffset, rightOffset);
    return [{path: leftPath + '.finPitch', value: newFinPitch}];
  } else {
    const newNumFins = calcNumFins(extrusion.baseWidth, extrusion.finThick, extrusion.finPitch, leftOffset, rightOffset);
    return [{path: leftPath + '.numFins', value: newNumFins}];
  }  
}

const calcDimsFromLeftOffset = (extrusion, leftPath, newLeftOffset) => {
  const leftOffset = extrusion.hasOffset ? newLeftOffset : dimension;
  const rightOffset = extrusion.hasOffset ? extrusion.rightOffset : dimension;
  if (extrusion.finLayoutStyle === type.finLayoutStyle.numFins) {
    const newFinPitch = calcFinPitch(extrusion.baseWidth, extrusion.finThick, extrusion.numFins, extrusion.finPitch.units, leftOffset, rightOffset);
    return [{path: leftPath + '.finPitch', value: newFinPitch}];
  } else {
    const newNumFins = calcNumFins(extrusion.baseWidth, extrusion.finThick, extrusion.finPitch, leftOffset, rightOffset);
    return [{path: leftPath + '.numFins', value: newNumFins}];
  }  
}

const calcDimsFromRightOffset = (extrusion, leftPath, newRightOffset) => {
  const leftOffset = extrusion.hasOffset ? extrusion.leftOffset : dimension;
  const rightOffset = extrusion.hasOffset ? newRightOffset : dimension;
  if (extrusion.finLayoutStyle === type.finLayoutStyle.numFins) {
    const newFinPitch = calcFinPitch(extrusion.baseWidth, extrusion.finThick, extrusion.numFins, extrusion.finPitch.units, leftOffset, rightOffset);
    return [{path: leftPath + '.finPitch', value: newFinPitch}];
  } else {
    const newNumFins = calcNumFins(extrusion.baseWidth, extrusion.finThick, extrusion.finPitch, leftOffset, rightOffset);
    return [{path: leftPath + '.numFins', value: newNumFins}];
  }  
}

const calcFinPitch = (width, finThick, nf, finPitchUnit, leftOffset, rightOffset) => {
  const bw = convertValueUnitsTo({value: width, to: finPitchUnit});
  const lo = convertValueUnitsTo({value: leftOffset, to: finPitchUnit});
  const ro = convertValueUnitsTo({value: rightOffset, to: finPitchUnit});
  const ft = convertValueUnitsTo({value: finThick, to: finPitchUnit});
  const fp = (bw - lo - ro - ft) / (nf - 1);
  return { ...dimension, value: fp, units: finPitchUnit };
}

const calcNumFins = (width, finThick, finPitch, leftOffset, rightOffset) => {
  const finPitchUnit = finPitch.units;
  const fp = finPitch.value;
  const bw = convertValueUnitsTo({value: width, to: finPitchUnit});
  const lo = convertValueUnitsTo({value: leftOffset, to: finPitchUnit});
  const ro = convertValueUnitsTo({value: rightOffset, to: finPitchUnit});
  const ft = convertValueUnitsTo({value: finThick, to: finPitchUnit});
  const nf = parseInt(Math.round(1 + (bw - lo - ro - ft) / fp));
  return nf;
}