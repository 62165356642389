import { useState } from 'react';
import { getPerformanceEstimates } from '../costing/project-api';
import { Button, Card, Statistic } from 'antd';

export const PerformanceEstimate = ({ project }) => {
  const [loading, setLoading] = useState(false);
  const [performanceEstimates, setPerformanceEstimates] = useState({ dP: 0, dT: 0, dR: 0, weight: 0 });

  const updatePerformanceEstimate = async () => {
    setLoading(true);
    const newEstimates = await getPerformanceEstimates(project);
    if (newEstimates.length > 0){
      setPerformanceEstimates(newEstimates[0]);
    }
    setLoading(false);
  };

  const { dP, dT, dR, weight } = performanceEstimates;

  return (
    <Card
      title="Performance Estimate"
      bordered={false}
      actions={[
        <Button type="primary" loading={loading} onClick={updatePerformanceEstimate}>
          Update
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
        <Statistic title="ΔP (Pa)" value={dP} precision={2} loading={loading} />
        <Statistic title="ΔT (C)" value={dT} precision={2} loading={loading} />
        <Statistic title="ΔR (C/W)" value={dR} precision={2} loading={loading} />
        <Statistic title="Weight (Kg)" value={weight} precision={2} loading={loading} />
      </div>
    </Card>
  );
};
