import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Select, Button, Modal } from 'antd';
import { authState } from '../../state/auth';
import { TagOutlined } from '@ant-design/icons';
import { projectState } from '../../state/project';

const { Option } = Select;

export const ProjectTagsModal = ({
  showButton = true,
  showIcon = false,
  show = false,
  close = () => {},
  projectId,
  tags,
}) => {
  const auth = useRecoilValue(authState);
  const [project, setProject] = useRecoilState(projectState({ userId: auth.username, projectId }));
  const [selectedTags, setSelectedTags] = useState(project.tags || []);
  const [showModal, setShowModal] = useState(show);

  const onCancel = () => {
    setShowModal(false);
    close(false);
  };

  const onChange = (values) =>
    setSelectedTags([...new Set(values.map((value) => value.trim().toLowerCase().substring(0, 25)))].filter(Boolean));

  const saveTags = () => {
    const { createdAt, updatedAt, owner, tags, ...rest } = project;
    const input = { createdAt, ...rest, tags: selectedTags };
    setProject(input);
    onCancel();
  };

  useEffect(() => setShowModal(show), [show]);

  const options = tags.map((name) => <Option key={name}>{name}</Option>);

  return (
    <>
      {showButton && <Button onClick={() => setShowModal(true)} icon={<TagOutlined title="Tags" />} />}
      {showIcon && <TagOutlined onClick={() => setShowModal(true)} />}
      <Modal title="Project Tags" visible={showModal} onCancel={onCancel} onOk={saveTags} okText="Save" width={480}>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          tokenSeparators={[',']}
          maxTagCount="responsive"
          placeholder="Tags..."
          defaultValue={selectedTags}
          value={selectedTags}
          onChange={onChange}
        >
          {options}
        </Select>
      </Modal>
    </>
  );
};
