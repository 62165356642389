import { Tooltip, Button } from 'antd';
import { PlusSquareOutlined, RotateRightOutlined, CopyOutlined, AimOutlined, DeleteOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

export const HeatSourceControls = ({ action, disabled = false, isSelected = false }) => {
  return (
    <div className="heat-source-controls">
      <Tooltip title="Add">
        <Button disabled={disabled} onClick={() => action('ADD')}>
          <PlusSquareOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Copy">
        <Button disabled={!isSelected} onClick={() => action('COPY')}>
          <CopyOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button disabled={!isSelected} onClick={() => action('DELETE')}>
          <DeleteOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Rotate">
        <Button disabled={!isSelected} onClick={() => action('ROTATE')}>
          <RotateRightOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Center">
        <Button disabled={!isSelected} onClick={() => action('CENTER')}>
          <AimOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Zoom Out">
        <Button disabled={disabled} onClick={() => action('ZOOMOUT')}>
          <ZoomOutOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Zoom In">
        <Button disabled={disabled} onClick={() => action('ZOOMIN')}>
          <ZoomInOutlined />
        </Button>
      </Tooltip>
    </div>
  );
};

export default HeatSourceControls;
