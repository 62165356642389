import { useEffect, useState } from 'react';
import { Hub } from '@aws-amplify/core';
import './common.less';

export const AutoSaveHeader = ({ title }) => {
  const [autoSaveStatus, setAutoSaveStatus] = useState('');

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const callback = ({ payload }) => setAutoSaveStatus(payload.message);
    if (title) {
      Hub.listen('autosave', callback);
    }
    return () => Hub.remove('autosave', callback);
  }, [title]);

  return (
    <div className="autosave-layout">
      <div>
        <h3>{title}</h3>
      </div>
      <div className="autosave-status">{autoSaveStatus}</div>
    </div>
  );
};
