import { Suspense } from 'react';
import './layout-cards.less';

export const LayoutCards = ({ header, children }) => {
  return (
    <div className="layout-cards-wrapper">
      <div className="layout-cards-header">{header}</div>
      <div className="layout-cards-grid">
        <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
      </div>
    </div>
  );
};
