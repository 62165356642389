import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'wouter';
import { API } from '@aws-amplify/api';
import { onCreateProject, onDeleteProject, onUpdateProject } from '../../graphql/subscriptions';
import { projectsState } from '../../state/projects';
import {hasGroup, authState} from '../../state/auth';
import { sortByTimestamp } from '../../utils/date';

export function useProjectsSubscribe() {
  const [, setLocation] = useLocation();
  const auth = useRecoilValue(authState);
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const setProjects = useSetRecoilState(projectsState({ userId: auth.username, isAdmin }));

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const onCreateProjectListener = API.graphql({
      query: onCreateProject,
      variables: {
        owner: auth.username,
      },
    }).subscribe({
      next: (result) => {
        const createdProject = result.value.data.onCreateProject;
        setProjects((prevProjects) => [createdProject, ...prevProjects]);
        setLocation(`/p/${createdProject.id}`);
      },
    });

    const onUpdateProjectListener = API.graphql({
      query: onUpdateProject,
      variables: {
        owner: auth.username,
      },
    }).subscribe({
      next: (result) => {
        const updatedProject = result.value.data.onUpdateProject;
        setProjects((prevProjects) =>
          sortByTimestamp({
            items: prevProjects.map((project) => {
              return project.id === updatedProject.id ? updatedProject : project;
            }),
            key: 'updatedAt',
          }),
        );
      },
    });

    const onDeleteProjectListener = API.graphql({
      query: onDeleteProject,
      variables: {
        owner: auth.username,
      },
    }).subscribe({
      next: (result) => {
        const deletedProject = result.value.data.onDeleteProject;
        setProjects((prevProjects) => prevProjects.filter((project) => project.id !== deletedProject.id));
      },
    });

    return () => {
      onCreateProjectListener.unsubscribe();
      onUpdateProjectListener.unsubscribe();
      onDeleteProjectListener.unsubscribe();
    };
  }, [auth.username]);
}
