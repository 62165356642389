import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'wouter';
import { Button, Card, Form, Input, message } from 'antd';
import { ProfileForm } from '../profile/profile-form';
import { authState } from '../../state/auth';
import { userState } from '../../state/user';
import { API } from '@aws-amplify/api';
import { verifyChallenge } from '../../graphql/queries';
import shajs from 'sha.js';

const { Meta } = Card;
const { Item } = Form;

export const VerifyEmail = () => {
  const [, setLocation] = useLocation();
  const auth = useRecoilValue(authState);
  const user = useRecoilValue(userState(auth.username));
  const [verifyCode, setVerifyCode] = useState();
  const [loading, setLoading] = useState(false);

  const sendChallenge = async () => {
    try {
      const response = await API.graphql({
        query: verifyChallenge,
        variables: { email: auth.email },
      });
      const codeHash = JSON.parse(response.data.verifyChallenge);
      setVerifyCode(codeHash);
    } catch (error) {
      console.error(error);
      throw new Error('Verify Challenge error');
    }
  };

  const resendChallenge = () => {
    if (!loading) {
      setLoading(true);
      setTimeout(() => {
        sendChallenge();
        setLoading(false);
      }, 500);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (user.createdAt !== undefined) {
      sendChallenge().then();
    }
  }, []);

  return user.createdAt === undefined ? (
    <Card
      style={{
        width: 460,
        borderRadius: '6px',
        boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.15)',
      }}
    >
      <Meta title="Complete your profile" />
      <div style={{ margin: '32px 16px 16px' }}>
        <ProfileForm
          auth={auth}
          callback={() => {
            const verifyCode = shajs('sha256').update(new Date().getTime()).digest('hex');
            localStorage.setItem('verifyCode', verifyCode);
            setLocation('/projects');
          }}
        />
      </div>
    </Card>
  ) : (
    <Card
      style={{
        width: 460,
        borderRadius: '6px',
        boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.15)',
      }}
    >
      <Meta title="Confirm your email address" />
      <div style={{ margin: '32px 16px 16px' }}>
        <Form
          name="confirm"
          initialValues={{ code: '' }}
          onFinish={({ code }) => {
            const codeHash = shajs('sha256').update(code).digest('hex');
            if (codeHash === verifyCode) {
              // persist code in local session storage
              localStorage.setItem('verifyCode', verifyCode);
              setLocation('/projects');
            } else {
              message.error('Incorrect code');
            }
          }}
          layout="vertical"
        >
          <Item>
            <div>A confirmation code has been sent to your registered email address</div>
          </Item>
          <Item
            name="code"
            label="Confirmation Code"
            rules={[{ required: true, message: 'Please enter confirmation code' }]}
          >
            <Input />
          </Item>
          <Item>
            Lost your code?{' '}
            <Button type="link" loading={loading} onClick={resendChallenge}>
              {loading ? 'Sending...' : 'Resend code'}
            </Button>
          </Item>
          <Item style={{ textAlign: 'right' }}>
            <Button type="primary" size="medium" htmlType="submit">
              Confirm
            </Button>
          </Item>
        </Form>
      </div>
    </Card>
  );
};
