import { useState, Suspense } from 'react';
import { Card, Modal, Table, Popover, Spin } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { AmplifyS3Image } from '@aws-amplify/ui-react';
import { type } from '../../../state/units';
import './extrusion-select-modal.less';

const columns = [
  {
    key: 'flow',
    title: 'Flow',
    dataIndex: 'flow',
    align: 'center',
    width: 70,
  },
  {
    key: 'length',
    title: 'Length (in)',
    dataIndex: 'length',
    align: 'right',
    width: 70,
    sorter: (a, b) => a.length - b.length,
  },
  {
    title: 'Base',
    key: 'base',
    children: [
      {
        key: 'width',
        title: 'Width (in)',
        dataIndex: 'width',
        align: 'right',
        width: 90,
        sorter: (a, b) => a.width - b.width,
      },
      {
        key: 'baseThick',
        title: 'Thickness (in)',
        dataIndex: 'baseThick',
        align: 'right',
        width: 90,
        sorter: (a, b) => a.baseThick - b.baseThick,
      },
    ],
  },
  {
    title: 'Fins',
    key: 'fins',
    children: [
      {
        key: 'finThick',
        title: 'Thickness (in)',
        dataIndex: 'finThick',
        align: 'right',
        width: 90,
        sorter: (a, b) => a.finThick - b.finThick,
      },
      {
        key: 'finHeight',
        title: 'Height (in)',
        dataIndex: 'finHeight',
        align: 'right',
        width: 90,
        sorter: (a, b) => a.finHeight - b.finHeight,
      },
      {
        key: 'finPitch',
        title: 'Pitch (in)',
        dataIndex: 'finPitch',
        align: 'right',
        width: 90,
        sorter: (a, b) => a.finPitch - b.finPitch,
      },
      {
        key: 'numFins',
        title: 'Number',
        dataIndex: 'numFins',
        align: 'right',
        width: 90,
        sorter: (a, b) => a.numFins - b.numFins,
      },
    ],
  },
  {
    key: 'image',
    title: 'Image',
    dataIndex: 'imagePath',
    align: 'center',
    width: 70,
    render: (_, record) => (
      <Popover placement="right" content={ExtrusionImageHelp({ imagePath: record.imagePath, flow: record.flow })}>
        <PictureOutlined />
      </Popover>
    ),
  },
];

const ExtrusionImageHelp = ({ imagePath, flow }) => (
  <Card title={`${flow} Flow Extrusion`} bordered={false}>
    <AmplifyS3Image imgKey={imagePath} imgProps={{ height: 180 }} />
  </Card>
);

const Loading = () => <div>Loading...</div>;

export const OptimalExtrusionSelectModal = ({
  selectedFlow,
  data,
  unitSystem,
  visible,
  onCallback,
  onCancel,
  loadingData,
}) => {
  const [curSel, setCurSel] = useState(selectedFlow);
  if (unitSystem === type.system.metric) {
    columns.forEach((column) =>
      column.children
        ? column.children.forEach((childColumn) => (childColumn.title = childColumn.title.replace('(in)', '(mm)')))
        : (column.title = column.title.replace('(in)', '(mm)')),
    );
  } else {
    columns.forEach((column) =>
      column.children
        ? column.children.forEach((childColumn) => (childColumn.title = childColumn.title.replace('(mm)', '(in)')))
        : (column.title = column.title.replace('(mm)', '(in)')),
    );
  }

  return (
    <Modal
      title={`Select Extrusion`}
      visible={visible}
      onOk={(e) => {
        onCallback(curSel);
        setCurSel('');
      }}
      onCancel={onCancel}
      centered
      width={800}
      bodyStyle={{ height: '320px', overflowY: 'auto' }}
    >
      <Suspense fallback={<Loading />}>
        {loadingData ? (
          <div className="table-spinner">
            <Spin tip="Optimizing..." />
          </div>
        ) : (
          <Table
            className="extrusion-table"
            rowClassName={(record) => (record.flow === curSel ? 'table-row-select' : 'table-row-unselect')}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            rowKey={(record) => record.flow}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  setCurSel(record.flow);
                },
              };
            }}
          />
        )}
      </Suspense>
    </Modal>
  );
};
