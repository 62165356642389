import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Form, Input, Button } from 'antd';
import { customerState } from '../../state/customer';
import { customersState } from '../../state/customers';

const { Item } = Form;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};
const tailLayout = {
  wrapperCol: { offset: 19 },
};

export const CustomerForm = ({ customerId = undefined, callback = () => {} }) => {
  const [form] = Form.useForm();
  const [customer, setCustomer] = useRecoilState(customerState(customerId, callback));
  const customers = useRecoilValue(customersState(null));

  const validCustomerName = (value) => {
    const str = value.trim();
    if (str === '') return 0;
    const matches = customers.filter(
      ({ id, name }) =>
        id !== customer.id && name.localeCompare(str, 'en', { sensitivity: 'base', ignorePunctuation: true }) === 0,
    );
    return matches.length === 0;
  };

  const saveCustomer = (data) => {
    setCustomer({ ...customer, ...data }, callback);
    form.resetFields();
    callback();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form.resetFields();
  }, [customerId]);

  return (
    <Form form={form} name="customer" initialValues={customer} onFinish={saveCustomer} {...layout}>
      <Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: 'Customer name is required' },
          () => ({
            validator(_, value) {
              if (validCustomerName(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Customer name not unique'));
            },
          }),
        ]}
      >
        <Input />
      </Item>
      <Item {...tailLayout}>
        <Button type="primary" htmlType="submit" size="medium">
          Save
        </Button>
      </Item>
    </Form>
  );
};
