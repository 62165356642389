import './layout-home.less';
import { Logo } from '../common/';

export const LayoutHome = ({ children }) => {
  return (
    <div className="layout-home-container">
      <header className="layout-home-header">
        <div className="logo">
          <Logo />
        </div>
        <div className="logo-text">Boyd Genie</div>
        <div />
      </header>
      <main className="layout-home-main">{children}</main>
      <footer className="layout-home-footer">
        <div>Boyd Genie V2</div>
      </footer>
    </div>
  );
};
