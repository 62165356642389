import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Form, Input, Radio, Button } from 'antd';
import { authState, hasGroup } from '../../state/auth';
import { projectState } from '../../state/project';
import { type } from '../../state/units';
import { CustomerSelect } from '../customer';
import { convertObjectUnits } from '../../utils/unit-conversion';
import { projectsState } from '../../state/projects';

const { Item } = Form;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 20 },
};

export const ProjectForm = ({ projectId = undefined, visible, onCancel }) => {
  const [form] = Form.useForm();
  const auth = useRecoilValue(authState);
  const [project, setProject] = useRecoilState(projectState({ userId: auth.username, projectId }));
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const projects = useRecoilValue(projectsState({ userId: auth.username, isAdmin }));

  const validProjectName = (value) => {
    const str = value.trim();
    if (str === '') return 0;
    const matches = isAdmin
      ? projects
          .filter(({ userId }) => userId === auth.username)
          .filter(
            ({ id, name }) =>
              id !== project.id &&
              name.localeCompare(str, 'en', { sensitivity: 'base', ignorePunctuation: true }) === 0,
          )
      : projects.filter(
          ({ id, name }) =>
            id !== project.id && name.localeCompare(str, 'en', { sensitivity: 'base', ignorePunctuation: true }) === 0,
        );
    return matches.length === 0;
  };

  const saveProject = (data) => {
    setProject(convertObjectUnits({ ...project, ...data }, data.units));
    form.resetFields();
    onCancel();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form.resetFields();
  });

  // clear new project name, description as these will persisted in recoil state for projectId === undefined
  const initialValues = projectId ? project : {...project, name: '', description: '' };

  return (
    <Form form={form} name="project" initialValues={initialValues} onFinish={saveProject} {...layout}>
      <Item
        name="name"
        label="Name"
        validateFirst={true}
        rules={[
          { required: true, message: 'Project name is required' },
          // do not need to use getFieldValue(fieldName)
          () => ({
            validator(_, value) {
              if (validProjectName(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Project name not unique'));
            },
          }),
        ]}
      >
        <Input />
      </Item>
      <Item name="description" label="Description">
        <Input.TextArea />
      </Item>
      <Item name="units" label="Units">
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={type.system.metric}>Metric (mm, m, kg, °C)</Radio.Button>
          <Radio.Button value={type.system.american}>English (in, ft, lbs, °C)</Radio.Button>
        </Radio.Group>
      </Item>
      <Item name="customerId" label="Customer">
        <CustomerSelect projectId={project.customerId} />
      </Item>
      <Item {...tailLayout}>
        <Button type="primary" htmlType="submit" size="medium">
          Save
        </Button>
      </Item>
    </Form>
  );
};
