import { useRecoilState } from 'recoil';
import { Form, Switch } from 'antd';
import { userState } from '../../state/user';
import { Tags } from './tags';

const { Item } = Form;
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};

export const SettingsForm = ({ auth }) => {
  const [user, setUser] = useRecoilState(userState(auth.username));
  const { flags = [], tags = [] } = user.settings;

  const setFlags = ({ id, value }) => {
    // removed readonly records keys
    const { createdAt, updatedAt, owner, projects, settings, ...rest } = user;
    // createdAt used to determine new user record
    const { flags, ...other } = settings;
    const updatedFlags = flags.map((flag) => (flag.id === id ? { id, value } : flag));
    const input = { createdAt, ...rest, settings: { ...other, flags: updatedFlags } };
    setUser(input);
  };

  const setTags = ({ tags }) => {
    const { createdAt, updatedAt, owner, projects, settings, ...rest } = user;
    const input = { createdAt, ...rest, settings: { ...settings, tags } };
    setUser(input);
  };

  return (
    <Form name="settings" {...layout}>
      <Tags tags={tags} setTags={setTags} />

      {flags.map(({ id, value }) => (
        <Item key={id} label={id}>
          <Switch
            defaultChecked={value}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => setFlags({ id, value: checked })}
          />
        </Item>
      ))}
    </Form>
  );
};
