import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { Amplify } from '@aws-amplify/core';
import { Storage } from '@aws-amplify/storage';
import config from './aws-exports';
import { Route, Switch } from 'wouter';
import { Home, Verify, Customers, Projects, Project } from './views';
import { NotFound } from './components/common';
import { LayoutAuth } from './components/layout';
import { ReAuth } from './components/auth';
import './css/index.less';

Amplify.configure(config);
// S3 bucket and object access permissions are NOT public
Storage.configure({ level: 'public' });

const ProtectedRoute = ({ path, component: Component }) => {
  return (
    <Route path={path}>
      {(params) => (
        <LayoutAuth>
          <Component {...params} />
        </LayoutAuth>
      )}
    </Route>
  );
};

ReactDOM.render(
  <RecoilRoot>
    <Suspense
      fallback={
        <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
          Loading...
        </div>
      }
    >
      <ReAuth />
      <Switch>
        <Route path="/" component={Home} />
        <Route path="/verify" component={Verify} />
        <ProtectedRoute path="/projects" component={Projects} />
        <ProtectedRoute path="/p/:projectId" component={Project} />
        <ProtectedRoute path="/customers" component={Customers} />
        <ProtectedRoute path="/c/:customerId" component={Projects} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </RecoilRoot>,
  document.getElementById('root'),
);
