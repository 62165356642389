/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      type
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      qualified
      settings
      createdAt
      projects {
        items {
          id
          type
          name
          userId
          customerId
          description
          star
          tags
          units
          quoteSpecs {
            quoteHeader {
              quoteNo
              date
              quoteEntity
              customerPartNumber
              revision
              aavidPartNumber
              costedBy
              quantity
              customerContact
              customerAddress
              customerEmail
              customerPhone
              useUserContactAsSales
              salesContact
              salesEmail
              salesPhone
            }
            cutLength {
              type
              value
              units
            }
            stackable
            inventoryStock {
              type
              value
              units
            }
            inventoryCosts {
              cost
              minQtyWeight {
                type
                value
                units
              }
              override
              toolingOverride
              toolingOverrideCost
              factory
            }
            cnc {
              sides
              holes
              flycut
              flycutFlatnessCallout
              flycutRoughnessCallout
              customFlycutArea
              customFlycutWidth {
                type
                value
                units
              }
              customFlycutDepth {
                type
                value
                units
              }
              pocketVolume {
                type
                value
                units
              }
            }
            counterBores {
              cbores
              finCheck
              addTime
            }
            sandGaps
            finish {
              style
              blindHoles
              blindFaces
              cosmetic
              nonStandardFinish
            }
            finMachining {
              length {
                type
                value
                units
              }
              depth {
                type
                value
                units
              }
            }
            inserts {
              insertQty
              insertCost
            }
            marking
            margin
            surchargePercentage
            nreGMPercentage
            itarCompliance
            highProjectValue
            rawExtrusionBar
            visitedCostingTabs
            cuttingOption
            priceType
          }
          specifications {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            thermalConstraintType
            maxTemperatureRise {
              type
              value
              units
            }
            maxThermalResis {
              type
              value
              units
            }
            useMaxDP
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            optimizeSize
            totalLoad {
              type
              value
              units
            }
            thermalSpecificationsProvided
            useCustomerExtrusionMaxSize
            customerExtrusionProvided
            standardExtrusionRequested
            cdfNumberProvided
            cdfNumber
          }
          designs {
            name
            description
            type
            extrusion {
              partId
              material
              surfaceFinish {
                id
                name
                emissivity
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              region
              autoCalcWeightPerFeet
              weightPerFeet {
                type
                value
                units
              }
              nonFlatback
              class
              stickLength {
                type
                value
                units
              }
              factory
              vendor
              type
              inventory
              vendorId
              image
              shapeType
              baseWidth {
                type
                value
                units
              }
              baseThick {
                type
                value
                units
              }
              finPitch {
                type
                value
                units
              }
              finThick {
                type
                value
                units
              }
              finHeight {
                type
                value
                units
              }
              hasOffset
              leftOffset {
                type
                value
                units
              }
              rightOffset {
                type
                value
                units
              }
              leftThick {
                type
                value
                units
              }
              rightThick {
                type
                value
                units
              }
              numFins
              numFinsLeft
              numFinsRight
              numOuterFins
              numInnerFins
              gap {
                type
                value
                units
              }
              outerGap {
                type
                value
                units
              }
              innerGap {
                type
                value
                units
              }
              areaGain {
                type
                value
                units
              }
              perimeter {
                type
                value
                units
              }
              finLayoutStyle
              specifyHeightBy
            }
            base {
              size {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              material
              weight {
                type
                value
                units
              }
            }
            fins {
              tech
              material
              finOption
              fin {
                type
                style
                pitch {
                  type
                  value
                  units
                }
                thickness {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
                region
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
            }
            heatPipe {
              heatpipes {
                position {
                  x {
                    type
                    value
                    units
                  }
                  y {
                    type
                    value
                    units
                  }
                }
                length {
                  type
                  value
                  units
                }
                diameter {
                  type
                  value
                  units
                }
              }
            }
            lcp {
              baseMaterial
              tubeMaterial
              fluid
              percentageWater
              inletTemperature {
                type
                value
                units
              }
              headerSize
              tubeOuterDiameter
              pipeConfiguration
              embeddedPosition
              numberPasses
              pipes {
                x {
                  type
                  value
                  units
                }
                y {
                  type
                  value
                  units
                }
              }
              flowRate {
                type
                value
                units
              }
            }
            status
            simulationStatus {
              progress
              message
            }
            solution {
              simulationTime
              maximumTemperature {
                type
                value
                units
              }
              fluidPressureDrop {
                type
                value
                units
              }
              fluidExitTemperature {
                type
                value
                units
              }
              perHeatSource {
                coveragePercentage
                hskAverageT {
                  type
                  value
                  units
                }
                hskMaximumT {
                  type
                  value
                  units
                }
                caseT {
                  type
                  value
                  units
                }
                junctionT {
                  type
                  value
                  units
                }
              }
              createdAt
            }
            costEstimates {
              quantity
              quantityByWt
              vendor
              totalCost
              baseMaterialCost
              vendorMaterialCost
              basePlusVendorMaterialCost
              materialPlusVendorCostPerLb
              insertsMaterialCost
              materialCost
              osvCost
              freightCost
              laborCost
              overheadCost
              leadTime
              marginPercentage
              sellPrice
              surcharge
              specialCondition
              materialMinimumImposed
              minQuantityByWt
              lineMinimumImposed
              lineMinimumCost
              nreCost
              toolingCost
              faiCost
              drawingCost
              isLowerExtendedPrice
              isDirty
            }
            liteCosting {
              liteCostEstimates {
                quantity
                piecePrice
                extraPrice
                finalPrice
                extendedPrice
                totalMaterialLength
              }
              costedCDF
              isDirty
            }
            viewState
            createdAt
          }
          activeDesignId
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            interfaceMaterial
          }
          airFlow {
            airFlowType
            orientation
            forcedConvectionType
            velocity {
              type
              value
              units
            }
            flowRate {
              type
              value
              units
            }
            fan {
              id
              label
              acdc
              size
              fanCurve
              region
            }
            setup
            impingePercentage
          }
          viewState
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      type
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      qualified
      settings
      createdAt
      projects {
        items {
          id
          type
          name
          userId
          customerId
          description
          star
          tags
          units
          quoteSpecs {
            quoteHeader {
              quoteNo
              date
              quoteEntity
              customerPartNumber
              revision
              aavidPartNumber
              costedBy
              quantity
              customerContact
              customerAddress
              customerEmail
              customerPhone
              useUserContactAsSales
              salesContact
              salesEmail
              salesPhone
            }
            cutLength {
              type
              value
              units
            }
            stackable
            inventoryStock {
              type
              value
              units
            }
            inventoryCosts {
              cost
              minQtyWeight {
                type
                value
                units
              }
              override
              toolingOverride
              toolingOverrideCost
              factory
            }
            cnc {
              sides
              holes
              flycut
              flycutFlatnessCallout
              flycutRoughnessCallout
              customFlycutArea
              customFlycutWidth {
                type
                value
                units
              }
              customFlycutDepth {
                type
                value
                units
              }
              pocketVolume {
                type
                value
                units
              }
            }
            counterBores {
              cbores
              finCheck
              addTime
            }
            sandGaps
            finish {
              style
              blindHoles
              blindFaces
              cosmetic
              nonStandardFinish
            }
            finMachining {
              length {
                type
                value
                units
              }
              depth {
                type
                value
                units
              }
            }
            inserts {
              insertQty
              insertCost
            }
            marking
            margin
            surchargePercentage
            nreGMPercentage
            itarCompliance
            highProjectValue
            rawExtrusionBar
            visitedCostingTabs
            cuttingOption
            priceType
          }
          specifications {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            thermalConstraintType
            maxTemperatureRise {
              type
              value
              units
            }
            maxThermalResis {
              type
              value
              units
            }
            useMaxDP
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            optimizeSize
            totalLoad {
              type
              value
              units
            }
            thermalSpecificationsProvided
            useCustomerExtrusionMaxSize
            customerExtrusionProvided
            standardExtrusionRequested
            cdfNumberProvided
            cdfNumber
          }
          designs {
            name
            description
            type
            extrusion {
              partId
              material
              surfaceFinish {
                id
                name
                emissivity
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              region
              autoCalcWeightPerFeet
              weightPerFeet {
                type
                value
                units
              }
              nonFlatback
              class
              stickLength {
                type
                value
                units
              }
              factory
              vendor
              type
              inventory
              vendorId
              image
              shapeType
              baseWidth {
                type
                value
                units
              }
              baseThick {
                type
                value
                units
              }
              finPitch {
                type
                value
                units
              }
              finThick {
                type
                value
                units
              }
              finHeight {
                type
                value
                units
              }
              hasOffset
              leftOffset {
                type
                value
                units
              }
              rightOffset {
                type
                value
                units
              }
              leftThick {
                type
                value
                units
              }
              rightThick {
                type
                value
                units
              }
              numFins
              numFinsLeft
              numFinsRight
              numOuterFins
              numInnerFins
              gap {
                type
                value
                units
              }
              outerGap {
                type
                value
                units
              }
              innerGap {
                type
                value
                units
              }
              areaGain {
                type
                value
                units
              }
              perimeter {
                type
                value
                units
              }
              finLayoutStyle
              specifyHeightBy
            }
            base {
              size {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              material
              weight {
                type
                value
                units
              }
            }
            fins {
              tech
              material
              finOption
              fin {
                type
                style
                pitch {
                  type
                  value
                  units
                }
                thickness {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
                region
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
            }
            heatPipe {
              heatpipes {
                position {
                  x {
                    type
                    value
                    units
                  }
                  y {
                    type
                    value
                    units
                  }
                }
                length {
                  type
                  value
                  units
                }
                diameter {
                  type
                  value
                  units
                }
              }
            }
            lcp {
              baseMaterial
              tubeMaterial
              fluid
              percentageWater
              inletTemperature {
                type
                value
                units
              }
              headerSize
              tubeOuterDiameter
              pipeConfiguration
              embeddedPosition
              numberPasses
              pipes {
                x {
                  type
                  value
                  units
                }
                y {
                  type
                  value
                  units
                }
              }
              flowRate {
                type
                value
                units
              }
            }
            status
            simulationStatus {
              progress
              message
            }
            solution {
              simulationTime
              maximumTemperature {
                type
                value
                units
              }
              fluidPressureDrop {
                type
                value
                units
              }
              fluidExitTemperature {
                type
                value
                units
              }
              perHeatSource {
                coveragePercentage
                hskAverageT {
                  type
                  value
                  units
                }
                hskMaximumT {
                  type
                  value
                  units
                }
                caseT {
                  type
                  value
                  units
                }
                junctionT {
                  type
                  value
                  units
                }
              }
              createdAt
            }
            costEstimates {
              quantity
              quantityByWt
              vendor
              totalCost
              baseMaterialCost
              vendorMaterialCost
              basePlusVendorMaterialCost
              materialPlusVendorCostPerLb
              insertsMaterialCost
              materialCost
              osvCost
              freightCost
              laborCost
              overheadCost
              leadTime
              marginPercentage
              sellPrice
              surcharge
              specialCondition
              materialMinimumImposed
              minQuantityByWt
              lineMinimumImposed
              lineMinimumCost
              nreCost
              toolingCost
              faiCost
              drawingCost
              isLowerExtendedPrice
              isDirty
            }
            liteCosting {
              liteCostEstimates {
                quantity
                piecePrice
                extraPrice
                finalPrice
                extendedPrice
                totalMaterialLength
              }
              costedCDF
              isDirty
            }
            viewState
            createdAt
          }
          activeDesignId
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            interfaceMaterial
          }
          airFlow {
            airFlowType
            orientation
            forcedConvectionType
            velocity {
              type
              value
              units
            }
            flowRate {
              type
              value
              units
            }
            fan {
              id
              label
              acdc
              size
              fanCurve
              region
            }
            setup
            impingePercentage
          }
          viewState
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      type
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      qualified
      settings
      createdAt
      projects {
        items {
          id
          type
          name
          userId
          customerId
          description
          star
          tags
          units
          quoteSpecs {
            quoteHeader {
              quoteNo
              date
              quoteEntity
              customerPartNumber
              revision
              aavidPartNumber
              costedBy
              quantity
              customerContact
              customerAddress
              customerEmail
              customerPhone
              useUserContactAsSales
              salesContact
              salesEmail
              salesPhone
            }
            cutLength {
              type
              value
              units
            }
            stackable
            inventoryStock {
              type
              value
              units
            }
            inventoryCosts {
              cost
              minQtyWeight {
                type
                value
                units
              }
              override
              toolingOverride
              toolingOverrideCost
              factory
            }
            cnc {
              sides
              holes
              flycut
              flycutFlatnessCallout
              flycutRoughnessCallout
              customFlycutArea
              customFlycutWidth {
                type
                value
                units
              }
              customFlycutDepth {
                type
                value
                units
              }
              pocketVolume {
                type
                value
                units
              }
            }
            counterBores {
              cbores
              finCheck
              addTime
            }
            sandGaps
            finish {
              style
              blindHoles
              blindFaces
              cosmetic
              nonStandardFinish
            }
            finMachining {
              length {
                type
                value
                units
              }
              depth {
                type
                value
                units
              }
            }
            inserts {
              insertQty
              insertCost
            }
            marking
            margin
            surchargePercentage
            nreGMPercentage
            itarCompliance
            highProjectValue
            rawExtrusionBar
            visitedCostingTabs
            cuttingOption
            priceType
          }
          specifications {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            thermalConstraintType
            maxTemperatureRise {
              type
              value
              units
            }
            maxThermalResis {
              type
              value
              units
            }
            useMaxDP
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            optimizeSize
            totalLoad {
              type
              value
              units
            }
            thermalSpecificationsProvided
            useCustomerExtrusionMaxSize
            customerExtrusionProvided
            standardExtrusionRequested
            cdfNumberProvided
            cdfNumber
          }
          designs {
            name
            description
            type
            extrusion {
              partId
              material
              surfaceFinish {
                id
                name
                emissivity
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              region
              autoCalcWeightPerFeet
              weightPerFeet {
                type
                value
                units
              }
              nonFlatback
              class
              stickLength {
                type
                value
                units
              }
              factory
              vendor
              type
              inventory
              vendorId
              image
              shapeType
              baseWidth {
                type
                value
                units
              }
              baseThick {
                type
                value
                units
              }
              finPitch {
                type
                value
                units
              }
              finThick {
                type
                value
                units
              }
              finHeight {
                type
                value
                units
              }
              hasOffset
              leftOffset {
                type
                value
                units
              }
              rightOffset {
                type
                value
                units
              }
              leftThick {
                type
                value
                units
              }
              rightThick {
                type
                value
                units
              }
              numFins
              numFinsLeft
              numFinsRight
              numOuterFins
              numInnerFins
              gap {
                type
                value
                units
              }
              outerGap {
                type
                value
                units
              }
              innerGap {
                type
                value
                units
              }
              areaGain {
                type
                value
                units
              }
              perimeter {
                type
                value
                units
              }
              finLayoutStyle
              specifyHeightBy
            }
            base {
              size {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              material
              weight {
                type
                value
                units
              }
            }
            fins {
              tech
              material
              finOption
              fin {
                type
                style
                pitch {
                  type
                  value
                  units
                }
                thickness {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
                region
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
            }
            heatPipe {
              heatpipes {
                position {
                  x {
                    type
                    value
                    units
                  }
                  y {
                    type
                    value
                    units
                  }
                }
                length {
                  type
                  value
                  units
                }
                diameter {
                  type
                  value
                  units
                }
              }
            }
            lcp {
              baseMaterial
              tubeMaterial
              fluid
              percentageWater
              inletTemperature {
                type
                value
                units
              }
              headerSize
              tubeOuterDiameter
              pipeConfiguration
              embeddedPosition
              numberPasses
              pipes {
                x {
                  type
                  value
                  units
                }
                y {
                  type
                  value
                  units
                }
              }
              flowRate {
                type
                value
                units
              }
            }
            status
            simulationStatus {
              progress
              message
            }
            solution {
              simulationTime
              maximumTemperature {
                type
                value
                units
              }
              fluidPressureDrop {
                type
                value
                units
              }
              fluidExitTemperature {
                type
                value
                units
              }
              perHeatSource {
                coveragePercentage
                hskAverageT {
                  type
                  value
                  units
                }
                hskMaximumT {
                  type
                  value
                  units
                }
                caseT {
                  type
                  value
                  units
                }
                junctionT {
                  type
                  value
                  units
                }
              }
              createdAt
            }
            costEstimates {
              quantity
              quantityByWt
              vendor
              totalCost
              baseMaterialCost
              vendorMaterialCost
              basePlusVendorMaterialCost
              materialPlusVendorCostPerLb
              insertsMaterialCost
              materialCost
              osvCost
              freightCost
              laborCost
              overheadCost
              leadTime
              marginPercentage
              sellPrice
              surcharge
              specialCondition
              materialMinimumImposed
              minQuantityByWt
              lineMinimumImposed
              lineMinimumCost
              nreCost
              toolingCost
              faiCost
              drawingCost
              isLowerExtendedPrice
              isDirty
            }
            liteCosting {
              liteCostEstimates {
                quantity
                piecePrice
                extraPrice
                finalPrice
                extendedPrice
                totalMaterialLength
              }
              costedCDF
              isDirty
            }
            viewState
            createdAt
          }
          activeDesignId
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            interfaceMaterial
          }
          airFlow {
            airFlowType
            orientation
            forcedConvectionType
            velocity {
              type
              value
              units
            }
            flowRate {
              type
              value
              units
            }
            fan {
              id
              label
              acdc
              size
              fanCurve
              region
            }
            setup
            impingePercentage
          }
          viewState
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
      owner
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      name
      type
      projects {
        items {
          id
          type
          name
          userId
          customerId
          description
          star
          tags
          units
          quoteSpecs {
            quoteHeader {
              quoteNo
              date
              quoteEntity
              customerPartNumber
              revision
              aavidPartNumber
              costedBy
              quantity
              customerContact
              customerAddress
              customerEmail
              customerPhone
              useUserContactAsSales
              salesContact
              salesEmail
              salesPhone
            }
            cutLength {
              type
              value
              units
            }
            stackable
            inventoryStock {
              type
              value
              units
            }
            inventoryCosts {
              cost
              minQtyWeight {
                type
                value
                units
              }
              override
              toolingOverride
              toolingOverrideCost
              factory
            }
            cnc {
              sides
              holes
              flycut
              flycutFlatnessCallout
              flycutRoughnessCallout
              customFlycutArea
              customFlycutWidth {
                type
                value
                units
              }
              customFlycutDepth {
                type
                value
                units
              }
              pocketVolume {
                type
                value
                units
              }
            }
            counterBores {
              cbores
              finCheck
              addTime
            }
            sandGaps
            finish {
              style
              blindHoles
              blindFaces
              cosmetic
              nonStandardFinish
            }
            finMachining {
              length {
                type
                value
                units
              }
              depth {
                type
                value
                units
              }
            }
            inserts {
              insertQty
              insertCost
            }
            marking
            margin
            surchargePercentage
            nreGMPercentage
            itarCompliance
            highProjectValue
            rawExtrusionBar
            visitedCostingTabs
            cuttingOption
            priceType
          }
          specifications {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            thermalConstraintType
            maxTemperatureRise {
              type
              value
              units
            }
            maxThermalResis {
              type
              value
              units
            }
            useMaxDP
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            optimizeSize
            totalLoad {
              type
              value
              units
            }
            thermalSpecificationsProvided
            useCustomerExtrusionMaxSize
            customerExtrusionProvided
            standardExtrusionRequested
            cdfNumberProvided
            cdfNumber
          }
          designs {
            name
            description
            type
            extrusion {
              partId
              material
              surfaceFinish {
                id
                name
                emissivity
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              region
              autoCalcWeightPerFeet
              weightPerFeet {
                type
                value
                units
              }
              nonFlatback
              class
              stickLength {
                type
                value
                units
              }
              factory
              vendor
              type
              inventory
              vendorId
              image
              shapeType
              baseWidth {
                type
                value
                units
              }
              baseThick {
                type
                value
                units
              }
              finPitch {
                type
                value
                units
              }
              finThick {
                type
                value
                units
              }
              finHeight {
                type
                value
                units
              }
              hasOffset
              leftOffset {
                type
                value
                units
              }
              rightOffset {
                type
                value
                units
              }
              leftThick {
                type
                value
                units
              }
              rightThick {
                type
                value
                units
              }
              numFins
              numFinsLeft
              numFinsRight
              numOuterFins
              numInnerFins
              gap {
                type
                value
                units
              }
              outerGap {
                type
                value
                units
              }
              innerGap {
                type
                value
                units
              }
              areaGain {
                type
                value
                units
              }
              perimeter {
                type
                value
                units
              }
              finLayoutStyle
              specifyHeightBy
            }
            base {
              size {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              material
              weight {
                type
                value
                units
              }
            }
            fins {
              tech
              material
              finOption
              fin {
                type
                style
                pitch {
                  type
                  value
                  units
                }
                thickness {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
                region
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
            }
            heatPipe {
              heatpipes {
                position {
                  x {
                    type
                    value
                    units
                  }
                  y {
                    type
                    value
                    units
                  }
                }
                length {
                  type
                  value
                  units
                }
                diameter {
                  type
                  value
                  units
                }
              }
            }
            lcp {
              baseMaterial
              tubeMaterial
              fluid
              percentageWater
              inletTemperature {
                type
                value
                units
              }
              headerSize
              tubeOuterDiameter
              pipeConfiguration
              embeddedPosition
              numberPasses
              pipes {
                x {
                  type
                  value
                  units
                }
                y {
                  type
                  value
                  units
                }
              }
              flowRate {
                type
                value
                units
              }
            }
            status
            simulationStatus {
              progress
              message
            }
            solution {
              simulationTime
              maximumTemperature {
                type
                value
                units
              }
              fluidPressureDrop {
                type
                value
                units
              }
              fluidExitTemperature {
                type
                value
                units
              }
              perHeatSource {
                coveragePercentage
                hskAverageT {
                  type
                  value
                  units
                }
                hskMaximumT {
                  type
                  value
                  units
                }
                caseT {
                  type
                  value
                  units
                }
                junctionT {
                  type
                  value
                  units
                }
              }
              createdAt
            }
            costEstimates {
              quantity
              quantityByWt
              vendor
              totalCost
              baseMaterialCost
              vendorMaterialCost
              basePlusVendorMaterialCost
              materialPlusVendorCostPerLb
              insertsMaterialCost
              materialCost
              osvCost
              freightCost
              laborCost
              overheadCost
              leadTime
              marginPercentage
              sellPrice
              surcharge
              specialCondition
              materialMinimumImposed
              minQuantityByWt
              lineMinimumImposed
              lineMinimumCost
              nreCost
              toolingCost
              faiCost
              drawingCost
              isLowerExtendedPrice
              isDirty
            }
            liteCosting {
              liteCostEstimates {
                quantity
                piecePrice
                extraPrice
                finalPrice
                extendedPrice
                totalMaterialLength
              }
              costedCDF
              isDirty
            }
            viewState
            createdAt
          }
          activeDesignId
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            interfaceMaterial
          }
          airFlow {
            airFlowType
            orientation
            forcedConvectionType
            velocity {
              type
              value
              units
            }
            flowRate {
              type
              value
              units
            }
            fan {
              id
              label
              acdc
              size
              fanCurve
              region
            }
            setup
            impingePercentage
          }
          viewState
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      name
      type
      projects {
        items {
          id
          type
          name
          userId
          customerId
          description
          star
          tags
          units
          quoteSpecs {
            quoteHeader {
              quoteNo
              date
              quoteEntity
              customerPartNumber
              revision
              aavidPartNumber
              costedBy
              quantity
              customerContact
              customerAddress
              customerEmail
              customerPhone
              useUserContactAsSales
              salesContact
              salesEmail
              salesPhone
            }
            cutLength {
              type
              value
              units
            }
            stackable
            inventoryStock {
              type
              value
              units
            }
            inventoryCosts {
              cost
              minQtyWeight {
                type
                value
                units
              }
              override
              toolingOverride
              toolingOverrideCost
              factory
            }
            cnc {
              sides
              holes
              flycut
              flycutFlatnessCallout
              flycutRoughnessCallout
              customFlycutArea
              customFlycutWidth {
                type
                value
                units
              }
              customFlycutDepth {
                type
                value
                units
              }
              pocketVolume {
                type
                value
                units
              }
            }
            counterBores {
              cbores
              finCheck
              addTime
            }
            sandGaps
            finish {
              style
              blindHoles
              blindFaces
              cosmetic
              nonStandardFinish
            }
            finMachining {
              length {
                type
                value
                units
              }
              depth {
                type
                value
                units
              }
            }
            inserts {
              insertQty
              insertCost
            }
            marking
            margin
            surchargePercentage
            nreGMPercentage
            itarCompliance
            highProjectValue
            rawExtrusionBar
            visitedCostingTabs
            cuttingOption
            priceType
          }
          specifications {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            thermalConstraintType
            maxTemperatureRise {
              type
              value
              units
            }
            maxThermalResis {
              type
              value
              units
            }
            useMaxDP
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            optimizeSize
            totalLoad {
              type
              value
              units
            }
            thermalSpecificationsProvided
            useCustomerExtrusionMaxSize
            customerExtrusionProvided
            standardExtrusionRequested
            cdfNumberProvided
            cdfNumber
          }
          designs {
            name
            description
            type
            extrusion {
              partId
              material
              surfaceFinish {
                id
                name
                emissivity
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              region
              autoCalcWeightPerFeet
              weightPerFeet {
                type
                value
                units
              }
              nonFlatback
              class
              stickLength {
                type
                value
                units
              }
              factory
              vendor
              type
              inventory
              vendorId
              image
              shapeType
              baseWidth {
                type
                value
                units
              }
              baseThick {
                type
                value
                units
              }
              finPitch {
                type
                value
                units
              }
              finThick {
                type
                value
                units
              }
              finHeight {
                type
                value
                units
              }
              hasOffset
              leftOffset {
                type
                value
                units
              }
              rightOffset {
                type
                value
                units
              }
              leftThick {
                type
                value
                units
              }
              rightThick {
                type
                value
                units
              }
              numFins
              numFinsLeft
              numFinsRight
              numOuterFins
              numInnerFins
              gap {
                type
                value
                units
              }
              outerGap {
                type
                value
                units
              }
              innerGap {
                type
                value
                units
              }
              areaGain {
                type
                value
                units
              }
              perimeter {
                type
                value
                units
              }
              finLayoutStyle
              specifyHeightBy
            }
            base {
              size {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              material
              weight {
                type
                value
                units
              }
            }
            fins {
              tech
              material
              finOption
              fin {
                type
                style
                pitch {
                  type
                  value
                  units
                }
                thickness {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
                region
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
            }
            heatPipe {
              heatpipes {
                position {
                  x {
                    type
                    value
                    units
                  }
                  y {
                    type
                    value
                    units
                  }
                }
                length {
                  type
                  value
                  units
                }
                diameter {
                  type
                  value
                  units
                }
              }
            }
            lcp {
              baseMaterial
              tubeMaterial
              fluid
              percentageWater
              inletTemperature {
                type
                value
                units
              }
              headerSize
              tubeOuterDiameter
              pipeConfiguration
              embeddedPosition
              numberPasses
              pipes {
                x {
                  type
                  value
                  units
                }
                y {
                  type
                  value
                  units
                }
              }
              flowRate {
                type
                value
                units
              }
            }
            status
            simulationStatus {
              progress
              message
            }
            solution {
              simulationTime
              maximumTemperature {
                type
                value
                units
              }
              fluidPressureDrop {
                type
                value
                units
              }
              fluidExitTemperature {
                type
                value
                units
              }
              perHeatSource {
                coveragePercentage
                hskAverageT {
                  type
                  value
                  units
                }
                hskMaximumT {
                  type
                  value
                  units
                }
                caseT {
                  type
                  value
                  units
                }
                junctionT {
                  type
                  value
                  units
                }
              }
              createdAt
            }
            costEstimates {
              quantity
              quantityByWt
              vendor
              totalCost
              baseMaterialCost
              vendorMaterialCost
              basePlusVendorMaterialCost
              materialPlusVendorCostPerLb
              insertsMaterialCost
              materialCost
              osvCost
              freightCost
              laborCost
              overheadCost
              leadTime
              marginPercentage
              sellPrice
              surcharge
              specialCondition
              materialMinimumImposed
              minQuantityByWt
              lineMinimumImposed
              lineMinimumCost
              nreCost
              toolingCost
              faiCost
              drawingCost
              isLowerExtendedPrice
              isDirty
            }
            liteCosting {
              liteCostEstimates {
                quantity
                piecePrice
                extraPrice
                finalPrice
                extendedPrice
                totalMaterialLength
              }
              costedCDF
              isDirty
            }
            viewState
            createdAt
          }
          activeDesignId
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            interfaceMaterial
          }
          airFlow {
            airFlowType
            orientation
            forcedConvectionType
            velocity {
              type
              value
              units
            }
            flowRate {
              type
              value
              units
            }
            fan {
              id
              label
              acdc
              size
              fanCurve
              region
            }
            setup
            impingePercentage
          }
          viewState
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      name
      type
      projects {
        items {
          id
          type
          name
          userId
          customerId
          description
          star
          tags
          units
          quoteSpecs {
            quoteHeader {
              quoteNo
              date
              quoteEntity
              customerPartNumber
              revision
              aavidPartNumber
              costedBy
              quantity
              customerContact
              customerAddress
              customerEmail
              customerPhone
              useUserContactAsSales
              salesContact
              salesEmail
              salesPhone
            }
            cutLength {
              type
              value
              units
            }
            stackable
            inventoryStock {
              type
              value
              units
            }
            inventoryCosts {
              cost
              minQtyWeight {
                type
                value
                units
              }
              override
              toolingOverride
              toolingOverrideCost
              factory
            }
            cnc {
              sides
              holes
              flycut
              flycutFlatnessCallout
              flycutRoughnessCallout
              customFlycutArea
              customFlycutWidth {
                type
                value
                units
              }
              customFlycutDepth {
                type
                value
                units
              }
              pocketVolume {
                type
                value
                units
              }
            }
            counterBores {
              cbores
              finCheck
              addTime
            }
            sandGaps
            finish {
              style
              blindHoles
              blindFaces
              cosmetic
              nonStandardFinish
            }
            finMachining {
              length {
                type
                value
                units
              }
              depth {
                type
                value
                units
              }
            }
            inserts {
              insertQty
              insertCost
            }
            marking
            margin
            surchargePercentage
            nreGMPercentage
            itarCompliance
            highProjectValue
            rawExtrusionBar
            visitedCostingTabs
            cuttingOption
            priceType
          }
          specifications {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            thermalConstraintType
            maxTemperatureRise {
              type
              value
              units
            }
            maxThermalResis {
              type
              value
              units
            }
            useMaxDP
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            optimizeSize
            totalLoad {
              type
              value
              units
            }
            thermalSpecificationsProvided
            useCustomerExtrusionMaxSize
            customerExtrusionProvided
            standardExtrusionRequested
            cdfNumberProvided
            cdfNumber
          }
          designs {
            name
            description
            type
            extrusion {
              partId
              material
              surfaceFinish {
                id
                name
                emissivity
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              region
              autoCalcWeightPerFeet
              weightPerFeet {
                type
                value
                units
              }
              nonFlatback
              class
              stickLength {
                type
                value
                units
              }
              factory
              vendor
              type
              inventory
              vendorId
              image
              shapeType
              baseWidth {
                type
                value
                units
              }
              baseThick {
                type
                value
                units
              }
              finPitch {
                type
                value
                units
              }
              finThick {
                type
                value
                units
              }
              finHeight {
                type
                value
                units
              }
              hasOffset
              leftOffset {
                type
                value
                units
              }
              rightOffset {
                type
                value
                units
              }
              leftThick {
                type
                value
                units
              }
              rightThick {
                type
                value
                units
              }
              numFins
              numFinsLeft
              numFinsRight
              numOuterFins
              numInnerFins
              gap {
                type
                value
                units
              }
              outerGap {
                type
                value
                units
              }
              innerGap {
                type
                value
                units
              }
              areaGain {
                type
                value
                units
              }
              perimeter {
                type
                value
                units
              }
              finLayoutStyle
              specifyHeightBy
            }
            base {
              size {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              material
              weight {
                type
                value
                units
              }
            }
            fins {
              tech
              material
              finOption
              fin {
                type
                style
                pitch {
                  type
                  value
                  units
                }
                thickness {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
                region
              }
              weight {
                type
                value
                units
              }
              dimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
            }
            heatPipe {
              heatpipes {
                position {
                  x {
                    type
                    value
                    units
                  }
                  y {
                    type
                    value
                    units
                  }
                }
                length {
                  type
                  value
                  units
                }
                diameter {
                  type
                  value
                  units
                }
              }
            }
            lcp {
              baseMaterial
              tubeMaterial
              fluid
              percentageWater
              inletTemperature {
                type
                value
                units
              }
              headerSize
              tubeOuterDiameter
              pipeConfiguration
              embeddedPosition
              numberPasses
              pipes {
                x {
                  type
                  value
                  units
                }
                y {
                  type
                  value
                  units
                }
              }
              flowRate {
                type
                value
                units
              }
            }
            status
            simulationStatus {
              progress
              message
            }
            solution {
              simulationTime
              maximumTemperature {
                type
                value
                units
              }
              fluidPressureDrop {
                type
                value
                units
              }
              fluidExitTemperature {
                type
                value
                units
              }
              perHeatSource {
                coveragePercentage
                hskAverageT {
                  type
                  value
                  units
                }
                hskMaximumT {
                  type
                  value
                  units
                }
                caseT {
                  type
                  value
                  units
                }
                junctionT {
                  type
                  value
                  units
                }
              }
              createdAt
            }
            costEstimates {
              quantity
              quantityByWt
              vendor
              totalCost
              baseMaterialCost
              vendorMaterialCost
              basePlusVendorMaterialCost
              materialPlusVendorCostPerLb
              insertsMaterialCost
              materialCost
              osvCost
              freightCost
              laborCost
              overheadCost
              leadTime
              marginPercentage
              sellPrice
              surcharge
              specialCondition
              materialMinimumImposed
              minQuantityByWt
              lineMinimumImposed
              lineMinimumCost
              nreCost
              toolingCost
              faiCost
              drawingCost
              isLowerExtendedPrice
              isDirty
            }
            liteCosting {
              liteCostEstimates {
                quantity
                piecePrice
                extraPrice
                finalPrice
                extendedPrice
                totalMaterialLength
              }
              costedCDF
              isDirty
            }
            viewState
            createdAt
          }
          activeDesignId
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            interfaceMaterial
          }
          airFlow {
            airFlowType
            orientation
            forcedConvectionType
            velocity {
              type
              value
              units
            }
            flowRate {
              type
              value
              units
            }
            fan {
              id
              label
              acdc
              size
              fanCurve
              region
            }
            setup
            impingePercentage
          }
          viewState
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($owner: String) {
    onCreateProject(owner: $owner) {
      id
      type
      name
      userId
      customerId
      description
      star
      tags
      units
      quoteSpecs {
        quoteHeader {
          quoteNo
          date
          quoteEntity
          customerPartNumber
          revision
          aavidPartNumber
          costedBy
          quantity
          customerContact
          customerAddress
          customerEmail
          customerPhone
          useUserContactAsSales
          salesContact
          salesEmail
          salesPhone
        }
        cutLength {
          type
          value
          units
        }
        stackable
        inventoryStock {
          type
          value
          units
        }
        inventoryCosts {
          cost
          minQtyWeight {
            type
            value
            units
          }
          override
          toolingOverride
          toolingOverrideCost
          factory
        }
        cnc {
          sides
          holes
          flycut
          flycutFlatnessCallout
          flycutRoughnessCallout
          customFlycutArea
          customFlycutWidth {
            type
            value
            units
          }
          customFlycutDepth {
            type
            value
            units
          }
          pocketVolume {
            type
            value
            units
          }
        }
        counterBores {
          cbores
          finCheck
          addTime
        }
        sandGaps
        finish {
          style
          blindHoles
          blindFaces
          cosmetic
          nonStandardFinish
        }
        finMachining {
          length {
            type
            value
            units
          }
          depth {
            type
            value
            units
          }
        }
        inserts {
          insertQty
          insertCost
        }
        marking
        margin
        surchargePercentage
        nreGMPercentage
        itarCompliance
        highProjectValue
        rawExtrusionBar
        visitedCostingTabs
        cuttingOption
        priceType
      }
      specifications {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        thermalConstraintType
        maxTemperatureRise {
          type
          value
          units
        }
        maxThermalResis {
          type
          value
          units
        }
        useMaxDP
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        optimizeSize
        totalLoad {
          type
          value
          units
        }
        thermalSpecificationsProvided
        useCustomerExtrusionMaxSize
        customerExtrusionProvided
        standardExtrusionRequested
        cdfNumberProvided
        cdfNumber
      }
      designs {
        name
        description
        type
        extrusion {
          partId
          material
          surfaceFinish {
            id
            name
            emissivity
          }
          weight {
            type
            value
            units
          }
          dimensions {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          region
          autoCalcWeightPerFeet
          weightPerFeet {
            type
            value
            units
          }
          nonFlatback
          class
          stickLength {
            type
            value
            units
          }
          factory
          vendor
          type
          inventory
          vendorId
          image
          shapeType
          baseWidth {
            type
            value
            units
          }
          baseThick {
            type
            value
            units
          }
          finPitch {
            type
            value
            units
          }
          finThick {
            type
            value
            units
          }
          finHeight {
            type
            value
            units
          }
          hasOffset
          leftOffset {
            type
            value
            units
          }
          rightOffset {
            type
            value
            units
          }
          leftThick {
            type
            value
            units
          }
          rightThick {
            type
            value
            units
          }
          numFins
          numFinsLeft
          numFinsRight
          numOuterFins
          numInnerFins
          gap {
            type
            value
            units
          }
          outerGap {
            type
            value
            units
          }
          innerGap {
            type
            value
            units
          }
          areaGain {
            type
            value
            units
          }
          perimeter {
            type
            value
            units
          }
          finLayoutStyle
          specifyHeightBy
        }
        base {
          size {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          material
          weight {
            type
            value
            units
          }
        }
        fins {
          tech
          material
          finOption
          fin {
            type
            style
            pitch {
              type
              value
              units
            }
            thickness {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
            region
          }
          weight {
            type
            value
            units
          }
          dimensions {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
        }
        heatPipe {
          heatpipes {
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            length {
              type
              value
              units
            }
            diameter {
              type
              value
              units
            }
          }
        }
        lcp {
          baseMaterial
          tubeMaterial
          fluid
          percentageWater
          inletTemperature {
            type
            value
            units
          }
          headerSize
          tubeOuterDiameter
          pipeConfiguration
          embeddedPosition
          numberPasses
          pipes {
            x {
              type
              value
              units
            }
            y {
              type
              value
              units
            }
          }
          flowRate {
            type
            value
            units
          }
        }
        status
        simulationStatus {
          progress
          message
        }
        solution {
          simulationTime
          maximumTemperature {
            type
            value
            units
          }
          fluidPressureDrop {
            type
            value
            units
          }
          fluidExitTemperature {
            type
            value
            units
          }
          perHeatSource {
            coveragePercentage
            hskAverageT {
              type
              value
              units
            }
            hskMaximumT {
              type
              value
              units
            }
            caseT {
              type
              value
              units
            }
            junctionT {
              type
              value
              units
            }
          }
          createdAt
        }
        costEstimates {
          quantity
          quantityByWt
          vendor
          totalCost
          baseMaterialCost
          vendorMaterialCost
          basePlusVendorMaterialCost
          materialPlusVendorCostPerLb
          insertsMaterialCost
          materialCost
          osvCost
          freightCost
          laborCost
          overheadCost
          leadTime
          marginPercentage
          sellPrice
          surcharge
          specialCondition
          materialMinimumImposed
          minQuantityByWt
          lineMinimumImposed
          lineMinimumCost
          nreCost
          toolingCost
          faiCost
          drawingCost
          isLowerExtendedPrice
          isDirty
        }
        liteCosting {
          liteCostEstimates {
            quantity
            piecePrice
            extraPrice
            finalPrice
            extendedPrice
            totalMaterialLength
          }
          costedCDF
          isDirty
        }
        viewState
        createdAt
      }
      activeDesignId
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        interfaceMaterial
      }
      airFlow {
        airFlowType
        orientation
        forcedConvectionType
        velocity {
          type
          value
          units
        }
        flowRate {
          type
          value
          units
        }
        fan {
          id
          label
          acdc
          size
          fanCurve
          region
        }
        setup
        impingePercentage
      }
      viewState
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($owner: String) {
    onUpdateProject(owner: $owner) {
      id
      type
      name
      userId
      customerId
      description
      star
      tags
      units
      quoteSpecs {
        quoteHeader {
          quoteNo
          date
          quoteEntity
          customerPartNumber
          revision
          aavidPartNumber
          costedBy
          quantity
          customerContact
          customerAddress
          customerEmail
          customerPhone
          useUserContactAsSales
          salesContact
          salesEmail
          salesPhone
        }
        cutLength {
          type
          value
          units
        }
        stackable
        inventoryStock {
          type
          value
          units
        }
        inventoryCosts {
          cost
          minQtyWeight {
            type
            value
            units
          }
          override
          toolingOverride
          toolingOverrideCost
          factory
        }
        cnc {
          sides
          holes
          flycut
          flycutFlatnessCallout
          flycutRoughnessCallout
          customFlycutArea
          customFlycutWidth {
            type
            value
            units
          }
          customFlycutDepth {
            type
            value
            units
          }
          pocketVolume {
            type
            value
            units
          }
        }
        counterBores {
          cbores
          finCheck
          addTime
        }
        sandGaps
        finish {
          style
          blindHoles
          blindFaces
          cosmetic
          nonStandardFinish
        }
        finMachining {
          length {
            type
            value
            units
          }
          depth {
            type
            value
            units
          }
        }
        inserts {
          insertQty
          insertCost
        }
        marking
        margin
        surchargePercentage
        nreGMPercentage
        itarCompliance
        highProjectValue
        rawExtrusionBar
        visitedCostingTabs
        cuttingOption
        priceType
      }
      specifications {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        thermalConstraintType
        maxTemperatureRise {
          type
          value
          units
        }
        maxThermalResis {
          type
          value
          units
        }
        useMaxDP
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        optimizeSize
        totalLoad {
          type
          value
          units
        }
        thermalSpecificationsProvided
        useCustomerExtrusionMaxSize
        customerExtrusionProvided
        standardExtrusionRequested
        cdfNumberProvided
        cdfNumber
      }
      designs {
        name
        description
        type
        extrusion {
          partId
          material
          surfaceFinish {
            id
            name
            emissivity
          }
          weight {
            type
            value
            units
          }
          dimensions {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          region
          autoCalcWeightPerFeet
          weightPerFeet {
            type
            value
            units
          }
          nonFlatback
          class
          stickLength {
            type
            value
            units
          }
          factory
          vendor
          type
          inventory
          vendorId
          image
          shapeType
          baseWidth {
            type
            value
            units
          }
          baseThick {
            type
            value
            units
          }
          finPitch {
            type
            value
            units
          }
          finThick {
            type
            value
            units
          }
          finHeight {
            type
            value
            units
          }
          hasOffset
          leftOffset {
            type
            value
            units
          }
          rightOffset {
            type
            value
            units
          }
          leftThick {
            type
            value
            units
          }
          rightThick {
            type
            value
            units
          }
          numFins
          numFinsLeft
          numFinsRight
          numOuterFins
          numInnerFins
          gap {
            type
            value
            units
          }
          outerGap {
            type
            value
            units
          }
          innerGap {
            type
            value
            units
          }
          areaGain {
            type
            value
            units
          }
          perimeter {
            type
            value
            units
          }
          finLayoutStyle
          specifyHeightBy
        }
        base {
          size {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          material
          weight {
            type
            value
            units
          }
        }
        fins {
          tech
          material
          finOption
          fin {
            type
            style
            pitch {
              type
              value
              units
            }
            thickness {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
            region
          }
          weight {
            type
            value
            units
          }
          dimensions {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
        }
        heatPipe {
          heatpipes {
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            length {
              type
              value
              units
            }
            diameter {
              type
              value
              units
            }
          }
        }
        lcp {
          baseMaterial
          tubeMaterial
          fluid
          percentageWater
          inletTemperature {
            type
            value
            units
          }
          headerSize
          tubeOuterDiameter
          pipeConfiguration
          embeddedPosition
          numberPasses
          pipes {
            x {
              type
              value
              units
            }
            y {
              type
              value
              units
            }
          }
          flowRate {
            type
            value
            units
          }
        }
        status
        simulationStatus {
          progress
          message
        }
        solution {
          simulationTime
          maximumTemperature {
            type
            value
            units
          }
          fluidPressureDrop {
            type
            value
            units
          }
          fluidExitTemperature {
            type
            value
            units
          }
          perHeatSource {
            coveragePercentage
            hskAverageT {
              type
              value
              units
            }
            hskMaximumT {
              type
              value
              units
            }
            caseT {
              type
              value
              units
            }
            junctionT {
              type
              value
              units
            }
          }
          createdAt
        }
        costEstimates {
          quantity
          quantityByWt
          vendor
          totalCost
          baseMaterialCost
          vendorMaterialCost
          basePlusVendorMaterialCost
          materialPlusVendorCostPerLb
          insertsMaterialCost
          materialCost
          osvCost
          freightCost
          laborCost
          overheadCost
          leadTime
          marginPercentage
          sellPrice
          surcharge
          specialCondition
          materialMinimumImposed
          minQuantityByWt
          lineMinimumImposed
          lineMinimumCost
          nreCost
          toolingCost
          faiCost
          drawingCost
          isLowerExtendedPrice
          isDirty
        }
        liteCosting {
          liteCostEstimates {
            quantity
            piecePrice
            extraPrice
            finalPrice
            extendedPrice
            totalMaterialLength
          }
          costedCDF
          isDirty
        }
        viewState
        createdAt
      }
      activeDesignId
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        interfaceMaterial
      }
      airFlow {
        airFlowType
        orientation
        forcedConvectionType
        velocity {
          type
          value
          units
        }
        flowRate {
          type
          value
          units
        }
        fan {
          id
          label
          acdc
          size
          fanCurve
          region
        }
        setup
        impingePercentage
      }
      viewState
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($owner: String) {
    onDeleteProject(owner: $owner) {
      id
      type
      name
      userId
      customerId
      description
      star
      tags
      units
      quoteSpecs {
        quoteHeader {
          quoteNo
          date
          quoteEntity
          customerPartNumber
          revision
          aavidPartNumber
          costedBy
          quantity
          customerContact
          customerAddress
          customerEmail
          customerPhone
          useUserContactAsSales
          salesContact
          salesEmail
          salesPhone
        }
        cutLength {
          type
          value
          units
        }
        stackable
        inventoryStock {
          type
          value
          units
        }
        inventoryCosts {
          cost
          minQtyWeight {
            type
            value
            units
          }
          override
          toolingOverride
          toolingOverrideCost
          factory
        }
        cnc {
          sides
          holes
          flycut
          flycutFlatnessCallout
          flycutRoughnessCallout
          customFlycutArea
          customFlycutWidth {
            type
            value
            units
          }
          customFlycutDepth {
            type
            value
            units
          }
          pocketVolume {
            type
            value
            units
          }
        }
        counterBores {
          cbores
          finCheck
          addTime
        }
        sandGaps
        finish {
          style
          blindHoles
          blindFaces
          cosmetic
          nonStandardFinish
        }
        finMachining {
          length {
            type
            value
            units
          }
          depth {
            type
            value
            units
          }
        }
        inserts {
          insertQty
          insertCost
        }
        marking
        margin
        surchargePercentage
        nreGMPercentage
        itarCompliance
        highProjectValue
        rawExtrusionBar
        visitedCostingTabs
        cuttingOption
        priceType
      }
      specifications {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        thermalConstraintType
        maxTemperatureRise {
          type
          value
          units
        }
        maxThermalResis {
          type
          value
          units
        }
        useMaxDP
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        optimizeSize
        totalLoad {
          type
          value
          units
        }
        thermalSpecificationsProvided
        useCustomerExtrusionMaxSize
        customerExtrusionProvided
        standardExtrusionRequested
        cdfNumberProvided
        cdfNumber
      }
      designs {
        name
        description
        type
        extrusion {
          partId
          material
          surfaceFinish {
            id
            name
            emissivity
          }
          weight {
            type
            value
            units
          }
          dimensions {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          region
          autoCalcWeightPerFeet
          weightPerFeet {
            type
            value
            units
          }
          nonFlatback
          class
          stickLength {
            type
            value
            units
          }
          factory
          vendor
          type
          inventory
          vendorId
          image
          shapeType
          baseWidth {
            type
            value
            units
          }
          baseThick {
            type
            value
            units
          }
          finPitch {
            type
            value
            units
          }
          finThick {
            type
            value
            units
          }
          finHeight {
            type
            value
            units
          }
          hasOffset
          leftOffset {
            type
            value
            units
          }
          rightOffset {
            type
            value
            units
          }
          leftThick {
            type
            value
            units
          }
          rightThick {
            type
            value
            units
          }
          numFins
          numFinsLeft
          numFinsRight
          numOuterFins
          numInnerFins
          gap {
            type
            value
            units
          }
          outerGap {
            type
            value
            units
          }
          innerGap {
            type
            value
            units
          }
          areaGain {
            type
            value
            units
          }
          perimeter {
            type
            value
            units
          }
          finLayoutStyle
          specifyHeightBy
        }
        base {
          size {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          material
          weight {
            type
            value
            units
          }
        }
        fins {
          tech
          material
          finOption
          fin {
            type
            style
            pitch {
              type
              value
              units
            }
            thickness {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
            region
          }
          weight {
            type
            value
            units
          }
          dimensions {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
        }
        heatPipe {
          heatpipes {
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            length {
              type
              value
              units
            }
            diameter {
              type
              value
              units
            }
          }
        }
        lcp {
          baseMaterial
          tubeMaterial
          fluid
          percentageWater
          inletTemperature {
            type
            value
            units
          }
          headerSize
          tubeOuterDiameter
          pipeConfiguration
          embeddedPosition
          numberPasses
          pipes {
            x {
              type
              value
              units
            }
            y {
              type
              value
              units
            }
          }
          flowRate {
            type
            value
            units
          }
        }
        status
        simulationStatus {
          progress
          message
        }
        solution {
          simulationTime
          maximumTemperature {
            type
            value
            units
          }
          fluidPressureDrop {
            type
            value
            units
          }
          fluidExitTemperature {
            type
            value
            units
          }
          perHeatSource {
            coveragePercentage
            hskAverageT {
              type
              value
              units
            }
            hskMaximumT {
              type
              value
              units
            }
            caseT {
              type
              value
              units
            }
            junctionT {
              type
              value
              units
            }
          }
          createdAt
        }
        costEstimates {
          quantity
          quantityByWt
          vendor
          totalCost
          baseMaterialCost
          vendorMaterialCost
          basePlusVendorMaterialCost
          materialPlusVendorCostPerLb
          insertsMaterialCost
          materialCost
          osvCost
          freightCost
          laborCost
          overheadCost
          leadTime
          marginPercentage
          sellPrice
          surcharge
          specialCondition
          materialMinimumImposed
          minQuantityByWt
          lineMinimumImposed
          lineMinimumCost
          nreCost
          toolingCost
          faiCost
          drawingCost
          isLowerExtendedPrice
          isDirty
        }
        liteCosting {
          liteCostEstimates {
            quantity
            piecePrice
            extraPrice
            finalPrice
            extendedPrice
            totalMaterialLength
          }
          costedCDF
          isDirty
        }
        viewState
        createdAt
      }
      activeDesignId
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        interfaceMaterial
      }
      airFlow {
        airFlowType
        orientation
        forcedConvectionType
        velocity {
          type
          value
          units
        }
        flowRate {
          type
          value
          units
        }
        fan {
          id
          label
          acdc
          size
          fanCurve
          region
        }
        setup
        impingePercentage
      }
      viewState
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateLibrary = /* GraphQL */ `
  subscription OnCreateLibrary {
    onCreateLibrary {
      type
      partId
      label
      description
      extrusion {
        weightPerFeet
        nonFlatback
        class
        stickLength
        factory
        vendor
        type
        inventory
        vendorId
        dimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        image
        shapeType
        baseWidth
        baseThick
        finPitch
        finThick
        finHeight
        leftOffset
        rightOffset
        leftThick
        rightThick
        numFins
        numFinsLeft
        numFinsRight
        numOuterFins
        numInnerFins
        gap
        outerGap
        innerGap
        areaGain
        perimeter
      }
      fan {
        acdc
        size
        fanCurve
      }
      fin {
        finType
        style
        finPitch
        finThickness
        standardHeights
        cost
        costPerHeight
      }
      active
      region
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLibrary = /* GraphQL */ `
  subscription OnUpdateLibrary {
    onUpdateLibrary {
      type
      partId
      label
      description
      extrusion {
        weightPerFeet
        nonFlatback
        class
        stickLength
        factory
        vendor
        type
        inventory
        vendorId
        dimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        image
        shapeType
        baseWidth
        baseThick
        finPitch
        finThick
        finHeight
        leftOffset
        rightOffset
        leftThick
        rightThick
        numFins
        numFinsLeft
        numFinsRight
        numOuterFins
        numInnerFins
        gap
        outerGap
        innerGap
        areaGain
        perimeter
      }
      fan {
        acdc
        size
        fanCurve
      }
      fin {
        finType
        style
        finPitch
        finThickness
        standardHeights
        cost
        costPerHeight
      }
      active
      region
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLibrary = /* GraphQL */ `
  subscription OnDeleteLibrary {
    onDeleteLibrary {
      type
      partId
      label
      description
      extrusion {
        weightPerFeet
        nonFlatback
        class
        stickLength
        factory
        vendor
        type
        inventory
        vendorId
        dimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        image
        shapeType
        baseWidth
        baseThick
        finPitch
        finThick
        finHeight
        leftOffset
        rightOffset
        leftThick
        rightThick
        numFins
        numFinsLeft
        numFinsRight
        numOuterFins
        numInnerFins
        gap
        outerGap
        innerGap
        areaGain
        perimeter
      }
      fan {
        acdc
        size
        fanCurve
      }
      fin {
        finType
        style
        finPitch
        finThickness
        standardHeights
        cost
        costPerHeight
      }
      active
      region
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLibraryFactory = /* GraphQL */ `
  subscription OnCreateLibraryFactory {
    onCreateLibraryFactory {
      label
      manufacturingProcesses {
        code
        label
        laborCost
        deptVarOH
        machRate
        defaultSetup
      }
      rawMaterialCost
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLibraryFactory = /* GraphQL */ `
  subscription OnUpdateLibraryFactory {
    onUpdateLibraryFactory {
      label
      manufacturingProcesses {
        code
        label
        laborCost
        deptVarOH
        machRate
        defaultSetup
      }
      rawMaterialCost
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLibraryFactory = /* GraphQL */ `
  subscription OnDeleteLibraryFactory {
    onDeleteLibraryFactory {
      label
      manufacturingProcesses {
        code
        label
        laborCost
        deptVarOH
        machRate
        defaultSetup
      }
      rawMaterialCost
      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity {
    onCreateActivity {
      label
      createdAt
      updatedAt
      userEmail
      userId
      projectName
      projectId
      customer
      customerId
    }
  }
`;
export const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity {
    onUpdateActivity {
      label
      createdAt
      updatedAt
      userEmail
      userId
      projectName
      projectId
      customer
      customerId
    }
  }
`;
export const onDeleteActivity = /* GraphQL */ `
  subscription OnDeleteActivity {
    onDeleteActivity {
      label
      createdAt
      updatedAt
      userEmail
      userId
      projectName
      projectId
      customer
      customerId
    }
  }
`;
