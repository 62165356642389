import { API } from '@aws-amplify/api';
import { message, notification } from 'antd';
import { type } from '../../../state/units';
import { estimatorGetRequest, listLibrarys, getLibrary } from '../../../graphql/queries';
import { convertValueUnitsTo, convertValueUnits } from '../../../utils/unit-conversion';
import { ENDPOINTS } from '../../../utils/config-api';
import './project-costing.less'
import { lineMinimum, defaultGrossMargin } from '../../../utils/line-minimum';
// Todo: endpoints should be secured via Cognito groups
// - proxy through Amplify using @function lambda?
// - wire up with Recoil to use suspense/error boundaries

// Development API endpoints
// const { EXTRUSIONAPI, ESTIMATEAPI } = ENDPOINTS.DEV;
// Test API endpoints
const { EXTRUSIONAPI, ESTIMATEAPI } = ENDPOINTS.PROD;

const getEstimatorData = async (url) => {
  try {
    const response = await API.graphql({
      query: estimatorGetRequest,
      variables: { url },
    });
    return JSON.parse(response.data.estimatorGetRequest);
  } catch (error) {
    // console.error('getEstimatorData', error);
    message.error(error.errors[0].message);
    return [];
  }
};

export const getEstimates = async (project) => {
  const quoteSpecs = project.quoteSpecs;
  const url = new URL(EXTRUSIONAPI);
  url.pathname = '/estimate/extrusionByProject';
  url.searchParams.append('cost_center', quoteSpecs.quoteHeader.quoteEntity.toLowerCase());
  url.searchParams.append('project_id', project.id);
  const response = await getEstimatorData(url);

  var grossMargins = {}  // Get previous margin % for each quantity
  var freights = {} // Get previous freight for each quantity
  var osvs = {} // Get previous osv cost for each quantity

  project.designs[project.activeDesignId].costEstimates.forEach(({quantity, marginPercentage, freightCost, osvCost}) => {
    grossMargins[quantity] = marginPercentage;
    freights[quantity] = freightCost;
    osvs[quantity] = osvCost;
  });

  let previousExtendedPrice = 0.0;
  return response.length === 0
    ? []
    : response.sort((a, b)=>a.quantity -b.quantity).map((quote) => {
        const margin = grossMargins.hasOwnProperty(quote.quantity) ? grossMargins[quote.quantity] : defaultGrossMargin(project);
        const freightCost = freights.hasOwnProperty(quote.quantity) ? freights[quote.quantity] : 0.0;
        const osvCost = osvs.hasOwnProperty(quote.quantity) ? osvs[quote.quantity] : 0.0;
        const nreGMPercentage = project.quoteSpecs.nreGMPercentage;
        const totalCost = quote.materialCost + quote.laborCost + quote.overheadCost + osvCost;
        let sellPrice = parseFloat((totalCost / (1 - margin / 100)).toFixed(3));
        const lineMinimumCost = lineMinimum(project) / quote.quantity;
        const lineMinimumImposed = lineMinimumCost > sellPrice ? 1 : 0;
        let isLowerExtendedPrice = false;
        const nreCost = parseFloat((quote.faiCost + (quote.toolingCost + quote.drawingCost) / (1 - nreGMPercentage / 100.0)).toFixed(3));
        if (lineMinimumImposed === 1) {
          sellPrice = parseFloat(lineMinimumCost.toFixed(3))
        }
        //check extended price
        if (sellPrice * quote.quantity < previousExtendedPrice) {
          isLowerExtendedPrice = true;
        } else {
          previousExtendedPrice = sellPrice * quote.quantity;
        }
        return { 
        ...quote, 
        totalCost: totalCost,
        osvCost: osvCost,
        sellPrice: sellPrice,
        marginPercentage: margin,
        surcharge: parseFloat((project.quoteSpecs.surchargePercentage / 100.0 * sellPrice).toFixed(3)),
        specialCondition: '-',
        freightCost: freightCost,
        lineMinimumImposed: lineMinimumImposed,
        lineMinimumCost: lineMinimumCost,
        isLowerExtendedPrice: isLowerExtendedPrice, 
        nreCost: nreCost, 
        isDirty: false,
    }});
};

export const getLiteCostEstimates = async(cdfNumber, project) => {
  const url = new URL(EXTRUSIONAPI);
  url.pathname = '/estimate/cdf';
  url.searchParams.append('cost_center', project.quoteSpecs.quoteHeader.quoteEntity.toLowerCase());
  url.searchParams.append('quantities', project.quoteSpecs.quoteHeader.quantity);
  url.searchParams.append('cdf_number', cdfNumber);
  url.searchParams.append('price_list', project.quoteSpecs.priceType);
  const response = await getEstimatorData(url); 

  var extraPrices = {}  // Get previous extra price for each quantity
  project.designs[0].liteCosting.liteCostEstimates.forEach(({quantity, extraPrice}) => {
    extraPrices[quantity] = extraPrice;
  });
  

  return response.length === 0
    ? []
    : response.sort((a, b)=>a.quantity -b.quantity).map((quote) => {
      const piecePrice = quote.piece_price;
      const extraPrice = extraPrices.hasOwnProperty(quote.quantity) ? extraPrices[quote.quantity] : 0.0;
      const finalPrice = piecePrice + extraPrice;
      const extendedPrice = finalPrice * quote.quantity
      return {
        quantity: quote.quantity,
        piecePrice: piecePrice,
        extraPrice: extraPrice,
        finalPrice: finalPrice,
        extendedPrice: extendedPrice,
        totalMaterialLength: quote.tot_feet,
      }
    }
  )
};

export const validateExtrusion = async (project) => {
  const extrusionSpecs = project.designs[project.activeDesignId].extrusion;
  const url = new URL(EXTRUSIONAPI);
  url.pathname = '/validate/extrusion';
  url.searchParams.append('cost_center', project.quoteSpecs.quoteHeader.quoteEntity.toLowerCase());
  url.searchParams.append('base_width', convertValueUnitsTo({ value: extrusionSpecs.baseWidth, to: 'm' }));
  url.searchParams.append('base_height', convertValueUnitsTo({ value: extrusionSpecs.baseThick, to: 'm' }));
  url.searchParams.append('fin_thickness', convertValueUnitsTo({ value: extrusionSpecs.finThick, to: 'm' }));
  url.searchParams.append('fin_height', convertValueUnitsTo({ value: extrusionSpecs.finHeight, to: 'm' }));
  url.searchParams.append('num_fins', extrusionSpecs.numFins);
  url.searchParams.append('base_width_units', extrusionSpecs.baseWidth.units);
  url.searchParams.append('base_height_units', extrusionSpecs.baseThick.units);
  url.searchParams.append('fin_thickness_units', extrusionSpecs.finThick.units);
  url.searchParams.append('fin_height_units', extrusionSpecs.finHeight.units);
  const response = await getEstimatorData(url);
  // console.log(response);
  if (response.hasOwnProperty('ok')) {
    if (response.ok) {
      return true;
    } else {
      const messages = response.messages;
      // message.info(<ol>{messages.map(m => <li>{m}</li>)}</ol>);
      notification.error({ 
        className: 'error-notification-box',
        description: (
          <>
            <p style={{ marginBottom: 0, fontWeight: 600 }}>
              Fix the following Heat sink design manufacturing constraint violation(s).
            </p>
            <ol>
              {messages.map((m, idx) => (
                <li key={`constraint-violation-${idx}`}>{m}</li>
              ))}
            </ol>
          </>
        ),  
        placement: 'topLeft',
        top: '50%', 
        duration: null,
      });
      return false;
    }
  } else {
    return false;
  }
};

export const getPerformanceEstimates = async (project) => {
  if (project.heatSources.length === 0) {
    message.error('No estimate available. Define at least one heat source.');
    return [];
  }
  const extrusionSpecs = project.designs[project.activeDesignId].extrusion;
  let sl = 0.0;
  let sw = 0.0;
  let totalLoad = 0.0;
  project.heatSources.forEach((heatSource) => {
    sl = sl + convertValueUnitsTo({ value: heatSource.size.height, to: 'mm' });
    sw = sw + convertValueUnitsTo({ value: heatSource.size.width, to: 'mm' });
    totalLoad = totalLoad + convertValueUnitsTo({ value: heatSource.power, to: 'W' });
  });
  const ns = project.heatSources.length;
  const url = new URL(ESTIMATEAPI);
  url.pathname = '/estimates';
  url.searchParams.append('bw', convertValueUnitsTo({ value: extrusionSpecs.baseWidth, to: 'mm' }));
  url.searchParams.append('bh', convertValueUnitsTo({ value: extrusionSpecs.baseThick, to: 'mm' }));
  url.searchParams.append('bl', convertValueUnitsTo({ value: extrusionSpecs.dimensions.length, to: 'mm' }));
  url.searchParams.append('fw', convertValueUnitsTo({ value: extrusionSpecs.finThick, to: 'mm' }));
  url.searchParams.append('fp', convertValueUnitsTo({ value: extrusionSpecs.finPitch, to: 'mm' }));
  url.searchParams.append('sw', sw);
  url.searchParams.append('sl', sl);
  url.searchParams.append('load', totalLoad);
  url.searchParams.append('ns', ns);
  url.searchParams.append('fh', convertValueUnitsTo({ value: extrusionSpecs.finHeight, to: 'mm' }));
  url.searchParams.append('nf', extrusionSpecs.numFins);
  const tAmb = convertValueUnitsTo({ value: project.specifications.ambientTemperature, to: 'C' });
  url.searchParams.append('altitude', convertValueUnitsTo({ value: project.specifications.altitude, to: 'm' }));
  url.searchParams.append('Tamb', tAmb);
  if (extrusionSpecs.hasOffset) {
    url.searchParams.append('lf', convertValueUnitsTo({ value: extrusionSpecs.leftOffset, to: 'mm' }));
    url.searchParams.append('rf', convertValueUnitsTo({ value: extrusionSpecs.rightOffset, to: 'mm' }));
  }
  if (project.airFlow.airFlowType === type.airflow.forceConvection) {
    if (project.airFlow.forcedConvectionType === type.forcedFlow.velocity) {
      url.searchParams.append('velocity', convertValueUnitsTo({ value: project.airFlow.velocity, to: 'm/s' }));
    } else {
      url.searchParams.append('flowrate', convertValueUnitsTo({ value: project.airFlow.flowRate, to: 'cfm' }));
    }
  } else {
    url.searchParams.append('hskOrient', project.airFlow.orientation === type.naturalConvection.horizontal ? 'H' : 'V');
  }
  const response = await getEstimatorData(url);
  return response.length === 0
    ? []
    : response.map((estimate) => ({
        dP: parseFloat(estimate.dP.toFixed(3)),
        dT: parseFloat((estimate.T - tAmb).toFixed(3)),
        dR: parseFloat(((estimate.T - tAmb) / totalLoad).toFixed(3)),
        weight: parseFloat(estimate.weight.toFixed(3)),
      }));
};

const getExtrusionMatchData = async (url) => {
  try {
    const response = await API.graphql({
      query: estimatorGetRequest, //Here estimator get request used as it will work for any url otherwise need to define.
      variables: { url },
    });
    return JSON.parse(response.data.estimatorGetRequest);
  } catch (error) {
    console.error('getEstimatorData', error);
    return [];
  }
};

export const getStandardPartsIds = async () => {
  const url = new URL(EXTRUSIONAPI);
  url.pathname = '/match/extrusion';
  const response = await getExtrusionMatchData(url);
  return Object.entries(response).map((cm) => {return {label: cm[0]+', '+cm[1].class, value:cm[0], oracle: cm[1].oracle}})
}

export const getClosestExtrusionMatch = async (project) => {
  const extrusionSpecs = project.designs[0].extrusion;
  const maxSize =
    project.specifications.customerExtrusionProvided && project.specifications.useCustomerExtrusionMaxSize
      ? project.designs[0].extrusion.dimensions
      : project.specifications.maxSize;
  const getDeviation = project.specifications.customerExtrusionProvided;
  const url = new URL(EXTRUSIONAPI);
  url.pathname = '/match/extrusion';
  if (getDeviation) {
    if (extrusionSpecs.nonFlatback) {
      // const perimeter = 2 * convertValueUnitsTo({ value: extrusionSpecs.dimensions.width, to: 'in' }) + 2 * convertValueUnitsTo({ value: extrusionSpecs.dimensions.height, to: 'in' });
      url.searchParams.append('perimeter', convertValueUnitsTo({ value: extrusionSpecs.perimeter, to: 'in' }));
      url.searchParams.append('weight_per_length', convertValueUnitsTo({ value: extrusionSpecs.weightPerFeet, to: 'lb/ft' }));
    }
    url.searchParams.append('base_width', convertValueUnitsTo({ value: extrusionSpecs.baseWidth, to: 'in' }));
    url.searchParams.append('base_height', convertValueUnitsTo({ value: extrusionSpecs.baseThick, to: 'in' }));
    url.searchParams.append('fin_thickness', convertValueUnitsTo({ value: extrusionSpecs.finThick, to: 'in' }));
    url.searchParams.append('fin_height', convertValueUnitsTo({ value: extrusionSpecs.finHeight, to: 'in' }));
    url.searchParams.append('num_fins', extrusionSpecs.numFins);
    url.searchParams.append('max_width', convertValueUnitsTo({ value: maxSize.width, to: 'in' }));
    url.searchParams.append('max_height', convertValueUnitsTo({ value: maxSize.height, to: 'in' }));
    url.searchParams.append('non_flat_back', extrusionSpecs.nonFlatback ? 1 :0);
    url.searchParams.append('matches', 100);
  }
  const response = await getExtrusionMatchData(url);
  return Object.entries(response).map((cm) => {
    if (project.units === type.system.american) {
      return {
        partId: parseInt(cm[0]),
        perimeterDeviation: getDeviation ? cm[1]['performance_deviation'] : 0.0,
        materialDeviation: getDeviation ? cm[1]['material_cost_deviation'] : 0.0,
        width: cm[1]['width'],
        height: cm[1]['height'],
        finThickness: cm[1]['fin_thickness'],
        numFins: cm[1]['num_fins'],
        finPitch: cm[1]['fin_pitch'],
        class: cm[1]['class'],
      };
    } else {
      return {
        partId: parseInt(cm[0]),
        perimeterDeviation: getDeviation ? cm[1]['performance_deviation'] : 0.0,
        materialDeviation: getDeviation ? cm[1]['material_cost_deviation'] : 0.0,
        width: convertValueUnits({ value: cm[1]['width'], from: 'in', to: 'mm' }).toFixed(3),
        height: convertValueUnits({ value: cm[1]['height'], from: 'in', to: 'mm' }).toFixed(3),
        finThickness: convertValueUnits({ value: cm[1]['fin_thickness'], from: 'in', to: 'mm' }).toFixed(3),
        numFins: cm[1]['num_fins'],
        finPitch: convertValueUnits({ value: cm[1]['fin_pitch'], from: 'in', to: 'mm' }).toFixed(3),
        class: cm[1]['class'],
      };
    }
  });
};

export const getOptimalExtrusionDesigns = async (project) => {
  const url = new URL(ESTIMATEAPI);
  url.pathname = '/generate/optimalDesigns';
  url.searchParams.append('project_id', project.id);
  const response = await getEstimatorData(url);
  const flowTypes = ['Natural', 'Low', 'Medium', 'High'];
  const to_unit = project.units === type.system.american ? 'in' : 'mm';
  var results = [];
  if (response) {
    ['natural', 'low', 'medium', 'high'].forEach((flowKey, index) => {
      const data = response[flowKey];
      const flowType = flowTypes[index];
      if (Object.keys(data).length > 0) {
        const sol =  {
          flow: flowType,
          length: parseFloat(convertValueUnits({ value: data['length'], from: 'm', to: to_unit }).toFixed(3)),
          width: parseFloat(convertValueUnits({ value: data['width'], from: 'm', to: to_unit }).toFixed(3)),
          baseThick: parseFloat(convertValueUnits({ value: data['base_thickness'], from: 'm', to: to_unit }).toFixed(3)),
          finThick: parseFloat(convertValueUnits({ value: data['fin_thickness'], from: 'm', to: to_unit }).toFixed(3)),
          finHeight: parseFloat(convertValueUnits({ value: data['fin_height'], from: 'm', to: to_unit }).toFixed(3)),
          finPitch: parseFloat(convertValueUnits({ value: data['fin_pitch'], from: 'm', to: to_unit }).toFixed(3)),
          numFins: data['num_fins'],
          imagePath: `${project.id}/${flowType.toLowerCase()}.png`,
        };
        results.push(sol);
      }
    })
  return results;
  } else {
    return [];
  }
};

const getExtrusions = async ({ nextToken }) => {
  try {
    const response = await API.graphql({
      query: listLibrarys,
      variables: {
        type: type.design.extrusion,
        nextToken,
      },
    });

    return response.data.listLibrarys;
  } catch (error) {
    console.error(error);
  }
};

export const getExtrusionCodes = async () => {
  let { items, nextToken } = await getExtrusions({
    nextToken: null,
  });

  let extrusions = [...items];

  while (nextToken !== null) {
    const response = await getExtrusions({
      nextToken,
    });
    extrusions = [...extrusions, ...response.items];
    nextToken = response.nextToken;
  }
  // get active (true) extrusion codes.
  return extrusions.filter((extrusion) => extrusion.active).map(({ partId }) => partId);
};

const getExtrusion = async ({ partId }) => {
  try {
    const response = await API.graphql({
      query: getLibrary,
      variables: {
        type: type.design.extrusion,
        partId,
      },
    });
    return response.data.getLibrary;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getExtrusionData = async ({ partId }) => await getExtrusion({ partId });

export const shareProject = async (project, userEmail) => {
  const url = new URL(EXTRUSIONAPI);
  url.pathname = `/copy/${project.id}`;
  url.searchParams.append('userEmail', userEmail.toLowerCase());
  const response = await getEstimatorData(url);
  if (response.hasOwnProperty('result')) { 
    message.info(response.result);
    return true;
  }
  return false;
};