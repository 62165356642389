import { useRecoilValue } from 'recoil';
import { Button } from 'antd';
import { Page, Text, View, Document, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { dimensionString, extrusionDims } from '../../../utils/extrusion-info';
import { nanoid } from 'nanoid';
import { authState, userRoleState } from '../../../state/auth';
import { userState } from '../../../state/user';
import { type } from '../../../state/units';
import { customersState } from '../../../state/customers';
import { autoFlycutApplied } from '../../../utils/line-minimum';

const BORDER_COLOR = '#bfbfbf';
const BORDER_STYLE = 'solid';
const COL1_WIDTH = 50;
const COLN_WIDTH = 100 - COL1_WIDTH;
const COSTING_COLN_WIDTH = 100 / 9;

const getConidtionCodes = (q) => {
  let conditionCodes = [];
  if (q.lineMinimumImposed === 1) { 
    conditionCodes.push('1');
  }
  if (q.materialMinimumImposed === 1) {
    conditionCodes.push('2');
  }
  if (q.sellPrice * q.quantity > 100000.0) {
    conditionCodes.push('3');
  }

  if (conditionCodes.length > 0) {
    return conditionCodes.join(', ');
  } else {
    return '-';
  } 
}

const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    color: '#0a4474',
  },
  image: {
    width: 32,
    height: 32,
    // marginVertical: 15,
    // marginHorizontal: 32,
  },
  section: {
    fontSize: 14,
    margin: 6,
    color: '#e9593d',
  },
  text: {
    fontSize: 12,
    margin: 4,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    //borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'right',
  },
  tableColHeader: {
    width: COLN_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#fafafa',
    textAlign: 'center',
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: COLN_WIDTH + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  url: {
    position: 'absolute',
    fontSize: 12,
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'blue',
  },
});

const stylesCosting = { ...styles };
stylesCosting.tableCol1Header.width = COSTING_COLN_WIDTH + '%';
stylesCosting.tableColHeader.width = COSTING_COLN_WIDTH + '%';

const stylesNRETable = { ...styles, 
  tableCol1Header: {...styles.tableCol1Header, width: 20 + '%'}, 
  tableColHeader: {...styles.tableColHeader, width: 20 + '%'}, 
};

// stylesCosting.tableCol.width = COSTING_COLN_WIDTH + '%';

/* const notesStyles = StyleSheet.create({
  tableCol: {
    width: 100 + '%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
});

const notes = [
  '1. Metals pricing is based on Midwest and New York cash price per the American Metals Market as of this quote date.',
  '2. Boyd quotes machining tolerances as +/- .010” lead-intolerance and +/- .005” feature to feature non-cumulative.',
  '3. Boyd quotes standard extrusion tolerance on all extruded features.',
  '4. Boyd quotes +/- .015 on cutoff length. ( +/- .010 common)',
  '5. Note: Loose, Small, or Partial Fins in the Area of the Fin Counter-Bores may need to be removed at Boyds’ discretions.',
]; */

const finishes = {
  NONE: 'None',
  ETCHWASH: 'Etch/Wash - RoHS No finish; Clean part',
  AAVSHIELD3: 'Chromate - RoHS Clear (AavShield3); MIL-DTL-5541 Type II',
  CHROMATE: 'Chromate - Non-RoHS Gold or Clear; MIL-DTL-5541 Type I',
  ANODIZE: 'Anodize - RoHS Black (Class 2); MIL-A-8625 Type II',
  ANODIZECLEAR: 'Anodize - RoHS Clear (Class 1); MIL-A-8625 Type II',
};

const markings = {
  NONE: 'None',
  INK: 'Ink',
  BARCODE: 'Bar code label',
  LASER: 'Laser',
};

const AddRow = ({ label, value }) => (
  <View style={styles.tableRow}>
    <View style={styles.tableCol1}>
      <Text style={styles.tableCell}>{label}</Text>
    </View>
    <View style={styles.tableCol}>
      <Text style={styles.tableCell}>{value}</Text>
    </View>
  </View>
);

const CostingPDFReport = ({ project, userEmail, userName, customerName, userRole }) => {

  const firstquote = project.designs[project.activeDesignId].costEstimates[0];
  const quoteSpecs = project.quoteSpecs;
  const extrusion = project.designs[project.activeDesignId].extrusion;
  
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.image} src="/favicon-32x32.png" />
        {/*<Text style={styles.title}>Cost Estimate</Text>*/}
        <Text style={styles.section}>Summary</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Project name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{project.name}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Quote Number</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{project.quoteSpecs.quoteHeader.quoteNo ? project.quoteSpecs.quoteHeader.quoteNo : 'NA'}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{dayjs(project.quoteSpecs.quoteHeader.date).format('MM-DD-YYYY')}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Quote Entity</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Laconia</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Customer</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{customerName}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Customer part number</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{project.quoteSpecs.quoteHeader.customerPartNumber ? project.quoteSpecs.quoteHeader.customerPartNumber : 'NA' }</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Boyd part number</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {project.activeDesignId === 1 ? project.designs[1].extrusion.partId : 'NA'}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Costed By</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{userName}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Costed By Email</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{userEmail}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.section}></Text>
        <Text style={styles.section}>Quotes</Text>
        {/*<Text style={styles.text}>{`Surcharge rate: ${project.quoteSpecs.surchargePercentage.toFixed(2)}%`}</Text>*/}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>Quantity</Text>
            </View>
            <View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>Material</Text>
            </View>
            <View style={stylesCosting.tableColHeader}>
              <Text style={styles.tableCellHeader}>OSV</Text>
            </View>
            <View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>Labor</Text>
            </View>
            <View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>Overhead</Text>
            </View>
            <View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>Total</Text>
            </View>
            <View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>GM %</Text>
            </View>
            <View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>SellPrice</Text>
            </View>
            <View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>Condition</Text>
            </View>
            {/*<View style={stylesCosting.tableColHeader}>
              <Text style={stylesCosting.tableCellHeader}>Surcharge</Text>
            </View> */}
          </View>
          {project.designs[project.activeDesignId].costEstimates.map((q) => (
            <View key={nanoid(10)} style={styles.tableRow}>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{q.quantity}</Text>
              </View>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{q.materialCost.toFixed(2)}</Text>
              </View>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{q.osvCost.toFixed(2)}</Text>
              </View>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{q.laborCost.toFixed(2)}</Text>
              </View>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{q.overheadCost.toFixed(2)}</Text>
              </View>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{(q.totalCost + q.osvCost).toFixed(2)}</Text>
              </View>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{q.marginPercentage.toFixed(2)}</Text>
              </View>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{q.sellPrice.toFixed(2)}</Text>
              </View>
              <View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{getConidtionCodes(q)}</Text>
              </View>
              {/*<View style={stylesCosting.tableCol1Header}>
                <Text style={styles.tableCell}>{q.surcharge.toFixed(2)}</Text>
              </View> */}
            </View>
          ))}
        </View>
        <Text style={styles.text}>All cost/price values are in US $</Text>
        <Text style={styles.text}></Text>
        <Text style={styles.text}>Condition codes:</Text>
        <Text style={styles.text}>1 - Line minimum imposed</Text>
        <Text style={styles.text}>2 - Material minimum imposed</Text>
        <Text style={styles.text}>3 - High project value - please use Boyd’s white glove service through the factory or NPI team</Text>

        <Text style={styles.section}></Text>
        <Text style={styles.section}>One-time NRE</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={stylesNRETable.tableColHeader}>
              <Text style={styles.tableCellHeader}>FAI cost</Text>
            </View>
            <View style={stylesNRETable.tableColHeader}>
              <Text style={styles.tableCellHeader}>Tooling cost</Text>
            </View>
            <View style={stylesNRETable.tableColHeader}>
              <Text style={styles.tableCellHeader}>Drawing cost</Text>
            </View>
            <View style={stylesNRETable.tableColHeader}>
              <Text style={styles.tableCellHeader}>GM %</Text>
            </View>
            <View style={stylesNRETable.tableColHeader}>
              <Text style={styles.tableCellHeader}>NRE cost</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={stylesNRETable.tableCol1Header}>
              <Text style={styles.tableCell}>{firstquote.faiCost.toFixed(2)}</Text>
            </View>
              <View style={stylesNRETable.tableCol1Header}>
                <Text style={styles.tableCell}>{firstquote.toolingCost.toFixed(2)}</Text>
              </View>
              <View style={stylesNRETable.tableCol1Header}>
                <Text style={styles.tableCell}>{firstquote.drawingCost.toFixed(2)}</Text>
              </View>
              <View style={stylesNRETable.tableCol1Header}>
                <Text style={styles.tableCell}>{project.quoteSpecs.nreGMPercentage.toFixed(2)}</Text>
              </View>
              <View style={stylesNRETable.tableCol1Header}>
                <Text style={stylesNRETable.tableCell}>{firstquote.nreCost.toFixed(2)}</Text>
              </View>
            </View>
        </View>
        <Text style={styles.text}>All cost values are in US $</Text>

        <Text style={styles.section}></Text>
        <Text style={styles.section}>Heat sink</Text>
        <View style={styles.table}>
          <AddRow label='Extrusion type' value={{ 0: 'Customer', 1: 'Standard', 2: 'Optimal', 3: 'Custom' }[project.activeDesignId]} />
          { project.activeDesignId === 1 ? <AddRow label='Part ID' value={extrusion.partId} /> : <></> }
          <AddRow label='Dimensions' value={extrusionDims(project)} />
          <AddRow label='Non-flatback extrusion' value={extrusion.nonFlatback ? 'Yes': 'No'} />
          { extrusion.nonFlatback ? (
            <>
              <AddRow label='Weight/length' value={dimensionString(extrusion.weightPerFeet)} />
              <AddRow label='Perimeter' value={dimensionString(extrusion.perimeter)} />
            </>
          ) : (
            <>
              <AddRow label='Auto calculate weight per length from profile' value={extrusion.autoCalcWeightPerFeet ? 'Yes' : 'No'} />
              { !extrusion.autoCalcWeightPerFeet ? (
                <AddRow label='Weight/length' value={dimensionString(extrusion.weightPerFeet)} />
              ) : (
                <></>
              )}
              
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol1, width: '100%' }}>
                  <Text style={{ ...styles.tableCell, color: '#0a4474' }}>Base Dimensions</Text>
                </View>
              </View>
              <AddRow label='Width' value={dimensionString(extrusion.baseWidth)}/>
              <AddRow label='Length' value={dimensionString(extrusion.dimensions.length)}/>
              <AddRow label='Thickness' value={dimensionString(extrusion.baseThick)}/>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol1, width: '100%' }}>
                  <Text style={{ ...styles.tableCell, color: '#0a4474' }}>Fin Dimensions</Text>
                </View>
              </View>
              <AddRow label='Thickness' value={dimensionString(extrusion.finThick)}/>
              <AddRow label='Height' value={dimensionString(extrusion.finHeight)}/>
              <AddRow label='Number of fins' value={extrusion.numFins}/>
              <AddRow label='Pitch' value={dimensionString(extrusion.finPitch)}/>
            </>
          )}
        </View>

        <Text style={styles.section}></Text>
        <Text style={styles.section}>CNC Operations</Text>
        <View style={styles.table}>
          <AddRow label='Number of views having holes, pockets, or other machined features' value={quoteSpecs.cnc.sides}/>
          <AddRow label='Number of holes (all types) in the part' value={quoteSpecs.cnc.holes} />
          <AddRow label='Number of holes that do not break through (Blind holes)' value={quoteSpecs.finish.blindHoles} />
          <AddRow label='Number of different faces these blind holes are placed on' value={quoteSpecs.finish.blindFaces} />
          <AddRow label='Number of counterbores on the fin side' value={quoteSpecs.counterBores.cbores} />
          <AddRow label='Number of fin gaps having hole or counterbore break through' value={quoteSpecs.sandGaps} />
          { userRole !== type.userRole.sales ? (
            <>
              <AddRow label='Total volume of pockets' value={`${quoteSpecs.cnc.pocketVolume.value} ${quoteSpecs.cnc.pocketVolume.units}`}/>
              <AddRow label='Custom CNC time per part (minutes)' value={quoteSpecs.counterBores.addTime}/>
            </>
          ) : (
            <></>
          )}
        </View>

        { userRole !== type.userRole.sales ? (
          <>
            <Text style={styles.section}></Text>
            <Text style={styles.section}>Fin machining</Text>
            <View style={styles.table}>
              <AddRow label='Total length of fins removed' value={dimensionString(quoteSpecs.finMachining.length)}/>
              <AddRow label='Average depth of fins removed' value={dimensionString(quoteSpecs.finMachining.depth)}/>
            </View>
          </>
        ) : (
          <></>
        )}

        <Text style={styles.section}></Text>
        <Text style={styles.section}>Fly cut</Text>
        <View style={styles.table}>
          <AddRow label='Does the print call out a specific flatness requirement on the heat sink base?' value={quoteSpecs.cnc.flycutFlatnessCallout ? 'Yes' : 'No'} />
          <AddRow label='Does the print call out a surface roughness less than < Ra 63µinch or 1.6µm or N7 on the heat sink base?' value={quoteSpecs.cnc.flycutRoughnessCallout ? 'Yes' : 'No'} />
          <AddRow label='Part requires a fly cut (custom requirement, to reduce the base thickness etc.)?' value={quoteSpecs.cnc.flycut ? 'Yes' : 'No'} />
          <AddRow label={'Auto flycut applied? \n Auto flycut will be applied if heat sink width is greater than or equal to 6" and there are machined pockets or blind holes.'} value={autoFlycutApplied(project) ? 'Yes' : 'No'}/>
          { userRole !== type.userRole.sales ? (
            <>
              <AddRow label='Require custom flycut area?' value={quoteSpecs.cnc.customFlycutArea ? 'Yes' : 'No'}></AddRow>
              { quoteSpecs.cnc.customFlycutArea ? (
                <>
                  <AddRow label='Flycut width' value={dimensionString(quoteSpecs.cnc.customFlycutWidth)}></AddRow>
                  <AddRow label='Flycut length' value={dimensionString(quoteSpecs.cnc.customFlycutDepth)}></AddRow>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}  
        </View>

        <Text style={styles.section}></Text>
        <Text style={styles.section}>{'Finish & Hardware'}</Text>
        <View style={styles.table}>
          <AddRow label='Finish' value={finishes[quoteSpecs.finish.style]} />
          <AddRow label='Marking' value={markings[quoteSpecs.marking]} />
          { userRole !== type.userRole.sales ? (
            <>
              <AddRow label='Number of inserts in the part' value={quoteSpecs.inserts.insertQty} />
              <AddRow label='Average cost of each insert (in US$)' value={quoteSpecs.inserts.insertCost} />
            </>
          ) : (
            <></>
          )}
        </View>

        {/* <Text style={styles.section}></Text>
        <Text style={styles.section}>Comments / Notes / Exceptions :</Text>
        <View style={notesStyles.table}>
          {notes.map((n, index) => (
            <View key={`note-${index}`} style={notesStyles.tableRow}>
              <View style={notesStyles.tableCol}>
                <Text style={notesStyles.tableCell}>{n}</Text>
              </View>
            </View>
          ))}
        </View> */}

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
        <Text style={styles.url} fixed>
          www.boydcorp.com
        </Text>
      </Page>
    </Document>
  )
  };

export const GenerateCostingPDF = ({ project }) => {
  const auth = useRecoilValue(authState);
  const user = useRecoilValue(userState(auth.username));
  const customers = useRecoilValue(customersState(null));
  const customer = customers.filter(({ id }) => id === project.customerId); 
  const customerName = customer.length === 1 ? customer[0].name : 'NA';
  const userEmail = auth.email;
  const userName = user.name;
  const userRole = useRecoilValue(userRoleState);

  // Get user name from User email
  // const emailPrefix = userEmail.split('@')[0].split('.').map(n => n ? n[0].toUpperCase() + n.toLowerCase().slice(1): '');
  // const userName = emailPrefix.join(' ');
  return (
    <Button
      type="secondary"
      onClick={async () => {
        const doc = <CostingPDFReport project={project} userEmail={userEmail} userName={userName} customerName={customerName} userRole={userRole}/>;
        const asPdf = pdf([]); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, 'costing.pdf');
      }}
    >
      Internal Cost Sheet PDF
    </Button>
  );
};
