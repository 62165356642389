import { ProjectCard } from '../components/project';

export const ProjectsCards = ({ customers, projects, selectProject, editProject, copyProject, deleteProject, tags }) => {
  return projects.length === 0 ? (
    <div>No projects</div>
  ) : (
    projects.map((project) => (
      <ProjectCard
        key={`project-${project.id}`}
        project={project}
        customers={customers}
        selectProject={selectProject}
        editProject={editProject}
        copyProject={copyProject}
        deleteProject={deleteProject}
        tags={tags}
      />
    ))
  );
};
