import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// add dayjs plugin
dayjs.extend(relativeTime);

export const formatDate = (iso) => dayjs(iso).format('MMM DD, YYYY');
export const formatDateFromNow = (iso) => `${dayjs(iso).format('D/MM/YYYY')} (${dayjs(iso).fromNow()})`;

export const formatTime = (iso) => dayjs(iso).format('HH:mm:ss');
export const formatTimestamp = (iso) => dayjs(iso).format('D/MM/YYYY HH:mm:ss');
export const formatTimestampFromNow = (iso) => `${dayjs(iso).format('D/MM/YYYY HH:mm:ss')} (${dayjs(iso).fromNow()})`;

export const formatFromNow = (iso) => dayjs(iso).fromNow();

export const unixTimestamp = (iso) => dayjs(dayjs(iso).toISOString()).unix();

export const sortByTimestamp = ({items, key}) => sortBy(items, (item) => -unixTimestamp(item[key]));