import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Select, Button, Form, Input } from 'antd';
import { authState } from '../../state/auth';
import { customersState } from '../../state/customers';
import { PlusOutlined } from '@ant-design/icons';
import { SuspenseModal } from '../common';
import { createUpdateCustomer } from '../../state/customer';
import { initialCustomerState } from '../../state/customer';

const { Item } = Form;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};
const tailLayout = {
  wrapperCol: { offset: 19 },
};

const CustomerModalForm = ({ visible, cancel, callback }) => {
  const createCustomer = (value) =>
    createUpdateCustomer({ customerId: undefined, input: { ...initialCustomerState, ...value } })
      .then((response) => callback(response.id))
      .catch(cancel);

  return (
    <SuspenseModal title="New Customer" visible={visible} onCancel={cancel}>
      <Form name="customer" onFinish={createCustomer} {...layout}>
        <Item name="name" label="Name" rules={[{ required: true, message: 'Customer name is required' }]}>
          <Input />
        </Item>
        <Item {...tailLayout}>
          <Button type="primary" htmlType="submit" size="medium">
            Save
          </Button>
        </Item>
      </Form>
    </SuspenseModal>
  );
};

export const CustomerSelect = ({ value, onChange, projectId }) => {
  const auth = useRecoilValue(authState);
  const customers = useRecoilValue(customersState(null));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const options = [
    { value: auth.username, label: 'Unassigned' },
    ...customers.map(({ id, name }) => ({ value: id, label: name })),
  ];

  const [search, setSearch] = useState('');
  // this function can be used for async search requests
  const onSearch = (value) => setSearch(value);

  useEffect(() => {
    setSearch('');
  }, [value]);

  const onCustomerCreate = (value) => {
    onChange(value);
    setIsModalVisible(false);
  };

  return (
    <>
      <Select
        value={value}
        options={options}
        placeholder="Select customer"
        onChange={onChange}
        showSearch={true}
        searchValue={search}
        onSearch={onSearch}
        filterOption={(input, option) => option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0}
        notFoundContent={
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center' }}>
            <div>No matching results</div>
            <Button size="small" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
              New Customer
            </Button>
          </div>
        }
      />
      <CustomerModalForm
        projectId={projectId}
        visible={isModalVisible}
        cancel={() => setIsModalVisible(false)}
        callback={onCustomerCreate}
      />
    </>
  );
};
