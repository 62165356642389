import { API } from '@aws-amplify/api';
import { createActivity } from '../graphql/mutations';

export const addActivity = async ({ activity, userEmail, userId, projectName, projectId, customer, customerId}) => {
  const input = { label: activity, userEmail, userId, projectName, projectId, customer, customerId };
  try {
    const response = await API.graphql({
      query: createActivity,
      variables: { input }
    });
    return response.data.createActivity;
  } catch (error) {
    console.error(error);
    // throw new Error('Error updating user');
  }
};