import { HelpCard } from '.';
import { Image, Typography, Space } from 'antd';
import { nanoid } from 'nanoid';

const { Link } = Typography;

export const extrusionCodeHelp = ({ imageCode }) =>
  imageCode === '0' ? null : (
    <HelpCard title={`Extrusion ${imageCode}`} width={360}>
      <Image
        src={`https://www.boydcorp.com/files/part-documentation/extrusion-${imageCode}-drawing.png`}
        preview={false}
      />
    </HelpCard>
  );

export const classHelp = (
  <HelpCard title="Extrusion Classes" width={480}>
    <p>
      <strong>Class A</strong>: Popular extrusion used by multiple customers. Safety stock is set up to try and keep
      some inventory on hand all times.
    </p>
    {/*<p>
      <strong>Class B</strong>: Moderately popular extrusion used by multiple customers. Strong likelihood of some
      material being available from stock or on backorder.
    </p> */}
    <p>
      <strong>Class C</strong>: Low demand/low usage extrusion with few / one customer demand. Depending on stock at
      time of order a set up charge may apply.
    </p>
  </HelpCard>
);

export const oracleHelp = (
  <HelpCard title="Oracle #" width={480}>
    <p>It is standard Boyd part number for the raw extrusion profile.</p>
  </HelpCard>
);

export const weightByHeightHelp = (
  <HelpCard title="Weight/length" width={480}>
    <p>It is weight of extrusion per standard length typically provided as Lbs/ft or kg/m.</p>
  </HelpCard>
);

export const extrusionTypeHelp = (
  <HelpCard title={'Extrusion Type'} width={220}>
    <p>Specify dimensions for flat back extrusion</p>
    <Image src="/images/extrusion-heat-sink.png" preview={false} />
  </HelpCard>
);

export const finThicknessHelp = (
  <HelpCard title="Fin Thickness" width={480}>
    <p>If a fin does not have constant thickness, use the average value.</p>
    <p>Average fin thickness = (Top fin thickness + Bottom fin thickness) / 2</p>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-fin-thickness.png" preview={false} />
    </div>
  </HelpCard>
);

export const finPitchHelp = (
  <HelpCard title="Fin Pitch" width={480}>
    <p>Fin pitch is the distance between the center of each fin (not fin gap).</p>
    <p>If the spacing of the fins is not consistent, use the average or typical fin pitch.</p>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-fin-pitch.png" preview={false} />
    </div>
  </HelpCard>
);

export const finHeightHelp = (
  <HelpCard title="Fin Height" width={480}>
    <p>
      If an overall heat sink height is given then <br /> Fin height = Overall height – Base thickness.
    </p>
    <p>If fin height is not consistent, average fin height should be used.</p>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-fin-height.png" preview={false} />
    </div>
  </HelpCard>
);

export const finNumbersHelp = (
  <HelpCard title="Number of Fins" width={360}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-fins-numbers.png" preview={false} />
    </div>
  </HelpCard>
);

export const baseThicknessHelp = (
  <HelpCard title="Base Thickness" width={480}>
    <p>If base thickness is not consistent, average base thickness should be used.</p>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-base-thickness.png" preview={false} />
    </div>
  </HelpCard>
);

export const baseWidthHelp = (
  <HelpCard title="Base Width" width={360}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-base-width.png" preview={false} />
    </div>
  </HelpCard>
);

export const heatsinkLengthHelp = (
  <HelpCard title="Heat Sink Length" width={360}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-length.png" preview={false} />
    </div>
  </HelpCard>
);

export const heatsinkWidthHelp = (
  <HelpCard title="Heat Sink Width" width={360}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-width.png" preview={false} />
    </div>
  </HelpCard>
);

export const heatsinkHeightHelp = (
  <HelpCard title="Heat Sink Height" width={360}>
    <p>Heat sink height = Base thickness + Fin height</p>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-height.png" preview={false} />
    </div>
  </HelpCard>
);

export const heatsinkHasOffsetHelp = (
  <HelpCard title="Has offset" width={720}>
    <p>
      If the fins have a left or right offset as shown in the image below, use this option to input offset values.
      <br/>
      <br/>
      Generally, the offset is provided to mount/clamp the heat sink with another component. Sometimes the offset is called "flange".
    </p>
    <Image src="/images/hs-offset.png" width={700} preview={false} />
  </HelpCard>
);

export const heatsinkLeftOffsetHelp = (
  <HelpCard title="Left offset" width={480}>
    <Image src="/images/hs-left-offset.png" preview={false} />
  </HelpCard>
);

export const heatsinkRightOffsetHelp = (
  <HelpCard title="Right offset" width={480}>
    <Image src="/images/hs-right-offset.png" preview={false} />
  </HelpCard>
);

export const baseLengthHelp = (
  <HelpCard title="Base Length" width={360}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Image src="/images/hs-base-length.png" preview={false} />
    </div>
  </HelpCard>
);

export const cutLengthHelp = (
  <HelpCard title="Cut Length" width={280}>
    <Image src="/images/hs-cut-length.png" preview={false} />
  </HelpCard>
);

export const quoteDateHelp = (
  <HelpCard title="Quote Date" width={360}>
    <p>Date when the quote is generated. Material prices and other factory costs correspond to this date.</p>
  </HelpCard>
);

export const holesViewsHelp = (
  <HelpCard title="Number of Views having Holes, Pockets, or Other Machined Features" width={720}>
    <p>For example, in the image below, 5 out of 6 views have holes, pockets, or other machined features. 
      Holes should be considered being added in the bottom view and counter bores or other fin machining 
      from in the top view. For through holes do not double count as they could be seen in both top and 
      bottom views but only one should be counted for a through hole.
    </p>
    <Image src="/images/hs-holes-views.png" width={700} preview={false} />
  </HelpCard>
);

export const holesCountHelp = (
  <HelpCard title="Number of Holes (all types) in the Part" width={720}>
    <p>
      For example, the part in the image below has 14 holes. Count holes of all types (blind, thru, plain, threaded or
      tapped etc).
    </p>
    <Image src="/images/hs-holes.png" width={700} preview={false} />
  </HelpCard>
);

export const pocketsHelp = (
  <HelpCard title="Total Volume of Pockets" width={720}>
    <p>There are two pockets (A and B) in the image shown below.</p>
    <p>
      <b>Pocket A volume</b> = 20 mm x 7 mm x 4 mm = 560 mm3
      <br />
      <b>Pocket B volume</b> = 15 mm x 6 mm x 8 mm = 720 mm3
      <br />
      <b>Total volume of pockets</b> = Pocket A volume + Pocket B volume = 1280 mm3
    </p>
    <p>
      <b>Note:-</b> For Pocket B, depth is 8.0mm (thru base) while fins removed above it are considered “fin machining”.
    </p>
    <Image src="/images/hs-pockets.png" width={700} preview={false} />
  </HelpCard>
);

export const finishHelp = (
  <HelpCard title="Finish" width={720}>
    <p>
      Select finish applied to extrusion. Generally, a note about specific finish is given in the drawing title block.
    </p>
    <Image src="/images/hs-finish.png" width={700} preview={false} />
  </HelpCard>
);

export const flycutFlatnessRequirementHelp = (
  <HelpCard title="Print Calls Out a Specific Flatness Requirement on the Heat Sink Base" width={720}>
    <p>If print (drawing) calls out a specific flatness requirement on the heat sink base, turn on this option.</p>

    <div>
      Flatness is a dimensionless number and is mentioned in the drawing feature control frame as (
      <Image
        src="/images/flatness-number.png"
        style={{ verticalAlign: 'middle', display: 'inline' }}
        height={32}
        preview={false}
      />{' '}
      ,
      <Image
        src="/images/flatness-number2.png"
        style={{ verticalAlign: 'middle', display: 'inline' }}
        height={32}
        preview={false}
      />{' '}
      ,
      <Image
        src="/images/flatness-number3.png"
        style={{ verticalAlign: 'middle', display: 'inline' }}
        height={32}
        preview={false}
      />
      ) or in text as 0.1mm/25.4mm.
    </div>

    <div style={{ marginTop: 10 }}>
      Flatness may be defined in the drawing notes as shown below.
      <ol>
        <li>Base flatness to be 0.003” per inch max over base.</li>
        <li>Flatness 0.001 in/in on hatched area.</li>
      </ol>
    </div>

    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
      <div>
        <p style={{ textAlign: 'center' }}>
          <b>Metric unit</b>
        </p>
        <Image src="/images/hs-flycut-metric.png" width={350} preview={false} />
      </div>
      <div>
        <p style={{ textAlign: 'center' }}>
          <b>Mixed unit</b>
        </p>
        <Image src="/images/hs-flycut-mixed.png" width={350} preview={false} />
      </div>
    </div>
  </HelpCard>
);

export const flycutSurfaceRoughnessHelp = (
  <HelpCard
    title="Print Calls Out a Surface Roughness Less than < Ra 63&#956;inch or 1.6&#956;m or N7 on the Heat Sink Base"
    width={720}
  >
    <p>
      If print (drawing) calls out a surface roughness less than &#60; Ra 63&#956;inch or 1.6&#956;m or N7 on the heat
      sink base, turn on this option.
    </p>

    <div>
      <p>Roughness values are specified in &#956;m, &#956;inch or grade N letter like the below.</p>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Image src="/images/surface-roughness.png" preview={false} />
      </div>
    </div>

    <div style={{ marginTop: 10 }}>
      Surface roughness may be defined in the drawing notes as shown below.
      <ol>
        <li>Surface roughness must be less than: Ra &#60; 1.6&#956;m.</li>
        <li>Indicated surface to be N7 roughness.</li>
      </ol>
    </div>
  </HelpCard>
);

export const flycutHelp = (
  <HelpCard title="Flycut" width={720}>
    <p>
      Flycut is a machining process through which small amount of material (generally 0.1 to 0.5 mm) is removed from the
      base of the heat sink to achieve flatness.
    </p>
    <p>
      Flatness of raw extrusion is typically 0.004 in/in (0.1 mm/25.4 mm) or greater. Flycut is necessary if flatness
      less than 0.004 in/in or 0.1 mm/25.4mm is required.
    </p>
    <div>
      Flatness is a dimensionless number and is mentioned in the drawing feature control frame as{' '}
      <Image
        src="/images/flatness-number.png"
        style={{ verticalAlign: 'middle', display: 'inline' }}
        height={32}
        preview={false}
      />{' '}
      or in text as 0.1mm/25.4mm.
    </div>
    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
      <div>
        <p style={{ textAlign: 'center' }}>
          <b>Metric unit</b>
        </p>
        <Image src="/images/hs-flycut-metric.png" width={350} preview={false} />
      </div>
      <div>
        <p style={{ textAlign: 'center' }}>
          <b>Mixed unit</b>
        </p>
        <Image src="/images/hs-flycut-mixed.png" width={350} preview={false} />
      </div>
    </div>
  </HelpCard>
);

export const flycutCustomAreaHelp = (
  <HelpCard title="Custom Flycut Area" width={720}>
    <p>
      if flycut is only on part of the heat sink base, turn on this option to define custom flycut width and length.
    </p>

    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Image src="/images/flycut-area.png" width={600} preview={false} />
      </div>
    </div>
  </HelpCard>
);

export const flycutCustomAreaWidthHelp = (
  <HelpCard title="Flycut Width" width={720}>
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Image src="/images/flycut-area-width.png" width={600} preview={false} />
      </div>
    </div>
  </HelpCard>
);

export const flycutCustomAreaLengthHelp = (
  <HelpCard title="Flycut Length" width={720}>
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Image src="/images/flycut-area-length.png" width={600} preview={false} />
      </div>
    </div>
  </HelpCard>
);

export const counterBoreHelp = (
  <HelpCard title="Number of counterbores in the fins" width={720}>
    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto' }}>
      <p>
        A counterbore is a round hole with sections at different diameters. This allows captive head or other fixings
        with a square to be sunk into material without being proud of the surface.
      </p>
      <Image src="/images/counter-bore.png" width={50} preview={false} />
    </div>
    <p>For example, fins have 4 counterbores in the image below.</p>
    <Image src="/images/hs-fins-counterbores.png" width={600} preview={false} />
    <p>
      In the example below, there are fins removed around the 4 thru holes (C1, C2, C3 and C4). These small areas should
      be considered as 4 “counterbores” and not considered as side fin machining.
      <br />
      <br />
      <b>Note:-</b> A thru hole into the fin structure does not require a counterbore unless specifically called out on the 
      customer drawing.
    </p>
    <Image src="/images/hs-fins-counterbores-2.png" width={700} preview={false} />
  </HelpCard>
);

export const finGapsHolesHelp = (
  <HelpCard title="Number of Fin Gaps having Hole or Counterbore Break Through" width={720}>
    <p>
      For example, in the below image 6 fin gaps have break through (i.e thru) holes. Consider both holes breaking into
      a gap between fins as well as counterbores breaking through a fins from one gap to the next. Both create burrs
      that need to be removed.
      <br />
      <br />
      <b>Note:-</b> Do not double count the number of gaps.  For example, if there are 2 holes or a hole and a counter 
      bore intersecting one gap it just counts as 1 gap not 2.
    </p>
    <Image src="/images/hs-fins-holes-gaps.png" width={700} preview={false} />
  </HelpCard>
);

export const blindHolesHelp = (
  <HelpCard title="Number of Holes that Do Not Break Through (Blind holes)" width={720}>
    <p>
      For example, the part in the image below has total 6 holes ( "a" and "c" types) that do not break through (i.e
      blind holes).
    </p>
    <Image src="/images/hs-blind-holes.png" width={700} preview={false} />
  </HelpCard>
);

export const blindHolesFacesHelp = (
  <HelpCard title="Number of different faces these blind holes are placed on" width={720}>
    <p>
      For example, do not break through (blind) holes which are marked as "a" and "c" types are placed on 2 different
      (bottom and right) faces in the image below.
    </p>
    <Image src="/images/hs-blind-holes.png" width={700} preview={false} />
  </HelpCard>
);

export const finMachiningHelp = (
  <HelpCard title="Fin machining" width={720}>
    <p>
      Fin machining is a process to cut/remove the fins. For example, in the image below 3 fins are cut, 65 mm in the length 
      direction and 35 mm in the depth direction.
      <br />
      <br/>
      <b>Note:-</b> Fin machining would be done from fin tip side and depth of fin machining is measured from the 
      fin tip towards the base.
    </p>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Image src="/images/fin-machining.png" width={500} preview={false} />
    </div>
    <p>
        <b>Total length of fins removed = number of fins x length of fins cut</b> = 3 x 65 mm = 195 mm
        <br/>
        <b>Average depth of fins removed =</b> 35 mm
    </p>    
  </HelpCard>
);

export const finMachiningLengthHelp = (
  <HelpCard title="Total length of fins removed" width={720}>
    <p>
      For example, the part in the image below has three fin machining blocks (FM1, FM2 and FM3) from which fins are
      removed.
    </p>
    <p>
      <b>Note:-</b> There are fins removed around the 4 thru holes (C1, C2, C3 and C4). These small areas should be
      considered as 4 “counterbores” and not considered as side fin machining.
    </p>
    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto' }}>
      <p>
        <b>Total length of fins removed = number of fins x length of fins cut</b>
        <br />
        <b>FM1 length = FM3 length =</b> 8 x 8 mm = 64 mm
        <br />
        <b>FM2 length =</b> 12 x 17 mm = 204 mm
        <br />
        <b>Total length of fins removed =</b> FM1 length + FM2 length + FM3 length
        <br />
        <b>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;=</b> 64 mm + 204 mm + 64
        mm
        <br />
        <b>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;=</b> 332 mm
      </p>
      <Image src="/images/fin-machining-length-iso.png" width={240} preview={false} />
    </div>
    <Image src="/images/fin-machining-length-view.png" width={700} preview={false} />
  </HelpCard>
);

export const finMachiningDepthHelp = (
  <HelpCard title="Average depth of fins removed" width={720}>
    <p>
      For example, the part in the image below has three fin machining blocks (FM1, FM2 and FM3) from which fins are
      removed.
    </p>
    <p>
      <b>Note:-</b> There are fins removed around the 4 thru holes (C1, C2, C3 and C4). These small areas should be
      considered as 4 “counterbores” and not considered as side fin machining.
    </p>
    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto' }}>
      <p style={{ margin: 0 }}>
        <b>FM1 depth = FM3 depth =</b> 8 x 17 mm = 136 mm
        <br />
        <b>FM2 depth =</b> 12 x 15 mm = 180 mm
        <br />
        <b>Total depth of fins cut =</b> FM1 depth + FM2 depth + FM3 depth
        <br />
        <b>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;=</b> 136 mm + 180 mm + 136 mm
        <br />
        <b>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;=</b> 452 mm
      </p>
      <Image src="/images/fin-machining-length-iso.png" width={240} preview={false} />
    </div>
    <p style={{ margin: 0 }}>
      <b>Total number of fins cut =</b> FM1 fins + FM2 fins + FM3 fin = 8 + 12 + 8 = 28
      <br />
      <b>Average depth of fins removed = Total depth of fins cut / Total number of fins cut</b>
      <br />
      <b>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;=</b> 452 mm /
      28 = 16.14 mm
    </p>
    <Image src="/images/fin-machining-depth-view.png" width={700} preview={false} />
  </HelpCard>
);

export const insertsHelp = (
  <HelpCard title="Inserts" width={720}>
    <p>Inserts are used for fastening of screws.</p>
    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gridColumnGap: 50 }}>
      <div>
        <p>
          <b>Helicoils</b>
        </p>
        <Image src="/images/helicoils-insert.png" width={280} preview={false} />
      </div>
      <div>
        <p>
          <Link href="https://www.pemnet.com/fastening-products/pem-self-clinching-fasteners-new/" target="_blank">
            <strong>PEM inserts</strong>
          </Link>
        </p>
        <Image src="/images/pem-inserts.png" width={280} preview={false} />
      </div>
    </div>
  </HelpCard>
);

export const markingHelp = (
  <HelpCard title="Part Marking" width={720}>
    <p>
      Specific information like part number, vendor or customer name etc. written using ink, laser or bar code label.
    </p>
  </HelpCard>
);

export const quantityHelp = (
  <HelpCard title="Quantity" width={180}>
    <p>Lot size or Shipment quantity</p>
  </HelpCard>
);

export const nreCostHelp = (
  <HelpCard title="One-time NRE" width={720}>
    <p>
      <b>NRE cost = FAI cost + (Tooling cost + Drawing cost)  * Margin </b>
    </p>
    <ol>
      <li>FAI cost fee is US$ 250. No margin is applied.</li>
      <li>Tooling cost is new extrusion die cost (variable, depending on part complexity and die size)</li>
      <li>{'Drawing cost is for Oracle Part Setup & Internal Drawings (variable, depending on part complexity)'}</li>
    </ol>
  </HelpCard>
);

export const toolingCostHelp = (
  <HelpCard title="Tooling cost" width={720}>
    <p>
      Tooling cost is new extrusion die cost (variable, depending on part complexity and die size)
    </p>
  </HelpCard>
);

export const visitCostingTabsHelp = (titles) => { 
  return (
    <HelpCard title="All costing tabs are not visited" width={480}>
      <p style={{marginBottom: 0}}>
      To generate quote, please visit all costing tabs at least once. <br/><br/>The following costing tabs have not been visited yet:
      </p>
      <ul>
        {titles.map(title => <li key={nanoid(10)}>{title}</li>)}
    </ul>
    </HelpCard>
  )
}

export const airFlowTypeHelp = (
  <HelpCard title="Air Flow Type" width={180}>
    <p>Natural convection</p>
    <Image src="/images/natural-convection.png" preview={false} />
    <p>Forced convection</p>
    <Image src="/images/forced-convection.png" preview={false} />
  </HelpCard>
);

export const orientationHelp = (
  <HelpCard title="Natural Convection" width={180}>
    <p>Horizontal</p>
    <Image src="/images/horizontal-convection.png" preview={false} />
    <p>Vertical</p>
    <Image src="/images/vertical-convection.png" preview={false} />
  </HelpCard>
);

export const flowSetupHelp = (
  <HelpCard title="Flow Setup" width={180}>
    <p>Push</p>
    <Image src="/images/forced-convection.png" preview={false} />
    <p>Impinge</p>
    <Image src="/images/Impinge-fixed-convection.png" preview={false} />
  </HelpCard>
);

export const cosmeticPartHelp = (
  <HelpCard title="Cosmetic Part" width={360}>
    <p>
      Projects for customers with high cosmetic quality standards or products where the heat sink will be exposed to end
      consumers view may have special processes or lower yields not supported by Genie. Please review and quote from the
      factory.
    </p>
  </HelpCard>
);

export const unSupportedFinishHelp = (
  <HelpCard title="Unsupported Surface Finish" width={360}>
    <label>
      <strong>Supported standard finishes are:</strong>
    </label>
    <ol>
      <li>Etch/Wash - RoHS No finish; Clean part</li>
      <li>Chromate - RoHS Clear (AavShield3); MIL-DTL-5541 Type II</li>
      <li>Chromate - Non-RoHS Gold or Clear; MIL-DTL-5541 Type I</li>
      <li>Anodize - RoHS Black (Class 2); MIL-A-8625 Type II</li>
      <li>Anodize - RoHS Clear (Class 1); MIL-A-8625 Type II</li>
    </ol>
    <label>
      <strong>Unsupported Surface finishes due to varying OSV costs are:</strong>
    </label>
    <ol>
      <li>Ni plating</li>
      <li>Powder coating</li>
      <li>Hard anodizing</li>
      <li>Anodize colors other than clear or black</li>
    </ol>
  </HelpCard>
);

export const highlyRegulatedHelp = (
  <HelpCard title="Highly Regulated" width={360}>
    <p>
      Highly regulated industries or applications may require additional paperwork, product serialization, or testing
      that drive costs higher than a simple fabricated extrusion. Those may include Aerospace & Defense, ITAR projects,
      Medical, etc. Please review and quote from the factory if you expect these may exist for this part.
    </p>
  </HelpCard>
);

export const highProjectValueHelp = (
  <HelpCard title="High Project Value" width={360}>
    <p>
      For expected project values in excess of US$ 100,000 please use Boyd’s white glove service through the factory or
      NPI team.
    </p>
  </HelpCard>
);

export const extendedPriceHelp = (
  <HelpCard title="Costing Check (Extended Price) " width={480}>
    <p>
      The extended price (sell price * quantity) for any quantity needs to be greater than the extended price for any of
      the lower quantities quoted.
    </p>
  </HelpCard>
);

export const rawExtrusionBarHelp = (
  <HelpCard title="Raw Extrusion Bar" width={360}>
    <p>
      Users should not use Genie to price out lengths of raw extrusion bar (typically 4 or 8 ft lengths) and should
      refer to price books for standard tooled extrusions.
    </p>
  </HelpCard>
);

export const laconiaMaterialCostHelp = (
  <HelpCard title="Material Cost" width={360}>
    <p>Material cost = COMEX + MW Premium + Vendor</p>
  </HelpCard>
);

export const customExtrusionProvidedHelp = (
  <HelpCard title="Customer Extrusion Provided" width={480}>
    <p>
      If customer has provided extrusion information in the form of 2D drawing, 3D CAD model, sketch or dimensions, use
      this option to define heat sink specifications.
    </p>
  </HelpCard>
);

export const standardExtrusionRequestedHelp = (
  <HelpCard title="Standard Extrusion Requested" width={480}>
    <p>
      If customer has provided Boyd standard extrusion part number, use this option to select the standard extrusion.
    </p>
    <p>A typical standard part number will take this form: Boyd 6XXXX to 8XXXX.</p>
  </HelpCard>
);

export const nonFlatbackExtrusionHelp = (
  <HelpCard title="Non-flat back Extrusion" width={480}>
    <p>Extrusion is not a flat back, for example max clip or bonded fin base.</p>
    <Space direction="vertical" size={24}>
      <div style={{ margin: 0, padding: 0 }}>
        <p style={{ marginBottom: 0 }}>
          <b>Flatback extrusion</b>
        </p>
        <Image src="/images/flatback-60230.png" width={460} preview={false} />
      </div>
      <div>
        <p style={{ marginBottom: 0 }}>
          <b>Non-flatback extrusion</b>
        </p>
        <Image src="/images/nonflatback-78925.png" width={460} preview={false} />
      </div>
    </Space>
  </HelpCard>
);

export const ambientTemperatureHelp = (
  <HelpCard title="Ambient Temperature" width={360}>
    <p>Heat sink surrounding temperature in degree celcius or degree farenheit.</p>
  </HelpCard>
);

export const operatingAltitudeHelp = (
  <HelpCard title="Operating Altitude" width={360}>
    <p>Height above sea level at which heat sink will operate(meter or feet).</p>
  </HelpCard>
);

export const workingEnvelopeHelp = (
  <HelpCard title="Working Envelope" width={360}>
    <p>
      Heat sink enclosed volume. This working envelope is generally larger than heat sink size but can equal to size of
      heat sink also.
    </p>
    <Image src="/images/working-envelope.png" preview={false} />
  </HelpCard>
);

export const workingEnvelopeWidthHelp = (
  <HelpCard title="Working Envelope Width" width={360}>
    <Image src="/images/working-envelope-width.png" preview={false} />
  </HelpCard>
);

export const workingEnvelopeHeightHelp = (
  <HelpCard title="Working Envelope Height" width={360}>
    <Image src="/images/working-envelope-height.png" preview={false} />
  </HelpCard>
);

export const workingEnvelopeLengthHelp = (
  <HelpCard title="Working Envelope Length" width={360}>
    <Image src="/images/working-envelope-length.png" preview={false} />
  </HelpCard>
);

export const maxTemperatureRiseHelp = (
  <HelpCard title="Max Temperature Rise" width={360}>
    <p>Difference between max heat temperature and ambient air temperature.</p>
  </HelpCard>
);

export const useMaxDPHelp = (
  <HelpCard title="Use Max Pressure Drop" width={360}>
    <p>Use this option to define max pressure drop constraint.</p>
  </HelpCard>
);

export const maxPressureDropHelp = (
  <HelpCard title="Max Pressure Drop" width={360}>
    <p>Max allowable pressure difference between inlet and outlet of heat sink.</p>
  </HelpCard>
);

export const maxthermalResistanceHelp = (
  <HelpCard title="Max Thermal Resistance" width={360}>
    <p>(Max heat sink temperature - Ambient temperature) / Total Power</p>
  </HelpCard>
);

export const totalLoadHelp = (
  <HelpCard title="Total Load" width={360}>
    <p>Total power dissipation of all mounted devices.</p>
  </HelpCard>
);

export const cdfNumberHelp = (
  <HelpCard title="CDF Number" width={720}>
    <p>
      The CDF number is defined by 12 or 13 digits using the part number, cutting option, finish and cut length.
    </p>
    <Image src="/images/cdf-number.png" width={700} preview={false} />
  </HelpCard>
);
