import { useState } from 'react';
import { useRecoilState } from 'recoil';
import Auth from '@aws-amplify/auth';
import { useLocation } from 'wouter';
import { authState } from '../../state/auth';
import { Button, Modal, PageHeader, Row, Col, Menu, Popconfirm } from 'antd';
import { UserOutlined, LockOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { ProfileForm } from './profile-form';
import { SettingsForm } from './settings-form';
import { SecurityForm } from './security-form';
import { useMessage, setMessage } from '../common';

const FormatMessage = () => {
  const message = useMessage();
  return <div style={{ display: 'inline', fontSize: '0.8rem', fontWeight: 400, fontStyle: 'italic' }}>{message}</div>;
};

export const Profile = () => {
  const [, setLocation] = useLocation();
  const [auth, setAuth] = useRecoilState(authState);
  const [menuKey, setMenuKey] = useState('profile');
  const [showModal, setShowModal] = useState(false);

  const handleSignOut = () => {
    Auth.signOut()
      .catch((error) => console.error(error))
      .finally(() => {
        setShowModal(false);
        setLocation('/');
        localStorage.removeItem('verifyCode');
        setAuth(undefined);
      });
  };

  const closeModal = () => {
    setShowModal(false);
    setMessage({ message: '' });
  };

  return (
    <div className="profile-wrapper">
      <Button
        icon={<UserOutlined />}
        type="secondary"
        onClick={() => {
          setMenuKey('profile');
          setShowModal(true);
        }}
        style={{ width: '117px' }}
      >
        Profile
      </Button>
      <Modal visible={showModal} onCancel={closeModal} footer={null} width={640}>
        <PageHeader title={auth.email} extra={<FormatMessage />} />
        <Row>
          <Col span={6}>
            <Menu
              mode="vertical"
              style={{ padding: 0, borderRight: 0 }}
              onClick={({ key }) => {
                setMessage({ message: '' });
                switch (key) {
                  case 'signout':
                    //handleSignOut();
                    break;

                  default:
                    setMenuKey(key);
                }
              }}
              selectedKeys={[menuKey]}
            >
              <Menu.Item key="profile" icon={<UserOutlined />}>
                Profile
              </Menu.Item>
              <Menu.Item key="settings" icon={<SettingOutlined />}>
                Settings
              </Menu.Item>
              <Menu.Item key="security" icon={<LockOutlined />}>
                Security
              </Menu.Item>
              <Menu.Item key="signout" icon={<LogoutOutlined />} style={{ marginTop: '48px' }}>
                <Popconfirm
                  title="Are you sure you want to sign out?"
                  onConfirm={handleSignOut}
                  okText="Sign Out"
                  cancelText="Cancel"
                >
                  Sign Out
                </Popconfirm>
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={18}>
            {menuKey === 'profile' && <ProfileForm auth={auth} />}
            {menuKey === 'settings' && <SettingsForm auth={auth} />}
            {menuKey === 'security' && <SecurityForm auth={auth} />}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
