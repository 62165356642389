import { Suspense } from 'react';
import { ProjectLayout } from '../components/project';

export const Project = ({ projectId }) => (
    <div className="project-layout-wrapper">
      <Suspense fallback={<div style={{ marginTop: 16 }}>Loading...</div>}>
        <ProjectLayout projectId={projectId} />
      </Suspense>
    </div>
);
