import { useRecoilValue } from 'recoil';
import { projectsState } from '../../state/projects';
import { authState, hasGroup } from '../../state/auth';
import { userFilterState } from '../../state/user';

export const useProjects = () => {
  const auth = useRecoilValue(authState);
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const projects = useRecoilValue(projectsState({ userId: auth.username, isAdmin }));
  const userFilter = useRecoilValue(userFilterState);
  const p = projects.filter((project) => (userFilter === 'USER' ? project.userId === auth.username : true));
  // remove duplicate tags (possible for Admin to see tags across all user projects)
  const tags = [...new Set(p.map(({ tags }) => tags || []).flat())].sort();
  return { projects, tags };
};
