import convert from 'convert-units';
import _cloneDeep from 'lodash/cloneDeep';

// 1 inch water = 249. ... Pascal
const INH2OPA = 249.08890833333;
// 1 kg/m to lb/ft
const KGMTOLBFT = 0.671969;

const validUnits = (units) => {
  const lookUp = {
    cfm: 'ft3/min',
    gpm: 'gal/min',
    inH2O: 'inH2O',
    // legacy r-tools api returned in_water
    in_water: 'inH2O',
  };
  return lookUp[units] || units;
};

// convertUnits takes value object with units and returns converted value object with to units
export const convertUnits = ({ value, to }) => ({
  ...value,
  value: convertValueUnits({ value: value.value, from: value.units, to }),
  units: to,
});

// convertValueUnitsTo takes value object with units and returns value only (not object) converted to units
export const convertValueUnitsTo = ({ value, to }) => convertValueUnits({ value: value.value, from: value.units, to });

export const convertValueUnits = ({ value, from, to }) => {
  // nothing to convert
  if (from === to) return value;
  const validFrom = validUnits(from);
  const validTo = validUnits(to);
  // convert library does not include these...
  // should define custom measures for convert-units library
  // https://github.com/convert-units/convert-units
  try {
    // inches water
    if (validFrom === 'Pa' && validTo === 'inH2O') {
      return value / INH2OPA;
    }
    if (validFrom === 'inH2O' && validTo === 'Pa') {
      return value * INH2OPA;
    }
    if (validFrom === 'inH2O' && validTo === 'inH2O') {
      return value;
    }
    // linear density kg/m, lb/ft
    if (validFrom === 'kg/m' && validTo === 'lb/ft') {
      return value * KGMTOLBFT;
    }
    if (validFrom === 'lb/ft' && validTo === 'kg/m') {
      return value / KGMTOLBFT;
    }
    // LFM = ft/min
    if (validFrom === 'LFM') {
      return convert(value/60.0).from('ft/s').to(validTo);
    }
    if (validTo === 'LFM') {
      return 60.0 * convert(value).from(validFrom).to('ft/s');
    }
    return convert(value).from(validFrom).to(validTo);
  } catch (error) {
    console.error(
      `Failed to convert from ${from} to ${to} for ${validFrom}
      )} to ${validTo}`,
    );
    return value;
  }
};

export const convertToMillimeters = ({ value, units }) => convert(value).from(units).to('mm');

const convertUnitsLookup = {
  METRIC: {
    TEMPERATURE: 'C',
    ALTITUDE: 'm',
    DIMENSION: 'mm',
    PRESSURE: 'Pa',
    RESISTANCE: 'C/W',
    VELOCITY: 'm/s',
    FLOWRATE: 'm3/min',
    POWER: 'W',
    MASS: 'kg',
    VOLUME: 'mm3',
    LINEARDENSITY: 'kg/m',
  },
  AMERICAN: {
    TEMPERATURE: 'C',
    ALTITUDE: 'ft',
    DIMENSION: 'in',
    PRESSURE: 'inH2O',
    RESISTANCE: 'C/W',
    VELOCITY: 'LFM',
    FLOWRATE: 'cfm',
    POWER: 'W',
    MASS: 'lb',
    VOLUME: 'in3',
    LINEARDENSITY: 'lb/ft',
  },
};

const traverseObject = (obj, units) => {
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      if (obj[key]?.type && obj[key]?.units && convertUnitsLookup[units][obj[key].type]) {
        obj[key] = convertUnits({ value: obj[key], to: convertUnitsLookup[units][obj[key].type] });
      }
      traverseObject(obj[key], units);
    }
  }
  return obj;
};

// convert all unit values as per convertUnitsLookup
export const convertObjectUnits = (obj, units) => traverseObject(_cloneDeep(obj), units);

export const roundDecimalPlaces = (number, decimalPlaces = 3) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
};