import { dimension, mass, power, position, size, resistance, velocity, flowRate, type, linearDensity } from './units';
import { atom } from 'recoil';
//import { initialFanSpecifications } from './fan-specification';

// Base Module

export const initialBaseModuleState = {
  size,
  material: '',
  weight: mass,
};

export const baseModuleState = atom({
  key: 'baseModuleState',
  default: initialBaseModuleState,
});

// Extrusion Module

export const initialExtrusionModuleState = {
  partId: '0',
  //length: { ...dimension, value: 100.0, units: 'mm' },
  material: 'Al',
  surfaceFinish: { id: 'id', name: '', emissivity: 0.0 },
  weight: { ...mass, value: 0.347, units: 'kg' },
  dimensions: {
    width: { ...dimension, value: 80.0, units: 'mm' },
    height: { ...dimension, value: 35.0, units: 'mm' },
    length: { ...dimension, value: 100.0, units: 'mm' },
  },
  region: [],
  autoCalcWeightPerFeet: true,
  weightPerFeet: { ...linearDensity, value: 7.56, units: 'kg/m'},
  //width: 0.0,
  //height: 0.0,
  class: '',
  stickLength: { ...dimension, value: 2.4511, units: 'm' },
  factory: '',
  vendor: '',
  type: '',
  inventory: 0.0,
  vendorId: '',
  image: '',
  shapeType: 0,
  baseWidth: { ...dimension, value: 80.0, units: 'mm' },
  baseThick: { ...dimension, value: 7.0, units: 'mm' },
  finPitch: { ...dimension, value: 8.667, units: 'mm' },
  finThick: { ...dimension, value: 2.0, units: 'mm' },
  finHeight: { ...dimension, value: 28.0, units: 'mm' },
  hasOffset: false,
  leftOffset: dimension,
  rightOffset: dimension,
  leftThick: dimension,
  rightThick: dimension,
  nonFlatback: false,
  numFins: 10,
  numFinsLeft: 0,
  numFinsRight: 0,
  numOuterFins: 0,
  numInnerFins: 0,
  gap: dimension,
  outerGap: dimension,
  innerGap: dimension,
  areaGain: dimension,
  perimeter: { ...dimension, value: 734.0, units: 'mm' },
  finLayoutStyle: type.finLayoutStyle.numFins,
  specifyHeightBy: type.specifyExtrusionHeightBy.overall,
};

export const extrusionModuleState = atom({
  key: 'extrusionModuleState',
  default: initialExtrusionModuleState,
});

export const initialStandardExtrusionModuleState = {
  ...initialExtrusionModuleState,
  partId: '60095',
};

// HeatSource Module

export const initialHeatSourceModuleState = {
  sources: [
    {
      label: '0',
      power,
      position,
      size,
      rjc: resistance,
      interface: type.interface.none,
      interfaceMaterial: '',
    },
  ],
};

export const heatSourceModuleState = atom({
  key: 'heatSourceModuleState',
  default: initialHeatSourceModuleState,
});

// AirFlow Module

export const initialAirFlowModuleState = {
  airFlowType: type.airflow.naturalConvection,
  orientation: type.naturalConvection.horizontal,
  forcedConvectionType: type.forcedFlow.flowRate,
  velocity,
  flowRate,
  fan: null, //initialFanSpecifications,
  setup: type.flowSetup.push,
  impingePercentage: 0,
};

export const airFlowModuleState = atom({
  key: 'airFlowModuleState',
  default: initialAirFlowModuleState,
});

// Fins Module
