import { atomFamily } from 'recoil';
import { API } from '@aws-amplify/api';
import { listProjects, projectsByUserId } from '../graphql/queries';
import { sortByTimestamp } from '../utils/date';

const getProjects = async ({ nextToken }) => {
  try {
    const response = await API.graphql({
      query: listProjects,
      variables: {
        nextToken,
      },
    });
    return response.data.listProjects;
  } catch (error) {
    console.error(error);
  }
};

const getAllProjects = async () => {
  let response = await getProjects({
    nextToken: null,
  });

  if (response === undefined) return [];

  let { items, nextToken } = response;
  let projects = [...items];

  while (nextToken !== null) {
    const response = await getProjects({
      nextToken,
    });
    projects = [...projects, ...response.items];
    nextToken = response.nextToken;
  }
  return projects;
};

const getUserProjects = async ({ userId, nextToken }) => {
  try {
    const response = await API.graphql({
      query: projectsByUserId,
      variables: {
        userId,
        nextToken,
      },
    });
    return response.data.projectsByUserId;
  } catch (error) {
    console.error(error);
  }
};

const getAllUserProjects = async ({ userId }) => {
  let { items, nextToken } = await getUserProjects({
    userId,
    nextToken: null,
  });

  let projects = [...items];

  while (nextToken !== null) {
    const response = await getUserProjects({
      userId,
      nextToken,
    });
    projects = [...projects, ...response.items];
    nextToken = response.nextToken;
  }
  return projects;
};

// OPTION 1: atomFamily (import { atomFamily } from 'recoil')
// atomFamily and default value set by promise

export const projectsState = atomFamily({
  key: 'projectsState',
  default: ({ userId, isAdmin = false }) =>
    isAdmin
      ? getAllProjects()
          .then((projects) => sortByTimestamp({ items: projects, key: 'updatedAt' }))
          .catch((error) => {
            console.error('Recoil projects state', error);
          })
      : getAllUserProjects({ userId })
          .then((projects) => sortByTimestamp({ items: projects, key: 'updatedAt' }))
          .catch((error) => {
            console.error('Recoil projects state', error);
          }),
});

// OPTION 1: atomFamily (import { atomFamily } from 'recoil')
// atomFamily and atom effects using promise ({setSelf, onSet, trigger, node, resetSelf})
/*

const projectsAtom = atom({
  key: 'projectsAtom',
  default: [],
});

export const projectsState = atomFamily({
  key: 'projectsState',
  default: projectsAtom,
  effects: ({ userId }) => [
    ({ setSelf, onSet }) => {
      const projectsPromise =
        userId === undefined
          ? new DefaultValue()
          : getAllUserProjects({ userId })
              .then((projects) => sortByTimestamp({ items: projects, key: 'updatedAt' }))
              .catch((error) => {
                console.error('Recoil projects state', error);
                return new DefaultValue();
              });

      setSelf(projectsPromise);

      onSet((data) => console.log(data));
    },
  ],
});
*/

/*
  OPTION 2: Atom Effects
  Atom Effects are a new experimental API for managing side-effects and initializing Recoil atoms. They have a variety of useful applications such as state persistence, state synchronization, managing history, logging, &c. They are defined as part of the atom definition, so each atom can specify and compose their own policies.

// OPTION 2: using async/await
export const projectsState = atomFamily({
  key: 'projectsState',
  default: [],
  effects: (userId) => [
    ({ onSet, setSelf }) => {
      const loadProjects = async () => {
        const projects = await getAllProjects({ userId });
        setSelf(projects);
      };
      // load projects
      loadProjects();

      onSet((newValue) => {
        console.log('newValue', newValue);
      });
    },
  ],
});
*/

/*
  OPTION 3: selectorFamily (import { selectorFamily } from 'recoil')
  Selectors can be used as one way to incorporate asynchronous data into the Recoil data-flow graph. Please keep in mind that selectors represent "idempotent" functions: For a given set of inputs they should always produce the same results (at least for the lifetime of the application).

export const projectsState = selectorFamily({
  key: 'projectsState',
  default: [],
  get: (userId) => async ({ get }) => await getAllProjects({ userId }),
});
*/

/*
  OPTION 3 (example): selectorFamily (import { selectorFamily } from 'recoil')
  Selectors can be used as one way to incorporate asynchronous data into the Recoil data-flow graph. Please keep in mind that selectors represent "idempotent" functions: For a given set of inputs they should always produce the same results (at least for the lifetime of the application).
*/
/*
export const projectsState = selectorFamily({
  key: 'projectsState',
  default: projectsAtom,
  get: ({ userId }) => ({ get }) =>
    getAllUserProjects({ userId })
      .then((projects) => sortByTimestamp({ items: projects, key: 'updatedAt' }))
      .catch((error) => {
        console.error('Recoil projects state', error);
      }),
  set: ({ userId }) => ({ set, get }, projects) => {
    console.log(projects)
    set(projectsAtom, projects);
  },
});
*/
