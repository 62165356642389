import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Checkbox, Modal, Row, Form, Input, Space } from 'antd';
import { Page, Text, View, Document, StyleSheet, pdf, Image, Link} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { authState } from '../../../state/auth';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { userState } from '../../../state/user';
import { customersState } from '../../../state/customers';
import { useState } from 'react';
import { type } from '../../../state/units';
import { autoFlycutApplied } from '../../../utils/line-minimum';
import { addActivity } from '../../../state/activity';

const {Item} = Form;
// import ARIAL from '../../../fonts/ARIAL.TTF';
// import ARIALBD from '../../../fonts/ARIALBD 1.TTF';
// import ARIALBI from '../../../fonts/ARIALBI.TTF';

// const registerFont = () => {
//   Font.register({
//     family: 'Arial',
//     fonts: [
//       {
//         src: ARIAL,
//       },
//       {
//         src: ARIALBD,
//         fontWeight: 'bold',
//       },
//       {
//         src: ARIALBI,
//         fontWeight: 'bold',
//         fontStyle: 'italic',
//       }
//     ]
//   })
// };

const autoSelectedNotes = [
  'Final detailed engineering review and DFM to be completed before order acceptance.',
  'Machining tolerances are quoted as the greater of customer print tolerance or +/- 0.010” [+/-0.25mm] lead-in and +/- 0.005” [0.13mm] feature to feature non-cumulative.',
  'Cut off length quoted as the greater of customer print tolerance or +/- 0.015 [0.38mm].',
  'Quoting as standard extrusion tolerance on all extruded features with Aluminum Association standard mill finish.',

  'Quoted as a wash/etch finish.',
  'Quoted as Standard Chem Film - RoHS Compliant AavShield 3.',
  'Quoted as Chromate - Non-RoHS finish per MIL-DTL-5541 Type I.',
  'Quoted as Black Anodize finish per intent of MIL-A-8625 TYPE II CLASS 2.',
  'Quoted as Clear Anodize finish per intent of MIL-A-8625 TYPE II CLASS 1.',
  'Flatness to be flycut to 0.001” inch per inch [0.025mm/25mm].',
  'A minimum amount of material may need to be machined from base to maintain flatness.',
  'Part marking to be Boyd’s standard permanent ink stamp marking method.',
  'Loose, Small, or Partial Fins in the Area of the Fin Counter-Bores may need to be removed at Boyd’s discretions.',
];

const userSelectedNotes = [
  'Class C - low demand/low usage material. Quoted with material in stock but depending on stock at time of order a set up charge may apply.',
  'Quoted without customer specifications noted on drawing. Building to specifications may require additional cost review.',
  'Thermal Performance per drawing note is considered as a reference only. If actual performance results are required additional engineering costs would need to be quoted as a separate line item.',
  'This a study quote only. There are design concerns from a manufacturing stand point that will need to be agreed on before a final quote can be completed.',
  'This is being quoted to CAD models and Concept drawings. Any major changes are subject to additional cost review.',
  'There is manufacturing concerns with the supplied drawing and quotes to the marked up drawing as a solution to customer requirements.'
];

const styles = StyleSheet.create({
  page: {
    paddingTop: 125,
    paddingBottom: 65,
    paddingHorizontal: 40,
  },

  pageHeader: {
    top: 10,
    left: 40,
    right: 40,
    position: 'absolute',
  },

  headerSection: {
    fontSize: 9,
    margin: 0,
    backgroundColor: '#DEE5EC',
    padding: 5,
    // fontFamily: 'Arial',
  },

  section: {
    fontSize: 9,
    margin: 0,
    backgroundColor: '#DEE5EC',
    padding: 5,
    // fontFamily: 'Arial',
    marginTop: 10,
  },

  table: {
    display: 'table',
    width: 'auto',
  },

  tableHalfWidthColumn: {
    width: '50%',
  },

  tableFullWidthColumn: {
    width: '100%',
  },

  image: {
    width: 140,
    height: 60,
    marginTop: 2,
  },

  text: {
    fontSize: 8,
    marginTop: 0,
    marginLeft: 0,
    // fontFamily: 'Arial',
  },

  boldText: {
    fontSize: 8,
    marginTop: 0,
    marginLeft: 0,
    // fontFamily: 'Arial',
    // fontWeight: 'bold',
  },

  labelText: {
    width: '30%',
    fontSize: 8,
    marginVertical: 3,
    // fontFamily: 'Arial',
    paddingRight: 10,
  },
  valueText: {
    width: '70%',
    fontSize: 8,
    marginVertical: 3 ,
    // fontFamily: 'Arial',
  },
  noteHeader: {
    width: '100%',
    fontSize: 8,
    marginVertical: 3,
    // fontFamily: 'Arial',
    paddingRight: 10,
  },
  noteText: {
    width: '100%',
    fontSize: 8,
    marginVertical: 3,
    // fontFamily: 'Arial',
    paddingRight: 10,
    marginLeft: 10,
  },

  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
 
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footerText: {
    position: 'absolute',
    fontSize: 8,
    bottom: 40,
    left: 40,
    right: 40,
    // fontFamily: 'Arial',
    textAlign: 'left',
  },
  footerTextBI: {
    position: 'absolute',
    fontSize: 8.5,
    // fontStyle: 'italic',
    // fontWeight: 'bold',
    // fontFamily: 'Arial',
    bottom: 20,
    left: 40,
    right: 40,
    textAlign: 'center',
  },
});

const AddRow = ({ label, value }) => (
  <View style={styles.tableRow}>
    <Text style={styles.labelText}>{label}</Text>
    <Text style={styles.valueText}>{value}</Text>
  </View>
);

const CostingPDFReport = ({ project, userEmail, user, customerName, autoSelectedNotesIds, userSelectedNotesIds, customNote }) => {
  const customNoteLength = customNote ?  1 : 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        
        <View style={styles.pageHeader} fixed>
          <Text style={styles.headerSection}>QUOTATION</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableHalfWidthColumn}>
                <Image style={styles.image} src="/aavid-logo.png"/>
                <Text style={{ ...styles.text, marginLeft: 20}}>www.boydcorp.com</Text> 
              </View>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableFullWidthColumn}> 
                      <Image style={{ ...styles.image, textAlign: 'right', marginLeft: 'auto', height: 40 }} src="/boyd-logo.png"/>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableHalfWidthColumn}>
                      <Text style={styles.boldText}>Manufacturing Address</Text>
                      <Text style={styles.text}>1 Aavid Circle {'\n'} Laconia, NH 03246 {'\n'} +1 (603) 528-3400 {'\n'} Cage Code: 1N6F3</Text> 
                    </View>
                    <View style={styles.tableHalfWidthColumn}>
                      <Text style={styles.boldText}>Headquarters Address</Text>
                      <Text style={styles.text}>5960 Inglewood Dr. Ste 115 {'\n'} Pleasanton, CA 94588 {'\n'} +1 (888) 244-6931</Text>
                    </View>
                  </View>    
                </View>  
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.section}>General Information</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Today's Date"} value={dayjs(project.quoteSpecs.quoteHeader.date).format('MMMM DD, YYYY')}/>
                <AddRow label={"Expiration Date"} value={dayjs(project.quoteSpecs.quoteHeader.date).add(30, 'day').format('MMMM DD, YYYY')}/>
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Quote Number"} value={project.quoteSpecs.quoteHeader.quoteNo ? project.quoteSpecs.quoteHeader.quoteNo : 'NA' }/>
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.section}>Prepared For</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Customer Name"} value={customerName} />
                <AddRow label={"Customer Address"} value={project.quoteSpecs.quoteHeader.customerAddress}/>
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Customer Contact"} value={project.quoteSpecs.quoteHeader.customerContact} />
                <AddRow label={"Customer Email"} value={<Link src={`mailto:${project.quoteSpecs.quoteHeader.customerEmail}`}>{project.quoteSpecs.quoteHeader.customerEmail}</Link>} />
                <AddRow label={"Customer Phone"} value={project.quoteSpecs.quoteHeader.customerPhone} />
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.section}>Boyd Corporation Information</Text>
        { project.quoteSpecs.quoteHeader.useUserContactAsSales ? (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={"Sales Contact"} value={user.name} />
                  <AddRow label={"Sales Phone"} value={user.phone} />
                  <AddRow label={"Sales Email"} value={<Link src={`mailto:${userEmail}`}>{userEmail}</Link>} />
                </View>
              </View>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={"Internal Contact"} value={user.name} />
                  <AddRow label={"Internal Phone"} value={user.phone} />
                  <AddRow label={"Internal Email"} value={<Link src={`mailto:${userEmail}`}>{userEmail}</Link>} />
                </View>
              </View>
            </View>
          </View>
          ) : (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={"Sales Contact"} value={project.quoteSpecs.quoteHeader.salesContact} />
                  <AddRow label={"Sales Phone"} value={project.quoteSpecs.quoteHeader.salesPhone} />
                  <AddRow label={"Sales Email"} value={<Link src={`mailto:${project.quoteSpecs.quoteHeader.salesEmail}`}>{project.quoteSpecs.quoteHeader.salesEmail}</Link>} />
                </View>
              </View>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={"Internal Contact"} value={project.quoteSpecs.quoteHeader.salesContact} />
                  <AddRow label={"Internal Phone"} value={project.quoteSpecs.quoteHeader.salesPhone} />
                  <AddRow label={"Internal Email"} value={<Link src={`mailto:${project.quoteSpecs.quoteHeader.salesEmail}`}>{project.quoteSpecs.quoteHeader.salesEmail}</Link>} />
                </View>
              </View>
            </View>
          </View>
          )
        }

        <Text style={styles.section}>Quote Details</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Customer Part No."} value={project.quoteSpecs.quoteHeader.customerPartNumber ? project.quoteSpecs.quoteHeader.customerPartNumber : 'NA' } />
                <AddRow label={"Boyd ID"} value={project.activeDesignId === 1 ? project.designs[1].extrusion.partId : 'NA'} />
                <AddRow label={"Manufacturing  Location"} value={"Laconia, NH"} />
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"MOQ"} value={project.designs[project.activeDesignId].costEstimates[0].quantity} />
                <AddRow label={"UOM"} value={'EACH'} />
                <AddRow label={"Production Lead Time"} value={"Inquire at time of order"} />
                <AddRow label={"Ship Location"} value={"Laconia, NH"} />
                <AddRow label={"Country of Origin"} value={"United States"} />   
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.section}>{`QTY & Price Break Line Items`}</Text>
        
          <View style={styles.table}>
          {project.designs[project.activeDesignId].costEstimates.filter(q => (q.sellPrice * q.quantity <= 100000.0) && !q.isLowerExtendedPrice).map((q, index) => (
            <View key={nanoid(10)} style={styles.tableRow}>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={`Quantity ${index + 1}`} value={q.quantity} />
                </View>
              </View>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={`Price Break ${index + 1}`} value={`USD ${(q.sellPrice).toFixed(2)}`} />
                  <AddRow label={`Extended Total QTY ${index + 1}`} value={`USD ${(q.sellPrice * q.quantity).toFixed(2)}`} />
                </View>
              </View>
            </View>     
          ))}
        </View>

        <Text style={styles.section}>{`Notes & Exceptions`}</Text>
        <Text style={styles.noteHeader}>{'Boyd Corp quotes with the following Notes/Exceptions:'}</Text>
        { customNote ? (
          <View style={styles.table}> 
            <View key={`notes-${nanoid(10)}`} style={styles.tableRow}>
              <View style={styles.tableFullWidthColumn}>
                <Text style={styles.noteText}>{`${1}. ${customNote}`}</Text> 
              </View>
            </View>
          </View>
          ) : (
            <></>
          )}
        
        <View style={styles.table}>
          {
            autoSelectedNotesIds.sort((a, b) => a - b).map((noteId, index) => (
              <View key={`notes-${nanoid(10)}`} style={styles.tableRow}>
                <View style={styles.tableFullWidthColumn}>
                  <Text style={styles.noteText}>{`${index + 1 + customNoteLength}. ${autoSelectedNotes[noteId]}`}</Text> 
                </View>
              </View>
            ))
          }
        </View>

        <View style={styles.table}>
          {
            userSelectedNotesIds.sort((a, b) => a - b).map((noteId, index) => (
              <View key={`notes-${nanoid(10)}`} style={styles.tableRow}>
                <View style={styles.tableFullWidthColumn}>
                  <Text style={styles.noteText}>{`${index + 1 + customNoteLength + autoSelectedNotesIds.length}. ${userSelectedNotes[noteId]}`}</Text> 
                </View>
              </View>
            ))
          }
        </View>
        
        
        <Text style={styles.section}>Non-Recurring Service Charges (NRSU)</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"NRSU1"} value={"FAI"} />
                <AddRow label={"NRSU1 Notes"} value={'-'} />
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"NRSU1 Charge"} value={`USD ${project.designs[project.activeDesignId].costEstimates[0].faiCost.toFixed(2)}`} />
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"NRSU2"} value={"Engineer Services"} />
                <AddRow label={"NRSU2 Notes"} value={'NRE'} />
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"NRSU2 Charge"} value={`USD ${(project.designs[project.activeDesignId].costEstimates[0].nreCost - project.designs[project.activeDesignId].costEstimates[0].faiCost).toFixed(2)}`} />
              </View>
            </View>
          </View>
        </View>
        

        <Text style={styles.section}>{`Terms & Conditions`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Payment Terms"} value={"Net 30"} />
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.text}>No change to the remittance address and tax ID information that you have on file.</Text>
        {/* <Text style={styles.boldText}>FAI: First Article Requirements will be at an additional charge if required.</Text> */}
        <Text style={styles.text}>Complete Certification Package $250.00 at the time of the order upon request. Cost increases to $500.00 if requested after product shipment.</Text>
        <Text style={styles.text}>Pricing based on shipment lot size, not quantity ordered.</Text>
        <Text style={styles.text}>Any purchase order for this quotation is Non-Cancellable and Non-Returnable.</Text>
        {/*<Text style={styles.text}>Standard extrusion tolerances are quoted on all extruded features.</Text>
        <Text style={styles.boldText}>Shipping Terms:</Text>
        <Text style={styles.text}>Ship date will be confirmed at the time of your order based on current material availability and capacity.</Text>
        <Text style={{...styles.boldText }}>Please sign off on exceptions when placing order by signing quote or an email approval to your Account Manager.</Text>
        <Text style={{...styles.text, fontSize:10}}>Modified pricing at time of PO placement might apply due to tariffs or surcharges, and will be based on commodity
          index fluctuations, tariff regulations and/or increased freight costs.</Text> */}

        {/*<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed /> */}
        <Text style={styles.footerText} fixed>
          We appreciate your interest in Boyd Corporation and thank you for the opportunity to help you solve your design challenges. This quotation and
          any related purchase orders received are governed by the Terms and Conditions found at <Link style={styles.url} src="https://www.boydcorp.com/terms-and-conditions-of-sale"> https://www.boydcorp.com/terms-and-conditions-of-sale </Link>
        </Text>
        <Text style={styles.footerTextBI} fixed>
          One Company, Many Solutions
        </Text>
      </Page>
    </Document>
  )
};

const  NotesModal = ({visible, project, updateProject, okCallback, cancelCallback}) => {
  const [userCheckedItemsIds, setUserCheckedItemsIds] = useState([]);
  const [autoCheckedItemsIds, setAutoCheckedItemsIds] = useState([]);
  const [customNote, setCustomNote] = useState('');
  const [quoteNo, setQuoteNO] = useState(project.quoteSpecs.quoteHeader.quoteNo);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // Remove auto selected notes ids from current selection
    // var alreadyCheckedItemsIds = checkedItemsIds.filter(itemId => !(autoSelectedNotesIds.includes(itemId)));

    // alreadyCheckedItemsIds.push(engineeringReviewNoteId); // Always select engineering review note

    var autoSelectedNotesIds = [...Array(4).keys()];

    // Select finish note
    if (project.quoteSpecs.finish.style === type.finishStyle.etchwash) {
      autoSelectedNotesIds.push(4);
    } else if (project.quoteSpecs.finish.style === type.finishStyle.aavshield3) {
      autoSelectedNotesIds.push(5);
    }  else if (project.quoteSpecs.finish.style === type.finishStyle.chromate) {
      autoSelectedNotesIds.push(6);
    }  else if (project.quoteSpecs.finish.style === type.finishStyle.anodize) {
      autoSelectedNotesIds.push(7);
    } else if (project.quoteSpecs.finish.style === type.finishStyle.anodizeclear) {
    autoSelectedNotesIds.push(8);
  }

    // console.log((hsWidth > 6 && (hasPocketVolume || hasBlindHoles)));

    if (project.quoteSpecs.cnc.flycut || project.quoteSpecs.cnc.flycutFlatnessCallout || project.quoteSpecs.cnc.flycutRoughnessCallout ||
      (autoFlycutApplied(project))) {
      autoSelectedNotesIds.push(9, 10);
    }

    // Select ink marking style note
    if(project.quoteSpecs.marking === type.markStyle.ink) {
      autoSelectedNotesIds.push(11);
    }

    if(project.quoteSpecs.counterBores.cbores > 0) {
      autoSelectedNotesIds.push(12);
    }

    setAutoCheckedItemsIds(autoSelectedNotesIds);
    setQuoteNO(project.quoteSpecs.quoteHeader.quoteNo);

  }, [visible]);

  const onChange = (checkedValues) => {
    setUserCheckedItemsIds( [...checkedValues] )
  }

  return(
    <Modal
      title={`Notes and Exceptions`}
      visible={visible}
      closable={false}
      centered
      width={900}
      bodyStyle={{ height: '600px', overflowY: 'auto', paddingTop: 4, paddingBottom: 0}}
      footer={[
        <Button key="costing-notes-modal-cancel" onClick={cancelCallback}>Cancel</Button>,
        <Button key="costing-notes-modal-generate-pdf" type="primary" onClick={(e) => okCallback(autoCheckedItemsIds, userCheckedItemsIds, customNote)}>Generate PDF</Button>
      ]}
    >   
      <Form name="Generate-customer-costing-pdf-modal">
        <Space style={{ alignItems: 'flex-start'}} size={32}>
          <Item
            label="Opportunity number"
            style ={{marginBottom: 0.0}}
          >
            <Input 
              style={{ width: 150 }} 
              value={quoteNo}
              onChange={(e) => {setQuoteNO(e.target.value); updateProject({path: 'quoteSpecs.quoteHeader.quoteNo', value: e.target.value})}}
            />
          </Item>
          <Item 
            label="Custom Note"
            style ={{marginBottom: 0.0}}
          >
            <Input.TextArea 
              placeholder="Enter custom note" 
              style={{ width: 480, height: 32 }} 
              value={customNote} 
              onChange={(e) => setCustomNote(e.target.value)}
            />
          </Item>
        </Space>
      </Form>
      <h4 style={{marginBottom: 0, marginTop:7}}>Automatic selections</h4>
      <Checkbox.Group style={{ width: '100%' }} value={autoCheckedItemsIds} >
        {autoSelectedNotes.map((note, id) => 
          <Row key={`costing-auto-notes-${id}`}>
            <Checkbox className={"costing-notes-checkbox"} value={id} disabled={true}>{note}</Checkbox>
          </Row>  
        )}
      </Checkbox.Group>
      <h4 style={{marginBottom: 0, marginTop:7}}>User selections</h4>
      <Checkbox.Group style={{ width: '100%' }} value={userCheckedItemsIds} onChange={onChange}>
        {userSelectedNotes.map((note, id) => 
          <Row key={`costing-user-notes-${id}`}>
            <Checkbox value={id}>{note}</Checkbox>
          </Row>  
        )}
      </Checkbox.Group>  
    </Modal>
  )
}


export const GenerateCustomerQuotePDF = ({ project, updateProject }) => {
  const [showNotes, setShowNotes] = useState(false)
  const auth = useRecoilValue(authState);
  const user = useRecoilValue(userState(auth.username));
  const customers = useRecoilValue(customersState(null));
  const customer = customers.filter(({ id }) => id === project.customerId); 
  const userEmail = auth.email;
  // Get user name from User email
  // const emailPrefix = userEmail.split('@')[0].split('.').map(n => n ? n[0].toUpperCase() + n.toLowerCase().slice(1): '');
  // const userName = emailPrefix.join(' ');

  // useEffect(() => {
  //   registerFont();
  //   console.log("Arial font registered")  
  // }, []);

  const generatePDF = async(autoCheckedNotesIds, userCheckedNotesIds, customNote) => {
    setShowNotes(false)
    const doc = <CostingPDFReport project={project} userEmail={userEmail} user={user} customerName={customer.length === 1 ? customer[0].name : 'NA' } autoSelectedNotesIds={[...autoCheckedNotesIds]} userSelectedNotesIds={[...userCheckedNotesIds]} customNote={customNote}/>;
    const asPdf = pdf([]); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, project.quoteSpecs.quoteHeader.quoteNo ? project.quoteSpecs.quoteHeader.quoteNo+'.pdf' : 'costing.pdf');
    addActivity({ 
      activity: type.activityType.prjQuote, 
      userEmail: userEmail, 
      userId: project.userId,
      projectName: project.name,
      projectId: project.id,
      customer: customer.length === 1 ? customer[0].name : '',
      customerId: project.customerId,
     });
  }

  return (
    <>
    <Button
      type="secondary"
      onClick={async () => {
        setShowNotes(true)   
      }}
      disabled={!user.qualified}
    >
      Customer Quote PDF
    </Button>
    <NotesModal visible={showNotes} project={project} updateProject={updateProject} cancelCallback={() => setShowNotes(false)} okCallback={generatePDF}> 
    </NotesModal>
  </>
  );
};
