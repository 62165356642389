import { useState, Suspense } from 'react';
import { Form } from 'antd';
import { LayoutWorkspace, LayoutStepPanel } from '../../layout';
import { PerformancePanel0 } from './performance-panel-0';
import { PerformancePanel1 } from './performance-panel-1';
import { PerformancePanel2 } from './performance-panel-2';
import { PerformanceEstimate } from './performance-estimate';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const steps = [
  { step: 0, title: 'Heat Sources and Flow Conditions' },
  { step: 1, title: 'Simulation' },
];

export const ProjectPerformance = ({ project, updateProject, previousTab, nextTab, setActiveTab, show }) => {
  const [form] = Form.useForm();
  const [selectedStep, setSelectedStep] = useState(0);
  const [panelSize, setPanelSize] = useState({ left: 0, right: 0 });

  // extract the field name (path) and value
  const onItemChange = (value) => updateProject({ path: value[0].name[0], value: value[0].value });

  return (
    <LayoutWorkspace
      steps={steps}
      selectedStep={selectedStep}
      setSelectedStep={setSelectedStep}
      previousTab={previousTab}
      nextTab={nextTab}
      setActiveTab={setActiveTab}
      left={
        <Form form={form} name="performance" layout="vertical" onFieldsChange={onItemChange} {...layout}>
          <LayoutStepPanel show={show && selectedStep === 0}>
            <div style={{ display: 'grid', gap: '8px', gridTemplateColumns: 'auto 1fr' }}>
              <div style={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                <PerformancePanel1 project={project} />
                <PerformanceEstimate project={project} />
              </div>
              <PerformancePanel0 project={project} updateProject={updateProject} panelSize={panelSize} />
            </div>
          </LayoutStepPanel>

          <LayoutStepPanel show={show && selectedStep === 1}>
            <Suspense fallback={<div>Loading...</div>}>
              <PerformancePanel2 project={project} />
            </Suspense>
          </LayoutStepPanel>
        </Form>
      }
      expandLeft={true}
      showLeft={true}
      onChange={setPanelSize}
    />
  );
};

/*
      right={
        {/*<div style={{ display: 'grid', gap: '8px' }}>
          <PerformanceEstimate project={project} />
          <AmplifyS3Album path={`${project.id}/`} />
      </div>
      }
  */
