import { Card, InputNumber, Popover, Radio, Space, Form } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Measurement } from '../../common/measurement';
import { flowRate, type, velocity } from '../../../state/units';
import { airFlowTypeHelp, orientationHelp, flowSetupHelp } from '../../common/tooltip';

const { Item } = Form;

export const PerformancePanel1 = ({ project }) => {
  return (
    <Card title="Flow Conditions" bordered={false}>
      <Item label="Air flow type" style={{marginBottom: 0.0}}>
        <Space>
          <Item name="airFlow.airFlowType" initialValue={project.airFlow.airFlowType}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={type.airflow.naturalConvection}>Natural convection</Radio.Button>
              <Radio.Button value={type.airflow.forceConvection}>Forced convection</Radio.Button>
            </Radio.Group>
          </Item>
          <Popover placement="right" content={airFlowTypeHelp}>
            <Item>
              <QuestionCircleOutlined />
            </Item>
          </Popover>
        </Space>
      </Item>
      <Item label="Orientation" hidden={project.airFlow.airFlowType !== type.airflow.naturalConvection} style={{marginBottom: 0.0}}>
        <Space>
          <Item name="airFlow.orientation" initialValue={project.airFlow.orientation}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={type.naturalConvection.horizontal}>Horizontal</Radio.Button>
              <Radio.Button value={type.naturalConvection.vertical}>Vertical</Radio.Button>
            </Radio.Group>
          </Item>
          <Popover placement="right" content={orientationHelp}>
            <Item>
              <QuestionCircleOutlined />
            </Item>
          </Popover>
        </Space>
      </Item>
      <Item
        label="Forced flow type"
        name="airFlow.forcedConvectionType"
        initialValue={project.airFlow.forcedConvectionType}
        hidden={project.airFlow.airFlowType !== type.airflow.forceConvection}
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={type.forcedFlow.velocity}>Velocity</Radio.Button>
          <Radio.Button value={type.forcedFlow.flowRate}>Flow rate</Radio.Button>
        </Radio.Group>
      </Item>
      {project.airFlow.airFlowType === type.airflow.forceConvection && (
        <>
          <Item
            label="Velocity"
            name="airFlow.velocity"
            initialValue={project.airFlow.velocity}
            hidden={project.airFlow.forcedConvectionType !== type.forcedFlow.velocity}
          >
            <Measurement.FlowVelocity minimum={ velocity }/>
          </Item>
          <Item
            label="Flow rate"
            name="airFlow.flowRate"
            initialValue={project.airFlow.flowRate}
            hidden={project.airFlow.forcedConvectionType !== type.forcedFlow.flowRate}
          >
            <Measurement.FlowRate minimum={ flowRate }/>
          </Item>
          <Item label="Flow setup" style={{marginBottom: 0.0}}>
            <Space>
              <Item name="airFlow.setup" initialValue={project.airFlow.setup}>
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={type.flowSetup.push}>Push</Radio.Button>
                  <Radio.Button value={type.flowSetup.impinge}>Impinge</Radio.Button>
                </Radio.Group>
              </Item>
              <Popover placement="right" content={flowSetupHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>
          <Item
            label="Fan Heatsink coverage (%)"
            name="airFlow.impingePercentage"
            initialValue={project.airFlow.impingePercentage}
            hidden={project.airFlow.setup !== type.flowSetup.impinge}
          >
            <InputNumber min={0}/>
          </Item>
        </>
      )}
    </Card>
  );
};
