import { Card, Image } from 'antd';
import { LayoutHome } from '../components/layout';
import { SignInAuth } from '../components/auth';

export const Home = () => {
  return (
    <LayoutHome>
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px', justifyItems: 'center', maxWidth: 920 }}
      >
        <div>
          <Card
            cover={<Image src="/images/genie-heatsink-login.png" preview={false} />}
            style={{
              maxWidth: 420,
              height: '460px',
              borderRadius: '6px',
              boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.15)',
              padding: '16px',
            }}
          >
            <p>
              Boyd Genie is an online heat sink design tool that allows for rapid quoting, thermal modeling and
              comparison of multiple heat sink constructions of manufacturable heat sink designs.
            </p>
          </Card>
        </div>
        <div>
          <SignInAuth />
        </div>
      </div>
    </LayoutHome>
  );
};
