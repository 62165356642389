import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Radio } from 'antd';
import { AppstoreOutlined, BarsOutlined, PlusOutlined } from '@ant-design/icons';
import { hasGroup, authState } from '../state/auth';
import { customersState } from '../state/customers';
import { CustomerCard, CustomerModal } from '../components/customer';
import { LayoutCards } from '../components/layout';
import { AutoSaveHeader } from '../components/common';

const CustomersList = ({ userId, editCustomer }) => {
  const customers = useRecoilValue(customersState(null));
  return customers.length === 0 ? (
    <div>No customers</div>
  ) : (
    customers.map((customer) => (
      <CustomerCard key={`customer-${customer.id}`} customer={customer} editCustomer={editCustomer} />
    ))
  );
};

export const Customers = () => {
  const auth = useRecoilValue(authState);
  const isAdmin = useRecoilValue(hasGroup('ADMIN'));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [layout, setLayout] = useState('CARDS');

  const editCustomer = ({ id }) => {
    setCustomerId(id);
    setIsModalVisible(true);
  };

  // todo: switch between tiles and list
  const toggleLayout = (event) => setLayout(event.target.value);

  const Header = () => (
    <>
      <AutoSaveHeader title="Customers" />
      <div />
      <div />
      <div>
        <Radio.Group onChange={toggleLayout} value={layout}>
          <Radio.Button value="CARDS">
            <AppstoreOutlined />
          </Radio.Button>
          <Radio.Button value="LIST">
            <BarsOutlined />
          </Radio.Button>
        </Radio.Group>
      </div>
      {isAdmin && (
        <div>
          <Button icon={<PlusOutlined />} onClick={editCustomer}>
            New Customer
          </Button>
        </div>
      )}
    </>
  );

  return (
    <>
      <LayoutCards header={<Header />}>
        <CustomersList userId={auth.username} editCustomer={editCustomer} />
      </LayoutCards>
      <CustomerModal customerId={customerId} visible={isModalVisible} onCancel={() => setIsModalVisible(false)} />
    </>
  );
};
