import {atom, atomFamily, DefaultValue, selector} from 'recoil';
import { API } from '@aws-amplify/api';
import _find from 'lodash/find';
import { getUser } from '../graphql/queries';
import { createUser, updateUser } from '../graphql/mutations';
import { setMessage } from '../components/common';
import { type } from './units';


export const userFilterState = atom({
  key: 'userFilterState',
  default: 'USER',
});


export const getSelectedUser = async ({ id }) => {
  if (id === undefined) return null;
  try {
    const response = await API.graphql({
      query: getUser,
      variables: { id },
    });
    return response.data.getUser;
  } catch (error) {
    console.error(error);
    throw new Error('Error loading user');
  }
};

export const createUpdateUser = async ({ createdAt, input }) => {
  setMessage({ message: 'SAVING' });
  const isNew = createdAt === undefined;
  try {
    const response = await API.graphql({
      query: isNew ? createUser : updateUser,
      variables: { input },
    });
    setMessage({ message: 'SAVED' });
    return isNew ? response.data.createUser : response.data.updateUser;
  } catch (error) {
    setMessage({ message: 'ERROR' });
    console.error(error);
    throw new Error('Error updating user');
  }
};

// user settings (stored as JSON)
// feature flags are defined like this [{ id: 'featureFlagName', value: false }, ...]
// and will be displayed in the
const defaultSettings = {
  flags: [],
  tags: [],
};

// remove flags that are not in defaults
const validateSettings = (settings) => {
  let validSettings = {};
  Object.keys(defaultSettings).forEach((key, value) => (validSettings[key] = settings[key] || defaultSettings[key]));
  // keep flags in sync (added, removed)
  if (defaultSettings.flags) {
    const validFlags = defaultSettings.flags.map((flag) => _find(settings.flags, ({ id }) => id === flag.id) || flag);
    return { ...validSettings, flags: validFlags };
  }
  return validSettings;
};

// user id = undefined for NEW user
export const initialUserState = {
  type: type.userType.user,
  name: '',
  company: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  country: { value: 'US', label: 'United States' },
  qualified: false,
  settings: defaultSettings,
};

export const userState = atomFamily({
  key: 'userState',
  default: initialUserState,
  effects: (userId) => [
    ({ setSelf, onSet }) => {
      const userPromise = getSelectedUser({ id: userId })
        .then((user) => {
          if (user === null) return { id: userId, ...initialUserState };
          try {
            return { ...user, settings: validateSettings(JSON.parse(user.settings)) };
          } catch (error) {
            console.error('Settings parse error', error);
            return { ...user, settings: { ...defaultSettings } };
          }
        })
        .catch((error) => {
          console.error('Recoil user state initialize', error);
          return new DefaultValue();
        });

      setSelf(userPromise);

      const updateUserPromise = ({ createdAt, updatedAt, owner, projects, settings, ...input }) => {
        const settingsJSON = JSON.stringify(settings);
        return createUpdateUser({ createdAt, input: { id: userId, ...input, settings: settingsJSON } });
      };

      onSet(async (data) => {
        const { settings, ...rest } = await updateUserPromise(data);
        setSelf({ ...rest, settings: validateSettings(JSON.parse(settings)) });
      });
    },
  ],
});

export const isVerified = selector({
  key: 'isVerified',
  get: ({ get }) => (userId) => get(userState(userId)) !== undefined,
});
