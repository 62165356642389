import { Popconfirm, Table, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { type } from '../state/units';
import { formatDate } from '../utils/date';
import { ProjectTagsModal } from '../components/project/project-tags-modal';
import { ProjectShareModal } from '../components/project/project-share-modal';

const { Text } = Typography;

// https://www.freecodecamp.org/news/descending-order-vs-ascending-order-what-does-it-mean/
const alphaSort = (a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base', ignorePunctuation: true });

const columns = ({ customers, selectProject, editProject, copyProject, deleteProject, tags }) => [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    sorter: alphaSort,
    render: (name, { id }) => (
      <div className="link" onClick={() => selectProject({ id })}>
        {name}
      </div>
    ),
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    width: 250,
    render: (description) => (
      <Text style={{ width: 250 }} ellipsis={{ tooltip: description }}>
        {description}
      </Text>
    ),
  },
  {
    key: 'customerId',
    title: 'Customer',
    dataIndex: 'customerId',
    render: (customerId) => {
      const customer = customers.filter(({ id }) => id === customerId);
      return customer.length === 1 ? <div>{customer[0].name}</div> : <div style={{ color: '#e00' }}>Unassigned</div>;
    },
  },
  {
    key: 'units',
    title: 'Units',
    dataIndex: 'units',
    render: (units) => (units === type.system.metric ? 'Metric' : 'Mixed'),
  },
  {
    key: 'createdAt',
    title: 'Created',
    dataIndex: 'createdAt',
    width: 110,
    sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
    render: (createdAt) => formatDate(createdAt),
  },
  {
    key: 'actions',
    title: 'Actions',
    align: 'center',
    width: 100,
    render: (_, { id }) => {
      return (
        <div style={{ display: 'flex', gap: 16, justifyContent: 'center' }}>
          <EditOutlined onClick={() => editProject({ id })} />
          <ProjectTagsModal projectId={id} showIcon={true} showButton={false} tags={tags} />
          <ProjectShareModal projectId={id} showIcon={true} showButton={false} />
          <CopyOutlined onClick={() => copyProject({ id })} />
          <Popconfirm
            title="Are you sure to delete this project?"
            onConfirm={() => deleteProject({ id })}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      );
    },
  },
];

export const ProjectsList = ({
  customers,
  projects,
  selectProject,
  editProject,
  copyProject,
  deleteProject,
  editProjectTags,
  tags,
}) => {
  return projects.length === 0 ? (
    <div>No projects</div>
  ) : (
    <Table
      size="small"
      columns={columns({ customers, selectProject, editProject, copyProject, deleteProject, editProjectTags, tags })}
      dataSource={projects}
      pagination={false}
      bordered
      rowKey={(record) => record.id}
    />
  );
};
