import { type } from "../state/units";
import { convertValueUnitsTo } from "./unit-conversion";

export const dimensionString = ({ value, units } = { value: 0, units: undefined }) =>
  units === undefined ? '-' : `${parseFloat(value.toFixed(3)).toString()} ${units}`;

const dimensionStringWithLabel = ({ value, units } = { value: 0, units: undefined }, suffix) =>
  units === undefined ? '-' : `${parseFloat(value.toFixed(3)).toString()} ${units} (${suffix})`;

export const extrusionDims = (project, designId) => {
  if (designId === undefined) {
    designId = project.activeDesignId;
  }
  const { width, height, length } = project.designs[designId].extrusion.dimensions;
  const w = dimensionStringWithLabel(width, 'w');
  const h = dimensionStringWithLabel(height, 'h');
  const l = dimensionStringWithLabel(length, 'l');
  return `${w} x ${h} x ${l}`;
};

export const extrusionInfo = (project) => {
  const designId = project.activeDesignId;
  const extrusionType = { 0: 'Customer', 1: 'Standard', 2: 'Optimal', 3: 'Custom' }[designId];
  const { width, height, length } = project.designs[designId].extrusion.dimensions;
  const w = dimensionStringWithLabel(width, 'w');
  const h = dimensionStringWithLabel(height, 'h');
  const l = dimensionStringWithLabel(length, 'l');
  const partID = designId === 1 ? ' - ' + project.designs[designId].extrusion.partId : '';
  return `${extrusionType}${partID}: ${w} x ${h} x ${l}`;
};

export const customerExtrusionSpecs = (project) => {
  const extrusion = project.designs[0].extrusion;
  const toUnits = project.units === type.system.metric ? 'mm' : 'in';
  if (extrusion.nonFlatback) {
    return [
      {
        baseLength: convertValueUnitsTo({ value: extrusion.dimensions.length, to: toUnits }).toFixed(3),
        width: convertValueUnitsTo({ value: extrusion.dimensions.width, to: toUnits }).toFixed(3),
        height: convertValueUnitsTo({ value: extrusion.dimensions.height, to: toUnits }).toFixed(3),
        weightPerFeet: convertValueUnitsTo({ value: extrusion.weightPerFeet, to: project.units === type.system.metric ? 'kg/m' : 'lb/ft' }).toFixed(3),
      },
    ];
  } else {
    return [
      {
        baseLength: convertValueUnitsTo({ value: extrusion.dimensions.length, to: toUnits }).toFixed(3),
        width: convertValueUnitsTo({ value: extrusion.dimensions.width, to: toUnits }).toFixed(3),
        height: convertValueUnitsTo({ value:extrusion.dimensions.height, to: toUnits }).toFixed(3),
        baseWidth: convertValueUnitsTo({ value: extrusion.baseWidth, to: toUnits }).toFixed(3),
        baseThick: convertValueUnitsTo({ value: extrusion.baseThick, to: toUnits }).toFixed(3),
        finThick: convertValueUnitsTo({ value: extrusion.finThick, to: toUnits }).toFixed(3),
        finHeight: convertValueUnitsTo({ value: extrusion.finHeight, to: toUnits }).toFixed(3),
        numFins: extrusion.numFins,
        finPitch: convertValueUnitsTo({ value: extrusion.finPitch, to: toUnits }).toFixed(3),
      },
    ];
  }
};
