import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Checkbox, Modal, Row, Form, Input, Col, Switch, InputNumber } from 'antd';
import { Page, Text, View, Document, StyleSheet, pdf, Image, Link} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { authState } from '../../../state/auth';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { userState } from '../../../state/user';
import { customersState } from '../../../state/customers';
import { useState } from 'react';
import { type } from '../../../state/units';
import { addActivity } from '../../../state/activity';
import { CustomerSelect } from '../../customer';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const {Item} = Form;
// import ARIAL from '../../../fonts/ARIAL.TTF';
// import ARIALBD from '../../../fonts/ARIALBD 1.TTF';
// import ARIALBI from '../../../fonts/ARIALBI.TTF';

// const registerFont = () => {
//   Font.register({
//     family: 'Arial',
//     fonts: [
//       {
//         src: ARIAL,
//       },
//       {
//         src: ARIALBD,
//         fontWeight: 'bold',
//       },
//       {
//         src: ARIALBI,
//         fontWeight: 'bold',
//         fontStyle: 'italic',
//       }
//     ]
//   })
// };

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const salesLayout = {
  labelCol: { span: 16 },
  wrapperCol: { span: 8 },
};

const noteLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const autoSelectedNotes = [
  'Cut off length quoted as the greater of customer print tolerance or +/- 0.015 [0.38mm].',
  'Quoting as standard extrusion tolerance on all extruded features with Aluminum Association standard mill finish.',

  'Quoted as a wash/etch finish.',
  'Quoted as Standard Chem Film - RoHS Compliant AavShield 3.',
  'Quoted as Chromate - Non-RoHS finish per MIL-DTL-5541 Type I.',
  'Quoted as Black Anodize finish per intent of MIL-A-8625 TYPE II CLASS 2.',
  'Quoted as Clear Anodize finish per intent of MIL-A-8625 TYPE II CLASS 1.',
  'Quoted Mill as extruded finish.',

  'Class A - Pricing and lead-time based on current material availability, not guaranteed at time of order.',
  'Class C - Pricing and lead-time based on current material availability, not guaranteed at time of order. If no material in stock, an MOQ will be required.'
];

const userSelectedNotes = [];

const finishCodes = {
  'U': 'ETCHWASH',
  'V': 'AAVSHIELD3',
  'C': 'CHROMATE',
  'B': 'ANODIZE',
  'N': 'ANODIZECLEAR',
  'F': 'MILLFINISH',
}

const styles = StyleSheet.create({
  page: {
    paddingTop: 120,
    paddingBottom: 65,
    paddingHorizontal: 40,
  },

  pageHeader: {
    top: 10,
    left: 40,
    right: 40,
    position: 'absolute',
  },

  headerSection: {
    fontSize: 9,
    margin: 0,
    backgroundColor: '#DEE5EC',
    padding: 5,
    // fontFamily: 'Arial',
  },

  section: {
    fontSize: 9,
    margin: 0,
    backgroundColor: '#DEE5EC',
    padding: 5,
    // fontFamily: 'Arial',
    marginTop: 10,
  },

  table: {
    display: 'table',
    width: 'auto',
  },

  table1: {
    display: 'table',
    width: 'auto',
    height: 40
  },

  tableHalfWidthColumn: {
    width: '50%',
  },

  tableFullWidthColumn: {
    width: '100%',
  },

  image: {
    width: 140,
    height: 40,
    marginTop: 10,
  },

  text: {
    fontSize: 8,
    marginTop: 0,
    marginLeft: 0,
    // fontFamily: 'Arial',
  },

  boldText: {
    fontSize: 8,
    marginTop: 0,
    marginLeft: 0,
    // fontFamily: 'Arial',
    // fontWeight: 'bold',
  },

  labelText: {
    width: '30%',
    fontSize: 8,
    marginVertical: 3,
    // fontFamily: 'Arial',
    paddingRight: 10,
  },
  valueText: {
    width: '70%',
    fontSize: 8,
    marginVertical: 3 ,
    // fontFamily: 'Arial',
  },
  noteHeader: {
    width: '100%',
    fontSize: 8,
    marginVertical: 3,
    // fontFamily: 'Arial',
    paddingRight: 10,
  },
  noteText: {
    width: '100%',
    fontSize: 8,
    marginVertical: 3,
    // fontFamily: 'Arial',
    paddingRight: 10,
    marginLeft: 10,
  },

  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
 
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footerText: {
    position: 'absolute',
    fontSize: 8,
    bottom: 40,
    left: 40,
    right: 40,
    // fontFamily: 'Arial',
    textAlign: 'left',
  },
  footerTextBI: {
    position: 'absolute',
    fontSize: 8.5,
    // fontStyle: 'italic',
    // fontWeight: 'bold',
    // fontFamily: 'Arial',
    bottom: 20,
    left: 40,
    right: 40,
    textAlign: 'center',
  },
});

const AddRow = ({ label, value }) => (
  <View style={styles.tableRow}>
    <Text style={styles.labelText}>{label}</Text>
    <Text style={styles.valueText}>{value}</Text>
  </View>
);

const CostingPDFReport = ({ project, userEmail, user, customerName, autoSelectedNotesIds, userSelectedNotesIds, customNote, leadTime, cdfNumber, costEstimates }) => {
  const customNoteLength = customNote ?  1 : 0;
  const todayDate = dayjs();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        
        <View style={styles.pageHeader} fixed>
          <Text style={styles.headerSection}>QUOTATION</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableHalfWidthColumn}>
                <Image style={styles.image} src="/boyd-trusted-logo.png"/>
                <Text style={{ ...styles.text, marginLeft: 20, marginTop: 10}}>www.boydcorp.com</Text> 
                {/*<Text style={{ ...styles.text, marginLeft: 20, marginTop: 10}}><a href='https://www.boydcorp.com' target="_blank" rel="noreferrer">www.boydcorp.com</a></Text>*/}
              </View>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableHalfWidthColumn}>
                      <Text style={styles.text}>{'\n\n'}</Text>
                      <Text style={styles.boldText}>Manufacturing Address</Text>
                      <Text style={styles.text}>1 Aavid Circle {'\n'} Laconia, NH 03246 {'\n'} +1 (603) 528-3400 {'\n'} Cage Code: 1N6F3</Text> 
                    </View>
                    <View style={styles.tableHalfWidthColumn}>
                      <Text style={styles.text}>{'\n\n'}</Text>
                      <Text style={styles.boldText}>Headquarters Address</Text>
                      <Text style={styles.text}>5960 Inglewood Dr. Ste 115 {'\n'} Pleasanton, CA 94588 {'\n'} +1 (888) 244-6931</Text>
                    </View>
                  </View>    
                </View>  
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.section}>General Information</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Today's Date"} value={todayDate.format('MMMM DD, YYYY')}/>
                <AddRow label={"Expiration Date"} value={todayDate.add(30, 'day').format('MMMM DD, YYYY')}/>
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Quote Number"} value={project.quoteSpecs.quoteHeader.quoteNo ? project.quoteSpecs.quoteHeader.quoteNo : 'NA' }/>
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.section}>Prepared For</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Customer Name"} value={customerName} />
                <AddRow label={"Customer Address"} value={project.quoteSpecs.quoteHeader.customerAddress}/>
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Customer Contact"} value={project.quoteSpecs.quoteHeader.customerContact} />
                <AddRow label={"Customer Email"} value={<Link src={`mailto:${project.quoteSpecs.quoteHeader.customerEmail}`}>{project.quoteSpecs.quoteHeader.customerEmail}</Link>} />
                <AddRow label={"Customer Phone"} value={project.quoteSpecs.quoteHeader.customerPhone} />
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.section}>Boyd Corporation Information</Text>
        { project.quoteSpecs.quoteHeader.useUserContactAsSales ? (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={"Sales Contact"} value={user.name} />
                  <AddRow label={"Sales Phone"} value={user.phone} />
                  <AddRow label={"Sales Email"} value={<Link src={`mailto:${userEmail}`}>{userEmail}</Link>} />
                </View>
              </View>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={"Internal Contact"} value={user.name} />
                  <AddRow label={"Internal Phone"} value={user.phone} />
                  <AddRow label={"Internal Email"} value={<Link src={`mailto:${userEmail}`}>{userEmail}</Link>} />
                </View>
              </View>
            </View>
          </View>
          ) : (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={"Sales Contact"} value={project.quoteSpecs.quoteHeader.salesContact} />
                  <AddRow label={"Sales Phone"} value={project.quoteSpecs.quoteHeader.salesPhone} />
                  <AddRow label={"Sales Email"} value={<Link src={`mailto:${project.quoteSpecs.quoteHeader.salesEmail}`}>{project.quoteSpecs.quoteHeader.salesEmail}</Link>} />
                </View>
              </View>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={"Internal Contact"} value={project.quoteSpecs.quoteHeader.salesContact} />
                  <AddRow label={"Internal Phone"} value={project.quoteSpecs.quoteHeader.salesPhone} />
                  <AddRow label={"Internal Email"} value={<Link src={`mailto:${project.quoteSpecs.quoteHeader.salesEmail}`}>{project.quoteSpecs.quoteHeader.salesEmail}</Link>} />
                </View>
              </View>
            </View>
          </View>
          )
        }

        <Text style={styles.section}>Quote Details</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Customer Part No."} value={project.quoteSpecs.quoteHeader.customerPartNumber ? project.quoteSpecs.quoteHeader.customerPartNumber : 'NA' } />
                <AddRow label={"Boyd CDF number"} value={cdfNumber ? cdfNumber : 'NA'} />
                <AddRow label={"Manufacturing  Location"} value={"Laconia, NH"} />
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"MOQ"} value={costEstimates[0].quantity} />
                <AddRow label={"UOM"} value={'EACH'} />
                <AddRow label={"Production Lead Time"} value={leadTime === undefined || leadTime === null ? 'Inquire at time of order' : leadTime + " Weeks"} />
                <AddRow label={"Ship Location"} value={"Laconia, NH"} />
                <AddRow label={"Country of Origin"} value={"United States"} />   
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.section}>{`QTY & Price Break Line Items`}</Text>
        
          <View style={styles.table}>
          {costEstimates.filter(q => (q.extendedPrice <= 100000.0)).map((q, index) => (
            <View key={nanoid(10)} style={styles.tableRow}>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={`Quantity ${index + 1}`} value={q.quantity} />
                </View>
              </View>
              <View style={styles.tableHalfWidthColumn}>
                <View style={styles.table}>
                  <AddRow label={`Price Break ${index + 1}`} value={`USD ${(q.finalPrice).toFixed(2)}`} />
                  <AddRow label={`Extended Total QTY ${index + 1}`} value={`USD ${(q.extendedPrice).toFixed(2)}`} />
                </View>
              </View>
            </View>     
          ))}
        </View>

        <Text style={styles.section}>{`Notes & Exceptions`}</Text>
        <Text style={styles.noteHeader}>{'Boyd Corp quotes with the following Notes/Exceptions:'}</Text>
        { customNote ? (
          <View style={styles.table}> 
            <View key={`notes-${nanoid(10)}`} style={styles.tableRow}>
              <View style={styles.tableFullWidthColumn}>
                <Text style={styles.noteText}>{`${1}. ${customNote}`}</Text> 
              </View>
            </View>
          </View>
          ) : (
            <></>
          )}
        
        <View style={styles.table}>
          {
            autoSelectedNotesIds.sort((a, b) => a - b).map((noteId, index) => (
              <View key={`notes-${nanoid(10)}`} style={styles.tableRow}>
                <View style={styles.tableFullWidthColumn}>
                  <Text style={styles.noteText}>{`${index + 1 + customNoteLength}. ${autoSelectedNotes[noteId]}`}</Text> 
                </View>
              </View>
            ))
          }
        </View>

        <View style={styles.table}>
          {
            userSelectedNotesIds.sort((a, b) => a - b).map((noteId, index) => (
              <View key={`notes-${nanoid(10)}`} style={styles.tableRow}>
                <View style={styles.tableFullWidthColumn}>
                  <Text style={styles.noteText}>{`${index + 1 + customNoteLength + autoSelectedNotesIds.length}. ${userSelectedNotes[noteId]}`}</Text> 
                </View>
              </View>
            ))
          }
        </View>
        
        
        {/* <Text style={styles.section}>Non-Recurring Service Charges (NRSU)</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"NRSU1"} value={"FAI"} />
                <AddRow label={"NRSU1 Notes"} value={'-'} />
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"NRSU1 Charge"} value={`USD ${project.designs[project.activeDesignId].costEstimates[0].faiCost.toFixed(2)}`} />
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"NRSU2"} value={"Engineer Services"} />
                <AddRow label={"NRSU2 Notes"} value={'NRE'} />
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"NRSU2 Charge"} value={`USD ${(project.designs[project.activeDesignId].costEstimates[0].nreCost - project.designs[project.activeDesignId].costEstimates[0].faiCost).toFixed(2)}`} />
              </View>
            </View>
          </View>
        </View> */}
        

        <Text style={styles.section}>{`Terms & Conditions`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
                <AddRow label={"Payment Terms"} value={"Net 30"} />
              </View>
            </View>
            <View style={styles.tableHalfWidthColumn}>
              <View style={styles.table}>
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.text}>No change to the remittance address and tax ID information that you have on file.</Text>
        {/* <Text style={styles.boldText}>FAI: First Article Requirements will be at an additional charge if required.</Text> */}
        <Text style={styles.text}>Complete Certification Package $250.00 at the time of the order upon request. Cost increases to $500.00 if requested after product shipment.</Text>
        <Text style={styles.text}>Pricing based on shipment lot size, not quantity ordered.</Text>
        <Text style={styles.text}>Any purchase order for this quotation is Non-Cancellable and Non-Returnable.</Text>
        {/*<Text style={styles.text}>Standard extrusion tolerances are quoted on all extruded features.</Text>
        <Text style={styles.boldText}>Shipping Terms:</Text>
        <Text style={styles.text}>Ship date will be confirmed at the time of your order based on current material availability and capacity.</Text>
        <Text style={{...styles.boldText }}>Please sign off on exceptions when placing order by signing quote or an email approval to your Account Manager.</Text>
        <Text style={{...styles.text, fontSize:10}}>Modified pricing at time of PO placement might apply due to tariffs or surcharges, and will be based on commodity
          index fluctuations, tariff regulations and/or increased freight costs.</Text> */}

        {/*<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed /> */}
        <Text style={styles.footerText} fixed>
          We appreciate your interest in Boyd Corporation and thank you for the opportunity to help you solve your design challenges. This quotation and
          any related purchase orders received are governed by the Terms and Conditions found at <Link style={styles.url} src="https://www.boydcorp.com/terms-and-conditions-of-sale"> https://www.boydcorp.com/terms-and-conditions-of-sale </Link>
        </Text>
        <Text style={styles.footerTextBI} fixed>
          One Company, Many Solutions
        </Text>
      </Page>
    </Document>
  )
};

const  NotesModal = ({visible, project, updateProject, okCallback, cancelCallback, cdfNumber, standardPartsIds}) => {
  // const [userCheckedItemsIds, setUserCheckedItemsIds] = useState([]);
  const [autoCheckedItemsIds, setAutoCheckedItemsIds] = useState([]);
  const [customNote, setCustomNote] = useState('');
  const [leadTime, SetLeadTime] = useState(); // lead time in weeks
  // const [quoteNo, setQuoteNO] = useState(project.quoteSpecs.quoteHeader.quoteNo);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // Remove auto selected notes ids from current selection
    // var alreadyCheckedItemsIds = checkedItemsIds.filter(itemId => !(autoSelectedNotesIds.includes(itemId)));

    // alreadyCheckedItemsIds.push(engineeringReviewNoteId); // Always select engineering review note

    var autoSelectedNotesIds = [...Array(2).keys()];

    const finish = finishCodes[cdfNumber[6]];

    // Select finish note
    if (finish === type.finishStyle.etchwash) {
      autoSelectedNotesIds.push(2);
    } else if (finish === type.finishStyle.aavshield3) {
      autoSelectedNotesIds.push(3);
    }  else if (finish === type.finishStyle.chromate) {
      autoSelectedNotesIds.push(4);
    }  else if (finish === type.finishStyle.anodize) {
      autoSelectedNotesIds.push(5);
    }  else if (finish === type.finishStyle.anodizeclear) {
      autoSelectedNotesIds.push(6);
    } else if (finish === type.finishStyle.millfinish) {
      autoSelectedNotesIds.push(7);
    }

    if (cdfNumber && standardPartsIds.length > 1) {
      const partNumber = cdfNumber.slice(0, 5);
      const partData = standardPartsIds.filter(part => part.value === partNumber);
      if (partData.length > 0) {
        if (partData[0].label.split(',')[1].trim() === 'C') {
          autoSelectedNotesIds.push(9);
        } else if(partData[0].label.split(',')[1].trim() === 'A') {
          autoSelectedNotesIds.push(8);
        }
      }
    }

    setAutoCheckedItemsIds(autoSelectedNotesIds);
    // setQuoteNO(project.quoteSpecs.quoteHeader.quoteNo);

  }, [visible]);

  // const onChange = (checkedValues) => {
  //   setUserCheckedItemsIds( [...checkedValues] )
  // }

  const onItemChange = (value) => updateProject({ path: value[0].name[0], value: value[0].value });

  return(
    <Modal
      title={`Customer Quote PDF`}
      visible={visible}
      closable={false}
      centered
      width={900}
      bodyStyle={{ height: '640px', overflowY: 'auto', paddingTop: 4, paddingBottom: 0}}
      footer={[
        <Button key="costing-notes-modal-cancel" onClick={cancelCallback}>Cancel</Button>,
        <Button key="costing-notes-modal-generate-pdf" type="primary" onClick={(e) => okCallback(autoCheckedItemsIds, [], customNote, leadTime)}>Generate PDF</Button>
      ]}
    >   
      <Form name="Generate-customer-lite-costing-pdf-modal" onFieldsChange={onItemChange} {...layout} >
        <Row gutter={8}>
          <Col span={12}>
            <Item
              name="quoteSpecs.quoteHeader.quoteNo"     
              initialValue={project.quoteSpecs.quoteHeader.quoteNo}
              label="Opportunity number"
            >
              <Input/>
            </Item>
          </Col>
          <Col span={12}>
            <Item name="customerId" initialValue={project.customerId} label="Customer">
              <CustomerSelect projectId={project.id} />
            </Item>  
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Item   
              name="quoteSpecs.quoteHeader.customerContact"
              label="Customer name"
              initialValue={project.quoteSpecs.quoteHeader.customerContact}
            >
              <Input/>
            </Item>
          </Col>
          <Col span={12}>
            <Item   
              name="quoteSpecs.quoteHeader.customerEmail"
              label="Customer email"
              initialValue={project.quoteSpecs.quoteHeader.customerEmail}
              rules={[{ type: 'email', message: 'Enter valid email!'}]}
            >
              <Input/>
            </Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Item   
              name="quoteSpecs.quoteHeader.customerPhone"
              label="Customer phone"
              initialValue={project.quoteSpecs.quoteHeader.customerPhone}
            >
              <Input/>
            </Item>
          </Col>
          <Col span={12}>
            <Item   
              name="quoteSpecs.quoteHeader.customerAddress"
              label="Customer address"
              initialValue={project.quoteSpecs.quoteHeader.customerAddress}
            >
              <Input.TextArea/>
            </Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Item  
              { ...salesLayout} 
              name="quoteSpecs.quoteHeader.useUserContactAsSales"
              label="Use Genie user info as sales contact"
              initialValue={project.quoteSpecs.quoteHeader.useUserContactAsSales}
              valuePropName="checked"
            >
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Item>
          </Col>
          <Col span={12}>
            <Item   
              name="quoteSpecs.quoteHeader.salesContact"
              label="Sales contact name"
              initialValue={project.quoteSpecs.quoteHeader.salesContact}
              hidden={project.quoteSpecs.quoteHeader.useUserContactAsSales}
            >
              <Input/>
            </Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Item   
              name="quoteSpecs.quoteHeader.salesEmail"
              label="Sales email"
              initialValue={project.quoteSpecs.quoteHeader.salesEmail}
              hidden={project.quoteSpecs.quoteHeader.useUserContactAsSales}
              rules={[{ type: 'email', message: 'Enter valid email!'}]}
            >
              <Input/>
            </Item>
          </Col>
          <Col span={12}>
            <Item   
              name="quoteSpecs.quoteHeader.salesPhone"
              label="Sales phone"
              initialValue={project.quoteSpecs.quoteHeader.salesPhone}
              hidden={project.quoteSpecs.quoteHeader.useUserContactAsSales}
            >
              <Input/>
            </Item>
          </Col>
        </Row>
        <Item 
          { ...noteLayout }
          label="Custom Note"
        >
          <Input.TextArea 
            placeholder="Enter custom note" 
            style={{ height: 32 }} 
            value={customNote} 
            onChange={(e) => setCustomNote(e.target.value)}
          />
        </Item>  
        <Item 
          { ...noteLayout }
          label="Lead time (weeks)"
        >
          <InputNumber
            value={leadTime} 
            min={0}
            onChange={(val) => SetLeadTime(val)}
          />
        </Item>   
      </Form>
      <h4 style={{marginBottom: 0, marginTop:7}}>Automatic selections</h4>
      <Checkbox.Group style={{ width: '100%' }} value={autoCheckedItemsIds} >
        {autoSelectedNotes.map((note, id) => 
          <Row key={`costing-auto-notes-${id}`}>
            <Checkbox className={"costing-notes-checkbox"} value={id} disabled={true}>{note}</Checkbox>
          </Row>  
        )}
      </Checkbox.Group>
      {/* <h4 style={{marginBottom: 0, marginTop:7}}>User selections</h4>
      <Checkbox.Group style={{ width: '100%' }} value={userCheckedItemsIds} onChange={onChange}>
        {userSelectedNotes.map((note, id) => 
          <Row key={`costing-user-notes-${id}`}>
            <Checkbox value={id}>{note}</Checkbox>
          </Row>  
        )}
      </Checkbox.Group>   */}
    </Modal>
  )
}


export const GenerateCustomerLiteQuotePDF = ({ project, updateProject, cdfNumber, costEstimates, standardPartsIds}) => {
  const [showNotes, setShowNotes] = useState(false)
  const auth = useRecoilValue(authState);
  const user = useRecoilValue(userState(auth.username));
  const customers = useRecoilValue(customersState(null));
  const customer = customers.filter(({ id }) => id === project.customerId); 
  const userEmail = auth.email;
  // Get user name from User email
  // const emailPrefix = userEmail.split('@')[0].split('.').map(n => n ? n[0].toUpperCase() + n.toLowerCase().slice(1): '');
  // const userName = emailPrefix.join(' ');

  // useEffect(() => {
  //   registerFont();
  //   console.log("Arial font registered")  
  // }, []);

  const generatePDF = async(autoCheckedNotesIds, userCheckedNotesIds, customNote, leadTime) => {
    setShowNotes(false)
    const doc = <CostingPDFReport project={project} userEmail={userEmail} user={user} customerName={customer.length === 1 ? customer[0].name : 'NA' } autoSelectedNotesIds={[...autoCheckedNotesIds]} userSelectedNotesIds={[...userCheckedNotesIds]} customNote={customNote} leadTime={leadTime} cdfNumber={cdfNumber} costEstimates={costEstimates}/>;
    const asPdf = pdf([]); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, project.quoteSpecs.quoteHeader.quoteNo ? project.quoteSpecs.quoteHeader.quoteNo+'.pdf' : 'costing.pdf');
    addActivity({ 
      activity: type.activityType.prjLiteQuote, 
      userEmail: userEmail, 
      userId: project.userId,
      projectName: project.name,
      projectId: project.id,
      customer: customer.length === 1 ? customer[0].name : '',
      customerId: project.customerId,
     });
  }

  return (
    <>
    <Button
      type="secondary"
      onClick={async () => {
        setShowNotes(true)   
      }}
      disabled={!user.qualified}
    >
      Customer Quote PDF
    </Button>
    <NotesModal visible={showNotes} project={project} updateProject={updateProject} cdfNumber={cdfNumber} standardPartsIds={standardPartsIds} cancelCallback={() => setShowNotes(false)} okCallback={generatePDF}> 
    </NotesModal>
  </>
  );
};
