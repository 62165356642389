import { useEffect, useState } from 'react';
import { Hub } from '@aws-amplify/core';

export const MESSAGES = {
  SAVING: 'Saving...',
  SAVED: 'Saved',
  ERROR: 'Not saved'
};

export const setMessage = ({ event = null, data = null, message = '' } = {}) => {
  Hub.dispatch('message', { event, data, message });
};

export const useMessage = () => {
  const [message, setMessage] = useState('');

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const callback = ({ payload }) => setMessage(MESSAGES[payload.message]);
    Hub.listen('message', callback);
    return () => Hub.remove('message', callback);
  }, []);

  return message;
};
