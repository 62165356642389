import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { API } from '@aws-amplify/api';
import { onCreateCustomer, onDeleteCustomer, onUpdateCustomer } from '../../graphql/subscriptions';
import { customersState } from '../../state/customers';
import { authState } from '../../state/auth';
import { alphaSort } from '../../utils/alpha-sort';

export function useCustomersSubscribe() {
  const auth = useRecoilValue(authState);
  const setCustomers = useSetRecoilState(customersState(null));

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const onCreateCustomerListener = API.graphql({
      query: onCreateCustomer,
      variables: {
        owner: auth.username,
      },
    }).subscribe({
      next: (result) => {
        const createdCustomer = result.value.data.onCreateCustomer;
        setCustomers((prevCustomers) => [createdCustomer, ...prevCustomers].sort((a, b) => alphaSort(a.name, b.name)));
      },
    });

    const onUpdateCustomerListener = API.graphql({
      query: onUpdateCustomer,
      variables: {
        owner: auth.username,
      },
    }).subscribe({
      next: (result) => {
        const updatedCustomer = result.value.data.onUpdateCustomer;
        setCustomers((prevCustomers) =>
          prevCustomers
            .map((customer) => (customer.id === updatedCustomer.id ? updatedCustomer : customer))
            .sort((a, b) => alphaSort(a.name, b.name)),
        );
      },
    });

    const onDeleteCustomerListener = API.graphql({
      query: onDeleteCustomer,
      variables: {
        owner: auth.username,
      },
    }).subscribe({
      next: (result) => {
        const deletedCustomer = result.value.data.onDeleteCustomer;
        setCustomers((prevCustomers) => prevCustomers.filter((customer) => customer.id !== deletedCustomer.id));
      },
    });

    return () => {
      onCreateCustomerListener.unsubscribe();
      onUpdateCustomerListener.unsubscribe();
      onDeleteCustomerListener.unsubscribe();
    };
  }, [auth.username]);
}
