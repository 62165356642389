import { initialExtrusionModuleState, initialStandardExtrusionModuleState } from './modules';
import { pressure, temperature, type } from './units';
import { atom } from 'recoil';

export const initialSimulationStatus = {
  progress: 0,
  message: '',
};

export const initialLiteCost = {
  liteCostEstimates: [],
  costedCDF: '',
  isDirty: false,
}

export const initialSolution = {
  simulationTime: 0.0,
  maximumTemperature: temperature,
  fluidPressureDrop: pressure,
  fluidExitTemperature: temperature,
  perHeatSource: [],
  createdAt: new Date().toISOString(),
};

export const initialDesignState = {
  name: '',
  description: '',
  type: type.design.extrusion,
  extrusion: initialExtrusionModuleState,
  base: null,
  fins: null,
  heatPipe: null,
  lcp: null,
  status: type.designStatus.incomplete,
  simulationStatus: initialSimulationStatus,
  solution: initialSolution,
  costEstimates: [],
  liteCosting: initialLiteCost,
  viewState: JSON.stringify(''),
  createdAt: new Date().toISOString(),
};

export const initialCustomerDesignState = {
  ...initialDesignState,
  name: 'customer',
};

export const initialStandardDesignState = {
  ...initialDesignState,
  extrusion: initialStandardExtrusionModuleState,
  name: 'standard'
};

export const initialOptimalDesignState = {
  ...initialDesignState,
  name: 'optimal',
};

export const initialCustomDesignState = {
  ...initialDesignState,
  name: 'custom',
};

export const designsState = atom({
  key: 'designsState',
  default: initialDesignState,
});
