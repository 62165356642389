import { Card, Form, Popover, Space, Switch, Radio } from 'antd';
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Measurement } from '../../common';
import { altitude, pressure, resistance, temperature, power, type } from '../../../state/units';
import { 
  ambientTemperatureHelp, 
  useMaxDPHelp, 
  maxPressureDropHelp, 
  maxTemperatureRiseHelp, 
  maxthermalResistanceHelp, 
  operatingAltitudeHelp, 
  totalLoadHelp, 
  workingEnvelopeHeightHelp, 
  workingEnvelopeHelp, 
  workingEnvelopeLengthHelp, 
  workingEnvelopeWidthHelp 
} from '../../common/tooltip';

const { Item } = Form;

// const optimizeSizeHelp = (
//   <HelpCard title="Optimize Size" width={360}>
//     Switching this option ON allows Genie to reduce the size of the heat sink from the provided Working Envelope
//     dimensions. This optimizes volume and mass while still meeting the required thermal performance.
//   </HelpCard>
// );

export const SpecificationsPanel2 = ({ designIndex, project }) => {
  return (
    <>
      <Card title="Customer Thermal">
        <Item
          name="specifications.thermalSpecificationsProvided"
          initialValue={project.specifications.thermalSpecificationsProvided}
          label="Customer thermal information provided"
          style={{ marginBottom: 0 }}
          valuePropName="checked"
        >
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
        </Item>
      </Card>
      {project.specifications.thermalSpecificationsProvided && (
        <>
          <Card title="Environment" bordered={false}>
            <Item label="Ambient temperature" style={{ marginBottom: 0 }}>
              <Space> 
                <Item
                  name="specifications.ambientTemperature"
                  initialValue={project.specifications.ambientTemperature}   
                >
                  <Measurement.Temperature minimum={temperature} />
                </Item>
                <Item>
                  <Popover placement="right" content={ambientTemperatureHelp}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Item>
              </Space>
            </Item>
            <Item label="Operating altitude" style={{ marginBottom: 0 }}>
              <Space> 
                <Item
                  name="specifications.altitude"
                  initialValue={project.specifications.altitude}
                  
                >
                  <Measurement.Altitude minimum={altitude} />
                </Item>
                <Item>
                  <Popover placement="right" content={operatingAltitudeHelp}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Item>
              </Space>
            </Item>
            <Item
              name="specifications.includeRadiation"
              initialValue={project.specifications.includeRadiation}
              valuePropName="checked"
              label="Include radiation"
            >
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Item>
          </Card>
          <Card 
            title={"Working Envelope"}
            bordered={false} 
            extra={
              <Popover placement="right" content={workingEnvelopeHelp}>
                <QuestionCircleOutlined />
              </Popover>
            }
          >
            <Item
              name="specifications.useCustomerExtrusionMaxSize"
              initialValue={project.specifications.useCustomerExtrusionMaxSize}
              label="Use extrusion dimensions"
              valuePropName="checked"
              hidden={!project.specifications.customerExtrusionProvided}
            >
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Item>
            {project.specifications.customerExtrusionProvided && project.specifications.useCustomerExtrusionMaxSize ? (
              <>
                <Item label="Width" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item>
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled
                        value={project.designs[0].extrusion.dimensions.width}
                      />
                    </Item>
                    <Item>
                      <Popover placement="right" content={workingEnvelopeWidthHelp}>
                        <QuestionCircleOutlined />
                      </Popover>
                    </Item>
                  </Space>
                </Item>
                <Item label="Height" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item>
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled
                        value={project.designs[0].extrusion.dimensions.height}
                      />
                    </Item>
                    <Item>
                      <Popover placement="right" content={workingEnvelopeHeightHelp}>
                        <QuestionCircleOutlined />
                      </Popover>
                    </Item>
                  </Space>
                </Item>
                <Item label="Length" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item>
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled
                        value={project.designs[0].extrusion.dimensions.length}
                      />
                    </Item>
                    <Item>
                      <Popover placement="right" content={workingEnvelopeLengthHelp}>
                        <QuestionCircleOutlined />
                      </Popover>
                    </Item>
                  </Space>
                </Item>
              </>
            ) : (
              <>
                <Item label="Width" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name="specifications.maxSize.width"
                      initialValue={project.specifications.maxSize.width}
                    >
                      <Measurement.Dimension minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }} />
                    </Item>
                    <Item>
                      <Popover placement="right" content={workingEnvelopeWidthHelp}>
                        <QuestionCircleOutlined />
                      </Popover>
                    </Item>
                  </Space>
                </Item>

                <Item label="Height" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name="specifications.maxSize.height"
                      initialValue={project.specifications.maxSize.height}
                    >
                      <Measurement.Dimension minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }} />
                    </Item>
                    <Item>
                      <Popover placement="right" content={workingEnvelopeHeightHelp}>
                        <QuestionCircleOutlined />
                      </Popover>
                    </Item>
                  </Space>
                </Item>

                <Item label="Length" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name="specifications.maxSize.length"
                      initialValue={project.specifications.maxSize.length}
                    >
                      <Measurement.Dimension minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }} />
                    </Item>
                    <Item>
                      <Popover placement="right" content={workingEnvelopeLengthHelp}>
                        <QuestionCircleOutlined />
                      </Popover>
                    </Item>
                  </Space>
                </Item>
              </>
            )}
            {/* <Item label="Optimize size" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="specifications.optimizeSize"
                  initialValue={project.specifications.optimizeSize}
                  valuePropName="checked"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Popover placement="right" content={optimizeSizeHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item> */}
          </Card>
          <Card title="Design Constraints" bordered={false}>
            <Item
              name="specifications.thermalConstraintType"
              initialValue={project.specifications.thermalConstraintType}
              label="Specify thermal constraint as"
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={[
                  { label: 'Max temperature rise', value: type.thermalConstraintType.temperature },
                  { label: 'Max thermal resistance', value: type.thermalConstraintType.thermalResistance },
                ]}
              />
            </Item>
            <Item label="Max temperature rise" hidden={project.specifications.thermalConstraintType === type.thermalConstraintType.thermalResistance} style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="specifications.maxTemperatureRise"
                  initialValue={project.specifications.maxTemperatureRise}
                >
                  <Measurement.Temperature minimum={{ ...temperature, value: 0.1 }} />
                </Item>
                <Item>
                  <Popover placement="right" content={maxTemperatureRiseHelp}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Item>
              </Space>
            </Item>

            <Item label="Max thermal resistance" hidden={project.specifications.thermalConstraintType === type.thermalConstraintType.temperature} style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="specifications.maxThermalResis"
                  initialValue={project.specifications.maxThermalResis}
                >
                  <Measurement.ThermalResistance minimum={resistance} />
                </Item>
                <Item>
                  <Popover placement="right" content={maxthermalResistanceHelp}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Item>
              </Space>
            </Item>

            <Item label="Use max pressure drop" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="specifications.useMaxDP"
                  initialValue={project.specifications.useMaxDP}
                  valuePropName="checked"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Item>
                  <Popover placement="right" content={useMaxDPHelp}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Item>
              </Space>
            </Item>

            <Item label="Max pressure drop" hidden={!project.specifications.useMaxDP} style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="specifications.maxPressureDrop"
                  initialValue={project.specifications.maxPressureDrop}
                >
                  <Measurement.Pressure minimum={pressure} />
                </Item>
                <Item>
                  <Popover placement="right" content={maxPressureDropHelp}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Item>
              </Space>
            </Item>
          </Card>
          <Card title="Total Load of All Devices" bordered={false}>

            <Item label="Total load" style={{ marginBottom: 0 }}>
              <Space>
                <Item name="specifications.totalLoad" initialValue={project.specifications.totalLoad} >
                  <Measurement.Power minimum={{ ...power, value: 0.1 }} />
                </Item>
                <Item>
                  <Popover placement="right" content={totalLoadHelp}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Item>
              </Space>
            </Item>
          </Card>
        </>
      )}
    </>
  );
};
