import { altitude, dimension, power, pressure, size, temperature, resistance, type } from './units';

export const initialProjectSpecifications = {
  ambientTemperature: { ...temperature, value: 25.0, units: 'C' },
  altitude: { ...altitude, value: 0.0, units: 'm' },
  includeRadiation: true,
  optimizeSize: false,
  thermalConstraintType: type.thermalConstraintType.temperature,
  maxTemperatureRise: { ...temperature, value: 75.0, units: 'C' },
  maxThermalResis: { ...resistance, value:7.5, units: 'C/W'},
  useMaxDP: false,
  maxPressureDrop: { ...pressure, value: 10.0, units: 'Pa' },
  maxSize: {
    ...size,
    width: { ...dimension, value: 100.0, units: 'mm' },
    height: { ...dimension, value: 100.0, units: 'mm' },
    length: { ...dimension, value: 100.0, units: 'mm' },
  },
  totalLoad: { ...power, value: 10.0, units: 'W' },
  thermalSpecificationsProvided: true,
  useCustomerExtrusionMaxSize: true,
  customerExtrusionProvided: true,
  standardExtrusionRequested: false,
  cdfNumberProvided: true,
  cdfNumber: '',
};
