import { useRecoilValue } from 'recoil';
import { isAuthenticated } from '../../state/auth';

export const IsAuth = ({ verify = true, children }) => {
  const isAuth = useRecoilValue(isAuthenticated);
  const verifyCode = localStorage.getItem('verifyCode');
  if (verify) {
    return isAuth && verifyCode ? children : null;
  }
  return isAuth ? children : null;
};
