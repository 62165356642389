import { Card, Form, InputNumber, Switch, Radio, Space, Popover, Input, Select } from 'antd';
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Measurement } from '../../common';
import { dimension, type } from '../../../state/units';
import {
  cosmeticPartHelp,
  unSupportedFinishHelp,
  highlyRegulatedHelp,
  highProjectValueHelp,
  rawExtrusionBarHelp,
  baseThicknessHelp,
  finThicknessHelp,
  finHeightHelp,
  finPitchHelp,
  finNumbersHelp,
  heatsinkLengthHelp,
  heatsinkWidthHelp,
  heatsinkHeightHelp,
  weightByHeightHelp,
  classHelp,
  nonFlatbackExtrusionHelp,
  standardExtrusionRequestedHelp,
  customExtrusionProvidedHelp,
  heatsinkLeftOffsetHelp,
  heatsinkRightOffsetHelp,
  heatsinkHasOffsetHelp,
} from '../../common/tooltip';
import { costingToolAllowed } from '../../../utils/costing-tool-allowed';

const { Item } = Form;
const inputWidth = '432px';

const selectWidth = '108px';

export const SpecificationsPanel1 = ({ project, standardPartsIds }) => {
  const designIndex = 0;
  const finLayoutStyleOptions = [
    { label: 'Number of fins', value: type.finLayoutStyle.numFins },
    { label: 'Fin pitch', value: type.finLayoutStyle.finPitch },
  ];

  const specifyHeightByOptions = [
    { label: 'Overall height', value: type.specifyExtrusionHeightBy.overall },
    { label: 'Fin height', value: type.specifyExtrusionHeightBy.finHeight },
  ];

  return (
    <>
      <Card title="Customer Extrusion">
        <Item label="Customer extrusion provided" style={{ marginBottom: 0 }}>
          <Space>
            <Item
              name="specifications.customerExtrusionProvided"
              initialValue={project.specifications.customerExtrusionProvided}
              valuePropName="checked"
            >
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Item>
            <Popover placement="right" content={customExtrusionProvidedHelp}>
              <Item>
                <QuestionCircleOutlined />
              </Item>
            </Popover>
          </Space>
        </Item>

        <Item
          name="quoteSpecs.quoteHeader.customerPartNumber"
          initialValue={project.quoteSpecs.quoteHeader.customerPartNumber}
          label="Customer P/N"
          style={{ width: inputWidth }}
        >
          <Input />
        </Item>
        <Item style={{ marginBottom: 0 }}>
          <Space size={64}>
            <Item label="Cosmetic part" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="quoteSpecs.finish.cosmetic"
                  initialValue={project.quoteSpecs.finish.cosmetic}
                  valuePropName="checked"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Item>
                  <Popover placement="right" content={cosmeticPartHelp}>
                    <QuestionCircleOutlined />
                  </Popover>
                </Item>
              </Space>
            </Item>
            <Item label="Unsupported surface finish" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="quoteSpecs.finish.nonStandardFinish"
                  initialValue={project.quoteSpecs.finish.nonStandardFinish}
                  valuePropName="checked"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Popover placement="right" content={unSupportedFinishHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Highly regulated" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="quoteSpecs.itarCompliance"
                  initialValue={project.quoteSpecs.itarCompliance}
                  valuePropName="checked"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Popover placement="right" content={highlyRegulatedHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="High project value" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="quoteSpecs.highProjectValue"
                  initialValue={project.quoteSpecs.highProjectValue}
                  valuePropName="checked"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Popover placement="right" content={highProjectValueHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
            <Item label="Raw extrusion bar" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name="quoteSpecs.rawExtrusionBar"
                  initialValue={project.quoteSpecs.rawExtrusionBar}
                  valuePropName="checked"
                >
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Popover placement="right" content={rawExtrusionBarHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
          </Space>
        </Item>

        <div style={{ border: 'thin solid black', padding: '5px' }} hidden={costingToolAllowed(project)}>
          <p style={{ color: 'red', marginBottom: 0 }}>
            <b>
              This tool cannot be used for the costing in the following conditions. Please contact the factory
              estimating team.
            </b>
          </p>
          <ol>
            <li>Cosmetic part</li>
            <li>Unsupported surface finish</li>
            <li>Highly regulated</li>
            <li>High project value</li>
          </ol>
        </div>
      </Card>
      {project.specifications.customerExtrusionProvided && (
        <>
          <Card title="Heat Sink Specifications" bordered={false}>
            <Item style={{ marginBottom: 0 }}>
              <Space size={32}>
                <Item label="Standard extrusion requested" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name="specifications.standardExtrusionRequested"
                      initialValue={project.specifications.standardExtrusionRequested}
                      valuePropName="checked"
                    >
                      <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                    </Item>
                    <Popover placement="right" content={standardExtrusionRequestedHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item label="Non-flatback extrusion" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.nonFlatback`}
                      initialValue={project.designs[designIndex].extrusion.nonFlatback}
                      valuePropName="checked"
                    >
                      <Switch
                        disabled={project.specifications.standardExtrusionRequested}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </Item>
                    <Popover placement="right" content={nonFlatbackExtrusionHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>
              </Space>
            </Item>

            <Item
              label="Standard part number and class"
              style={{ marginBottom: 0 }}
              hidden={!project.specifications.standardExtrusionRequested}
            >
              <Space>
                <Item
                  name={`designs.${designIndex}.extrusion.partId`}
                  initialValue={project.designs[designIndex].extrusion.partId}
                >
                  <Select showSearch style={{ width: selectWidth }} options={standardPartsIds} />
                </Item>
                <Popover placement="right" content={classHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item label="Length" style={{ marginBottom: 0 }}>
              <Space>
                <Item
                  name={`designs.${designIndex}.extrusion.dimensions.length`}
                  initialValue={project.designs[designIndex].extrusion.dimensions.length}
                >
                  <Measurement.Dimension minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }} />
                </Item>
                <Popover placement="right" content={heatsinkLengthHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            {project.designs[designIndex].extrusion.nonFlatback ? (
              <>
                <Item label="Width" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.dimensions.width`}
                      initialValue={project.designs[designIndex].extrusion.dimensions.width}
                    >
                      <Measurement.Dimension disabled={project.specifications.standardExtrusionRequested} />
                    </Item>
                    <Popover placement="right" content={heatsinkWidthHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item label="Height" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.dimensions.height`}
                      initialValue={project.designs[designIndex].extrusion.dimensions.height}
                    >
                      <Measurement.Dimension disabled={project.specifications.standardExtrusionRequested} />
                    </Item>
                    <Popover placement="right" content={heatsinkHeightHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item label="Weight/length" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.weightPerFeet`}
                      initialValue={project.designs[designIndex].extrusion.weightPerFeet}
                    >
                      <Measurement.LinearDensity disabled={project.specifications.standardExtrusionRequested} />
                    </Item>
                    <Popover placement="right" content={weightByHeightHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item
                  label="Perimeter"
                  name={`designs.${designIndex}.extrusion.perimeter`}
                  initialValue={project.designs[designIndex].extrusion.perimeter}
                >
                  <Measurement.Dimension disabled={project.specifications.standardExtrusionRequested} />
               </Item>
              </>
            ) : (
              <>
                <Item label="Width" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.baseWidth`}
                      initialValue={project.designs[designIndex].extrusion.baseWidth}
                    >
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled={project.specifications.standardExtrusionRequested}
                      />
                    </Item>
                    <Popover placement="right" content={heatsinkWidthHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item label="Base thickness" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.baseThick`}
                      initialValue={project.designs[designIndex].extrusion.baseThick}
                    >
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled={project.specifications.standardExtrusionRequested}
                      />
                    </Item>
                    <Popover placement="right" content={baseThicknessHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item
                  name={`designs.${designIndex}.extrusion.specifyHeightBy`}
                  initialValue={project.designs[designIndex].extrusion.specifyHeightBy}
                  label="Specify height by"
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={specifyHeightByOptions}
                    disabled={project.specifications.standardExtrusionRequested}
                  />
                </Item>

                <Item label="Overall height" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.dimensions.height`}
                      initialValue={project.designs[designIndex].extrusion.dimensions.height}
                    >
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled={
                          project.designs[designIndex].extrusion.specifyHeightBy !==
                            type.specifyExtrusionHeightBy.overall || project.specifications.standardExtrusionRequested
                        }
                      />
                    </Item>
                    <Popover placement="right" content={heatsinkHeightHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item label="Fin height" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.finHeight`}
                      initialValue={project.designs[designIndex].extrusion.finHeight}
                    >
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled={
                          project.designs[designIndex].extrusion.specifyHeightBy !==
                            type.specifyExtrusionHeightBy.finHeight || project.specifications.standardExtrusionRequested
                        }
                      />
                    </Item>
                    <Popover placement="right" content={finHeightHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item label="Fin thickness" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.finThick`}
                      initialValue={project.designs[designIndex].extrusion.finThick}
                    >
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled={project.specifications.standardExtrusionRequested}
                      />
                    </Item>
                    <Popover placement="right" content={finThicknessHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item
                  name={`designs.${designIndex}.extrusion.finLayoutStyle`}
                  initialValue={project.designs[designIndex].extrusion.finLayoutStyle}
                  label="Specify fins by"
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={finLayoutStyleOptions}
                    disabled={project.specifications.standardExtrusionRequested}
                  />
                </Item>

                <Item label="Number of fins" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.numFins`}
                      initialValue={project.designs[designIndex].extrusion.numFins}
                    >
                      <InputNumber
                        min={2}
                        disabled={
                          project.designs[designIndex].extrusion.finLayoutStyle !== type.finLayoutStyle.numFins ||
                          project.specifications.standardExtrusionRequested
                        }
                      />
                    </Item>
                    <Popover placement="right" content={finNumbersHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item label="Fin pitch" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item
                      name={`designs.${designIndex}.extrusion.finPitch`}
                      initialValue={project.designs[designIndex].extrusion.finPitch}
                    >
                      <Measurement.Dimension
                        minimum={{ type: 'DIMENSION', value: 0.1, units: 'mm' }}
                        disabled={
                          project.designs[designIndex].extrusion.finLayoutStyle !== type.finLayoutStyle.finPitch ||
                          project.specifications.standardExtrusionRequested
                        }
                      />
                    </Item>
                    <Popover placement="right" content={finPitchHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item label="Has offset" style={{ marginBottom: 0 }}>
                  <Space>
                    <Item    
                      name={`designs.${designIndex}.extrusion.hasOffset`}
                      initialValue={project.designs[designIndex].extrusion.hasOffset}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        disabled={project.specifications.standardExtrusionRequested}
                      />
                    </Item>
                    <Popover placement="right" content={heatsinkHasOffsetHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>
                  
                <Item 
                  label="Left offset" 
                  hidden={!project.designs[designIndex].extrusion.hasOffset}
                  style={{ marginBottom: 0 }}
                >
                  <Space>
                    <Item 
                      name={`designs.${designIndex}.extrusion.leftOffset`}
                      initialValue={project.designs[designIndex].extrusion.leftOffset}
                    >
                      <Measurement.Dimension 
                        disabled={project.specifications.standardExtrusionRequested} 
                        minimum={dimension}
                      />
                    </Item>
                    <Popover placement="right" content={heatsinkLeftOffsetHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>

                <Item 
                  label="Right Offset" 
                  hidden={!project.designs[designIndex].extrusion.hasOffset}
                  style={{ marginBottom: 0 }}
                >
                  <Space>
                    <Item 
                      name={`designs.${designIndex}.extrusion.rightOffset`}
                      initialValue={project.designs[designIndex].extrusion.rightOffset}
                    >
                      <Measurement.Dimension
                        disabled={project.specifications.standardExtrusionRequested} 
                        minimum={dimension} 
                      />
                    </Item>
                    <Popover placement="right" content={heatsinkRightOffsetHelp}>
                      <Item>
                        <QuestionCircleOutlined />
                      </Item>
                    </Popover>
                  </Space>
                </Item>
              </>
            )}

            <Item label="Class" style={{ marginBottom: 0 }} hidden={!project.specifications.standardExtrusionRequested}>
              <Space>
                <Item
                  name={`designs.${designIndex}.extrusion.class`}
                  initialValue={project.designs[designIndex].extrusion.class}
                >
                  <Input disabled />
                </Item>
                <Popover placement="right" content={classHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>
          </Card>
        </>
      )}
    </>
  );
};
