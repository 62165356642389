import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { nanoid } from 'nanoid';
import { Card, Form, InputNumber, Space, Switch, Select, Popover, DatePicker, notification, Input } from 'antd';
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { LayoutWorkspace, LayoutStepPanel } from '../../layout';
import { Measurement, EditableTag } from '../../common';
import { getEstimates, validateExtrusion } from './project-api';
import { ProjectEstimateGrid } from './project-estimate-grid';
import { factoryState } from '../../../state/quote-specifications';
import dayjs from 'dayjs';
import { type, volume } from '../../../state/units';
import { userRoleState } from '../../../state/auth';
import {
  quantityHelp,
  holesViewsHelp,
  holesCountHelp,
  blindHolesHelp,
  blindHolesFacesHelp,
  flycutHelp,
  pocketsHelp,
  counterBoreHelp,
  finGapsHolesHelp,
  finishHelp,
  insertsHelp,
  markingHelp,
  quoteDateHelp,
  finMachiningLengthHelp,
  finMachiningDepthHelp,
  flycutFlatnessRequirementHelp,
  flycutSurfaceRoughnessHelp,
  flycutCustomAreaHelp,
  flycutCustomAreaWidthHelp,
  flycutCustomAreaLengthHelp,
  finMachiningHelp,
} from '../../common/tooltip';

const { Item } = Form;
const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const inputWidth = '400px';
//const selectWidth = '216px';

const steps = [
  { step: 0, title: 'Quote Details' },
  // { step: 1, title: 'Extrusion' },
  { step: 1, title: 'Machining' },
  { step: 2, title: 'Flycut' },
  { step: 3, title: 'Finish & Hardware' },
];

export const stepsTitles = steps.map(step => step.title);

// const destinationOptions = [
//   { label: 'North America', value: 0 },
//   { label: 'Europe', value: 1 },
//   { label: 'Asia', value: 2 },
// ];

const Costing = ({ project, updateProject, show }) => {
  const [loading, setLoading] = useState(false);

  const saveCosting = async () => {
    setLoading(true);
    
    let res = false;
    if (project.activeDesignId !== 1 && !project.designs[project.activeDesignId].extrusion.nonFlatback) { // if flat back extrusion and not a standard design, validate constarint
      res = await validateExtrusion(project);
    } else { // for non flat back extrusion, do not validate constraint.
      res = true;
    }

    if (res) {  // if no contraint violations, get estimates
      const newEstimates = await getEstimates(project);
      updateProject({ path: `designs.${project.activeDesignId}.costEstimates`, value: newEstimates });
      
      if (dayjs().diff(project.quoteSpecs.quoteHeader.date, 'day') > 30) {
        notification.warning({ 
          className:"warning-notification-box",
          description: "Quote date is older than 30 days. Please update the quote date.",
          placement: 'topLeft',
          top: '50%', 
          duration: 10,
        });
      }   
    }
    setLoading(false);
  };

  const onMarginChange = (margin) => {
    const newEstimates = project.designs[project.activeDesignId].costEstimates.map((quote) => {
      const totalCost = quote['totalCost'];
      const sellPrice = parseFloat((totalCost / (1 - margin / 100)).toFixed(3));
      return { ...quote, sellPrice };
    });
    updateProject([
      { path: `designs.${project.activeDesignId}.costEstimates`, value: newEstimates },
      { path: 'quoteSpecs.margin', value: margin },
    ]);
  };

  return (
    <ProjectEstimateGrid
      project={project}
      onChange={onMarginChange}
      updateProject={updateProject}
      saveCosting={saveCosting}
      loading={loading}
    />
  );
};

// const finishColumns = [
//   {
//     key: 'finish',
//     title: 'Finish',
//     dataIndex: 'finish',
//     render: (_, record) => <label style={{ fontWeight: 500 }}>{record.finish}</label>,
//   },
//   {
//     key: 'laconia',
//     title: 'Laconia',
//     dataIndex: 'laconia',
//     align: 'center',
//     width: 80,
//     render: (_, record) =>
//       record.laconia ? (
//         <CheckOutlined style={{ fontWeight: 700, color: 'green' }} />
//       ) : (
//         <CloseOutlined style={{ color: 'red' }} />
//       ),
//   },
//   {
//     key: 'juarez',
//     title: 'Juarez',
//     dataIndex: 'juarez',
//     align: 'center',
//     width: 80,
//     render: (_, record) =>
//       record.juarez ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />,
//   },
//   {
//     key: 'shenzhen',
//     title: 'Shenzhen',
//     dataIndex: 'shenzhen',
//     align: 'center',
//     width: 80,
//     render: (_, record) =>
//       record.shenzhen ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />,
//   },
// ];

// const finishData = [
//   { key: nanoid(10), finish: 'Etch/Wash - RoHS', laconia: true, juarez: false, shenzhen: true },
//   { key: nanoid(10), finish: 'Chromate - RoHS', laconia: true, juarez: true, shenzhen: true },
//   { key: nanoid(10), finish: 'Chromate - Non-RoHS', laconia: true, juarez: true, shenzhen: true },
//   { key: nanoid(10), finish: 'Anodize - RoHS', laconia: true, juarez: true, shenzhen: true },
// ];

const CostingForm = ({ project, factory, selectedStep, show }) => {
  const userRole = useRecoilValue(userRoleState);

  return (
    <>
      <LayoutStepPanel show={show && selectedStep === 0}>
        <Card title="Quote Details" bordered={false}>
          <Item label="Quantities" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.quoteHeader.quantity" initialValue={project.quoteSpecs.quoteHeader.quantity}>
                <EditableTag addLabel="Quantity" />
              </Item>
              <Popover placement="right" content={quantityHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Quote date" style={{ marginBottom: 0 }}>
            <Space>
              <Item   
                name="quoteSpecs.quoteHeader.date"
                initialValue={dayjs(project.quoteSpecs.quoteHeader.date)}
              >
                <DatePicker format="MMMM DD, YYYY" />
              </Item>
              <Popover placement="right" content={quoteDateHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item   
            name="quoteSpecs.quoteHeader.customerContact"
            label="Customer contact name"
            initialValue={project.quoteSpecs.quoteHeader.customerContact}
          >
            <Input/>
          </Item>

          <Item   
            name="quoteSpecs.quoteHeader.customerEmail"
            label="Customer email"
            initialValue={project.quoteSpecs.quoteHeader.customerEmail}
            rules={[{ type: 'email', message: 'Enter valid email!'}]}
          >
            <Input/>
          </Item>

          <Item   
            name="quoteSpecs.quoteHeader.customerPhone"
            label="Customer phone"
            initialValue={project.quoteSpecs.quoteHeader.customerPhone}
          >
            <Input/>
          </Item>

          <Item   
            name="quoteSpecs.quoteHeader.customerAddress"
            label="Customer address"
            initialValue={project.quoteSpecs.quoteHeader.customerAddress}
          >
            <Input.TextArea/>
          </Item>

          <Item   
            name="quoteSpecs.quoteHeader.useUserContactAsSales"
            label="Use Genie user info as sales contact"
            initialValue={project.quoteSpecs.quoteHeader.useUserContactAsSales}
            valuePropName="checked"
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Item>

          <Item   
            name="quoteSpecs.quoteHeader.salesContact"
            label="Sales contact name"
            initialValue={project.quoteSpecs.quoteHeader.salesContact}
            hidden={project.quoteSpecs.quoteHeader.useUserContactAsSales}
          >
            <Input/>
          </Item>

          <Item   
            name="quoteSpecs.quoteHeader.salesEmail"
            label="Sales email"
            initialValue={project.quoteSpecs.quoteHeader.salesEmail}
            hidden={project.quoteSpecs.quoteHeader.useUserContactAsSales}
            rules={[{ type: 'email', message: 'Enter valid email!'}]}
          >
            <Input/>
          </Item>

          <Item   
            name="quoteSpecs.quoteHeader.salesPhone"
            label="Sales phone"
            initialValue={project.quoteSpecs.quoteHeader.salesPhone}
            hidden={project.quoteSpecs.quoteHeader.useUserContactAsSales}
          >
            <Input/>
          </Item>

          {/* <Item label="Destination">
            <Select
              showSearch
              style={{ width: selectWidth }}
              options={destinationOptions}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              defaultValue={0}
            />
          </Item> */}
        </Card>
      </LayoutStepPanel>

      {/*
      <LayoutStepPanel show={selectedStep === 1}>
        <Card title="Extrusion Information" bordered={false} hidden={project.activeDesignId !== 1}>
          <p style={{ textAlign: 'center', marginBottom: 10 }}>
            {' '}
            Part ID : <b>{project.designs[1].extrusion.partId}</b>
          </p>
          <Image
            src={`https://www.boydcorp.com/files/part-documentation/extrusion-${project.designs[1].extrusion.partId}-drawing.png`}
            preview={false}
          />
        </Card>
        
        <Card title="Inventory Details" bordered={false}>
          <Item
            name="quoteSpecs.inventoryStock"
            initialValue={project.quoteSpecs.inventoryStock}
            label="Extrusion length in stock"
            hidden={project.activeDesignId === 1}
          >
            <Measurement.Dimension />
          </Item>
          <Item label="Extrusion length in stock" hidden={project.activeDesignId !== 1}>
            <Measurement.Dimension disabled value={project.quoteSpecs.inventoryStock} />
          </Item>
          <Item
            name="rawMaterialCost"
            initialValue={factory.rawMaterialCost}
            label="Extrusion cost per pound (in US$) "
          >
            <InputNumber min={0.0} />
          </Item>
        </Card>
        
        <Card title="Cut Length" bordered={false}>
          <Item label="Cut length">
            <Space>
              <Item>
                <Measurement.Dimension
                  disabled
                  value={project.designs[project.activeDesignId].extrusion.dimensions.length}
                />
              </Item>
              <Popover placement="right" content={cutLengthHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          {/*
          <Item
            name="quoteSpecs.stackable"
            initialValue={project.quoteSpecs.stackable}
            label="Stackable"
            valuePropName="checked"
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Item>
          
        </Card>
      </LayoutStepPanel>
    */}

      <LayoutStepPanel show={show && selectedStep === 1}>
        <Card title="CNC Operations" bordered={false}>
          <Item label="Number of views having holes, pockets, or other machined features" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.cnc.sides" initialValue={project.quoteSpecs.cnc.sides}>
                <InputNumber min={0} max={6}/>
              </Item>
              <Popover placement="right" content={holesViewsHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Number of holes (all types) in the part" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.cnc.holes" initialValue={project.quoteSpecs.cnc.holes}>
                <InputNumber min={0} />
              </Item>
              <Popover placement="right" content={holesCountHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Number of holes that do not break through (Blind holes)" style={{ marginBottom: 0 }}>
            <Space>
              <Item 
                name="quoteSpecs.finish.blindHoles" 
                initialValue={project.quoteSpecs.finish.blindHoles}
                rules={[
                  {
                    validator: (_, value) =>
                      value <= project.quoteSpecs.cnc.holes ? Promise.resolve() : Promise.reject(new Error('This value must be <= Number of holes (all types) in the part.')),
                  },
                ]}
              >
                <InputNumber min={0} />
              </Item>
              <Popover placement="right" content={blindHolesHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Number of different faces these blind holes are placed on" style={{ marginBottom: 0 }}>
            <Space>
              <Item 
                name="quoteSpecs.finish.blindFaces" 
                initialValue={project.quoteSpecs.finish.blindFaces}
                rules={[
                  {
                    validator: (_, value) =>
                      value <= project.quoteSpecs.cnc.sides ? Promise.resolve() : Promise.reject(new Error('This value must be <= Number of views having holes, pockets, or other machined features.')),
                  },
                ]}
              >
                <InputNumber min={0} max={6}/>
              </Item>
              <Popover placement="right" content={blindHolesFacesHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Number of counterbores in the fins" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.counterBores.cbores" initialValue={project.quoteSpecs.counterBores.cbores}>
                <InputNumber min={0} />
              </Item>
              <Popover placement="right" content={counterBoreHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>
          
          <Item label="Number of fin gaps having hole or counterbore break through" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.sandGaps" initialValue={project.quoteSpecs.sandGaps}>
                <InputNumber min={0} />
              </Item>
              <Popover placement="right" content={finGapsHolesHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Total volume of pockets" style={{ marginBottom: 0 }} hidden={userRole === type.userRole.sales}>
            <Space>
              <Item name="quoteSpecs.cnc.pocketVolume" initialValue={project.quoteSpecs.cnc.pocketVolume}>
                <Measurement.Volume minimum={volume} />
              </Item>
              <Popover placement="right" content={pocketsHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item
            name="quoteSpecs.counterBores.addTime"
            initialValue={project.quoteSpecs.counterBores.addTime}
            label="Custom CNC time per part (minutes)"
            hidden={userRole === type.userRole.sales}
          >
            <InputNumber min={0.0} />
          </Item>

        </Card>
        <Card 
          title = {
            <Space>
              <div>Fin machining</div>
              <Popover placement="right" content={finMachiningHelp}>
                <QuestionCircleOutlined /> 
              </Popover>
            </Space>
          }
          bordered={false} 
          hidden={userRole === type.userRole.sales}
        >
          <Item label="Total length of fins removed" style={{ marginBottom: 0 }}> 
            <Space>
              <Item
              name="quoteSpecs.finMachining.length"
              initialValue={project.quoteSpecs.finMachining.length}
              >
                <Measurement.Dimension />
              </Item>
              <Popover placement="right" content={finMachiningLengthHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>
           
          <Item label="Average depth of fins removed" style={{ marginBottom: 0 }}>
            <Space>
              <Item
                name="quoteSpecs.finMachining.depth"
                initialValue={project.quoteSpecs.finMachining.depth}
              >
                <Measurement.Dimension />
              </Item>
              <Popover placement="right" content={finMachiningDepthHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>
        </Card>
      </LayoutStepPanel>

      <LayoutStepPanel show={show && selectedStep === 2}>
        <Card title="Flycut" bordered={false}>
          <Item label="Does the print call out a specific flatness requirement on the heat sink base?" style={{ marginBottom: 0 }}>
              <Space>
                <Item name="quoteSpecs.cnc.flycutFlatnessCallout" initialValue={project.quoteSpecs.cnc.flycutFlatnessCallout} valuePropName="checked">
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Popover placement="right" content={flycutFlatnessRequirementHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

            <Item label="Does the print call out a surface roughness less than < Ra 63µinch or 1.6µm or N7 on the heat sink base?" style={{ marginBottom: 0 }}>
              <Space>
                <Item name="quoteSpecs.cnc.flycutRoughnessCallout" initialValue={project.quoteSpecs.cnc.flycutRoughnessCallout} valuePropName="checked">
                  <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Item>
                <Popover placement="right" content={flycutSurfaceRoughnessHelp}>
                  <Item>
                    <QuestionCircleOutlined />
                  </Item>
                </Popover>
              </Space>
            </Item>

          <Item label="Part requires a fly cut (custom requirement, to reduce the base thickness etc.)?" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.cnc.flycut" initialValue={project.quoteSpecs.cnc.flycut} valuePropName="checked">
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
              </Item>
              <Popover placement="right" content={flycutHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Require custom flycut area?" hidden={userRole === type.userRole.sales} style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.cnc.customFlycutArea" initialValue={project.quoteSpecs.cnc.customFlycutArea} valuePropName="checked">
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
              </Item>
              <Popover placement="right" content={flycutCustomAreaHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Flycut width" style={{ marginBottom: 0 }} hidden={!project.quoteSpecs.cnc.customFlycutArea || userRole === type.userRole.sales}>
            <Space>
              <Item
                name="quoteSpecs.cnc.customFlycutWidth"
                initialValue={project.quoteSpecs.cnc.customFlycutWidth}
              >
                <Measurement.Dimension />
              </Item>
              <Popover placement="right" content={flycutCustomAreaWidthHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          <Item label="Flycut length" style={{ marginBottom: 0 }} hidden={!project.quoteSpecs.cnc.customFlycutArea || userRole === type.userRole.sales}>
            <Space>
              <Item
                name="quoteSpecs.cnc.customFlycutDepth"
                initialValue={project.quoteSpecs.cnc.customFlycutDepth}
              >
                <Measurement.Dimension />
              </Item>
              <Popover placement="right" content={flycutCustomAreaLengthHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

        </Card>
      </LayoutStepPanel>

      <LayoutStepPanel show={show && selectedStep === 3}>
        <Card title="Finish" bordered={false}>
          <Item label="Finish" style={{ marginBottom: 0 }}>
            <Space>
              <Item
                name="quoteSpecs.finish.style"
                initialValue={project.quoteSpecs.finish.style}
                style={{ width: inputWidth }}
              >
                <Select>
                  <Option value="ETCHWASH"><b>Etch/Wash - RoHS</b> No finish; Clean part</Option>
                  <Option value="AAVSHIELD3"><b>Chromate - RoHS</b> Clear (AavShield3); MIL-DTL-5541 Type II</Option>
                  <Option value="CHROMATE"><b>Chromate - Non-RoHS</b> Gold or Clear; MIL-DTL-5541 Type I</Option>
                  <Option value="ANODIZE"><b>Anodize - RoHS</b> Black (Class 2); MIL-A-8625 Type II</Option>
                  <Option value="ANODIZECLEAR"><b>Anodize - RoHS</b> Clear (Class 1); MIL-A-8625 Type II</Option>
                </Select>
              </Item>
              <Popover placement="right" content={finishHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>

          {/*<Table size="small" columns={finishColumns} dataSource={finishData} pagination={false} bordered />*/}
        </Card>
        <Card title="Marking" bordered={false}>
          <Item label="Part marking" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.marking" initialValue={project.quoteSpecs.marking} style={{ width: inputWidth }}>
                <Select>
                  <Option value="NONE">None</Option>
                  <Option value="INK">Ink</Option>
                  {/*<Option value="BARCODE">Bar code label</Option>
                  <Option value="LASER">Laser</Option>*/}
                </Select>
              </Item>
              <Popover placement="right" content={markingHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>
        </Card>
        <Card title="Inserts" bordered={false} hidden={userRole === type.userRole.sales}>
          <Item label="Number of inserts in the part" style={{ marginBottom: 0 }}>
            <Space>
              <Item name="quoteSpecs.inserts.insertQty" initialValue={project.quoteSpecs.inserts.insertQty}>
                <InputNumber min={0} />
              </Item>
              <Popover placement="right" content={insertsHelp}>
                <Item>
                  <QuestionCircleOutlined />
                </Item>
              </Popover>
            </Space>
          </Item>
          <Item
            name="quoteSpecs.inserts.insertCost"
            initialValue={project.quoteSpecs.inserts.insertCost}
            label="Average cost of each insert (in US$)"
          >
            <InputNumber min={0.0} />
          </Item>
        </Card>
      </LayoutStepPanel>
    </>
  );
};

export const ProjectCosting = ({ project, updateProject, previousTab, setActiveTab, show }) => {
  const [form] = Form.useForm();
  const [selectedStep, setSelectedStep] = useState(0);
  const [factory, setFactory] = useRecoilState(factoryState({}));

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const updatedVisitedCostingTabs = [...project.quoteSpecs.visitedCostingTabs];
    updatedVisitedCostingTabs.splice(selectedStep, 1, true);
    updateProject({ path: 'quoteSpecs.visitedCostingTabs', value: updatedVisitedCostingTabs }); 
  }, [selectedStep]);

  const onItemChange = (value) => {
    const path = value[0].name[0];
    const contactItems = [
      'customerContact',
      'customerAddress',
      'customerEmail',
      'customerPhone',
      'useUserContactAsSales',
      'salesContact',
      'salesEmail',
      'salesPhone',
    ];
    if (path === 'rawMaterialCost') {
      setFactory({ ...factory, rawMaterialCost: value[0].value });
    } else {
      const updatedItems = [{ path: value[0].name[0], value: value[0].value }]
      const pathArray = path.split('.');
      const itemName = pathArray.length > 1 ? pathArray[pathArray.length - 1] : path;
      if (!contactItems.includes(itemName)) {
        // Make cost estimates isdirty value true for all designs since change in the costing input.
        for (let did = 0; did < 4; did++) {
          if (project.designs[did].costEstimates) {
            const  dirtyEstimates = project.designs[did].costEstimates.map(quote => {return {...quote, isDirty: true}});
            updatedItems.push({path: `designs.${did}.costEstimates`, value: dirtyEstimates})
          }  
        }
      }
      
      updateProject(updatedItems)   
    }
  };

  return (
    <LayoutWorkspace
      steps={steps}
      selectedStep={selectedStep}
      setSelectedStep={setSelectedStep}
      previousTab={previousTab}
      setActiveTab={setActiveTab}
      left={
        <Form form={form} name="costing" layout="vertical" onFieldsChange={onItemChange} {...layout}>
          <CostingForm project={project} factory={factory} selectedStep={selectedStep} show={show} />
        </Form>
      }
      right={<Costing project={project} updateProject={updateProject} show={show} />}
      expandRight={true}
    />
  );
};
