import { Suspense } from 'react';
import './layout-list.less';

export const LayoutList = ({ header, children }) => {
  return (
    <div className="layout-list-wrapper">
      <div className="layout-list-header">{header}</div>
      <div className="layout-list-grid">
        <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
      </div>
    </div>
  );
};
