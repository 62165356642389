import Auth from '@aws-amplify/auth';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authState } from '../../state/auth';
import { userState } from '../../state/user';
import { useLocation } from 'wouter';

export const ReAuth = () => {
  const [, setLocation] = useLocation();
  const [auth, setAuth] = useRecoilState(authState);
  // load user profile
  useRecoilValue(userState(auth?.username));

  const handleSignOut = () => {
    Auth.signOut()
      .catch((error) => console.error(error))
      .finally(() => {
        localStorage.removeItem('verifyCode');
        setAuth(undefined);
        setLocation('/');
      });
  };

  // useEffect reloads the auth if the browser is refreshed
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Auth.currentSession()
      .then((userSession) => {
        const { payload } = userSession.idToken;
        setAuth({
          email: payload['email'],
          username: payload['cognito:username'],
          groups: payload['cognito:groups'],
        });
        const verifyCode = localStorage.getItem('verifyCode');
        if (verifyCode === null) {
          handleSignOut();
        }
      })
      .catch((error) => {
        handleSignOut();
      });
  }, []);

  return null;
};
